import moment from 'moment-timezone';
// import _ from 'lodash';

const Test = () => {
  console.log('Test');
};

const REGEXP = {
  ALL20: '^.{0,20}$',
  PHONE: '^01[0-9]{8,9}$',
  MAX999: '^([1-9][0-9]{0,2}|999)$',
};

const VARIABLES = {
  PAGE_LIMIT: 10,
};

const ENUM = {
  METHODOLOGY: {
    STANDARD: (value) => {
      switch(value) {
        case 'CDM': {
          return 'CDM';
        }
        case 'Domestic': {
          return '국내 방법론';
        }
        case 'Oversea': {
          return '국외 방법론';
        }
        default: {
          return 'none';
        }
      }
    }
  },
  ACTION: {
    STATUS: (value) => {
      switch (value) {
        case 'WritingReductionCert':
        case 'WritingProject': {
          return '임시저장';
        }
        case 'WaitingForAuditReductionCert':
        case 'WaitingForAuditProject': {
          return '1차 심사 대기';
        }
        case 'AuditReductionCert':
        case 'AuditProject': {
          return '1차 심사중';
        }
        case 'ReductionCertRejected':
        case 'ProjectRejected': {
          return '최종 반려';
        }
        case 'RequestImproveReductionCert':
        case 'RequestImproveProject': {
          return '보완 요청';
        }
        case 'ReductionCertApproved':
        case 'ProjectApproved': {
          return '최종 승인';
        }
        case 'WaitingForAuditLaunch': {
          return '착수 심사 대기';
        }
        case 'AuditLaunch': {
          return '착수 심사중';
        }
        case 'LaunchApproved': {
          return '착수 승인';
        }
        case 'AuditCancel': {
          return '사업 중단 요청';
        }
        case 'CancelApproved': {
          return '사업 중단';
        }
        case 'WritingReductionCertForImprove':
        case 'WritingProjectForImprove': {
          return '보완 진행중';
        }
        case 'LaunchRejected': {
          return '착수 심사 반려';
        }
        case 'RequestImproveLaunch': {
          return '착수 보완 요청';
        }
        case 'CanceledReductionCert':
        case 'CanceledProject': {
          return '심사 중단';
        }
        case 'ProjectGiveUp': {
          return '최종 반려';
        }
        case 'ReductionCertGiveUp':
        case 'LaunchGiveUp': {
          return '최종 반려';
        }

        default: {
          return 'none';
        }
      }
    },
    NEXT: (value) => {
      switch (value) {
        case 'None': {
          return 'None';
        }
        case 'AuditReductionCert':
        case 'AuditProject': {
          return '1차 심사 진행';
        }

        case 'ReductionCertRejected':
        case 'ProjectRejected': {
          return '1차 심사 반려';
        }
        case 'ReductionCertApproved':
        case 'ProjectApproved': {
          return '1차 심사 수락';
        }
        case 'RequestImproveReductionCert':
        case 'RequestImproveProject': {
          return '1차 심사 보완 요청';
        }
        case 'CanceledProject': {
          return '사업 중단';
        }
        case 'AuditLaunch': {
          return '착수 심사 진행';
        }
        case 'LaunchRejected': {
          return '착수 심사 반려';
        }
        case 'LaunchApproved': {
          return '착수 심사 승인';
        }
        case 'RequestImproveLaunch': {
          return '착수 심사 보완 요청';
        }
        case 'CancelRejected': {
          return '사업 중단 취소';
        }
        case 'CancelApproved': {
          return '사업 중단 승인';
        }
        case 'ReducionCertApproved': {
          return '1차 심사 수락';
        }
        case 'CanceledReductionCert': {
          return '심사 중단';
        }

        default: {
          return 'none';
        }
      }
    },
    STOP_REASON: (value) => {
      console.log('stopReason', value);
      switch (value) {
        case 'CanNotProceed': {
          return '사업 진행 불가능';
        }
        case 'UserSignout': {
          return '회원 탈퇴';
        }
        case 'Etc': {
          return '기타';
        }
        default: {
          return 'none';
        }
      }
    },
  },
  REDUCTION_ACTION: {
    STATUS: (value) => {
      switch (value) {
        case 'WritingReductionCert':
        case 'WritingProject': {
          return '임시저장';
        }
        case 'WaitingForAuditReductionCert':
        case 'WaitingForAuditProject': {
          return '1차 심사 대기';
        }
        case 'AuditReductionCert':
        case 'AuditProject': {
          return '1차 심사중';
        }
        case 'ReductionCertRejected':
        case 'ProjectRejected': {
          return '최종 반려';
        }
        case 'RequestImproveReductionCert':
        case 'RequestImproveProject': {
          return '보완 요청';
        }
        case 'ReductionCertApproved':
        case 'ProjectApproved': {
          return '최종 승인';
        }
        case 'WaitingForAuditLaunch': {
          return '착수 심사 대기';
        }
        case 'AuditLaunch': {
          return '착수 심사중';
        }
        case 'LaunchApproved': {
          return '착수 승인';
        }
        case 'AuditCancel': {
          return '심사 중단 요청';
        }
        case 'CancelApproved': {
          return '심사 중단';
        }
        case 'WritingReductionCertForImprove':
        case 'WritingProjectForImprove': {
          return '보완 진행중';
        }
        case 'LaunchRejected': {
          return '착수 심사 반려';
        }
        case 'RequestImproveLaunch': {
          return '착수 보완 요청';
        }
        case 'CanceledReductionCert':
        case 'CanceledProject': {
          return '심사 중단';
        }
        case 'ProjectGiveUp': {
          return '최종 반려';
        }
        case 'ReductionCertGiveUp':
        case 'LaunchGiveUp': {
          return '최종 반려';
        }
        default: {
          return 'none';
        }
      }
    },
    NEXT: (value) => {
      switch (value) {
        case 'None': {
          return 'None';
        }
        case 'AuditReductionCert':
        case 'AuditProject': {
          return '1차 심사 진행';
        }
        case 'ReductionCertRejected':
        case 'ProjectRejected': {
          return '1차 심사 반려';
        }
        case 'ReductionCertApproved':
        case 'ProjectApproved': {
          return '1차 심사 수락';
        }
        case 'RequestImproveReductionCert':
        case 'RequestImproveProject': {
          return '1차 심사 보완 요청';
        }
        case 'CanceledProject': {
          return '심사 중단';
        }
        case 'AuditLaunch': {
          return '착수 심사 진행';
        }
        case 'LaunchRejected': {
          return '착수 심사 반려';
        }
        case 'LaunchApproved': {
          return '착수 심사 승인';
        }
        case 'RequestImproveLaunch': {
          return '착수 심사 보완 요청';
        }
        case 'CancelRejected': {
          return '심사 중단 취소';
        }
        case 'CancelApproved': {
          return '심사 중단 승인';
        }
        case 'ReducionCertApproved': {
          return '1차 심사 수락';
        }
        case 'CanceledReductionCert': {
          return '심사 중단';
        }

        default: {
          return 'none';
        }
      }
    },
    STOP_REASON: (value) => {
      console.log('stopReason', value);
      switch (value) {
        case 'CanNotProceed': {
          return '사업 진행 불가능';
        }
        case 'UserSignout': {
          return '회원 탈퇴';
        }
        case 'Etc': {
          return '기타';
        }
        default: {
          return 'none';
        }
      }
    },
  },
  CREDIT_ACTION: {
    STATUS: (value) => {
      switch (value) {
        case 'WritingZCCredit': {
          return '임시저장';
        }
        case 'WaitingForAuditZCCredit': {
          return '1차 심사 대기';
        }
        case 'AuditZCCredit': {
          return '1차 심사중';
        }
        case 'ZCCreditRejected': {
          return '최종 반려';
        }
        case 'RequestImproveZCCredit': {
          return '보완 요청';
        }
        case 'ZCCreditApproved': {
          return '1차 심사 완료';
        }
        case 'AuditApproved': {
          return '1차 최종 승인';
        }
        case 'WaitingForAuditCommittee': {
          return '2차 심사 대기';
        }
        case 'AuditCommittee': {
          return '2차 심사중';
        }
        case 'CommitteeApproved': {
          return '2차 최종 승인';
        }
        case 'AuditCancel': {
          return '심사 중단 요청';
        }
        case 'CancelApproved': {
          return '심사 중단';
        }
        case 'WritingCommitteeForImprove':
        case 'WritingZCCreditForImprove': {
          return '보완 진행중';
        }
        case 'CommitteeRejected': {
          return '2차 심사 반려';
        }
        case 'RequestImproveCommittee': {
          return '2차 보완 요청';
        }
        case 'CanceledZCCredit': {
          return '심사 중단';
        }
        case 'ZCCreditGiveUp': {
          return '최종 반려';
        }
        case 'CommitteeGiveUp': {
          return '최종 반려';
        }
        default: {
          return 'none';
        }
      }
    },
    NEXT: (value) => {
      switch (value) {
        case 'None': {
          return 'None';
        }
        case 'AuditZCCredit': {
          return '1차 심사 진행';
        }
        case 'ZCCreditRejected': {
          return '1차 심사 반려';
        }
        case 'ZCCreditApproved': {
          return '1차 심사 수락';
        }
        case 'RequestImproveZCCredit': {
          return '1차 심사 보완 요청';
        }
        case 'CanceledZCCredit': {
          return '심사 중단';
        }
        case 'AuditNext': {
          return '2차 심사';
        }
        case 'AuditCommittee': {
          return '2차 심사 진행';
        }
        case 'CommitteeRejected': {
          return '2차 심사 반려';
        }
        case 'CommitteeApproved': {
          return '2차 심사 승인';
        }
        case 'RequestImproveCommittee': {
          return '2차 심사 보완 요청';
        }
        case 'CancelRejected': {
          return '심사 중단 취소';
        }
        case 'CancelApproved': {
          return '심사 중단 승인';
        }
        case 'CanceledReductionCert': {
          return '심사 중단';
        }
        case 'AuditApproved': {
          return '1차 심사 최종 승인';
        }

        default: {
          return 'none';
        }
      }
    },
    STOP_REASON: (value) => {
      console.log('stopReason', value);
      switch (value) {
        case 'CanNotProceed': {
          return '사업 진행 불가능';
        }
        case 'UserSignout': {
          return '회원 탈퇴';
        }
        case 'Etc': {
          return '기타';
        }
        default: {
          return 'none';
        }
      }
    },
  },
  PROJECT: {
    TYPE: (value) => {
      switch (value) {
        case 'New': {
          return '신규';
        }
        case 'Modify': {
          return '변경';
        }
        case 'Move': {
          return '이관';
        }
        default: {
          return 'none';
        }
      }
    },
  },
  AREA: (value) => {
    switch (value) {
      case 'Removal': {
        return '탄소제거';
      }
      case 'Reduction': {
        return '탄소감축';
      }
      default: {
        return 'none';
      }
    }
  },
  SUB_AREA: (value) => {
    switch (value) {
      case 'Forestry': {
        return '산림';
      }
      case 'BioChar': {
        return '바이오차';
      }
      case 'SoilCarbon': {
        return '토양탄소';
      }
      case 'DAC': {
        return 'DAC';
      }
      case 'RenewableEnergy': {
        return '재생에너지';
      }
      case 'EMobility': {
        return 'e-mobility';
      }
      case 'Recycle': {
        return '재활용';
      }
      case 'ReplaceElement': {
        return '대체물질';
      }
      case 'EnergyConversion': {
        return '에너지전환';
      }
      case 'Chemical': {
        return '화학공정';
      }
      case 'Agriculture': {
        return '농업';
      }
      case 'CCS': {
        return 'CCS';
      }
      case 'SecondHand': {
        return '중고거래';
      }
      case 'ReplaceVehicle': {
        return '이동수단 대체';
      }
      case 'Service': {
        return '서비스';
      }
      case 'Etc': {
        return '기타';
      }
      default: {
        return 'none';
      }
    }
  },
  // AREA: (value) => {
  //   switch (value) {
  //     case 'Removal': {
  //       return '탄소 제거';
  //     }
  //     case 'Reduction': {
  //       return '탄소 감축';
  //     }
  //     default: {
  //       return 'none';
  //     }
  //   }
  // },
  // SUB_AREA: (value) => {
  //   switch (value) {
  //     case 'RenewableEnergy': {
  //       return '재생 에너지';
  //     }
  //     case 'MicroGrid': {
  //       return '마이크로그리드';
  //     }
  //     case 'FuelConversion': {
  //       return '연료전환';
  //     }
  //     case 'WaterTreatment': {
  //       return '수처리';
  //     }
  //     case 'Forestry': {
  //       return '산림 조성';
  //     }
  //     case 'ForestManagement': {
  //       return '산림 관리';
  //     }
  //     case 'BioChar': {
  //       return 'BioChar';
  //     }
  //     case 'DAC': {
  //       return 'DAC';
  //     }

  //     case 'EMobility': {
  //       return 'e-mobility';
  //     }
  //     case 'EnergyConversion': {
  //       return '에너지 전환';
  //     }
  //     case 'SmartCity': {
  //       return '스마트 시티';
  //     }
  //     default: {
  //       return 'none';
  //     }
  //   }
  // },
  AUTHORITY: {
    TYPE: (value) => {
      switch (value) {
        case 'AdminUser': {
          return '관리자 권한';
        }
        case 'Methodology': {
          return '방법론 관리';
        }
        case 'Project': {
          return '프로젝트 관리';
        }
        case 'ReductionCert': {
          return '감축량 관리';
        }
        case 'ZCCredit': {
          return '크레딧 관리';
        }
        case 'Trading': {
          return '선도거래 관리';
        }
        case 'Payment': {
          return '결제 관리';
        }
        case 'MarketPlace': {
          return '마켓플레이스';
        }
        case 'Member': {
          return '회원 관리';
        }
        case 'CustomerService': {
          return '고객센터 관리';
        }
        case 'Consultation': {
          return '논평 관리';
        }
        default: {
          return 'none';
        }
      }
    },
  },
  CS: {
    SITE: (value) => {
      switch (value) {
        case 'Credit': {
          return '인증시스템';
        }
        case 'Market': {
          return '마켓플레이스';
        }
        default: {
          return 'none';
        }
      }
    },
    TYPE: (value) => {
      switch (value) {
        case 'Member': {
          return '회원 가입/탈퇴';
        }
        case 'Methodology': {
          return '방법론';
        }
        case 'Project': {
          return '프로젝트 관리';
        }
        case 'ReductionCert': {
          return '감축량 인증';
        }
        case 'ZCCredit': {
          return 'ZC크레딧';
        }
        case 'Etc': {
          return '기타';
        }
        default: {
          return 'none';
        }
      }
    },
    STATUS: (value) => {
      switch (value) {
        case 'Inquiry': {
          return '답변대기';
        }
        case 'Complete': {
          return '답변완료';
        }
        case 'Contact': {
          return '답변대기';
        }
        default: {
          return 'none';
        }
      }
    },
  },
  CREDIT: {
    MODE: (value) => {
      switch (value) {
        case 'Issue': {
          return '발행';
        }
        case 'Move': {
          return '이전';
        }
        case 'Offset': {
          return '철회';
        }
        default: {
          return 'none';
        }
      }
    },
    SMALL_TO_DB: (value) => {
      switch (value) {
        case 'issue': {
          return 'Issue';
        }
        case 'move': {
          return 'Move';
        }
        case 'offset': {
          return 'Offset';
        }
        case 'retire': {
          return 'Retire';
        }
        default: {
          return 'none';
        }
      }
    },
    SMALL_TO_TEXT: (value) => {
      switch (value) {
        case 'issue': {
          return '발행';
        }
        case 'move': {
          return '이전';
        }
        case 'offset': {
          return '철회';
        }
        case 'retire': {
          return '폐기';
        }
        default: {
          return 'none';
        }
      }
    },
  },
  CONSULTATION: {
    CATEGORY: (value) => {
      switch (value) {
        case 'Methodology': {
          return '방법론';
        }
        case 'Project': {
          return '프로젝트';
        }
        case 'Standard': {
          return '팝플 스탠다드';
        }
        case 'Terms': {
          return '레지스트리 이용약관';
        }
      }
    },
  },
};

const formToBody = (formData, formMeta) => {
  console.log('formToBody formData', formData);
  console.log('formToBody formMeta', formMeta);
  let isValidation = true;
  Object.keys(formMeta).forEach((key) => {
    console.log('key', key);
    let target = formMeta[key];
    if (target.required && !formData[key]) {
      console.log('[[[[[required MISSING target key]]]]]]', key);
      isValidation = false;
    }
  });

  if (isValidation) {
    return formData;
  } else {
    return null;
  }
};

const filterToBody = (filterData) => {
  console.log('filterToBody formData', filterData);
  let body = {};
  for (let i in filterData) {
    let target = filterData[i];

    //REMOVE_ALL select box의 all 일 때는 빼줘야 하기 때문
    if (target.value && target.value !== 'SELECT_ALL') {
      body[target.key] = target.value;
    }
  }
  return body;
};

const getYYYYMMDDByUnix = (unix) => {
  //server에서 second로 떨어짐
  let temp_moment = moment(unix * 1000).format('YYYY.MM.DD');
  return temp_moment;
};

const getYYYYMMDDhhmmssByUnix = (unix) => {
  //server에서 second로 떨어짐
  let temp_moment = moment(unix * 1000).format('YYYY.MM.DD HH:mm:ss');
  return temp_moment;
};

const getYYYYMMDDByString = (stringDate) => {
  const parsedDate = new Date(stringDate);

  const year = parsedDate.getFullYear();
  const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
  const day = parsedDate.getDate().toString().padStart(2, '0');

  const formattedDate = `${year}.${month}.${day}`;
  return formattedDate;
};

const isTodayBeforeDate = (stringDate) => {
  const inputDate = new Date(stringDate.replace(/\./g, '-'));
  const today = new Date();
  today.setHours(0, 0, 0, 0);
    
  return inputDate < today;
}

const isTodayAfterDate = (stringDate) => {
  const inputDate = new Date(stringDate.replace(/\./g, '-'));
  const today = new Date();
  today.setHours(0, 0, 0, 0);
    
  return inputDate > today;
}

const isTodayBetweenDate = (stringStartDate, stringEndDate) => {
  const inputStartDate = new Date(stringStartDate.replace(/\./g, '-'));
  const inputEndDate = new Date(stringEndDate.replace(/\./g, '-'));
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return inputStartDate <= today && inputEndDate >= today;
}

const numberWithCommas = (x) => {
  // console.log('numberWithCommas x', x);
  if (!x) {
    return '0';
  }
  if (isNaN(Number(x))) {
    return x;
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

//content

// authority utils

// seq 를 가지고, authoriy Array를 만듬

const buildNavigationListBySeq = (seq_list, authority_info) => {
  console.log('buildNavigationListBySeq seq_list', seq_list);
  console.log('buildNavigationListBySeq authority_info', authority_info);

  let return_array = [];

  for (let i in authority_info) {
    let target_authority = authority_info[i];
    console.log('target_authority', target_authority);

    let exist = false;
    for (let j in seq_list) {
      let target_seq = Number(seq_list[j]);
      if (target_seq === target_authority.seq) {
        exist = true;
      }
    }
    if (exist) {
      return_array.push({ ...target_authority, text: ENUM.AUTHORITY.TYPE(target_authority.type) });
    }
  }

  return return_array;
};

// 디비에서 가져온 값으로 check array 만들때 사용
const buildAuthorityCheckByDB = (authorityInfo, data) => {
  let check = [];

  const dataToList = data.split(',');
  for (let i in authorityInfo) {
    let target = authorityInfo[i];
    if (target.type !== 'AdminUser') {
      let is_checked = false;
      for (let i in dataToList) {
        let authority_target = dataToList[i];
        if (Number(authority_target) === target.seq) {
          is_checked = true;
          break;
        }
      }
      check.push(is_checked);
    }
  }
  return check;
};

// 디비에 넣을 때 사용, data => meta vlaue 매칭
const buildAuthorityValueByDB = (metaValues, data) => {
  let values = [];
  let result = '';

  for (let i in data) {
    let target = data[i];
    if (target) {
      values.push(metaValues[i]);
    }
    result = values.join(', ');
  }
  return result;
};

//초기 check array
const buildAuthorityCheck = (authorityInfo) => {
  let check = [];
  for (let i in authorityInfo) {
    if (authorityInfo[i].type !== 'AdminUser') {
      check.push(false);
    }
  }
  return check;
};

const buildAuthorityLabels = (authorityInfo) => {
  console.log('buildAuthoritylabels authorityInfo', authorityInfo);

  let labels = [];

  for (let i in authorityInfo) {
    if (authorityInfo[i].type !== 'AdminUser') {
      labels.push(ENUM.AUTHORITY.TYPE(authorityInfo[i].type));
    }
  }
  return labels;
};

const buildAuthorityValues = (authorityInfo) => {
  console.log('buildAuthorityValues authorityInfo', authorityInfo);

  let values = [];

  for (let i in authorityInfo) {
    if (authorityInfo[i].type !== 'AdminUser') {
      values.push(authorityInfo[i].seq);
    }
  }
  console.log(">>>>> " + values)
  return values;
};

// 방법론 약관

// 디비에서 가져온 값으로 check array 만들때 사용
const buildTermsCheckByDB = (methodologyInfo, data) => {
  let check = [];

  for (let i in methodologyInfo['terms']) {
    let base_target = methodologyInfo['terms'][i];
    console.log('buildTermsCheckByDB target', base_target);
    //mapper check
    let is_chcekd = false;
    for (let i in data) {
      let db_target = data[i];
      console.log('buildTermsCheckByDB db_target', db_target);

      if (db_target.terms.seq === base_target.seq) {
        is_chcekd = true;
        break;
      }
    }
    check.push(is_chcekd);
  }

  return check;
};

// 디비에 넣을 때 사용, data => meta vlaue 매칭
const buildTermsValueByDB = (metaValues, data) => {
  let values = [];

  for (let i in data) {
    if (data[i]) {
      values.push({
        seq: metaValues[i],
      });
    }
  }
  return values;
};

const buildTermsCheck = (methodologyInfo) => {
  let check = [];
  methodologyInfo['terms'].forEach((element) => {
    check.push(false);
  });
  // for (let i in methodologyInfo['terms']) {
  //   let target = methodologyInfo['terms'][i];
  //   check.push(false);
  // }
  return check;
};

const buildTermsLabels = (methodologyInfo) => {
  let labels = [];

  for (let i in methodologyInfo['terms']) {
    let target = methodologyInfo['terms'][i];
    labels.push(target.title);
  }
  return labels;
};

const buildTermsValues = (methodologyInfo) => {
  let labels = [];

  for (let i in methodologyInfo['terms']) {
    let target = methodologyInfo['terms'][i];
    labels.push(target.seq);
  }
  return labels;
};

//방법론 증빙서류

// 디비에서 가져온 값으로 check array 만들때 사용
const buildEvidenceCheckByDB = (methodologyInfo, data) => {
  let check = [];

  for (let i in methodologyInfo['evidence']) {
    let base_target = methodologyInfo['evidence'][i];
    console.log('base_target', base_target);

    //mapper check
    let is_chcekd = false;
    let is_forced = false;
    for (let i in data) {
      let db_target = data[i];
      // console.log('terms db_target', db_target);

      if (db_target.evidence.seq === base_target.seq) {
        is_chcekd = true;
        is_forced = db_target.isForce;
        break;
      }
    }

    check.push({
      seq: is_chcekd,
      isForce: is_forced,
    });
  }

  return check;
};

// 디비에 넣을 때 사용, data => meta vlaue 매칭
const buildEvidenceValueByDB = (metaValues, data) => {
  let values = [];

  for (let i in data) {
    if (data[i].seq) {
      values.push({
        seq: metaValues[i],
        isForce: data[i].isForce,
      });
    }
  }
  return values;
};

const buildEvidenceCheck = (methodologyInfo) => {
  let check = [];
  methodologyInfo['evidence'].forEach((element) => {
    check.push({
      seq: false,
      isForce: false,
    });
  });
  // for (let i in methodologyInfo['evidence']) {
  //   let target = methodologyInfo['evidence'][i];
  //   check.push({
  //     seq: false,
  //     isForce: false,
  //   });
  // }
  return check;
};

const buildEvidenceLabels = (methodologyInfo) => {
  let labels = [];

  for (let i in methodologyInfo['evidence']) {
    let target = methodologyInfo['evidence'][i];
    labels.push(target.title);
  }
  return labels;
};

const buildEvidenceValues = (methodologyInfo) => {
  let labels = [];

  for (let i in methodologyInfo['evidence']) {
    let target = methodologyInfo['evidence'][i];
    labels.push(target.seq);
  }
  return labels;
};

//address

// const addressToDbData = (value) => {
//   if (value) {
//     return {
//       post: value.daum.zonecode,
//       address: value.daum.roadAddress,
//       addressDetail: value.detail,
//     };
//   } else {
//     return null;
//   }
// };

// const dbDataToAddress = (db_data) => {
//   if (db_data.address && db_data.addressDetail && db_data.post) {
//     return {
//       daum: {
//         zonecode: db_data.post,
//         roadAddress: db_data.address,
//       },
//       detail: db_data.addressDetail,
//     };
//   } else {
//     return null;
//   }
// };

//CS - Category

const buildCategoryMenus = (data, site, isAll) => {
  let menus = [];
  if (isAll) {
    menus.push({
      value: 'SELECT_ALL',
      label: '전체',
    });
  }
  for (let i in data) {
    let target = data[i];

    if (site === 'SELECT_ALL') {
      menus.push({
        value: target.category,
        label: target.category,
      });
    } else {
      if (target.site === site) {
        menus.push({
          value: target.category,
          label: target.category,
        });
      }
    }
  }
  return menus;
};

//old
const dbDateToJsDate = (date) => {
  //db date is setup by Asia/Seoul

  if (date) {
    let subString = date.replace('T', ' ').substring(0, 19);
    let YYYY = subString.substring(0, 4);
    let MM = subString.substring(5, 7);
    let DD = subString.substring(8, 10);
    let HH = subString.substring(11, 13);
    let mm = subString.substring(14, 16);
    let ss = subString.substring(17, 19);
    let returnDate = new Date(0);
    returnDate.setFullYear(YYYY);
    returnDate.setMonth(MM - 1);
    returnDate.setDate(DD);
    returnDate.setHours(HH);
    returnDate.setMinutes(mm);
    returnDate.setSeconds(ss);

    return returnDate;
  } else {
    return null;
  }
};

const dbDateToYYYYMMDDHHMMSS = (date) => {
  if (date) {
    return date.slice(0, 19).replace('T', ' ');
  } else {
    return null;
  }
};

const dbDateToYYYYMMDD = (date) => {
  if (date) {
    return date.slice(0, 10).replace('T', ' ');
  } else {
    return null;
  }
};

const dbDateToYYYYMM = (date) => {
  if (date) {
    return date.slice(0, 7).replace('T', ' ');
  } else {
    return null;
  }
};

const getYYYYMMDDByDate = (date) => {
  let temp_moment = moment(date).format('YYYY.MM.DD');
  return temp_moment;
};

// const getDashedYYYYMMDDByDate = (date) => {
//   let temp_moment = moment(date).format('YYYY-MM-DD');
//   return temp_moment;
// };

const BuildArticleClassname = (value, meta) => {
  if (!value || !meta) {
    return '';
  }
  // console.log('meta', meta);
  let return_classname = value;

  if (meta.className) {
    return_classname += ' ' + meta.className;
  }
  if (meta.type) {
    return_classname += ' ' + meta.type;
  }
  if (meta.disabled) {
    return_classname += ' ' + 'disabled';
  }
  if (meta.readOnly) {
    return_classname += ' ' + 'readOnly';
  }
  return return_classname;
};

const getPublicUrlPath = () => {
  return process.env.REACT_APP_ENV === 'production' ? 'https://pople.credit/' : 'http://dev.pople.credit/';
};

const textEllipsis = (text, limit = 40) => {
  if (!text) return '';
  let adjustedLength = 0;
  for (let i = 0; i < text.length; i++) {
    // 한글인 경우 가중치 적용
    if (text.charCodeAt(i) > 255) {
      adjustedLength += 2;
    } else {
      adjustedLength += 1;
    }

    // 조정된 길이가 제한을 초과하는 경우
    if (adjustedLength > limit) {      
      return `${text.substring(0, i)}...`;
    }
  }

  return text;
};

const Handler = {
  Test: Test,
  REGEXP: REGEXP,
  VARIABLES: VARIABLES,
  ENUM: ENUM,
  formToBody: formToBody,
  filterToBody: filterToBody,
  numberWithCommas: numberWithCommas,
  getYYYYMMDDByUnix: getYYYYMMDDByUnix,
  getYYYYMMDDhhmmssByUnix: getYYYYMMDDhhmmssByUnix,
  getYYYYMMDDByString: getYYYYMMDDByString,
  dbDateToYYYYMM: dbDateToYYYYMM,
  dbDateToYYYYMMDD: dbDateToYYYYMMDD,
  dbDateToYYYYMMDDHHMMSS: dbDateToYYYYMMDDHHMMSS,
  dbDateToJsDate: dbDateToJsDate,
  buildNavigationListBySeq: buildNavigationListBySeq,
  buildAuthorityCheckByDB: buildAuthorityCheckByDB,
  buildAuthorityValueByDB: buildAuthorityValueByDB,
  buildAuthorityCheck: buildAuthorityCheck,
  buildAuthorityLabels: buildAuthorityLabels,
  buildAuthorityValues: buildAuthorityValues,
  buildTermsCheckByDB: buildTermsCheckByDB,
  buildTermsValueByDB: buildTermsValueByDB,
  buildTermsCheck: buildTermsCheck,
  buildTermsLabels: buildTermsLabels,
  buildTermsValues: buildTermsValues,
  buildEvidenceValueByDB: buildEvidenceValueByDB,
  buildEvidenceCheckByDB: buildEvidenceCheckByDB,
  buildEvidenceCheck: buildEvidenceCheck,
  buildEvidenceLabels: buildEvidenceLabels,
  buildEvidenceValues: buildEvidenceValues,
  BuildArticleClassname: BuildArticleClassname,
  // addressToDbData: addressToDbData,
  // dbDataToAddress: dbDataToAddress,
  buildCategoryMenus: buildCategoryMenus,
  getPublicUrlPath: getPublicUrlPath,
  getYYYYMMDDByDate: getYYYYMMDDByDate,
  isTodayBeforeDate: isTodayBeforeDate,
  isTodayAfterDate: isTodayAfterDate,
  isTodayBetweenDate: isTodayBetweenDate,
  textEllipsis: textEllipsis,
};

export default Handler;
