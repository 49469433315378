import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'common/';

const GetAuthority = () => {
  const url = BASE_URL + 'authority';
  return AxiosInstance.get(url);
};

const PostFile = (formData) => {
  const url = BASE_URL + PATH + 'file';
  const headers = {
    'content-type': 'multipart/form-data',
  };
  return AxiosInstance.post(url, formData, headers);
};

const DeleteFile = (body) => {
  console.log('DeleteFile body', body);
  const url = BASE_URL + PATH + 'file';
  return AxiosInstance.delete(url, { data: body });
};

const GetBusinessType = () => {
  const url = BASE_URL + PATH + 'business-type';
  return AxiosInstance.get(url);
};

const GetEts = () => {
  const url = BASE_URL + 'eu-ets';
  return AxiosInstance.get(url);
};

const Common = {
  GetAuthority: GetAuthority,
  GetBusinessType: GetBusinessType,
  PostFile: PostFile,
  DeleteFile: DeleteFile,
  GetEts: GetEts,
};

export default Common;
