import React, { useContext, useEffect } from 'react';
import Handler from '../../Handler';
import Button from '@mui/material/Button';
import { TextInput, TextViewer } from '../../components';
import { useForm } from '../../hooks';
import { UserContext } from '../../contexts';

export const CardReply = (props) => {
  const { userInfo } = useContext(UserContext);
  const { data, requestSubmit, label } = props;
  const { status } = data;
  const [formData, formMeta, formChange, setFormData, setFormMeta] = useForm(
    { answer: '' },
    {
      answer: {
        key: 'answer',
        type: 'text',
        multiline: true,
        fullWidth: true,
        label: '내용',
      },
    },
  );

  useEffect(() => {
    console.log('data.status', data.status);
    if (data.status && data.status === 'Complete') {
      setFormMeta({
        answer: {
          key: 'answer',
          type: 'text',
          multiline: true,
          fullWidth: true,
          label: '내용',
          disabled: true,
        },
      });
      setFormData({
        answer: data.answerContent,
      });
    }
  }, [data.status]);

  if (!data) {
    return null;
  }
  return (
    <article className='card-reply'>
      <header>
        <div className='text__box'>
          <h3>답변</h3>
          <span className={status}>({Handler.ENUM.CS.STATUS(status)})</span>
          {label && <span className='label'>{label}</span>}
        </div>

        <div className='button__box'>
          <Button
            disabled={data.status === 'Complete'}
            variant='contained'
            onClick={() => {
              requestSubmit(formData.answer);
            }}
          >
            답변등록
          </Button>
        </div>
      </header>
      <section className='input__box'>
        <TextViewer
          value={userInfo ? userInfo.id : ''}
          meta={{
            label: '작성자',
            style: {},
            render: (value) => {
              return <span>{value}</span>;
            },
          }}
        ></TextViewer>

        <TextInput value={formData.answer} onChange={formChange} meta={formMeta.answer}></TextInput>
      </section>
    </article>
  );
};
