import React from 'react';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export const FileMultipleViewer = (props) => {
  const { value, meta } = props;

  console.log('value', value);
  return (
    <article className='form-file-multiple-viewer'>
      <header>
        <span>{meta.label}</span>
      </header>
      <div className='file__box'>
        {value &&
          value.length > 0 &&
          value.map((item, index) => {
            const fileName = item ? item.split('/')[item.split('/').length - 1] : '';

            return (
              <Button
                key={fileName}
                onClick={() => {
                  window.open(item, '_blank');
                }}
                size='large'
                variant='outlined'
                endIcon={<FileDownloadIcon />}
              >
                {fileName}
              </Button>
            );
          })}
      </div>
    </article>
  );
};
