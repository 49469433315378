import { useState } from 'react';

export const useForm = (value, meta) => {
  const [form, setForm] = useState(value);
  const [formMeta, setFormMeta] = useState(meta);

  const onChange = (newValue, key) => {
    setForm({ ...form, [key]: newValue });
  };

  const reset = () => {
    setForm(value);
  };

  return [form, formMeta, onChange, setForm, setFormMeta, reset];
};
