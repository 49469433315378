import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'payment/';

const GetList = (params) => {
  const url = BASE_URL + 'payment' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetDetail = (seq) => {
  const url = BASE_URL + PATH + seq;
  return AxiosInstance.get(url);
};

const PutListStatus = (seq, params) => {
  const url = BASE_URL + PATH + seq + '/status' + Utils.ObjectToParams(params);
  return AxiosInstance.put(url);
};

const PutPresentResend = (seq, body) => {
  const url = BASE_URL + PATH + seq + '/present/resend';
  return AxiosInstance.put(url, body);
};

const GetWithdrawList = (params) => {
  const url = BASE_URL + PATH + 'withdraw' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetWithdrawDetail = (seq) => {
  const url = BASE_URL + PATH + 'withdraw/' + seq;
  return AxiosInstance.get(url);
};

const PutWithdraw = (seq, params) => {
  const url = BASE_URL + PATH + 'withdraw/' + seq + Utils.ObjectToParams(params);
  return AxiosInstance.put(url);
};

const GetRevenueList = (params) => {
  const url = BASE_URL + PATH + 'revenue' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetRevenueDetail = (seq) => {
  const url = BASE_URL + PATH + 'revenue/' + seq;
  return AxiosInstance.get(url);
};

const RegenPdf = (params) => {
  const url = BASE_URL + PATH + 'regen-pdf' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetEuetsList = (params) => {
  const url = BASE_URL + PATH + 'eu-ets' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const PostEuets = (params) => {
  const url = BASE_URL + PATH + 'eu-ets' + Utils.ObjectToParams(params);
  return AxiosInstance.post(url);
};

const GetEuetsDetail = (seq) => {
  const url = BASE_URL + PATH + 'eu-ets/' + seq;
  return AxiosInstance.get(url);
};

const PutEuets = (seq, params) => {
  const url = BASE_URL + PATH + 'eu-ets/' + seq + Utils.ObjectToParams(params);
  return AxiosInstance.put(url);
};

const Payment = {
  GetList: GetList,
  GetDetail: GetDetail,
  PutListStatus: PutListStatus,
  PutPresentResend: PutPresentResend,
  GetWithdrawList: GetWithdrawList,
  GetWithdrawDetail: GetWithdrawDetail,
  PutWithdraw: PutWithdraw,
  GetRevenueList: GetRevenueList,
  GetRevenueDetail: GetRevenueDetail,
  RegenPdf: RegenPdf,
  GetEuetsList: GetEuetsList,
  PostEuets: PostEuets,
  GetEuetsDetail: GetEuetsDetail,
  PutEuets: PutEuets,
};

export default Payment;
