import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'methodology/';

const Get = (params) => {
  const url = BASE_URL + 'methodology' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetInfo = () => {
  const url = BASE_URL + PATH + 'info';
  return AxiosInstance.get(url);
};

const GetDetail = (seq) => {
  const url = BASE_URL + PATH + seq;
  return AxiosInstance.get(url);
};

const Post = (body) => {
  const url = BASE_URL + 'methodology';
  return AxiosInstance.post(url, body);
};

const Put = (seq, body) => {
  const url = BASE_URL + PATH + seq;
  return AxiosInstance.put(url, body);
};

const PutUpdate = (seq, body) => {
  const url = BASE_URL + PATH + seq + '/update';
  return AxiosInstance.put(url, body);
};

const GetHistoryList = (seq, params) => {
  const url = BASE_URL + PATH + seq + '/history' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const Methodology = {
  Get: Get,
  GetInfo: GetInfo,
  GetDetail: GetDetail,
  Post: Post,
  Put: Put,
  PutUpdate: PutUpdate,
  GetHistoryList: GetHistoryList,
};

export default Methodology;
