import React, { useContext, useEffect, useState } from 'react';
import Handler from '../../Handler';
import Button from '@mui/material/Button';
import API from '../../api';
import { SnackbarContext, DialogContext } from '../../contexts';
import { TextInput } from '../../components';
import { useForm } from '../../hooks';

export const CardReductionMemo = (props) => {
  const { value } = props;
  const [list, setList] = useState(null);
  const { updateSnackbar } = useContext(SnackbarContext);
  const { updateDialog } = useContext(DialogContext);
  console.log('CardMemo value', value);
  const [formData, formMeta, formChange] = useForm(
    { memo: '' },
    {
      memo: {
        key: 'memo',
        type: 'text',
        multiline: true,
        fullWidth: true,
        label: '메모',
        customButton: {
          label: '등록',
          onClick: (text) => {
            console.log('memo text', text);
            const body = {
              targetSeq: value,
              memo: text,
              type: 'ReductionCert',
            };
            API.Memo.PostMemo(body).then(
              (res) => {
                console.log('res', res);
                updateSnackbar({
                  type: 'success',
                  open: true,
                  message: '메모 등록 성공',
                });
                refreshData();
              },
              (err) => {
                console.log('err', err);
                updateSnackbar({
                  type: 'error',
                  open: true,
                  message: err.data.message,
                });
              },
            );
          },
        },
      },
    },
  );

  useEffect(() => {
    if (value) {
      API.Memo.GetMemo(value, { memoType: 'ReductionCert' }).then(
        (res) => {
          console.log('res', res);
          let result = res.data;
          setList(result);
        },
        (err) => {
          console.log('err', err);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    }
  }, [value]);

  const refreshData = () => {
    API.Memo.GetMemo(value, { memoType: 'ZCCredit' }).then(
      (res) => {
        console.log('res', res);
        let result = res.data;
        setList(result);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requestDelete = (seq) => {
    console.log('requestDelete', seq);
    updateDialog({
      type: 'error',
      open: true,
      title: '메모 삭제',
      description: '삭제 후 되돌릴 수 없습니다. 그래도 진행하시겠습니까?',
      onConfirm: () => {
        onDeleteConfirm(seq);
      },
    });
  };

  const onDeleteConfirm = (seq) => {
    console.log('onDeleteConfirm', seq);
    API.Memo.DeleteMemo(seq).then(
      (res) => {
        updateSnackbar({
          type: 'success',
          open: true,
          message: '메모 삭제 성공',
        });
        refreshData();
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  return (
    <article className='card-memo'>
      <section className='input__box'>
        <TextInput value={formData.memo} onChange={formChange} meta={formMeta.memo}></TextInput>
      </section>
      <section className='list__box'>
        <ul>
          {list &&
            list.map((item, index) => {
              console.log('item', item);
              return (
                <li>
                  <header className='id__box'>
                    <div className='id__box'>
                      <span>{item.adminId}</span>
                      <span className='date'>({Handler.getYYYYMMDDByUnix(item.createdAt)})</span>
                    </div>

                    <div className='button__box'>
                      <Button
                        variant='contained'
                        onClick={() => {
                          requestDelete(item.seq);
                        }}
                        color='secondary'
                      >
                        삭제
                      </Button>
                    </div>
                  </header>
                  <div className='text__box'>
                    <p>{item.memo}</p>
                  </div>
                </li>
              );
            })}
        </ul>
      </section>
    </article>
  );
};
