import React, { useEffect, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { TableBox, TextInput, SelectBox, Check, ToastEditor, CardReply, TextViewer, DatePickerBox } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext, UserContext, DialogContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';

export const PaymentRevenueDetailPage = (props) => {
  const history = useHistory();
  const { slug } = useParams();
  const { userInfo } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const [lastCalculate, setLastCalculate] = useState(null);
  const [calculate, setCalculate] = useState([]);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      amount: null,
      bankCode: null,
      bankName: null,
      buyerName: null,
      calcPrice: 0,
      cancelledAt: 0,
      cert: null,
      certNumber: null,
      createdAt: 0,
      failedAt: 0,
      forwardComplete: null,
      forwardDate: null,
      forwardReady: null,
      impUid: null,
      importStatus: null,
      isForward: false,
      isForwardComplete: false,
      merchantUid: null,
      message: null,
      meta: null,
      orderDate: null,
      orderFile: null,
      orderNumber: null,
      paidAt: 0,
      pgId: null,
      pgTid: null,
      presentEmail: null,
      presentName: null,
      price: 0,
      project: null,
      purchaseType: null,
      reason: null,
      receiptUrl: null,
      revenue: 0,
      seq: 0,
      startedAt: 0,
      status: null,
      statusCode: null,
      type: null,
      unitPrice: 0,
      user: null,
      vbankCode: null,
      vbankDate: 0,
      vbankHolder: null,
      vbankIssuedAt: 0,
      vbankName: null,
      vbankNum: null,
      //
      revenue: 0,
      calculate: [],
    },
    {
      orderNumber: {
        key: 'orderNumber',
        label: '주문번호',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      projectId: {
        key: 'projectId',
        label: '사업ID',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      projectTitle: {
        key: 'projectTitle',
        label: '사업명',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      purchaseType: {
        label: '거래구분',
        fullWidth: true,
        render: (value) => {
          switch (value) {
            case 'ForwardPersonal': {
              return '개인선도'
            }
            case 'NormalPersonal': {
              return '개인일반'
            }
            case 'ForwardEnterprise': {
              return '기업선도'
            }
            case 'NormalEnterprise': {
              return '기업일반'
            }
            default: {
              return 'none'
            }
          }
        },
      },
      unitPrice: {
        fullWidth: true,
        key: 'unitPrice',
        label: '판매가격(톤)',
        render: (value) => {
          return Handler.numberWithCommas(value) + '원';
        },
      },
      salePrice: {
        fullWidth: true,
        key: 'salePrice',
        label: '판매가격(톤)',
        render: (value) => {
          return Handler.numberWithCommas(value) + '원';
        },
      },
      amount: {
        fullWidth: true,
        key: 'amount',
        label: '구매수량',
        render: (value) => {
          return Handler.numberWithCommas(value) + '톤';
        },
      },
      isForwardComplete: {
        label: '정산 상태',
        fullWidth: true,
        render: (value) => {

          return value ? '정산완료' : '정산진행'

        },
      },
      status: {
        label: '정산 상태',
        fullWidth: true,
        render: (value) => {
          switch (value) {
            case 'Proceeding': {
              return '정산진행'
            }
            case 'Complete': {
              return '정산완료'
            }
            default: {
              return 'none'
            }
          }
        },
      },
      type: {
        label: '결제수단',
        fullWidth: true,
        render: (value) => {
          switch (value) {
            case 'CARD': {
              return '개인선도'
            }
            case 'BANK': {
              return '무통장입금'
            }
            case 'VBANK': {
              return '가상계좌'
            }
            case 'MOBILE': {
              return '모바일'
            }
            case 'BCSH': {
              return 'BCSH'
            }
            case 'CULTURE': {
              return '문화상품권'
            }
            case 'HPMN': {
              return '입금완료'
            }
            case 'EWALLET': {
              return 'EWALLET'
            }
            case 'ETC_': {
              return '기타'
            }
            default: {
              return 'none'
            }
          }
        },
      },
      paidAt: {
        label: '결제일시',
        fullWidth: true,
        render: (value) => {
          return Handler.getYYYYMMDDByUnix(value)
        },
      },
      price: {
        label: '상품금액',
        fullWidth: true,
        render: (value) => {
          return Handler.numberWithCommas(value) + '원'
        },
      },
      calcPrice: {
        label: '결제금액',
        fullWidth: true,
        render: (value) => {
          return Handler.numberWithCommas(value) + '원'
        },
      },
      price: {
        label: '결제금액',
        fullWidth: true,
        render: (value) => {
          return Handler.numberWithCommas(value) + '원'
        },
      },
      buyerName: {
        label: '구매자명',
        fullWidth: true,
        render: (value) => {
          return value
        },
      },
      buyerEmail: {
        label: '이메일',
        fullWidth: true,
        render: (value) => {
          return value
        },
      },
      buyerPhone: {
        label: '연락처',
        fullWidth: true,
        render: (value) => {
          return value
        },
      },
      reason: {
        label: '구매사유',
        fullWidth: true,
        render: (value) => {
          return value
        },
      },
      present: {
        label: '선물하기 대상',
        fullWidth: true,
        render: (value) => {
          return value.presentName + '(' + value.presentEmail + ')'
        },
      },
      companyName: {
        label: '기업명',
        fullWidth: true,
        render: (value) => {
          return value;
        },
      },
      businessNumber: {
        label: '사업자등록번호',
        fullWidth: true,
        render: (value) => {
          return value;
        },
      },
      accumulateAmount: {
        label: '누적 정산수량',
        fullWidth: true,
        render: (value) => {
          return Handler.numberWithCommas(value) + '톤';
        },
      },
      accumulatePrice: {
        label: '누적 정산금액',
        fullWidth: true,
        render: (value) => {
          return Handler.numberWithCommas(value) + '원';
        },
      },
      lastDate: {
        label: '최근 정산일',
        fullWidth: true,
        render: (value) => {
          if (value) {
            return Handler.getYYYYMMDDByDate(value);
          } else {
            return ''
          }
        },
      },
    }
  );
  const [header, setHeader] = useState([
    {
      key: 'createdAt',
      align: 'center',
      text: '정산일',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        return Handler.getYYYYMMDDByUnix(value);
      },
    },
    {
      key: 'amount',
      align: 'center',
      text: '정산수량',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        return Handler.numberWithCommas(value) + '톤'
      }
    },
    {
      key: 'calcPrice',
      align: 'center',
      text: '정산금액',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        return Handler.numberWithCommas(value) + '원'
      }
    },
    {
      key: 'unitPrice',
      align: 'center',
      text: '1톤당 책정 가격',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        return Handler.numberWithCommas(value) + '원'
      }
    },

  ]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (slug) {
      refreshData();
    }
  }, [slug]);


  const refreshData = () => {
    API.Payment.GetRevenueDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log('GetWithdrawDetail result', result);
        setFormData({ ...result });
        setLoading(false);

        if (result.calculate.length > 0) {
          setLastCalculate(result.calculate[0])
        }
        setCalculate(result.calculate)
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
        setLoading(false);
      },
    );
  };


  const handleEvent = (type) => {
    switch (type) {
      case 'REQUEST_PRESENT_EMAIL': {
        console.log('REQUEST_PRESENT_EMAIL');
        API.Payment.PutPresentResend(slug).then(res => {
          updateSnackbar({
            type: 'success',
            open: true,
            message: '재발송 성공',
          });
        }, err => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        })
      }
    }
  }

  if (loading) {
    return null;
  }

  console.log('formData', formData);
  console.log('lastCalculate', lastCalculate);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>수익현황 상세</h3>
        </header>
      </section>
      <section className='form__container'>
        <header>
          <h3>주문 정보</h3>
        </header>
        <div className='flex'>
          <TextInput value={formData.orderNumber} onChange={formChange} meta={formMeta.orderNumber}></TextInput>
          <TextViewer value={formData.isForwardComplete} meta={formMeta.isForwardComplete}></TextViewer>
          <TextViewer value={formData.buyerName} meta={formMeta.buyerName}></TextViewer>
          <TextInput value={formData.project.id} onChange={formChange} meta={formMeta.projectId}></TextInput>
          <TextInput value={formData.project.title} onChange={formChange} meta={formMeta.projectTitle}></TextInput>
          <TextViewer value={formData.reason} meta={formMeta.reason}></TextViewer>
          <TextViewer value={formData.amount} meta={formMeta.amount}></TextViewer>
          <TextViewer value={formData.salePrice} meta={formMeta.salePrice}></TextViewer>
          <TextViewer value={formData.price} meta={formMeta.price}></TextViewer>
          <TextViewer value={formData.paidAt} meta={formMeta.paidAt}></TextViewer>
          <TextViewer value={formData.forwardComplete} meta={formMeta.accumulateAmount}></TextViewer>
          <TextViewer value={formData.calcPrice} meta={formMeta.accumulatePrice}></TextViewer>
          <TextViewer value={formData.forwardDate} meta={formMeta.lastDate}></TextViewer>
        </div>
      </section>
      <div style={{ height: 40 }}></div>
      <section className='form__container'>
        <header>
          <h3>수익금 정산 정보</h3>
        </header>
        <TableBox data={calculate} header={header} rowClick={() => { }} callback={() => { }}></TableBox>
      </section>
    </article>
  );
};
