import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export const RadioGroupBox = (props) => {
  const { value, onChange, meta } = props;

  const handleChange = (e) => {
    console.log('handleChange', e.target.value);
    onChange(e.target.value, meta.key);
  };

  const renderStyle = () => {
    if (meta.style) {
      return { ...meta.style };
    } else {
      return {};
    }
  };

  return (
    <article className='form-radio-box' style={renderStyle()}>
      <header>
        <span>
          {meta.label} {meta.required ? '*' : ''}
        </span>
      </header>
      <FormControl fullWidth={meta.fullWidth}>
        <RadioGroup value={value} onChange={handleChange}>
          {meta.menus.map((item, index) => {
            return <FormControlLabel value={item.value} control={<Radio />} label={item.label} />;
          })}
        </RadioGroup>
      </FormControl>
    </article>
  );
};
