import React, { useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import { TextInput, FileUploader, Address, TextViewer, CardSignout, CardMemo } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext, DialogContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import { useParams } from 'react-router-dom';

export const MemberEnterpriseDetailPage = (props) => {
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);

  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      email: '',
      name: '',
      phone: '',
      companyName: '',
      ceoName: '',
      profilePath: '',
      businessNumber: '',
      businessFile: '',
      address: {
        daum: null,
        detail: '',
      },
      site: '',
      fax: '',
      marketingAgreeDate: '',
      account: null,
      status: '',
      createdAt: '',
      signOut: '',
    },
    {
      email: {
        disabled: true,
        key: 'email',
        label: '이메일 아이디',
        style: {},
        required: true,
        fullWidth: true,
      },
      name: {
        key: 'name',
        label: '담당자명',
        style: {},
        fullWidth: true,
        required: true,
      },
      phone: {
        key: 'phone',
        label: '휴대전화',
        style: {},
        fullWidth: true,
        required: true,
      },
      companyName: {
        key: 'companyName',
        label: '기업명',
        style: {},
        fullWidth: true,
        required: true,
      },
      ceoName: {
        key: 'ceoName',
        label: '대표자명',
        style: {},
        fullWidth: true,
        required: true,
      },
      businessNumber: {
        key: 'businessNumber',
        label: '사업자등록번호',
        style: {},
        fullWidth: true,
        required: true,
      },
      profilePath: {
        type: 'image',
        key: 'profilePath',
        label: '대표이미지',
        path: 'singup/profile_image',
        helperText: '256x256 사이즈 또는 정사각형 2mb사이즈를 넘지말아야 합니다.',
      },
      businessFile: {
        type: 'file',
        key: 'businessFile',
        label: '사업자등록증',
        path: 'singup/business_file',
        required: true,
      },
      address: {
        required: true,

        key: 'address',
        label: '본사 주소',
      },
      site: {
        key: 'site',
        label: '홈페이지',
        style: {},
        fullWidth: true,

        required: false,
      },
      fax: {
        key: 'fax',
        label: '팩스번호',
        style: {},
        fullWidth: true,
        required: false,
      },
      marketingAgreeDate: {
        key: 'marketingAgreeDate',
        label: '마케팅 수신 동의',
        style: {},
        render: (value) => {
          console.log('custom ', value);
          if (value) {
            return 'O (' + Handler.dbDateToYYYYMMDD(value) + ')';
          } else {
            return 'X';
          }
        },
      },
      account: {
        key: 'account',
        label: '계좌번호',
        style: {},
        render: (value) => {
          console.log('custom ', value);
          if (value) {
            return value.bank + ' / ' + value.account + '/ ' + value.name;
          }
          return null;
        },
      },
      status: {
        key: 'status',
        label: '상태',
        style: {},
        render: (value) => {
          console.log('custom ', value);
          switch (value) {
            case 'Registered': {
              return '가입 등록';
            }
            case 'Certification': {
              return '메일 승인 대기';
            }
            case 'ApproveRequired': {
              return '관리자 승인 대기';
            }
            case 'Confirmed': {
              return '승인 완료';
            }
            case 'Disabled': {
              return '사용 불가';
            }
            default: {
              return '';
            }
          }
        },
      },
      createdAt: {
        key: 'createdAt',
        label: '생성일',
        style: {},
        fullWidth: true,
        required: false,
      },
      signOut: {
        key: 'signOut',
        label: '탈퇴승인',
        style: {},
        fullWidth: true,
        required: false,
      },
    },
  );

  useEffect(() => {
    if (slug) {
      API.Member.GetMember(slug).then(
        (res) => {
          let result = res.data;
          console.log('GetMember result', result);

          setFormData({
            ...result,
            address: result.address ? result.address : { daum: {}, detail: '' },
            createdAt: Handler.getYYYYMMDDByUnix(res.data.createdAt),
          });
        },
        (err) => {
          console.log('err', err);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    }
  }, [slug]);

  const refreshData = () => {
    API.Member.GetMember(slug).then(
      (res) => {
        let result = res.data;
        console.log('GetMember result', result);

        setFormData({
          ...result,
          createdAt: Handler.getYYYYMMDDByUnix(res.data.createdAt),
        });
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requestSave = () => {
    console.log('requestSave');
    let body = Handler.formToBody(formData, formMeta);
    console.log('requestSave', body);
    if (body) {
      const db_body = {
        email: body.email,
        name: body.name,
        phone: body.phone,
        companyName: body.companyName,
        ceoName: body.ceoName,
        businessNumber: body.businessNumber,
        address: body.address,
        site: body.site,
        fax: body.fax,
      };
      API.Member.PutMember(slug, db_body).then(
        (res) => {
          let result = res.data;
          console.log(' put result', result);
          updateSnackbar({
            type: 'success',
            open: true,
            message: '저장 성공',
          });
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  const requestMail = () => {
    console.log('requestMail', slug);
    API.Member.PutEmailResend(slug).then(
      (res) => {
        updateSnackbar({
          type: 'success',
          open: true,
          message: '재발송 성공',
        });
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requestSignout = () => {
    updateDialog({
      type: 'error',
      open: true,
      title: '탈퇴 승인',
      description: '탈퇴처리 후 되돌릴 수 없습니다. 그래도 진행하시겠습니까?',
      onConfirm: onConfirmSignout,
    });
  };

  const onConfirmSignout = () => {
    API.Member.PutSignout(slug).then(
      (res) => {
        updateSnackbar({
          type: 'success',
          open: true,
          message: '탈퇴 성공',
        });
        refreshData();
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };
  const requestApprove = () => {
    console.log('requestApprove');
    updateDialog({
      type: 'info',
      open: true,
      title: '가입 승인',
      description: '가입을 승인하시겠습니까?',
      onConfirm: onConfirmApprove,
    });
  };

  const onConfirmApprove = () => {
    API.Member.PutMemberApproved(slug).then(
      (res) => {
        updateSnackbar({
          type: 'success',
          open: true,
          message: '가입 성공',
        });
        refreshData();
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  console.log('formData', formData);
  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>기업회원 상세</h3>
        </header>
        <div className='button__box'>
          <Button disabled={formData.status === 'Disabled'} variant='contained' onClick={requestMail}>
            인증메일 재발송
          </Button>

          <Button
            disabled={formData.status !== 'ApproveRequired' || formData.status === 'Disabled'}
            variant='contained'
            onClick={requestApprove}
          >
            가입 승인
          </Button>
          <Button disabled={formData.status === 'Disabled'} variant='contained' onClick={requestSave}>
            저장
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <TextInput value={formData.companyName} onChange={formChange} meta={formMeta.companyName}></TextInput>
          <TextInput value={formData.ceoName} onChange={formChange} meta={formMeta.ceoName}></TextInput>
          <FileUploader value={formData.profilePath} onChange={formChange} meta={formMeta.profilePath}></FileUploader>
          <TextInput value={formData.businessNumber} onChange={formChange} meta={formMeta.businessNumber}></TextInput>
          <FileUploader value={formData.businessFile} onChange={formChange} meta={formMeta.businessFile}></FileUploader>
          <TextInput value={formData.email} onChange={formChange} meta={formMeta.email}></TextInput>
          <TextInput value={formData.name} onChange={formChange} meta={formMeta.name}></TextInput>
          <TextInput value={formData.phone} onChange={formChange} meta={formMeta.phone}></TextInput>
          <Address value={formData.address} onChange={formChange} meta={formMeta.address}></Address>
          <TextInput value={formData.site} onChange={formChange} meta={formMeta.site}></TextInput>
          <TextInput value={formData.fax} onChange={formChange} meta={formMeta.fax}></TextInput>
          {/* <TextViewer value={formData.marketingAgreeDate} meta={formMeta.marketingAgreeDate}></TextViewer> */}
          <TextViewer value={formData.status} meta={formMeta.status}></TextViewer>
          <TextInput value={formData.createdAt} meta={formMeta.createdAt}></TextInput>
          <TextViewer value={formData.account} meta={formMeta.account}></TextViewer>
        </div>
      </section>
      {formData.signOut && (
        <section className='card__container' style={{ marginTop: 40 }}>
          <header>
            <h3>탈퇴 정보</h3>
            <Button
              disabled={formData.status === 'Disabled'}
              variant='contained'
              onClick={requestSignout}
              color='secondary'
            >
              탈퇴 승인
            </Button>
          </header>
          <section className='card__box'>
            <CardSignout value={formData.signOut}></CardSignout>
          </section>
        </section>
      )}
      <section className='card__container' style={{ marginTop: 40 }}>
        <header>
          <h3>운영자 메모</h3>
        </header>
        <section className='card__box'>
          <CardMemo value={slug}></CardMemo>
        </section>
      </section>
    </article>
  );
};
