import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'monitoring-evidence/';

const PostEvidence = (body) => {
  const url = BASE_URL + 'monitoring-evidence';
  return AxiosInstance.post(url, body);
};

const GetEvidence = (params) => {
  const url = BASE_URL + 'monitoring-evidence' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetEvidenceDetail = (slug) => {
  const url = BASE_URL + PATH + slug;
  return AxiosInstance.get(url);
};

const PutEvidence = (slug, body) => {
  const url = BASE_URL + PATH + slug;
  return AxiosInstance.put(url, body);
};

const MonitoringEvidence = {
  PostEvidence: PostEvidence,
  GetEvidence: GetEvidence,
  GetEvidenceDetail: GetEvidenceDetail,
  PutEvidence: PutEvidence,
};

export default MonitoringEvidence;
