import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'terms/';

const GetTerms = (params) => {
  const url = BASE_URL + PATH + 'methodology' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetTermsDetail = (seq) => {
  const url = BASE_URL + PATH + seq + '/methodology';
  return AxiosInstance.get(url);
};

const PostTerms = (body) => {
  const url = BASE_URL + PATH + 'methodology';
  return AxiosInstance.post(url, body);
};

const PutTerms = (seq, body) => {
  const url = BASE_URL + PATH + seq + '/methodology';
  return AxiosInstance.put(url, body);
};

const Term = {
  GetTerms: GetTerms,
  GetTermsDetail: GetTermsDetail,
  PostTerms: PostTerms,
  PutTerms: PutTerms,
};

export default Term;
