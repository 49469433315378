import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'reduction-cert/';

const Get = (params) => {
  const url = BASE_URL + 'reduction-cert' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetAction = (seq, params) => {
  const url = BASE_URL + PATH + seq + '/action' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetDetail = (seq) => {
  const url = BASE_URL + PATH + seq;
  return AxiosInstance.get(url);
};

const PostNextAction = (seq, body) => {
  const url = BASE_URL + PATH + seq + '/next-action';
  return AxiosInstance.post(url, body);
};

// const Post = (body) => {
//   const url = BASE_URL + PATH;
//   return AxiosInstance.post(url, body);
// };

// const Put = (seq, body) => {
//   const url = BASE_URL + PATH + seq;
//   return AxiosInstance.put(url, body);
// };

const ReductionCert = {
  Get: Get,
  GetDetail: GetDetail,
  GetAction: GetAction,
  PostNextAction: PostNextAction,
};

export default ReductionCert;
