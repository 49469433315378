import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const NotFoundPage = (props) => {
  const history = useHistory();

  return (
    <article className='not-found__page'>
      <p style={{ margin: '200px 0px' }}>Page Not Found</p>
      <div
        style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
        onClick={() => {
          history.push('/');
        }}
      >
        Go Home
      </div>
    </article>
  );
};
