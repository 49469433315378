import React, { useEffect, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { TextInput, SelectBox, DatePickerBox, FileUploader, TextViewer, Paging } from '../../components';
import { usePagination, useForm } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 860,
  bgcolor: 'background.paper',
  border: '2px solid #bcbcbc',
  boxShadow: 24,
  p: 0,
  borderRadius: 2,
  overflow: 'hidden',
};

export const ForwardContractAddPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [methodologyInfo, setMethodologyInfo] = useState(null);
  const [formData, formMeta, formChange, setFormData] = useForm(
    {
      contractName: '',
      contractUserSeq: 0,
      projectId: '', //PA0002220102
      reason: '',
      contractAmount: 0,
      totalPrice: 0,
      unitPrice: 0,
      contractDate: null,
      contractFile: null,
      orderAmount: 0,
      orderPrice: 0,
      orderDate: null,
      orderFile: null,
    },
    {
      contractName: {
        key: 'contractName',
        label: '계약번호',
        fullWidth: true,
        required: true,
      },
      projectId: {
        key: 'projectId',
        label: '프로젝트ID',
        fullWidth: true,
        required: true,
      },
      reason: {
        key: 'reason',
        label: '구매사유',
        fullWidth: true,
        menus: [
          {
            label: '기후변화에 기여하고 싶습니다.',
            value: '기후변화에 기여하고 싶습니다.',
          },
          {
            label: '내가 배출한 온실가스를 상쇄합니다.',
            value: '내가 배출한 온실가스를 상쇄합니다.',
          },
          {
            label: '여행으로 발생한 온실가스를 상쇄합니다.',
            value: '여행으로 발생한 온실가스를 상쇄합니다.',
          },
          {
            label: '우리가족이 배출한 온실가스를 상쇄합니다.',
            value: '우리가족이 배출한 온실가스를 상쇄합니다.',
          },
          {
            label: '회사가 배출한 온실가스를 상쇄합니다.',
            value: '회사가 배출한 온실가스를 상쇄합니다.',
          },
          {
            label: '직접입력',
            value: '직접입력',
          },
        ],
      },
      contractAmount: {
        key: 'contractAmount',
        label: '총 계약수량',
        fullWidth: true,
        required: true,
      },
      totalPrice: {
        key: 'totalPrice',
        label: '총 결제금액',
        fullWidth: true,
        required: true,
      },
      unitPrice: {
        key: 'unitPrice',
        label: '1톤당 책정 가격',
        fullWidth: true,
        required: true,
      },
      contractDate: {
        key: 'contractDate',
        label: '계약일자',
        required: true,
        fullWidth: true,
      },
      contractFile: {
        required: true,
        type: 'file',
        key: 'contractFile',
        label: '계약서 첨부',
        path: 'contract/file',
      },
      orderAmount: {
        key: 'orderAmount',
        label: '구매수량',
        fullWidth: true,
        required: true,
      },
      orderPrice: {
        key: 'orderPrice',
        label: '결제금액',
        fullWidth: true,
        required: true,
      },
      orderDate: {
        key: 'orderDate',
        label: '결제일시',
        required: true,
        fullWidth: true,
      },
      orderFile: {
        required: true,
        type: 'file',
        key: 'orderFile',
        label: '입금증 첨부',
        path: 'contract/order',
      },
    },
  );
  const [open, setOpen] = useState(false);
  const [projectOpen, setProjectOpen] = useState(false);
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);
  const [searchValue, setSearchValue] = useState('');
  const [companyMeta, setCompanyMeta] = useState('');
  const [reasonCustom, setReasonCustom] = useState('');

  useEffect(() => {
    API.Methodology.GetInfo().then(
      (res) => {
        console.log('Getinfo res', res);
        setMethodologyInfo(res.data);
      },
      (err) => {
        console.log('Getinfo err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
    requsetNewCompnayList(1);
  }, []);

  useEffect(() => {
    if (formData.reason !== '직접입력') {
      setReasonCustom('');
    }
  }, [formData.reason]);

  useEffect(() => {
    console.log('page effect', page);
    requsetNewCompnayList();
  }, [page]);

  const handleEvent = (type) => {
    console.log('handleEvent', type);
    if (type === 'OPEN_SEARCH_COMPANY') {
      setOpen(true);
    } else if (type === 'OPEN_SEARCH_PROJECT') {
      setProjectOpen(true);
    }
  };

  const requsetNewCompnayList = (init_page) => {
    API.Trading.GetContractUser({
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
      searchValue: searchValue,
    }).then(
      (res) => {
        console.log('GetContractUser', res);
        let result = res.data;
        setList(result.content);
        setTotalPage(result.totalPages);
        setTotalCount(result.meta.totalItems);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requsetAdd = () => {
    let body = Handler.formToBody(formData, formMeta);
    console.log('body', body);

    if (body) {
      let db_body = {
        ...body,
        reason: body.reason === '직접입력' ? reasonCustom : body.reason,
      };
      console.log('db_body', db_body);
      API.Trading.PostContract(db_body).then(
        (res) => {
          let result = res.data;
          console.log('PostContract post result', result);
          history.push('/trading/contract_detail/' + result.seq);
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  console.log('formData', formData);
  console.log('formMeta', formMeta);
  console.log('list', list);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>기업 선도거래 계약 추가</h3>
        </header>
        <div className='button__box'>
          <Button variant='contained' onClick={requsetAdd}>
            추가
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>계약 정보</h3>
        </header>
        <div className='flex'>
          {/* <TextInput value={formData.contractName} onChange={formChange} meta={formMeta.contractName}></TextInput> */}
          <TextViewer
            value={formData.contractName}
            meta={{
              key: 'contractName',
              label: '기업명',
              fullWidth: true,
              required: true,
              render: (value) => {
                return value;
              },
            }}
            customButton={{
              label: '기업 검색',
              onClick: () => {
                handleEvent('OPEN_SEARCH_COMPANY');
              },
            }}
          ></TextViewer>
          <TextInput value={formData.projectId} onChange={formChange} meta={formMeta.projectId}></TextInput>
          <SelectBox value={formData.reason} onChange={formChange} meta={formMeta.reason}></SelectBox>
          {formData.reason === '직접입력' && (
            <TextInput
              value={reasonCustom}
              onChange={(value) => {
                setReasonCustom(value);
              }}
              meta={{
                key: 'reasonCustom',
                label: '구매사유(직접)',
                fullWidth: true,
                required: true,
              }}
            ></TextInput>
          )}
          <TextInput value={formData.contractAmount} onChange={formChange} meta={formMeta.contractAmount}></TextInput>
          <TextInput value={formData.totalPrice} onChange={formChange} meta={formMeta.totalPrice}></TextInput>
          <TextInput value={formData.unitPrice} onChange={formChange} meta={formMeta.unitPrice}></TextInput>
          <DatePickerBox
            value={formData.contractDate}
            onChange={formChange}
            meta={formMeta.contractDate}
          ></DatePickerBox>
          <FileUploader value={formData.contractFile} onChange={formChange} meta={formMeta.contractFile}></FileUploader>
        </div>
      </section>
      <div style={{ height: 40 }}></div>
      <section className='form__container'>
        <header style={{ alignItems: 'center' }}>
          <h3>주문 정보</h3>
          <span style={{ marginLeft: 30, fontSize: 12, color: '#777' }}>
            첫 주문은 계약수량의 30%만 결제합니다. 입금이 들어왔는지 확인 후 계약 내용을 저장하시기 바랍니다.
          </span>
        </header>
        <div className='flex'>
          <TextInput value={formData.orderAmount} onChange={formChange} meta={formMeta.orderAmount}></TextInput>
          <TextInput value={formData.orderPrice} onChange={formChange} meta={formMeta.orderPrice}></TextInput>
          <DatePickerBox value={formData.orderDate} onChange={formChange} meta={formMeta.orderDate}></DatePickerBox>
          <FileUploader value={formData.orderFile} onChange={formChange} meta={formMeta.orderFile}></FileUploader>
        </div>
      </section>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={style}>
          <article className='company-list__modal'>
            <header>
              <h3>기업 검색</h3>
              <IconButton
                style={{ marginRight: -10 }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </header>
            <section className='search__box'>
              <TextField
                label='기업명, 사업자등록번호 검색'
                fullWidth
                value={searchValue}
                onChange={(e) => {
                  console.log('e', e);
                  setSearchValue(e.target.value);
                }}
                onKeyPress={(e) => {
                  // console.log(`Pressed keyCode ${e.key}`);
                  if (e.key === 'Enter') {
                    // Do code here
                    e.preventDefault();
                    requsetNewCompnayList(1);
                  }
                }}
              ></TextField>
              <Button
                onClick={() => {
                  requsetNewCompnayList(1);
                }}
                style={{ marginLeft: 12 }}
                color='primary'
                variant='contained'
              >
                검색
              </Button>
            </section>
            <section className='list__box'>
              <header>
                <div className='summary'>
                  <span>
                    검색 결과 총 <em>{totalCount}</em> 건
                  </span>
                </div>
              </header>
              <ul>
                {list && list.length === 0 && <span>데이터가 없습니다.</span>}
                {list && list.length > 0 && (
                  <li className='header'>
                    <div className='name'>기업명</div>
                    <div className='address'>주소</div>
                  </li>
                )}
                {list &&
                  list.length > 0 &&
                  list.map((item, index) => {
                    return (
                      <li
                        onClick={() => {
                          console.log('item', item);
                          let new_formData = _.clone(formData);
                          new_formData.contractUserSeq = item.seq;
                          new_formData.contractName = item.companyName;
                          setFormData(new_formData);
                          setOpen(false);
                        }}
                      >
                        <div className='name'>{item.companyName}</div>
                        <div className='address'>
                          {item.address && item.address.daum && item.address.daum.roadAddress}
                          &nbsp;{item.address && item.address.detail}
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </section>
            <section className='paging__box'>
              <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
            </section>
          </article>
        </Box>
      </Modal>
      <Modal
        open={projectOpen}
        onClose={() => {
          setProjectOpen(false);
        }}
      >
        <Box sx={style}>
          <article className='company-list__modal'>
            <header>
              <h3>사업 검색</h3>
              <IconButton
                style={{ marginRight: -10 }}
                onClick={() => {
                  setProjectOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </header>
          </article>
        </Box>
      </Modal>
    </article>
  );
};
