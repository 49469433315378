import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

export const TableBox = (props) => {
  const { data, header, rowClick, callback } = props;

  if (!data) {
    return null;
  }

  return (
    <article className='custom-table'>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {header.map((item, index) => {
                return (
                  <TableCell key={'cell-' + index} align={item.align} style={{ ...item.style }}>
                    {item.text}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length === 0 && (
              <TableRow>
                <TableCell colSpan={header.length}>
                  <span>데이터가 없습니다.</span>
                </TableCell>
              </TableRow>
            )}
            {data &&
              data.map((row, index) => (
                <TableRow
                  key={'row-' + index}
                  onClick={() => {
                    rowClick(index);
                  }}
                >
                  {header.map((header, header_index) => {
                    if (header.render) {
                      if (header.key) {
                        return <TableCell key={'cell-' + header_index} align={header.align}>{header.render(row[header.key])}</TableCell>;
                      } else {
                        return <TableCell key={'cell-' + header_index} align={header.align}>{header.render(row)}</TableCell>;
                      }
                    } else if (header.key) {
                      return <TableCell key={'cell-' + header_index} align={header.align}>{row[header.key]}</TableCell>;
                    } else {
                      const { type, callbackType, label } = header.component;
                      switch (type) {
                        case 'button': {
                          return (
                            <TableCell align={header.align}>
                              <Button
                                variant='contained'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  callback(callbackType, index);
                                }}
                              >
                                {label}
                              </Button>
                            </TableCell>
                          );
                        }
                        default: {
                          return null;
                        }
                      }
                    }
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </article>
  );
};
