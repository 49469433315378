import React, { useEffect, useState } from 'react';
import DaumPostcode from 'react-daum-postcode';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import _ from 'lodash';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #bcbcbc',
  boxShadow: 24,
  p: 4,
};

export const Address = (props) => {
  const { value, onChange, meta } = props;
  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState();

  const handleComplete = (daum_data) => {
    let new_value = _.clone(value);
    new_value.daum = daum_data;
    onChange(new_value, meta.key);
    setOpen(false);
  };

  useEffect(() => {
    if (typeof value === 'string') {
      const valueToObject = JSON.parse(value);
      console.log('valueToObject', valueToObject);
      setAddress(valueToObject);
    } else {
      setAddress(value);
    }
  }, [value]);

  return (
    <article className='form-address' style={meta && meta.style ? { ...meta.style } : {}}>
      {meta.label && (
        <header>
          <span>
            {meta.label} {meta.required ? '*' : ''}
          </span>
        </header>
      )}
      <section className='address-value__box'>
        <div className='zonecode'>
          <TextField
            fullWidth
            label={'우편번호'}
            shrink
            readOnly
            value={address?.daum?.zonecode || address?.address_components?.[5]?.long_name || ''}
          ></TextField>
        </div>
        <div className='address'>
          <TextField
            fullWidth
            label={'주소'}
            shrink
            readOnly
            value={address?.daum?.roadAddress || address?.formatted_address || ''}
          ></TextField>
        </div>
        <div className='detail'>
          <TextField
            fullWidth
            shrink
            value={address && address.detail ? address.detail : ''}
            label={'상세주소'}
            placeholder={'상세주소'}
            disabled={meta.disabled}
            onChange={(e) => {
              let new_value = _.clone(value);
              new_value.detail = e.target.value;
              onChange(new_value, meta.key);
            }}
          ></TextField>
        </div>
        <div className='button__box'>
          <Button
            disabled={meta.disabled}
            style={{ marginLeft: 20, minWidth: 100, height: '100%' }}
            variant='contained'
            onClick={() => {
              setOpen(true);
            }}
          >
            주소 검색
          </Button>
        </div>
      </section>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={style}>
          <DaumPostcode onComplete={handleComplete} />
        </Box>
      </Modal>
    </article>
  );
};
