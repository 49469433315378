import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { TextInput, SelectBox, Check, ToastEditor, FileMultipleUploader, TextViewer } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext, UserContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import Utils from '../../api/Utils';

export const CsNoticeAddPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { userInfo } = useContext(UserContext);
  const userId = Utils.GetStorage('seq');
  const [formData, formMeta, formChange] = useForm(
    {
      adminId: userId.toString(),
      site: 'Credit',
      title: '',
      titleEn: '',
      content: '',
      contentEn: '',
      isTop: false,
      fileArray: [],
      fileArrayEn: [],
      view: 0,
    },
    {
      site: {
        key: 'site',
        label: '사이트',
        fullWidth: true,
        menus: [
          {
            value: 'Credit',
            label: '인증시스템',
          },
          {
            value: 'Market',
            label: '마켓플레이스',
          },
        ],
      },
      isTop: {
        key: 'isTop',
        label: '상단 고정',
      },
      title: {
        key: 'title',
        label: '제목',
        fullWidth: true,
        required: true,
      },
      titleEn: {
        key: 'titleEn',
        label: '제목(영문)',
        fullWidth: true,
        required: true,
      },
      content: {
        key: 'content',
        label: '내용',
        required: true,
        path: 'editor/notice',
      },
      contentEn: {
        key: 'contentEn',
        label: '내용(영문)',
        required: true,
        path: 'editor/notice',
      },
      fileArray: {
        type: 'file',
        key: 'fileArray',
        label: '파일 첨부',
        path: '/notice/file',
        max: 1,
      },
      fileArrayEn: {
        type: 'file',
        key: 'fileArrayEn',
        label: '파일 첨부(영문)',
        path: '/notice/fileEn',
        max: 1,
      },
    },
  );

  const requsetAdd = () => {
    let body = Handler.formToBody(formData, formMeta);
    console.log('body', body);

    if (body) {
      API.CS.PostNotice(body).then(
        (res) => {
          let result = res.data;
          console.log('PostTerms post result', result);
          updateSnackbar({
            type: 'success',
            open: true,
            message: '공지사항이 추가되었습니다.',
          });
          history.push('/cs/notice/list');
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>공지사항 추가</h3>
        </header>
        <div className='button__box'>
          <Button variant='contained' onClick={requsetAdd}>
            추가
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <SelectBox value={formData.site} onChange={formChange} meta={formMeta.site}></SelectBox>
          <Check value={formData.isTop} onChange={formChange} meta={formMeta.isTop}></Check>
          <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
          <TextInput value={formData.titleEn} onChange={formChange} meta={formMeta.titleEn}></TextInput>
          <ToastEditor value={formData.content} onChange={formChange} meta={formMeta.content}></ToastEditor>
          <ToastEditor value={formData.contentEn} onChange={formChange} meta={formMeta.contentEn}></ToastEditor>
          <FileMultipleUploader
            value={formData.fileArray}
            onChange={formChange}
            meta={formMeta.fileArray}
          ></FileMultipleUploader>
          <FileMultipleUploader
            value={formData.fileArrayEn}
            onChange={formChange}
            meta={formMeta.fileArrayEn}
          ></FileMultipleUploader>
          <TextViewer
            value={userInfo ? userInfo.id : ''}
            meta={{
              label: '어드민 ID',
              render: (value) => {
                return value;
              },
            }}
          ></TextViewer>
        </div>
      </section>
    </article>
  );
};
