import React, { useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import { TextInput, SelectBox, RadioGroupBox } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import { useParams } from 'react-router-dom';

export const ReductionEvidenceDetailPage = (props) => {
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [formData, formMeta, formChange, setFormData] = useForm(
    {
      seq: '',
      title: '',
      status: 'Valid',
      createdAt: '',
      updatedAt: '',
      isForce: true,
      type: 'All',
    },
    {
      seq: {
        key: 'seq',
        label: '일련번호',
        fullWidth: true,
        required: false,
        InputProps: {
          readOnly: true,
        },
      },
      title: {
        key: 'title',
        label: '증빙서류명',
        fullWidth: true,
        required: true,
      },
      createdAt: {
        key: 'createdAt',
        label: '등록일',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      updatedAt: {
        key: 'updatedAt',
        label: '수정일',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      status: {
        key: 'status',
        label: '상태',
        fullWidth: true,
        menus: [
          {
            value: 'Valid',
            label: '사용중',
          },
          {
            value: 'NotUse',
            label: '사용 안함',
          },
        ],
      },
      isForce: {
        key: 'isForce',
        label: '필수 여부',
        menus: [
          {
            label: '필수',
            value: true,
          },
          {
            label: '선택',
            value: false,
          },
        ],
      },
      type: {
        key: 'type',
        label: '모니터링 타입',
        fullWidth: true,
        menus: [
          {
            value: 'All',
            label: '전체',
          },
          {
            value: 'Document',
            label: '서면 모니터링',
          },
          {
            value: 'System',
            label: '시스템 모니터링',
          },
        ],
      },
    },
  );

  useEffect(() => {
    if (slug) {
      API.MonitoringEvidence.GetEvidenceDetail(slug).then(
        (res) => {
          console.log('GetEvidenceDetail res', res);
          let result = res.data;
          setFormData({
            ...res.data,
            createdAt: Handler.getYYYYMMDDByUnix(res.data.createdAt),
            updatedAt: Handler.getYYYYMMDDByUnix(res.data.updatedAt),
          });
        },
        (err) => {
          console.log('err', err);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    }
  }, [slug]);

  const requestSave = () => {
    console.log('requestSave');
    let body = Handler.formToBody(formData, formMeta);
    console.log('requestSave', body);

    if (body) {
      API.MonitoringEvidence.PutEvidence(slug, {
        status: body.status,
        title: body.title,
        type: body.type,
        isForce: body.isForce === 'true' ? true : false,
      }).then(
        (res) => {
          let result = res.data;
          console.log(' put result', result);
          updateSnackbar({
            type: 'success',
            open: true,
            message: '저장 성공',
          });
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  console.log('formData', formData);
  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>모니터링 증빙서류 상세</h3>
        </header>
        <div className='button__box'>
          <Button disabled={formData.deleteDate} variant='contained' onClick={requestSave}>
            저장
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <TextInput value={formData.seq} onChange={formChange} meta={formMeta.seq}></TextInput>
          <SelectBox value={formData.type} onChange={formChange} meta={formMeta.type}></SelectBox>
          <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
          <RadioGroupBox value={formData.isForce} onChange={formChange} meta={formMeta.isForce}></RadioGroupBox>
          <SelectBox value={formData.status} onChange={formChange} meta={formMeta.status}></SelectBox>
          <TextInput value={formData.createdAt} onChange={formChange} meta={formMeta.createdAt}></TextInput>
        </div>
      </section>
    </article>
  );
};
