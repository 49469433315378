import React, { useEffect, useContext, useState } from 'react';
import { format } from 'date-fns';
import Button from '@mui/material/Button';
import {
  TextInput,
  TextViewer,
  Check,
  TableBox,
  DatePickerBox,
  Paging,
  ModalConsultationFeedback,
} from '../../components';
import { useForm, usePagination } from '../../hooks';
import { DialogContext, SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 860,
  bgcolor: 'background.paper',
  border: '2px solid #bcbcbc',
  boxShadow: 24,
  p: 0,
  borderRadius: 2,
  overflow: 'hidden',
};

export const ConsultationDetailPage = (props) => {

  const history = useHistory();
  const { slug } = useParams();
  const { dialog, updateDialog } = useContext(DialogContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [initData, setInitData] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [consultationFeedback, setConsultationFeedback] = useState(false);
  const [consultationOpen, setConsultationOpen] = useState(false);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {      
      isOpen: true,
      category: '',
      title: '',
      startDate: '',
      endDate: '',
    },
    {      
      isOpen: {
        key: 'isOpen',
        label: '노출 여부',
      },
      category: {
        key: 'category',
        fullWidth: true,
        label: '카테고리',
        disabled: true,
      },
      title: {
        key: 'title',
        label: '제목',
        fullWidth: true,
        required: true,
      },
      startDate: {
        key: 'startDate',
        label: '논평 시작일',
        fullWidth: true,
        required: true,
      },
      endDate: {
        key: 'endDate',
        label: '논평 종료일',
        fullWidth: true,
        required: true,
      },      
      methodologyTitle: {
        key: '',
        label: '방법론 명',
        fullWidth: true,        
        render: (value) => {
          return value;
        },
        disabled: true,
        style: () => {
          return 
        }
      },
      projectTitle: {
        key: '',
        label: '방법론 명',
        fullWidth: true,        
        render: (value) => {
          return value;
        },
        disabled: true,
      },
    },
  );

  const [list, setList] = useState(null);  
  const [feedbackHeader, setFeedbackHeader] = useState([
    {
      key: 'createdAt',
      align: 'center',
      text: '일자',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.getYYYYMMDDByUnix(value);
      },
    },
    {
      key: 'title',
      align: 'center',
      text: '제목',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'content',
      align: 'center',
      text: '내용',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.textEllipsis(value, 50);
      }
    },
    {
      key: '',
      align: 'center',
      text: '첨부파일',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return value?.documents?.length > 0 ? `총 ${value?.documents?.length} 개` : '-';
      }
    },
    {
      key: '',
      align: 'center',
      text: '상세보기',
      style: {
        minWidth: 100,
      },
      render: (row) => {
        return (
          <Button
            variant='contained'
            onClick={(e) => {
              e.stopPropagation();
              console.log('row', row);
              setConsultationFeedback(row);
              setConsultationOpen(true);
            }}
          >
            상세보기
          </Button>
        );
      },
    }
  ]);

  const [summaryHeader, setSummaryHeader] = useState([
    {
      key: 'createdAt',
      align: 'center',
      text: '업로드 일자',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.getYYYYMMDDByUnix(value);
      },
    },
    {
      key: '',
      align: 'center',
      text: '한글 요약서',
      style: {
        minWidth: 100,
      },
      render: (row) => {
        return (
          <Button
            variant='contained'
            onClick={() => {
              window.open(row.attachedFile, '_blank');
            }}
          >
            {row.title}
          </Button>
        );
      },
    },
    {
      key: '',
      align: 'center',
      text: '영문 요약서',
      style: {
        minWidth: 100,
      },
      render: (row) => {
        return (
          <Button
            variant='contained'
            onClick={() => {
              window.open(row.attachedFile, '_blank');
            }}
          >
            {row.titleEn}
          </Button>
        );
      },
    },
  ]);

  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  useEffect(() => {
    if (slug) {
      refreshData();
    }
  }, [slug]);

  const refreshData = () => {
    API.Consultation.GetDetail(slug).then(
      (res) => {
        let result = res.data;
        setInitData(result);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      }
    )
  }

  useEffect(() => {
    if (initData) {
      setFormData({
        isOpen: initData.isOpen,
        category: Handler.ENUM.CONSULTATION.CATEGORY(initData.category),
        title: initData.title,
        startDate: format(new Date(initData.startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(initData.endDate), 'yyyy-MM-dd'),
        methodologyTitle: initData.methodology?.title,
        projectTitle: initData.project?.title,
      });
      
      switch(initData.category) {
        case "Methodology":
          setDocuments(initData.methodology?.documents?.filter(doc => doc.type === 'CONSULTATION') || []);
          break;
        case "Project":
          setDocuments(initData.project?.documents?.filter(doc => doc.type === 'CONSULTATION') || []);
          break;
        case "Standard":
          setDocuments(initData.standard?.documents?.filter(doc => doc.type === 'CONSULTATION') || []);
          break;
        case "Terms": 
          setDocuments(initData.terms?.documents?.filter(doc => doc.type === 'CONSULTATION') || []);
          break;
        default:
          break;
      }
    }
  }, [initData]);

  useEffect(() => {
    getFeedbackList();
  }, [page]);

  // 등록된 공개논평 조회
  const getFeedbackList = (init_page) => {
    API.Consultation.GetFeedback(slug, {
      page: init_page ? init_page : page,
      size: 5,
    }).then(
      (res) => {
        let result = res.data;
        setList(result.content);
        setTotalCount(result.totalElements);
        setTotalPage(result.totalPages);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  // 공개논평 상세정보 수정
  const requestSave = (isOpen) => {
    let body = Handler.formToBody(formData, formMeta);
    if (body) {

      // validation check
      if (validateDates(body.startDate, body.endDate)) {
        updateSnackbar({
                  type: 'error',
                  open: true,
                  message: "논평 시작일이 논평 종료일보다 이전이어야 합니다.",
                });
        return;
      }

      body.startDate = Handler.getYYYYMMDDByDate(body.startDate);
      body.endDate = Handler.getYYYYMMDDByDate(body.endDate);
      
      let category = body.category;
      delete body.category;
      delete body.projectTitle;
      delete body.methodologyTitle;

      if (isOpen !== undefined) {
        body.isOpen = isOpen;
      }

      API.Consultation.Put(slug, body).then(
        (res) => {
          setInitData(res.data);
          body.category = category;
          updateSnackbar({
            type: 'success',
            open: true,
            message: '저장 성공',
          });
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );      
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  const validateDates = (startDate, endDate) => {
    return new Date(startDate) >= new Date(endDate);
  };

  const handleEvent = (type) => {
    if (type === 'OPEN_METHODOLOGY') {
      history.push('/methodology/detail/' + initData.methodology.seq)
    } else if (type === 'OPEN_PROJECT') {
      history.push('/project/detail/' + initData.project.seq)
    }
  };

  const closeConsultationModal = () => {
    setConsultationOpen(false);
  };

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>논평 상세</h3>
        </header>
        <div className='button__box'>
          {initData && Handler.isTodayBetweenDate(initData.startDate, initData.endDate) &&
            (initData.isOpen
              ? <Button variant='contained' color='warning' onClick={() => 
                  updateDialog({
                    type: 'error',
                    open: true,
                    title: '논평 중지',
                    description: '진행중인 논평을 중지하시겠습니까?',
                    onConfirm: () => {
                      requestSave(false);
                    },
                  })              
              }>중지</Button>
              : <Button variant='contained' color='success' onClick={() => 
                  updateDialog({
                    type: 'success',
                    open: true,
                    title: '논평 시작',
                    description: '논평을 다시 시작하시겠습니까?',
                    onConfirm: () => {
                      requestSave(true);
                    },
                  })              
              }>시작</Button>
            )
          }
          <Button variant='contained' onClick={() => requestSave()}>저장</Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        {!initData ? (
          <div className='loading__container'>
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <div className='flex'>
            {Handler.isTodayAfterDate(initData.startDate) && <Check value={formData.isOpen} onChange={formChange} meta={formMeta.isOpen}></Check> }            
            <TextInput value={formData.category} onChange={formChange} meta={formMeta.category}></TextInput>    
            {initData.category === 'Methodology' && 
              <TextViewer
                value={formData.methodologyTitle}
                meta={formMeta.methodologyTitle}
                customButton={{
                  label: '해당 방법론 보기',
                  onClick: () => {
                    handleEvent('OPEN_METHODOLOGY');
                  },
                }}
              ></TextViewer>
            }
            {initData.category === 'Project' && 
              <TextViewer
                value={formData.projectTitle}
                meta={formMeta.projectTitle}
                customButton={{
                  label: '해당 프로젝트 보기',
                  onClick: () => {
                    handleEvent('OPEN_PROJECT');
                  },
                }}
              ></TextViewer>
            }
            <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
            <DatePickerBox value={formData.startDate} onChange={formChange} meta={formMeta.startDate}></DatePickerBox>
            <DatePickerBox value={formData.endDate} onChange={formChange} meta={formMeta.endDate}></DatePickerBox>
          </div>
        )}
      </section>

      <section className='table-header__container' style={{ marginTop: 40 }}>
        <header>
          <div className='text__box'>
            <h3>등록된 논평 목록<span style={{marginLeft: 15, fontSize: 15, color: '#777'}}>총 {totalCount}건</span></h3>            
          </div>
        </header>
        <TableBox data={list} header={feedbackHeader} rowClick={() => consultationOpen} callback={() => {}}></TableBox>
        <section className='paging__container' style={{ marginTop: 20, marginBottom: 0 }}>
          <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
        </section>        
      </section>

      <section className='form__container' style={{ marginTop: 40 }}>
        <header>
          <h3>공개논평 요약서</h3>
        </header>        
        <TableBox data={documents} header={summaryHeader} rowClick={() => { }} callback={() => { }}></TableBox>        
      </section>

      <Modal open={consultationOpen} onClose={closeConsultationModal}>
        <Box sx={modalStyle}>
          <ModalConsultationFeedback
            data={consultationFeedback}
            onClose={closeConsultationModal}
          />
        </Box>
      </Modal>
      
    </article>
  );
};
