import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export const SelectBox = (props) => {
  const { value, onChange, meta } = props;

  const handleChange = (e) => {
    onChange(e.target.value, meta.key);
    console.log("select", e.target.value , meta.key)
  };


  const renderStyle = () => {
    if (meta.style) {
      return { ...meta.style };
    } else {
      return {};
    }
  };

  return (
    <article className='form-select-box' style={renderStyle()}>
      <header>
        <span>
          {meta.label} {meta.required ? '*' : ''}
        </span>
      </header>
      <FormControl fullWidth={meta.fullWidth}>
        <InputLabel required={meta.required}>{meta.label}</InputLabel>
        <Select defaultValue={value} value={value || ''} label={meta.label} onChange={handleChange}>
          {meta.menus.map((item, index) => {
            return <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </article>
  );
};
