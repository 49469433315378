import React, { useEffect, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import {
  TextInput,
  SelectBox,
  Check,
  DatePickerBox,
  FileUploader,
  CardReductionStatus,
  TextViewer,
  ModalReductionAction,
  ModalReductionView,
  CardReductionMemo,
  TableBox,
  Paging,
  Address,
} from '../../components';
import { useForm, usePagination } from '../../hooks';
import { SnackbarContext, UserContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 860,
  bgcolor: 'background.paper',
  border: '2px solid #bcbcbc',
  boxShadow: 24,
  p: 0,
  borderRadius: 2,
  overflow: 'hidden',
};

export const ReductionDetailPage = (props) => {
  const history = useHistory();
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { accessToken, businessInfo } = useContext(UserContext);
  const [methodologyInfo, setMethodologyInfo] = useState(null);
  const [initData, setInitData] = useState(false);
  const [open, setOpen] = useState(false);
  const [actionOpen, setActionOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedNextAction, setSelectedNextAction] = useState(null);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      id: '',
      title: '',
      methodologyId: '',
      area: '',
      subArea: '',
      companyName: '',
      ceoName: '',
      joinedValue: '',
      businessNumber: '',
      businessFile: '',
      address: {
        daum: null,
        detail: '',
      },
      name: '',
      department: '',
      phone: '',
      email: '',
      monitorDegree: '',
      startDate: '',
      endDate: '',
      reductionExpect: '',
      reductionSum: '',
      realReduction: '',
    },
    {
      id: {
        key: 'id',
        label: '사업ID',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      title: {
        key: 'title',
        label: '사업명',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      methodologyId: {
        key: 'methodologyId',
        label: '적용 방법론',
        style: {},
        render: (value) => {
          return value;
        },
      },
      area: {
        key: 'area',
        label: '사업 유형',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      subArea: {
        key: 'subArea',
        label: '시업 유형 상세',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      companyName: {
        key: 'companyName',
        label: '기업명',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      ceoName: {
        key: 'ceoName',
        label: '대표자명',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      joinedValue: {
        key: 'joinedValue',
        label: '이메일 아이디',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      businessNumber: {
        key: 'businessNumber',
        label: '사업자등록번호',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      businessFile: {
        type: 'file',
        key: 'businessFile',
        label: '사업자등록증',
        path: 'project/business_file',
        required: true,
        disabled: true,
      },
      address: {
        disabled: true,
        required: true,
        key: 'address',
        label: '본사 주소',
      },
      name: {
        key: 'name',
        label: '담당자명',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      department: {
        key: 'department',
        label: '부서명',
        fullWidth: true,
        required: false,
        InputProps: {
          readOnly: true,
        },
      },
      phone: {
        key: 'phone',
        label: '연락처',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      email: {
        key: 'email',
        label: '담당자 이메일',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      monitorDegree: {
        key: 'monitorDegree',
        label: '모니터링 차수',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      startDate: {
        key: 'startDate',
        label: '모니터링 시작일',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      endDate: {
        key: 'endDate',
        label: '모니터링 종료일',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      reductionExpect: {
        key: 'reductionExpect',
        label: '예상 총 감축량',
        style: {},

        render: (value) => {
          return Handler.numberWithCommas(value);
        },
      },
      reductionSum: {
        key: 'reductionSum',
        label: '누적 감축량 수량',
        style: {},

        render: (value) => {
          return Handler.numberWithCommas(value);
        },
      },
      realReduction: {
        key: 'realReduction',
        label: '신청 수량',
        style: {},

        render: (value) => {
          return Handler.numberWithCommas(value);
        },
      },
    },
  );
  const [list, setList] = useState(null);
  const [header, setHeader] = useState([
    {
      key: 'createdAt',
      align: 'center',
      text: '일자',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.getYYYYMMDDByUnix(value);
      },
    },
    {
      key: '',
      align: 'center',
      text: '상태',
      style: {
        minWidth: 100,
      },
      render: (row) => {
        switch (row.status) {
          case 'WaitingForAuditReductionCert':
          case 'AuditReductionCert': {
            return Handler.ENUM.REDUCTION_ACTION.STATUS(row.status) + '(' + row.degree + ')';
          }
          default: {
            return Handler.ENUM.REDUCTION_ACTION.STATUS(row.status);
          }
        }
      },
    },
    {
      key: '',
      align: 'center',
      text: '문서 버전',
      style: {
        minWidth: 100,
      },
      render: (row) => {
        if (row.isNewVersion) {
          return (
            <Button
              variant='contained'
              onClick={(e) => {
                e.stopPropagation();
                let open_url = Handler.getPublicUrlPath();
                console.log('open_url', window.screen);
                console.log('open_url', row);

                // open_url +=
                //   'public/reduction?seq=' +
                //   row.reductionCertDoc.reductionCert.seq +
                //   '&version=' +
                //   row.version +
                //   '&token=' +
                //   token;
                open_url +=
                  'public/reduction/' + row.reductionCertDoc.reductionCert.seq + '/' + row.version + '/' + accessToken;

                console.log('open_url ==> ', open_url);

                var left = window.screen.width / 2 - 1280 / 2;
                var top = window.screen.height / 2 - 860 / 2;
                console.log('left', left);
                let win = window.open(
                  open_url,
                  '',
                  `status=no, menubar=no, toolbar=no, resizable=no, width=1280, height=800, left=${left}, top=${top}`,
                );
              }}
            >
              {row.version}
            </Button>
          );
        } else {
          return '-';
        }
      },
    },
    {
      key: '',
      align: 'center',
      text: '상세보기',
      style: {
        minWidth: 100,
      },
      render: (row) => {
        switch (row.status) {
          case 'WaitingForAuditReductionCert': {
            return '-';
          }
          default: {
            return (
              <Button
                variant='contained'
                onClick={(e) => {
                  e.stopPropagation();
                  console.log('row', row);
                  setSelectedAction(row);
                  setViewOpen(true);
                }}
              >
                상세보기
              </Button>
            );
          }
        }
      },
    },
    {
      key: 'adminId',
      align: 'center',
      text: '담당자',
      style: {
        minWidth: 100,
      },
    },
  ]);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  //hooks
  useEffect(() => {
    if (slug) {
      refreshData();
    }
  }, [slug]);

  useEffect(() => {
    if (initData) {
      console.log('initData', initData);
      setFormData({
        id: initData.project.id,
        title: initData.project.title,
        methodologyId: initData.project.methodology.id,
        area: Handler.ENUM.AREA(initData.project.methodology.area),
        subArea: Handler.ENUM.SUB_AREA(initData.project.methodology.subArea),
        companyName: initData.project.user.companyName,
        ceoName: initData.project.user.ceoName,
        email: initData.project.projectLastDoc.email,
        businessNumber: initData.project.user.businessNumber,
        businessFile: initData.project.user.businessFile,
        joinedValue: initData.project.user.joinedValue,
        address: initData.project.user.address,
        name: initData.project.projectLastDoc.name,
        department: initData.project.projectLastDoc.department,
        phone: initData.project.projectLastDoc.phone,
        monitorDegree: initData.monitorDegree,
        startDate: initData.startDate,
        endDate: initData.endDate,
        reductionExpect: initData.project.reductionExpect,
        reductionSum: initData.project.reductionSum,
        realReduction: initData.lastDoc.realReduction,
      });
      setFormMeta({ ...formMeta });
    }
  }, [initData]);

  useEffect(() => {
    console.log('page effect', page);
    requestNewList();
  }, [page]);

  //event

  const handleEvent = (type) => {
    switch (type) {
      case 'OPEN_METHODOLOGY': {
        history.push('/methodology/detail/' + initData.project.methodology.seq);
      }

      default: {
        console.log('handleEvent none');
      }
    }
  };

  const handleAction = (action) => {
    // project status에서 누른 NextAction
    console.log('handleAction action', action);
    console.log('handleAction initData', initData);
    setSelectedAction(initData.lastDoc.actionSeq);
    setSelectedNextAction(action);
    setActionOpen(true);
  };

  const handleModalRequest = (body, data) => {
    setButtonDisabled(true)
    API.ReductionCert.PostNextAction(slug, body).then(
      (res) => {
        let result = res.data;
        console.log('PostNextAction  result', result);
        closeActionModal();
        setButtonDisabled(false);
        refreshData();
        requestNewList(0);
        setPage(0);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  //table event
  const tableCallback = (type, index) => {
    console.log('tableCallback type', type);
    console.log('tableCallback index', index);
  };

  const tableRowClick = (index) => {
    console.log('tableRowClick index', index);
    let target_seq = list[index].seq;
    console.log('tableRowClick target_seq', target_seq);
  };

  //server

  const refreshData = () => {
    API.ReductionCert.GetDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log('Project result', result);
        setInitData(result);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requestNewList = (init_page) => {
    API.ReductionCert.GetAction(slug, {
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
    }).then(
      (res) => {
        console.log('requestNewList res', res);
        let result = res.data;
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const closeModal = () => {
    setOpen(false);
    setSelectedNextAction(null);
  };

  const closeActionModal = () => {
    setActionOpen(false);
  };

  const closeViewModal = () => {
    setViewOpen(false);
  };

  if (!initData) {
    return null;
  }

  console.log('initData', initData);
  console.log('formData', formData);
  console.log('formMeta', formMeta);
  console.log('list', list);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>감축량 인증 상세</h3>
        </header>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        {!initData ? (
          <div className='loading__container'>
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <div className='flex'>
            <TextInput value={formData.id} onChange={formChange} meta={formMeta.id}></TextInput>
            <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
            <TextViewer
              value={formData.methodologyId}
              meta={formMeta.methodologyId}
              customButton={{
                label: '해당 방법론 보기',
                onClick: () => {
                  handleEvent('OPEN_METHODOLOGY');
                },
              }}
            ></TextViewer>
            <TextInput value={formData.area} onChange={formChange} meta={formMeta.area}></TextInput>
            <TextInput value={formData.subArea} onChange={formChange} meta={formMeta.subArea}></TextInput>
            {/* <DatePickerBox value={formData.startDate} onChange={formChange} meta={formMeta.startDate}></DatePickerBox>
            <DatePickerBox value={formData.endDate} onChange={formChange} meta={formMeta.endDate}></DatePickerBox> */}
            <TextInput value={formData.companyName} onChange={formChange} meta={formMeta.companyName}></TextInput>
            <TextInput value={formData.ceoName} onChange={formChange} meta={formMeta.ceoName}></TextInput>
            <TextInput value={formData.joinedValue} onChange={formChange} meta={formMeta.joinedValue}></TextInput>
            <FileUploader
              value={formData.businessFile}
              onChange={formChange}
              meta={formMeta.businessFile}
            ></FileUploader>
            <TextInput value={formData.businessNumber} onChange={formChange} meta={formMeta.businessNumber}></TextInput>
            <Address value={formData.address} onChange={formChange} meta={formMeta.address}></Address>
            <TextInput value={formData.name} onChange={formChange} meta={formMeta.name}></TextInput>
            <TextInput value={formData.department} onChange={formChange} meta={formMeta.department}></TextInput>
            <TextInput value={formData.email} onChange={formChange} meta={formMeta.email}></TextInput>
            <TextInput value={formData.phone} onChange={formChange} meta={formMeta.phone}></TextInput>
          </div>
        )}
      </section>
      <section className='form__container' style={{ marginTop: 40 }}>
        <header>
          <h3>모니터링 정보</h3>
        </header>
        {!initData ? (
          <div className='loading__container'>
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <div className='flex'>
            <TextInput
              value={formData.monitorDegree}
              onChange={formChange}
              meta={formMeta.monitorDegree}
            ></TextInput>
            <DatePickerBox value={formData.startDate} onChange={formChange} meta={formMeta.startDate}></DatePickerBox>
            <DatePickerBox value={formData.endDate} onChange={formChange} meta={formMeta.endDate}></DatePickerBox>
            <TextViewer value={formData.reductionExpect} meta={formMeta.reductionExpect}></TextViewer>
            <TextViewer value={formData.reductionSum} meta={formMeta.reductionSum}></TextViewer>
            <TextViewer value={formData.realReduction} meta={formMeta.realReduction}></TextViewer>{' '}
          </div>
        )}
      </section>
      <section className='card__container' style={{ marginTop: 40 }}>
        <CardReductionStatus category={'reduction'} data={initData} onAction={handleAction}></CardReductionStatus>
      </section>
      <section className='table-header__container' style={{ marginTop: 40 }}>
        <header>
          <h3>이력 조회</h3>
        </header>
        <TableBox data={list} header={header} rowClick={tableRowClick} callback={tableCallback}></TableBox>
        <section className='paging__container' style={{ marginTop: 20, marginBottom: 0 }}>
          <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
        </section>
      </section>

      <section className='card__container' style={{ marginTop: 40 }}>
        <header>
          <h3>운영자 메모</h3>
        </header>
        <section className='card__box'>
          <CardReductionMemo value={slug}></CardReductionMemo>
        </section>
      </section>
      <Modal open={actionOpen} onClose={closeActionModal}>
        <Box sx={style}>
          <ModalReductionAction
            action={selectedAction}
            nextAction={selectedNextAction}
            requestAction={handleModalRequest}
            onClose={closeActionModal}
            disabled={buttonDisabled}
          ></ModalReductionAction>
        </Box>
      </Modal>
      <Modal open={viewOpen} onClose={closeViewModal}>
        <Box sx={style}>
          <ModalReductionView action={selectedAction} onClose={closeViewModal}></ModalReductionView>
        </Box>
      </Modal>
    </article>
  );
};
