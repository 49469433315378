import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TableBox, SearchFilter, Paging } from '../../components';
import { usePagination, useFilter } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';

export const ProjectListPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState(null);
  const [header, setHeader] = useState([
    {
      key: 'seq',
      align: 'center',
      text: 'No',
      style: {
        minWidth: 20,
      },
    },
    {
      key: 'id',
      align: 'center',
      text: '프로젝트 ID',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'subType',
      align: 'center',
      text: '신청유형',
      style: {
        minWidth: 50,
      },
      render: (value) => {
        return Handler.ENUM.PROJECT.TYPE(value);
      },
    },
    {
      key: 'title',
      align: 'center',
      text: '프로젝트 명',
      style: {
        minWidth: 300,
      },
      render: (value) => {
        return (
          <div style={{ maxWidth: 300 }} className='ellipsis-text'>
            <span>{value}</span>
          </div>
        );
      },
    },
    {
      key: 'methodology',
      align: 'center',
      text: '적용 방법론',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return <span>{value?.id}</span>;
      },
    },
    {
      key: 'user',
      align: 'center',
      text: '기업명',
      style: {
        minWidth: 160,
      },
      render: (value) => {
        return <span>{value.companyName}</span>;
      },
    },
    {
      key: 'user',
      align: 'center',
      text: '이메일',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return <span>{value.email}</span>;
      },
    },
    {
      key: 'startDate',
      align: 'center',
      text: '유효기간 시작일',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.dbDateToYYYYMMDD(value);
      },
    },
    {
      key: 'endDate',
      align: 'center',
      text: '유효기간 종료일',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.dbDateToYYYYMMDD(value);
      },
    },
    {
      key: 'reductionStartDate',
      align: 'center',
      text: '감축기간 시작일',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.dbDateToYYYYMMDD(value);
      },
    },
    {
      key: 'reductionEndDate',
      align: 'center',
      text: '감축기간 종료일',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.dbDateToYYYYMMDD(value);
      },
    },
    {
      key: '',
      align: 'center',
      text: '상태',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        switch (value.lastProjectDoc.projectDocAction.status) {
          case 'WaitingForAuditLaunch':
          case 'WaitingForAuditProject':
          case 'AuditProject': {
            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontSize: 14 }}>
                  {Handler.ENUM.ACTION.STATUS(value.lastProjectDoc.projectDocAction.status) +
                    '(' +
                    value.lastProjectDoc.projectDocAction.degree +
                    ')'}
                </span>
                <span style={{ fontSize: 12, color: '#777' }}>
                  {Handler.getYYYYMMDDByUnix(value.lastProjectDoc.createdAt)}
                </span>
              </div>
            );
          }
          default: {
            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontSize: 14 }}>
                  {Handler.ENUM.ACTION.STATUS(value.lastProjectDoc.projectDocAction.status)}
                </span>
                <span style={{ fontSize: 12, color: '#777' }}>
                  {Handler.getYYYYMMDDByUnix(value.lastProjectDoc.createdAt)}
                </span>
              </div>
            );
          }
        }
      },
    },
  ]);

  const [filter, onFilterChange, setFilter] = useFilter([
    {
      key: 'type',
      type: 'select',
      label: '신청 유형',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: 'New',
          label: '신규',
        },
        {
          value: 'Modify',
          label: '변경',
        },
        {
          value: 'Move',
          label: '이관',
        },
      ],
    },
    {
      key: 'status',
      type: 'select',
      label: '문서 상태',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          label: '임시 저장',
          value: 'Writing',
        },
        {
          label: '심사 대기',
          value: 'Waiting',
        },
        {
          label: '심사중',
          value: 'Audit',
        },
        {
          label: '보완요청',
          value: 'ImprovementRequest',
        },
        {
          label: '최종승인 / 착수승인',
          value: 'Approved',
        },
        {
          label: '최종반려 / 착수반려',
          value: 'Rejected',
        },
        {
          label: '신청취소 / 사업취소',
          value: 'Canceled',
        },
      ],
    },
    {
      key: 'searchType',
      type: 'select',
      label: '검색 종류',
      value: 'Title',
      menus: [
        {
          value: 'Name',
          label: '이름',
        },
        {
          value: 'Email',
          label: '이메일',
        },
        {
          value: 'Title',
          label: '프로젝트 명',
        },
        {
          value: 'ProjectId',
          label: '프로젝트 ID',
        },
        {
          value: 'Phone',
          label: '전화번호',
        },
        {
          value: 'MethodologyId',
          label: '방법론 ID',
        },
      ],
    },
    {
      key: 'searchValue',
      type: 'input',
      label: '검색 값',
      value: '',
      inputType: 'text',
    },
  ]);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  const tableCallback = (type, index) => {
    console.log('tableCallback type', type);
    console.log('tableCallback index', index);
  };

  const tableRowClick = (index) => {
    console.log('tableRowClick index', index);
    let target_seq = list[index].seq;
    history.push('/project/detail/' + target_seq);
  };

  const onSearch = (body) => {
    setPage(0);
    requestNewList(0);
  };

  const onReset = () => {
    setPage(0);
    requestNewList(0, true)
  }

  useEffect(() => {
    requestNewList();
    history.push(`?page=${page}`);
  }, [page]);

  useEffect(() => {
    const page = parseInt(params.get('page') || '0', 9);
    setPage(page);
  }, []);

  const requestNewList = (init_page, isReset = false) => {
    let body = isReset ? null : Handler.filterToBody(filter);
    console.log('page body', body);
    API.Project.Get({
      ...body,
      page: init_page  ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
    }).then(
      (res) => {
        console.log('res', res);
        let result = res.data;
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>프로젝트 목록</h3>
        </header>
      </section>
      <section className='filter__container'>
        <SearchFilter data={filter} onChange={onFilterChange} onSearch={onSearch} onReset={onReset}></SearchFilter>
      </section>
      <section className='table__container'>
        <TableBox data={list} header={header} rowClick={tableRowClick} callback={tableCallback}></TableBox>
      </section>
      <section className='paging__container'>
        <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
      </section>
    </article>
  );
};
