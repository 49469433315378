import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'trading/';

const GetInquiryList = (params) => {
  const url = BASE_URL + PATH + 'inquiry' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetInquiryDetail = (slug) => {
  const url = BASE_URL + PATH + 'inquiry/' + slug;
  return AxiosInstance.get(url);
};

const PutInquiry = (slug, body) => {
  const url = BASE_URL + PATH + 'inquiry/' + slug;
  return AxiosInstance.put(url, body);
};

const GetContractList = (params) => {
  const url = BASE_URL + PATH + 'contract' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const PostContract = (body) => {
  const url = BASE_URL + PATH + 'contract/';
  return AxiosInstance.post(url, body);
};

const GetContractUser = (params) => {
  const url = BASE_URL + PATH + 'contract/user' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetContractDetail = (slug) => {
  const url = BASE_URL + PATH + 'contract/' + slug;
  return AxiosInstance.get(url);
};

const PutContractCancel = (slug, body) => {
  const url = BASE_URL + PATH + 'contract/' + slug + '/cancel';
  return AxiosInstance.put(url, body);
};

const PostContractOrder = (slug, body) => {
  const url = BASE_URL + PATH + 'contract/' + slug + '/order';
  return AxiosInstance.post(url, body);
};

const PutContractOrder = (slug, orderNumber, body) => {
  const url = BASE_URL + PATH + 'contract/' + slug + '/order/' + orderNumber;
  return AxiosInstance.put(url, body);
};

const Trading = {
  GetInquiryList: GetInquiryList,
  GetInquiryDetail: GetInquiryDetail,
  PutInquiry: PutInquiry,
  GetContractList: GetContractList,
  PostContract: PostContract,
  GetContractUser: GetContractUser,
  GetContractDetail: GetContractDetail,
  PutContractCancel: PutContractCancel,
  PostContractOrder: PostContractOrder,
  PutContractOrder: PutContractOrder,
};

export default Trading;
