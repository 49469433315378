import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { MainLayout, LoginLayout } from './layouts';
import {
  HomePage,
  UserLoginPage,
  NotFoundPage,
  SampleAddPage,
  SampleListPage,
  SampleDetailPage,
  AdminUserAddPage,
  AdminUserDetailPage,
  AdminUserListPage,
  MethodologyAddPage,
  MethodologyDetailPage,
  MethodologyListPage,
  MethodologyTermsAddPage,
  MethodologyTermsDetailPage,
  MethodologyTermsListPage,
  MethodologyEvidenceAddPage,
  MethodologyEvidenceDetailPage,
  MethodologyEvidenceListPage,
  CreditDetailPage,
  CreditListPage,
  MemberEnterpriseListPage,
  MemberEnterpriseDetailPage,
  MemberPersonalDetailPage,
  MemberPersonalListPage,
  CsInquiryDetailPage,
  CsInquiryListPage,
  CsNoticeDetailPage,
  CsNoticeListPage,
  CsNoticeAddPage,
  CsFaqCategoryDetailPage,
  CsFaqCategoryAddPage,
  CsFaqCategoryListPage,
  CsFaqListPage,
  CsFaqDetailPage,
  CsFaqAddPage,
  CsContactUsListPage,
  CsContactUsDetailPage,
  ProjectDetailPage,
  ProjectListPage,
  ReductionDetailPage,
  ReductionListPage,
  ReductionEvidenceAddPage,
  ReductionEvidenceDetailPage,
  ReductionEvidenceListPage,
  MarketplaceDetailPage,
  MarketplaceListPage,
  EuEtsAddPage,
  EuEtsListPage,
  EuEtsDetailPage,
  NewsListPage,
  NewsDetailPage,
  NewsAddPage,
  PaymentListPage,
  PaymentDetailPage,
  PaymentWithdrawDetailPage,
  PaymentWithdrawListPage,
  PaymentRevenueListPage,
  PaymentRevenueDetailPage,
  ForwardContractDetailPage,
  ForwardContractListPage,
  ForwardInquiryListPage,
  ForwardInquiryDetailPage,
  ForwardContractAddPage,
  ConsultationListPage,
  ConsultationDetailPage,
} from './pages';
import { UserContext } from './contexts';

const ROUTER_LIST = [
  {
    path: '/login',
    Layout: LoginLayout,
    Component: UserLoginPage,
  },
  {
    path: '/',
    Layout: MainLayout,
    Component: HomePage,
    protect: true,
    props: {
      exact: true,
    },
  },
  // sample
  {
    path: '/sample/list',
    Layout: MainLayout,
    Component: SampleListPage,
  },
  {
    path: '/sample/add',
    Layout: MainLayout,
    Component: SampleAddPage,
  },
  {
    path: '/sample/:id',
    Layout: MainLayout,
    Component: SampleDetailPage,
  },
  {
    path: '/marketplace/list',
    Layout: MainLayout,
    Component: MarketplaceListPage,
  },
  {
    path: '/marketplace/detail/:slug',
    Layout: MainLayout,
    Component: MarketplaceDetailPage,
  },
  {
    path: '/marketplace/news/list',
    Layout: MainLayout,
    Component: NewsListPage,
  },
  {
    path: '/marketplace/news/detail/:slug',
    Layout: MainLayout,
    Component: NewsDetailPage,
  },
  {
    path: '/marketplace/news/add',
    Layout: MainLayout,
    Component: NewsAddPage,
  },
  // content
  {
    path: '/admin_user/list',
    Layout: MainLayout,
    Component: AdminUserListPage,
  },
  {
    path: '/admin_user/add',
    Layout: MainLayout,
    Component: AdminUserAddPage,
  },
  {
    path: '/admin_user/detail/:slug',
    Layout: MainLayout,
    Component: AdminUserDetailPage,
  },
  {
    path: '/methodology/list',
    Layout: MainLayout,
    Component: MethodologyListPage,
  },
  {
    path: '/methodology/add',
    Layout: MainLayout,
    Component: MethodologyAddPage,
  },
  {
    path: '/methodology/detail/:slug',
    Layout: MainLayout,
    Component: MethodologyDetailPage,
  },
  {
    path: '/methodology/terms/list',
    Layout: MainLayout,
    Component: MethodologyTermsListPage,
  },
  {
    path: '/methodology/terms/add',
    Layout: MainLayout,
    Component: MethodologyTermsAddPage,
  },
  {
    path: '/methodology/terms/detail/:slug',
    Layout: MainLayout,
    Component: MethodologyTermsDetailPage,
  },
  {
    path: '/methodology/evidence/list',
    Layout: MainLayout,
    Component: MethodologyEvidenceListPage,
  },
  {
    path: '/methodology/evidence/add',
    Layout: MainLayout,
    Component: MethodologyEvidenceAddPage,
  },
  {
    path: '/methodology/evidence/detail/:slug',
    Layout: MainLayout,
    Component: MethodologyEvidenceDetailPage,
  },
  {
    path: '/member/enterprise/list',
    Layout: MainLayout,
    Component: MemberEnterpriseListPage,
  },
  {
    path: '/member/enterprise/detail/:slug',
    Layout: MainLayout,
    Component: MemberEnterpriseDetailPage,
  },
  {
    path: '/member/personal/list',
    Layout: MainLayout,
    Component: MemberPersonalListPage,
  },
  {
    path: '/member/personal/detail/:slug',
    Layout: MainLayout,
    Component: MemberPersonalDetailPage,
  },
  {
    path: '/cs/inquiry/list',
    Layout: MainLayout,
    Component: CsInquiryListPage,
  },
  {
    path: '/cs/inquiry/detail/:slug',
    Layout: MainLayout,
    Component: CsInquiryDetailPage,
  },
  {
    path: '/cs/notice/add',
    Layout: MainLayout,
    Component: CsNoticeAddPage,
  },
  {
    path: '/cs/notice/list',
    Layout: MainLayout,
    Component: CsNoticeListPage,
  },
  {
    path: '/cs/notice/detail/:slug',
    Layout: MainLayout,
    Component: CsNoticeDetailPage,
  },
  {
    path: '/cs/faq_category/add',
    Layout: MainLayout,
    Component: CsFaqCategoryAddPage,
  },
  {
    path: '/cs/faq_category/list',
    Layout: MainLayout,
    Component: CsFaqCategoryListPage,
  },
  {
    path: '/cs/faq_category/detail/:slug',
    Layout: MainLayout,
    Component: CsFaqCategoryDetailPage,
  },
  {
    path: '/cs/faq/add',
    Layout: MainLayout,
    Component: CsFaqAddPage,
  },
  {
    path: '/cs/faq/list',
    Layout: MainLayout,
    Component: CsFaqListPage,
  },
  {
    path: '/cs/faq/detail/:slug',
    Layout: MainLayout,
    Component: CsFaqDetailPage,
  },
  {
    path: '/cs/contact_us/list',
    Layout: MainLayout,
    Component: CsContactUsListPage,
  },
  {
    path: '/cs/contact_us/detail/:slug',
    Layout: MainLayout,
    Component: CsContactUsDetailPage,
  },
  {
    path: '/project/list',
    Layout: MainLayout,
    Component: ProjectListPage,
  },
  {
    path: '/project/detail/:slug',
    Layout: MainLayout,
    Component: ProjectDetailPage,
  },
  {
    path: '/reduction/list',
    Layout: MainLayout,
    Component: ReductionListPage,
  },
  {
    path: '/reduction/detail/:slug',
    Layout: MainLayout,
    Component: ReductionDetailPage,
  },
  {
    path: '/reduction/evidence/list',
    Layout: MainLayout,
    Component: ReductionEvidenceListPage,
  },
  {
    path: '/reduction/evidence/add',
    Layout: MainLayout,
    Component: ReductionEvidenceAddPage,
  },
  {
    path: '/reduction/evidence/detail/:slug',
    Layout: MainLayout,
    Component: ReductionEvidenceDetailPage,
  },
  {
    path: '/credit/issue',
    Layout: MainLayout,
    Component: CreditListPage,
  },
  {
    path: '/credit/move',
    Layout: MainLayout,
    Component: CreditListPage,
  },
  {
    path: '/credit/offset',
    Layout: MainLayout,
    Component: CreditListPage,
  },
  {
    path: '/credit/retire',
    Layout: MainLayout,
    Component: CreditListPage,
  },
  {
    path: '/credit/detail/:slug',
    Layout: MainLayout,
    Component: CreditDetailPage,
  },
  {
    path: '/marketplace/eu_ets/list',
    Layout: MainLayout,
    Component: EuEtsListPage,
  },
  {
    path: '/marketplace/eu_ets/detail/:slug',
    Layout: MainLayout,
    Component: EuEtsDetailPage,
  },
  {
    path: '/marketplace/eu_ets/add',
    Layout: MainLayout,
    Component: EuEtsAddPage,
  },
  {
    path: '/payment/list',
    Layout: MainLayout,
    Component: PaymentListPage,
  },
  {
    path: '/payment/detail/:slug',
    Layout: MainLayout,
    Component: PaymentDetailPage,
  },
  {
    path: '/payment/withdraw_list',
    Layout: MainLayout,
    Component: PaymentWithdrawListPage,
  },
  {
    path: '/payment/withdraw_detail/:slug',
    Layout: MainLayout,
    Component: PaymentWithdrawDetailPage,
  },
  {
    path: '/payment/revenue_list',
    Layout: MainLayout,
    Component: PaymentRevenueListPage,
  },
  {
    path: '/payment/revenue_detail/:slug',
    Layout: MainLayout,
    Component: PaymentRevenueDetailPage,
  },
  {
    path: '/trading/inquiry_list',
    Layout: MainLayout,
    Component: ForwardInquiryListPage,
  },
  {
    path: '/trading/inquiry_detail/:slug',
    Layout: MainLayout,
    Component: ForwardInquiryDetailPage,
  },
  {
    path: '/trading/contract_list',
    Layout: MainLayout,
    Component: ForwardContractListPage,
  },
  {
    path: '/trading/contract_detail/:slug',
    Layout: MainLayout,
    Component: ForwardContractDetailPage,
  },
  {
    path: '/trading/contract_add',
    Layout: MainLayout,
    Component: ForwardContractAddPage,
  },
  {
    path: '/consultation/list',
    Layout: MainLayout,
    Component: ConsultationListPage,
  },
  {
    path: '/consultation/detail/:slug',
    Layout: MainLayout,
    Component: ConsultationDetailPage,
  },
  {
    path: '*',
    Layout: LoginLayout,
    Component: NotFoundPage,
  },
];

const Routers = () => {
  return (
    <Router>
      <Switch>
        {ROUTER_LIST.map((item, index) => {
          const { Layout, Component, path, protect, props } = item;
          if (protect) {
            return (
              <PrivateRoute key={'route-' + index} path={path} {...props}>
                <Layout>
                  <Component></Component>
                </Layout>
              </PrivateRoute>
            );
          } else {
            return (
              <Route key={'route-' + index} path={path} {...props}>
                <Layout>
                  <Component></Component>
                </Layout>
              </Route>
            );
          }
        })}
      </Switch>
    </Router>
  );
};

function PrivateRoute({ children, ...rest }) {
  const { accessToken } = useContext(UserContext);
  // const { path } = rest;
  let isValidation = false;
  if (accessToken) {
    isValidation = true;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isValidation ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default Routers;
