import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { TableBox, SearchFilter, Paging } from '../../components';
import { usePagination, useFilter } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Button from '@mui/material/Button';
import Handler from '../../Handler';
import API from '../../api';

export const PaymentListPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState([
    {
      name: 'name',
      description: 'description',
    },
    {
      name: 'name2',
      description: 'description2',
    },
  ]);
  const [header, setHeader] = useState([
    {
      key: 'seq',
      align: 'center',
      text: 'No',
      style: {
        minWidth: 50,
      },
    },
    {
      key: 'orderNumber',
      align: 'center',
      text: '주문번호',
      style: {
        minWidth: 80,
      },
    },
    {
      key: 'paidAt',
      align: 'center',
      text: '결제일시',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        return !!value && Handler.getYYYYMMDDhhmmssByUnix(value);
      },
    },
    {
      key: 'price',
      align: 'center',
      text: '결제금액',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        return Handler.numberWithCommas(value);
      },
    },
    {
      key: 'type',
      align: 'center',
      text: '결제 수단',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        switch (value) {
          case 'CARD': {
            return '신용카드';
          }
          case 'BANK': {
            return '계좌이체';
          }
          case 'VBANK': {
            return '가상계좌';
          }
          case 'MOBILE': {
            return '모바일';
          }
          case 'TAXBILL': {
            return '세금계산서\n발행';
          }
          // case 'BCSH': {
          //   return 'BCSH'
          // }
          // case 'CULTURE': {
          //   return '문화상품권'
          // }
          // case 'HPMN': {
          //   return '입금완료'
          // }
          // case 'EWALLET': {
          //   return 'EWALLET'
          // }
          // case 'ETC_': {
          //   return '기타'
          // }
          default: {
            return 'none';
          }
        }
      },
    },
    {
      key: 'status',
      align: 'center',
      text: '결제상태',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        switch (value) {
          case 'DepositReady': {
            return '입금대기';
          }
          case 'DepositCancel': {
            return '입금취소';
          }
          case 'DepositComplete': {
            return <span style={{ color: '#4458b8' }}>입금완료</span>;
          }
          case 'Complete': {
            return <span style={{ color: '#4458b8' }}>결제완료</span>;
          }
          case 'Waiting': {
            return '승인대기';
          }
          default: {
            return 'none';
          }
        }
      },
    },
    {
      key: null,
      align: 'center',
      text: '사업ID',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return value.project ? value.project.id : '';
      },
    },
    {
      key: null,
      align: 'center',
      text: '사업명',
      style: {
        minWidth: 200,
      },
      render: (value) => {
        return value.project ? value.project.title : '';
      },
    },
    {
      key: 'buyerName',
      align: 'center',
      text: '구매자명',
      style: {
        minWidth: 80,
      },
    },
    {
      key: null,
      align: 'center',
      text: '이메일',
      style: {
        minWidth: 200,
      },
      render: (value) => {
        return value.user ? value.user.email : '';
      },
    },
    {
      key: 'purchaseType',
      align: 'center',
      text: '거래 구분',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        switch (value) {
          case 'ForwardPersonal': {
            return '개인선도';
          }
          case 'NormalPersonal': {
            return '개인일반';
          }
          case 'ForwardEnterprise': {
            return '기업선도';
          }
          case 'NormalEnterprise': {
            return '기업일반';
          }
          default: {
            return 'none';
          }
        }
      },
    },
    {
      key: 'salePrice',
      align: 'center',
      text: '판매가격(톤)',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        return Handler.numberWithCommas(value);
      },
    },
    {
      key: 'amount',
      align: 'center',
      text: '구매수량',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        return Handler.numberWithCommas(value);
      },
    },
  ]);
  const [filter, onFilterChange, setFiler] = useFilter([
    {
      key: 'paymentStatus',
      type: 'select',
      label: '결제 상태',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: 'DepositComplete',
          label: '입금완료',
        },
        {
          value: 'DepositReady',
          label: '입금대기',
        },
        {
          value: 'DepositCancel',
          label: '입금취소',
        },
        {
          value: 'Complete',
          label: '결제완료',
        },
      ],
    },
    {
      key: 'paymentType',
      type: 'select',
      label: '결제 수단',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: 'CARD',
          label: '신용카드',
        },
        {
          value: 'BANK',
          label: '계좌이체',
        },
        {
          value: 'VBANK',
          label: '가상계좌',
        },
        {
          value: 'TAXBILL',
          label: '세금계산서 발행',
        },
        // {
        //   value: 'MOBILE',
        //   label: '모바일',
        // },
        // {
        //   value: 'BCSH',
        //   label: 'BCSH',
        // },
        // {
        //   value: 'CULTURE',
        //   label: '문화상품권',
        // },
        // {
        //   value: 'HPMN',
        //   label: '입금완료',
        // },
        // {
        //   value: 'EWALLET',
        //   label: 'EWALLET',
        // },
        // {
        //   value: 'ETC_',
        //   label: '기타',
        // },
      ],
    },
    {
      key: 'purchaseType',
      type: 'select',
      label: '거래 구분',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: 'ForwardPersonal',
          label: '개인선도',
        },
        {
          value: 'NormalPersonal',
          label: '개인일반',
        },
        {
          value: 'ForwardEnterprise',
          label: '기업선도',
        },
        {
          value: 'NormalEnterprise',
          label: '기업일반',
        },
      ],
    },
    {
      key: 'startDate',
      type: 'date',
      label: '시작일',
      value: null,
    },
    {
      key: 'endDate',
      type: 'date',
      label: '종료일',
      value: null,
    },
    {
      key: 'searchType',
      type: 'select',
      label: '검색 종류',
      value: 'Name',
      menus: [
        {
          value: 'Name',
          label: '구매자명',
        },
        {
          value: 'Email',
          label: '이메일',
        },
        {
          value: 'OrderNumber',
          label: '주문번호',
        },
        {
          value: 'ProjectTitle',
          label: '사업명',
        },
        {
          value: 'ProjectId',
          label: '사업ID',
        },
      ],
    },
    {
      key: 'searchValue',
      type: 'input',
      label: '검색 값',
      value: '',
      inputType: 'text',
    },
  ]);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  const tableCallback = (type, index) => {
    console.log('type', type);
    console.log('index', index);
  };

  const onSearch = (body) => {
    console.log('onSearch', body);
    requestNewList(0);
  };

  useEffect(() => {
    console.log('page effect', page);
    requestNewList(page);
  }, [page]);

  const requestNewList = (init_page) => {
    let body = Handler.filterToBody(filter);
    console.log('page body', body);
    API.Payment.GetList({
      ...body,
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
    }).then(
      (res) => {
        console.log('res', res);
        let result = res.data;
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const tableRowClick = (index) => {
    console.log('tableRowClick index', index);
    let target_seq = list[index].seq;
    console.log('tableRowClick target_seq', target_seq);
    history.push('/payment/detail/' + target_seq);
  };

  console.log('list', list);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>결제 목록</h3>
        </header>
      </section>
      <section className='filter__container'>
        <SearchFilter data={filter} onChange={onFilterChange} onSearch={onSearch}></SearchFilter>
      </section>
      <section className='table__container'>
        <TableBox data={list} header={header} rowClick={tableRowClick} callback={tableCallback}></TableBox>
      </section>
      <section className='paging__container'>
        <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
      </section>
    </article>
  );
};
