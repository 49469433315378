import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { TableBox, SearchFilter, Paging } from '../../components';
import { usePagination, useFilter } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import dayjs from 'dayjs';
import Handler from '../../Handler';
import API from '../../api';

export const NewsListPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState(null);
  const [header, setHeader] = useState([
    {
      key: 'seq',
      align: 'center',
      text: 'No',
      style: {
        minWidth: 50,
      },
    },
    {
      key: 'show',
      align: 'center',
      text: '공개여부',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return value ? '공개' : '비공개';
      },
    },
    {
      key: 'isMain',
      align: 'center',
      text: '메인뉴스 여부',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return value ? '메인' : '-';
      },
    },
    {
      key: 'press',
      align: 'center',
      text: '언론사',
      style: {
        minWidth: 50,
      },
    },
    {
      key: 'pressDate',
      align: 'center',
      text: '기사 보도일',
      style: {
        minWidth: 50,
      },
      render: (value) => {
        return value ? dayjs(value).format('YYYY/MM/DD') : '';
      },
    },
    {
      key: 'title',
      align: 'center',
      text: '제목',
      style: {
        minWidth: 100,
      },
    },
  ]);

  const [filter, onFilterChange, setFiler] = useFilter([    
    {
      key: 'searchType',
      type: 'select',
      label: '검색 종류',
      value: 'Title',
      menus: [
        {
          value: 'Title',
          label: '제목',
        },
        {
          value: 'Press',
          label: '언론사',
        },
      ],
    },
    {
      key: 'searchValue',
      type: 'input',
      label: '검색 값',
      value: '',
      inputType: 'text',
    },
  ]);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  const tableCallback = (type, index) => {};

  const tableRowClick = (index) => {
    let target_seq = list[index].seq;
    history.push('/marketplace/news/detail/' + target_seq);
  };

  const onSearch = (body) => {
    requestNewList(0);
  };

  useEffect(() => {
    requestNewList();
  }, [page]);

  const requestNewList = (init_page) => {
    let body = Handler.filterToBody(filter);
    API.Marketplace.GetNewsList({
      ...body,
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
    }).then(
      (res) => {
        let result = res.data;
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requestAdd = () => {
    history.push('/marketplace/news/add');
  };

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>뉴스 목록</h3>
        </header>
        <div className='button__box'>
          <Button variant='contained' onClick={requestAdd}>
            추가
          </Button>
        </div>
      </section>
      <section className='filter__container'>
        <SearchFilter data={filter} onChange={onFilterChange} onSearch={onSearch}></SearchFilter>
      </section>
      <section className='table__container'>
        <TableBox data={list} header={header} rowClick={tableRowClick} callback={tableCallback}></TableBox>
      </section>
      <section className='paging__container'>
        <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
      </section>
    </article>
  );
};
