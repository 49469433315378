import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'market_place/';

const GetList = (params) => {
  const url = BASE_URL + 'market_place' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetDetail = (seq) => {
  const url = BASE_URL + PATH + seq;
  return AxiosInstance.get(url);
};

const Put = (seq, body) => {
  const url = BASE_URL + PATH + seq;
  return AxiosInstance.put(url, body);
};

const NewsList = (params) => {
  const url = BASE_URL + 'news' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const NewsDetail = (seq) => {
  const url = BASE_URL + 'news/' + seq;
  return AxiosInstance.get(url);
};

const PostNews = (body) => {
  const url = BASE_URL + 'news';
  return AxiosInstance.post(url, body);
};

const PutNews = (seq, body) => {
  const url = BASE_URL + 'news/' + seq;
  return AxiosInstance.put(url, body);
};

const DeleteNews = (seq, body) => {
  const url = BASE_URL + 'news/' + seq;
  return AxiosInstance.delete(url, body);
};

const Marketplace = {
  GetList: GetList,
  GetDetail: GetDetail,
  Put: Put,
  GetNewsList: NewsList,
  GetNewsDetail: NewsDetail,
  PostNews: PostNews,
  PutNews: PutNews,
  DeleteNews: DeleteNews,
};

export default Marketplace;
