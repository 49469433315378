import AxiosInstance from '../AxiosInstance';
import { BASE_URL } from '../Config';

const PATH = 'feedback/';

const PostFeedback = (body) => {
  const url = BASE_URL + PATH;
  return AxiosInstance.post(url, body);
};

const Feedback = {
  PostFeedback: PostFeedback,
};

export default Feedback;
