import React, { useEffect, useContext, useMemo, useState } from 'react';
import { format } from 'date-fns';
import Button from '@mui/material/Button';
import {
  TextInput,
  SelectBox,
  Check,
  DatePicker,
  DatePickerBox,
  FileUploader,
  CardProjectStatus,
  TextViewer,
  ModalProjectAction,
  ModalProjectView,
  CardProjectMemo,
  TableBox,
  Paging,
  ModalOpenConsultation,
} from '../../components';
import { useForm, usePagination } from '../../hooks';
import { SnackbarContext, UserContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 860,
  bgcolor: 'background.paper',
  border: '2px solid #bcbcbc',
  boxShadow: 24,
  p: 0,
  borderRadius: 2,
  overflow: 'hidden',
};

export const ProjectDetailPage = (props) => {
  const history = useHistory();
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { accessToken, businessInfo } = useContext(UserContext);
  const [methodologyInfo, setMethodologyInfo] = useState(null);
  const [initData, setInitData] = useState(false);
  const [isConsultationOpen, setIsConsultationOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [actionOpen, setActionOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [consultationOpen, setConsultationOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedNextAction, setSelectedNextAction] = useState(null);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      id: '',
      title: '',
      titleEn: '',
      introOne: '',
      introOneEn: '',
      introTwo: '',
      introTwoEn: '',
      methodologyId: '',
      area: '',
      subArea: '',
      reductionExpect: '',
      monitorDegree: '',
      startDate: '',
      endDate: '',
      reductionStartDate: '',
      reductionEndDate: '',
      retirable: false,
      companyName: '',
      companyNameEn: '',
      ceoName: '',
      ceoNameEn: '',
      email: '',
      businessNumber: '',
      businessFile: '',
    },
    {
      retirable: {
        key: 'retirable',
        label: '폐기가능',
        fullWidth: true,
        required: false,
        InputProps: {
          readOnly: false,
        },
      },
      id: {
        key: 'id',
        label: '프로젝트 ID',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
        disabled: true,
      },
      title: {
        key: 'title',
        label: '프로젝트 명',
        fullWidth: true,
        required: true,
      },
      titleEn: {
        key: 'titleEn',
        label: '프로젝트 명(영문)',
        fullWidth: true,
        required: true,
      },
      introOne: {
        key: 'introOne',
        label: '2줄 소개(첫째줄)',
        fullWidth: true,
        required: true,
      },
      introOneEn: {
        key: 'introOneEn',
        label: '2줄 소개(첫째줄)(영문)',
        fullWidth: true,
        required: true,
      },
      introTwo: {
        key: 'introTwo',
        label: '2줄 소개(둘째줄)',
        fullWidth: true,
        required: true,
      },
      introTwoEn: {
        key: 'introTwoEn',
        label: '2줄 소개(둘째줄)(영문)',
        fullWidth: true,
        required: true,
      },
      methodologyId: {
        key: 'methodologyId',
        label: '적용 방법론',
        InputProps: {
          readOnly: true,
        },
        render: (value) => {
          return value;
        },
        disabled: true,
      },
      area: {
        key: 'area',
        label: '방법론 감축 분야',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
        disabled: true,
      },
      subArea: {
        key: 'subArea',
        label: '방법론 세부 분야',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
        disabled: true,
      },
      reductionExpect: {
        key: 'reductionExpect',
        label: '예상 감축량(tCO2eq)',
        fullWidth: true,
        required: true,
      },
      monitorDegree: {
        key: 'monitorDegree',
        label: '모니터링 차수',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
        disabled: true,
      },
      reductionStartDate: {
        key: 'reductionStartDate',
        label: '감축기간 시작일',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
        disabled: true,
      },
      reductionEndDate: {
        key: 'reductionEndDate',
        label: '감축기간 종료일',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
        disabled: true,
      },
      startDate: {
        key: 'startDate',
        label: '유효기간 시작일',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
        disabled: true,
      },
      endDate: {
        key: 'endDate',
        label: '유효기간 종료일',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
        disabled: true,
      },
      companyName: {
        key: 'companyName',
        label: '프로젝트 실행 기업',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
        disabled: true,
      },
      companyNameEn: {
        key: 'companyNameEn',
        label: '프로젝트 실행 기업(영문)',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
        disabled: true,
      },
      ceoName: {
        key: 'ceoName',
        label: '대표자명',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
        disabled: true,
      },
      ceoNameEn: {
        key: 'ceoNameEn',
        label: '대표자명(영문)',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
        disabled: true,
      },
      email: {
        key: 'email',
        label: '이메일',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
        disabled: true,
      },
      businessNumber: {
        key: 'businessNumber',
        label: '사업자등록번호',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
        disabled: true,
      },
      businessFile: {
        type: 'file',
        key: 'businessFile',
        label: '사업자등록증',
        path: 'project/business_file',
        required: true,
        disabled: true,
      },
    },
  );
  const [list, setList] = useState(null);
  const [header, setHeader] = useState([
    {
      key: 'createdAt',
      align: 'center',
      text: '일자',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.getYYYYMMDDByUnix(value);
      },
    },
    {
      key: '',
      align: 'center',
      text: '상태',
      style: {
        minWidth: 100,
      },
      render: (row) => {
        switch (row.status) {
          case 'WaitingForAuditLaunch':
          case 'WaitingForAuditProject':
          case 'AuditProject': {
            return Handler.ENUM.ACTION.STATUS(row.status) + '(' + row.degree + ')';
          }
          default: {
            return Handler.ENUM.ACTION.STATUS(row.status);
          }
        }
      },
    },
    {
      key: '',
      align: 'center',
      text: '문서 버전',
      style: {
        minWidth: 100,
      },
      render: (row) => {
        if (row.isNewVersion) {
          return (
            <Button
              variant='contained'
              onClick={(e) => {
                e.stopPropagation();
                console.log('row', row);

                let open_url = Handler.getPublicUrlPath();;
                console.log('open_url', window.screen);
                // open_url +=
                //   'public/project?seq=' +
                //   row.projectDoc.project.seq +
                //   '&version=' +
                //   row.projectVersion +
                //   '&token=' +
                //   token;

                open_url +=
                  'public/project/' + row.projectDoc.project.seq + '/' + row.projectVersion + '/' + accessToken;
                var left = window.screen.width / 2 - 1280 / 2;
                var top = window.screen.height / 2 - 860 / 2;
                console.log('left', left);
                console.log('open_url', open_url);

                const encoded = encodeURI(open_url);
                console.log('encoded', encoded);
                // return;
                let win = window.open(
                  encoded,
                  '',
                  `status=no, menubar=no, toolbar=no, resizable=no, width=1280, height=800, left=${left}, top=${top}`,
                );
              }}
            >
              {row.projectVersion}
            </Button>
          );
        } else {
          return '-';
        }
      },
    },
    {
      key: '',
      align: 'center',
      text: '상세보기',
      style: {
        minWidth: 100,
      },
      render: (row) => {
        switch (row.status) {
          case 'WaitingForAuditProject': {
            return '-';
          }
          default: {
            return (
              <Button
                variant='contained'
                onClick={(e) => {
                  e.stopPropagation();
                  console.log('row', row);
                  setSelectedAction(row);
                  setViewOpen(true);
                }}
              >
                상세보기
              </Button>
            );
          }
        }
      },
    },
    {
      key: 'adminId',
      align: 'center',
      text: '담당자',
      style: {
        minWidth: 100,
      },
    },
  ]);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  //hooks
  useEffect(() => {
    if (slug) {
      refreshData();
    }
  }, [slug]);

  useEffect(() => {
    if (initData) {
      console.log('initData', initData);
      setFormData({
        id: initData.id,
        title: initData.title,
        titleEn: initData.titleEn,
        introOne: initData.introOne,
        introOneEn: initData.introOneEn,
        introTwo: initData.introTwo,
        introTwoEn: initData.introTwoEn,
        retirable: initData.retirable,
        methodologyId: initData.methodology?.id,
        area: Handler.ENUM.AREA(initData.methodology?.area),
        subArea: Handler.ENUM.SUB_AREA(initData.methodology?.subArea),
        reductionExpect: initData.reductionExpect,
        monitorDegree: initData.monitorDegree,
        companyName: initData.user.companyName,
        companyNameEn: initData.user.companyNameEn,
        ceoName: initData.user.ceoName,
        ceoNameEn: initData.user.ceoNameEn,
        email: initData.user.email,
        businessNumber: initData.user.businessNumber,
        businessFile: initData.user.businessFile,
        startDate: format(new Date(initData.startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(initData.endDate), 'yyyy-MM-dd'),
        reductionStartDate: format(new Date(initData.reductionStartDate), 'yyyy-MM-dd'),
        reductionEndDate: format(new Date(initData.reductionEndDate), 'yyyy-MM-dd'),
      });
      setFormMeta({ ...formMeta });
      if (initData.consultation) {
        setIsConsultationOpen(initData.consultation.isOpen);
      }
    }
  }, [initData]);

  useEffect(() => {
    console.log('page effect', page);
    requestNewList();
  }, [page]);

  const canOpenConsultation = useMemo(() => {
    const canOpen = ['AuditProject', 'AuditLaunch'];
    return canOpen.includes(initData?.lastProjectDoc?.projectDocAction?.status);
  }, [initData?.lastProjectDoc?.projectDocAction?.status]);

  //event

  const handleEvent = (type) => {
    if (type === 'OPEN_METHODOLOGY') {
      history.push('/methodology/detail/' + initData.methodology.seq)
    }
  };

  const handleAction = (action) => {
    setSelectedAction(initData.lastProjectDoc.projectDocAction);
    setSelectedNextAction(action);
    setActionOpen(true);
  };

  const handleModalRequest = (body, data) => {
    setButtonDisabled(true);
    API.Project.PostNextAction(slug, body).then(
      (res) => {
        closeActionModal();
        setButtonDisabled(false);
        refreshData();
        requestNewList(0);
        setPage(0);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const handleConsultationRequest = (body) => {
    const slug = initData.consultation.seq || null;
    body.projectId = initData.seq;
    const API_REQUEST = slug ? API.Consultation.Put(slug, body) : API.Consultation.Post(body);
    API_REQUEST.then(
      (res) => {
        let result = res.data;
        console.log('PostNextAction  result', result);
        updateSnackbar({
          type: 'success',
          open: true,
          message: `논평이 ${slug ? '수정' : '등록'}되었습니다.`,
        });
        closeConsultationModal();
        refreshData();
        requestNewList(0);
        setPage(0);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  //table event
  const tableCallback = (type, index) => {
    console.log('tableCallback type', type);
    console.log('tableCallback index', index);
  };

  const tableRowClick = (index) => {
    console.log('tableRowClick index', index);
    let target_seq = list[index].seq;
    console.log('tableRowClick target_seq', target_seq);
  };

  //server

  const refreshData = () => {
    API.Project.GetDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log('Project result', result);
        setInitData(result);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requestNewList = (init_page) => {
    API.Project.GetAction(slug, {
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
    }).then(
      (res) => {
        console.log('res', res);
        let result = res.data;
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requestSave = () => {
    let body = Handler.formToBody(formData, formMeta);
    if (body) {
      let db_body = {
        title: body.title,
        titleEn: body.titleEn,
        introOne: body.introOne,
        introOneEn: body.introOneEn,
        introTwo: body.introTwo,
        introTwoEn: body.introTwoEn,
        retirable: body.retirable,
      };
      API.Project.Put(slug, db_body).then(
        (res) => {
          updateSnackbar({
            type: 'success',
            open: true,
            message: '저장 성공',
          });
        }).catch(
          (err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          }
        )
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  console.log('formData', formData);
  console.log('formMeta', formMeta);
  console.log('initData', initData);

  const closeModal = () => {
    setOpen(false);
    setSelectedNextAction(null);
  };

  const closeActionModal = () => {
    setActionOpen(false);
  };

  const closeViewModal = () => {
    setViewOpen(false);
  };

  const closeConsultationModal = () => {
    setConsultationOpen(false);
  };

  const showAndHideConsultation = () => {
    const body = { ...initData.consultation };
    body.isOpen = !body.isOpen;

    console.log('consultation body', body);

    API.Consultation.Put(body.seq, body).then(
      (res) => {
        let result = res.data;
        updateSnackbar({
          type: 'success',
          open: true,
          message: '논평이 수정되었습니다.',
        });
        closeActionModal();
        refreshData();
        requestNewList(0);
        setPage(1);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  if (!initData) {
    return null;
  }

  console.log('list', list);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>프로젝트 상세</h3>
        </header>
        <div className='button__box'>
          <Button variant='contained' onClick={requestSave}>
            저장
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        {!initData ? (
          <div className='loading__container'>
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <div className='flex'>
            <Check value={formData.retirable} onChange={formChange} meta={formMeta.retirable}></Check>
            <TextInput value={formData.id} onChange={formChange} meta={formMeta.id}></TextInput>
            <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
            <TextInput value={formData.titleEn} onChange={formChange} meta={formMeta.titleEn}></TextInput>
            <TextInput value={formData.introOne} onChange={formChange} meta={formMeta.introOne}></TextInput>
            <TextInput value={formData.introOneEn} onChange={formChange} meta={formMeta.introOneEn}></TextInput>
            <TextInput value={formData.introTwo} onChange={formChange} meta={formMeta.introTwo}></TextInput>
            <TextInput value={formData.introTwoEn} onChange={formChange} meta={formMeta.introTwoEn}></TextInput>
            <TextViewer
              value={formData.methodologyId}
              meta={formMeta.methodologyId}
              customButton={{
                label: '해당 방법론 보기',
                onClick: () => {
                  handleEvent('OPEN_METHODOLOGY');
                },
              }}
            ></TextViewer>
            <TextInput value={formData.area} onChange={formChange} meta={formMeta.area}></TextInput>
            <TextInput value={formData.subArea} onChange={formChange} meta={formMeta.subArea}></TextInput>
            <TextInput value={formData.reductionExpect} onChange={formChange} meta={formMeta.reductionExpect}></TextInput>
            <TextInput value={formData.monitorDegree} onChange={formChange} meta={formMeta.monitorDegree}></TextInput>
            <DatePickerBox value={formData.startDate} onChange={formChange} meta={formMeta.startDate}></DatePickerBox>
            <DatePickerBox value={formData.endDate} onChange={formChange} meta={formMeta.endDate}></DatePickerBox>
            <DatePickerBox
              value={formData.reductionStartDate}
              onChange={formChange}
              meta={formMeta.reductionStartDate}
            ></DatePickerBox>
            <DatePickerBox
              value={formData.reductionEndDate}
              onChange={formChange}
              meta={formMeta.reductionEndDate}
            ></DatePickerBox>
            <TextInput value={formData.companyName} onChange={formChange} meta={formMeta.companyName}></TextInput>
            <TextInput value={formData.companyNameEn} onChange={formChange} meta={formMeta.companyNameEn}></TextInput>
            <TextInput value={formData.ceoName} onChange={formChange} meta={formMeta.ceoName}></TextInput>
            <TextInput value={formData.ceoNameEn} onChange={formChange} meta={formMeta.ceoNameEn}></TextInput>
            <TextInput value={formData.email} onChange={formChange} meta={formMeta.email}></TextInput>
            <TextInput value={formData.businessNumber} onChange={formChange} meta={formMeta.businessNumber}></TextInput>
            <FileUploader
              value={formData.businessFile}
              onChange={formChange}
              meta={formMeta.businessFile}
            ></FileUploader>
          </div>
        )}
      </section>
      <section className='card__container' style={{ marginTop: 40 }}>
        <CardProjectStatus
          category={'project'}
          data={initData.lastProjectDoc}
          nextActionTypeList={initData.nextActionTypeList}
          onAction={handleAction}
        ></CardProjectStatus>
      </section>
      
      {/* TODO: 공개논평 기능 관련 추가 기획 필요
      <section className='table-header__container' style={{ marginTop: 40 }}>
        <header>
          <h3>논평</h3>
        </header>
        {initData.consultation ? (
          <article>
            <div className='py-4'>
              <span className='bg-slate-100 text-gray-700 font-bold text-sm px-3 py-1'>
                {initData.consultation.isOpen ? '공개' : '비공개'}
              </span>
              <p className='font-bold mt-3'>{initData.consultation.title}</p>
              <p>
                기간: {dayjs(initData.consultation.startDate).format('YYYY.MM.DD')} ~{' '}
                {dayjs(initData.consultation.endDate).format('YYYY.MM.DD')}
              </p>
            </div>
            <div className='flex gap-2'>
              {initData.consultation.status === 'Open' && (
                <Button variant='contained' onClick={() => console.log('')}>
                  논평종료
                </Button>
              )}
              <Button variant='contained' color='info' onClick={() => setConsultationOpen(true)}>
                논평수정
              </Button>
            </div>
          </article>
        ) : (
          <article>
            <p className='py-6 text-center'>개설된 논평이 없습니다.</p>
              <div>
                <Button variant='contained' onClick={() => setConsultationOpen(true)}>
                  논평열기
                </Button>
              </div>
          </article>
        )}
      </section> */}

      <section className='table-header__container' style={{ marginTop: 40 }}>
        <header>
          <h3>이력 조회</h3>
        </header>
        <TableBox data={list} header={header} rowClick={tableRowClick} callback={tableCallback}></TableBox>
        <section className='paging__container' style={{ marginTop: 20, marginBottom: 0 }}>
          <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
        </section>
      </section>

      <section className='card__container' style={{ marginTop: 40 }}>
        <header>
          <h3>운영자 메모</h3>
        </header>
        <section className='card__box'>
          <CardProjectMemo value={slug}></CardProjectMemo>
        </section>
      </section>
      <Modal open={actionOpen} onClose={closeActionModal}>
        <Box sx={style}>
          <ModalProjectAction
            action={selectedAction}
            nextAction={selectedNextAction}
            requestAction={handleModalRequest}
            onClose={closeActionModal}
            disabled={buttonDisabled}
          ></ModalProjectAction>
        </Box>
      </Modal>
      <Modal open={viewOpen} onClose={closeViewModal}>
        <Box sx={style}>
          <ModalProjectView action={selectedAction} onClose={closeViewModal}></ModalProjectView>
        </Box>
      </Modal>
      <Modal open={consultationOpen} onClose={closeConsultationModal}>
        <Box sx={style}>
          <ModalOpenConsultation
            data={initData}
            type='project'
            requestAction={handleConsultationRequest}
            onClose={closeConsultationModal}
          ></ModalOpenConsultation>
        </Box>
      </Modal>
    </article>
  );
};
