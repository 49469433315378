import React from 'react';
import { TextInput, TextViewer, FileViewer } from '../../components';
import Handler from '../../Handler';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

export const ModalCreditView = (props) => {
  const { mode, action, onClose } = props;

  console.log('ModalAction action', action);

  if (!action) {
    return null;
  }
  console.log('[View] action.status', action.status);

  const renderInfo = () => {
    switch (mode) {
      case 'Issue': {
        return (
          <section className='section__container'>
            <header>
              <span>신청 정보</span>
            </header>
            <ul className='form-list'>
              <TextViewer
                value={action.projectTitle}
                meta={{
                  label: '사업명',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.projectId}
                meta={{
                  label: '사업 ID',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>

              <TextViewer
                value={Handler.numberWithCommas(action.amount)}
                meta={{
                  label: '발행수량',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={Handler.getYYYYMMDDByUnix(action.createdAt)}
                meta={{
                  label: '신청일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
        );
      }
      case 'Move': {
        return (
          <section className='section__container'>
            <header>
              <span>신청 정보</span>
            </header>
            <ul className='form-list'>
              <TextViewer
                value={action.projectTitle}
                meta={{
                  label: '사업명',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.projectId}
                meta={{
                  label: '사업 ID',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.assignorName}
                meta={{
                  label: '양도인',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.assigneeName}
                meta={{
                  label: '양수인',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={Handler.numberWithCommas(action.amount)}
                meta={{
                  label: '이전수량',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={Handler.getYYYYMMDDByUnix(action.createdAt)}
                meta={{
                  label: '신청일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
        );
      }
      case 'Offset': {
        return (
          <section className='section__container'>
            <header>
              <span>신청 정보</span>
            </header>
            <ul className='form-list'>
              <TextViewer
                value={action.projectTitle}
                meta={{
                  label: '사업명',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.projectId}
                meta={{
                  label: '사업 ID',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={Handler.numberWithCommas(action.amount)}
                meta={{
                  label: '철회 수량',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={Handler.getYYYYMMDDByUnix(action.createdAt)}
                meta={{
                  label: '신청일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
        );
      }
      default: {
        return null;
      }
    }
  };

  switch (action.status) {
    case 'WaitingForAuditLaunch': {
      return (
        <article className='modal-action'>
          <header>
            <h3>{Handler.ENUM.CREDIT_ACTION.STATUS(action.status)}</h3>
            <IconButton style={{ marginRight: -10 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </header>
          {renderInfo()}
          <section className='section__container'>
            <header>
              <span>Comment (유저)</span>
            </header>
            <ul className='form-list'>
              <TextViewer
                value={action.projectLaunchDate}
                meta={{
                  label: '사업착수일시',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextInput
                value={action.userComment}
                meta={{
                  key: 'content',
                  label: '의견',
                  type: 'text',
                  multiline: true,
                  fullWidth: true,
                  InputProps: {
                    readOnly: true,
                  },
                }}
              ></TextInput>
              {action.userCommentFile && (
                <FileViewer
                  value={action.userCommentFile}
                  meta={{
                    label: '첨부파일',
                  }}
                ></FileViewer>
              )}
              <TextViewer
                value={action.userCommentDate}
                meta={{
                  label: '작성일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
        </article>
      );
    }
    case 'WritingZCCreditForImprove': {
      return (
        <article className='modal-action'>
          <header>
            <h3>{Handler.ENUM.CREDIT_ACTION.STATUS(action.status)}</h3>
            <IconButton style={{ marginRight: -10 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </header>
          {renderInfo()}
        </article>
      );
    }
    case 'AuditCancel': {
      return (
        <article className='modal-action'>
          <header>
            <h3>{Handler.ENUM.CREDIT_ACTION.STATUS(action.status)}</h3>
            <IconButton style={{ marginRight: -10 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </header>
          {renderInfo()}

          <section className='section__container'>
            <header>
              <span>Comment (유저)</span>
            </header>
            <ul className='form-list'>
              <TextViewer
                value={Handler.ENUM.CREDIT_ACTION.STOP_REASON(action.stopReason)}
                meta={{
                  label: '구분',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextInput
                value={action.userComment}
                meta={{
                  key: 'content',
                  label: '사유',
                  type: 'text',
                  multiline: true,
                  fullWidth: true,
                  InputProps: {
                    readOnly: true,
                  },
                }}
              ></TextInput>
              {action.userCommentFile && (
                <FileViewer
                  value={action.userCommentFile}
                  meta={{
                    label: '첨부파일',
                  }}
                ></FileViewer>
              )}
              <TextViewer
                value={action.userCommentDate}
                meta={{
                  label: '작성일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
        </article>
      );
    }
    case 'CommitteeGiveUp':
    case 'LaunchGiveUp':
    case 'LaunchRejected':
    case 'ZCCreditGiveUp':
    case 'ZCCreditRejected': {
      return (
        <article className='modal-action'>
          <header>
            <h3>{Handler.ENUM.CREDIT_ACTION.STATUS(action.status)}</h3>
            <IconButton style={{ marginRight: -10 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </header>
          {renderInfo()}
          <section className='section__container'>
            <header>
              <span>Comment (관리자)</span>
            </header>
            <ul className='form-list'>
              <TextInput
                value={action.adminComment}
                meta={{
                  key: 'content',
                  label: '의견',
                  type: 'text',
                  multiline: true,
                  fullWidth: true,
                  InputProps: {
                    readOnly: true,
                  },
                }}
              ></TextInput>
              {action.adminCommentFile && (
                <FileViewer
                  value={action.adminCommentFile}
                  meta={{
                    label: '첨부파일',
                  }}
                ></FileViewer>
              )}
              <TextViewer
                value={action.adminCommentDate}
                meta={{
                  label: '작성일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
          {action.userComment && (
            <section className='section__container'>
              <header>
                <span>Reply (유저)</span>
              </header>
              <ul className='form-list'>
                <TextInput
                  value={action.userComment}
                  meta={{
                    key: 'content',
                    label: '사유',
                    type: 'text',
                    multiline: true,
                    fullWidth: true,
                    InputProps: {
                      readOnly: true,
                    },
                  }}
                ></TextInput>
                {action.userCommentFile && (
                  <FileViewer
                    value={action.userCommentFile}
                    meta={{
                      label: '첨부파일',
                    }}
                  ></FileViewer>
                )}
                <TextViewer
                  value={action.userCommentDate}
                  meta={{
                    label: '작성일',
                    style: {},
                    render: (value) => {
                      return value;
                    },
                  }}
                ></TextViewer>
              </ul>
            </section>
          )}
        </article>
      );
    }
    case 'CanceledZCCredit': {
      return (
        <article className='modal-action'>
          <header>
            <h3>{Handler.ENUM.CREDIT_ACTION.STATUS(action.status)}</h3>
            <IconButton style={{ marginRight: -10 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </header>
          {renderInfo()}
        </article>
      );
    }
    default: {
      return (
        <article className='modal-action'>
          <header>
            <h3>{Handler.ENUM.CREDIT_ACTION.STATUS(action.status)}</h3>
            <IconButton style={{ marginRight: -10 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </header>
          {renderInfo()}
          <section className='section__container'>
            <header>
              <span>Comment (관리자)</span>
            </header>
            <ul className='form-list'>
              <TextInput
                value={action.adminComment}
                meta={{
                  key: 'content',
                  label: '의견',
                  type: 'text',
                  multiline: true,
                  fullWidth: true,
                  InputProps: {
                    readOnly: true,
                  },
                }}
              ></TextInput>
              {action.adminCommentFile && (
                <FileViewer
                  value={action.adminCommentFile}
                  meta={{
                    label: '첨부파일',
                  }}
                ></FileViewer>
              )}
              <TextViewer
                value={action.adminCommentDate}
                meta={{
                  label: '작성일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
        </article>
      );
    }
  }
};
