import React, { useEffect, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { TextInput, SelectBox, Check, ToastEditor, CardReply, TextViewer, DatePickerBox } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext, UserContext, DialogContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';

export const PaymentWithdrawDetailPage = (props) => {
  const history = useHistory();
  const { slug } = useParams();
  const { userInfo } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      bank: null,
      createdAt: null,
      deduction: null,
      deposit: null,
      depositDate: null,
      note: null,
      seq: null,
      status: null,
      user: null,
      withdraw: null,
      withdrawDate: null,
    },
    {
      name: {
        key: 'name',
        label: '이름',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      email: {
        key: 'email',
        label: '이메일',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      phone: {
        key: 'phone',
        label: '전화번호',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      withdraw: {
        fullWidth: true,
        key: 'withdraw',
        label: '출금 요청 금액',
        render: (value) => {
          return Handler.numberWithCommas(value) + '원';
        },
      },
      bank: {
        key: 'bank',
        label: '은행',
        fullWidth: true,
        render: (value) => {
          return value;
        },
      },
      account_name: {
        key: 'account_name',
        label: '예금주',
        fullWidth: true,
        render: (value) => {
          return value;
        },
      },
      account: {
        key: 'account',
        label: '계좌번호',
        fullWidth: true,
        render: (value) => {
          return value;
        },
      },
      withdrawDate: {
        key: 'withdrawDate',
        label: '출금요청일',
        fullWidth: true,
        render: (value) => {
          return value;
        },
      },
      status: {
        key: 'status',
        label: '상태',
        fullWidth: true,
        menus: [
          {
            value: 'Deposit',
            label: '지급',
          },
          {
            value: 'NotDeposit',
            label: '미지급',
          },
          {
            value: 'Etc',
            label: '기타',
          },
        ],
      },
      deposit: {
        key: 'deposit',
        label: '지급 금액',
        fullWidth: true,
      },
      depositDate: {
        key: 'depositDate',
        label: '지급일',
        fullWidth: true,
      },
      note: {
        key: 'note',
        label: '비고',
        fullWidth: true,
      },
    },
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (slug) {
      refreshData();
    }
  }, [slug]);

  const refreshData = () => {
    API.Payment.GetWithdrawDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log('GetWithdrawDetail result', result);
        setFormData({ ...result });
        setLoading(false);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
        setLoading(false);
      },
    );
  };

  const requestSave = () => {
    console.log('requestSave');

    let body = {
      status: formData.status,
      deposit: formData.deposit,
      depositDate: Handler.getYYYYMMDDByDate(formData.depositDate),
      note: formData.note,
    };

    if (body) {
      API.Payment.PutWithdraw(slug, body).then(
        (res) => {
          let result = res.data;
          console.log(' PutWithdraw result', result);
          updateSnackbar({
            type: 'success',
            open: true,
            message: '저장 성공',
          });
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  if (loading) {
    return null;
  }
  console.log('formData', formData)

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>출금 요청 상세</h3>
        </header>
        <div className='button__box'>
          <Button variant='contained' onClick={requestSave}>
            저장
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>회원 정보</h3>
        </header>
        <div className='flex'>
          <TextInput value={formData.user.name} onChange={formChange} meta={formMeta.name}></TextInput>
          <TextInput value={formData.user.email} onChange={formChange} meta={formMeta.email}></TextInput>
          <TextInput value={formData.user.phone} onChange={formChange} meta={formMeta.phone}></TextInput>
        </div>
      </section>
      <div style={{ height: 40 }}></div>
      <section className='form__container'>
        <header>
          <h3>출금 요청 정보</h3>
        </header>
        <div className='flex'>
          <TextViewer value={formData.withdraw} onChange={formChange} meta={formMeta.withdraw}></TextViewer>
          <TextViewer value={formData.user.account ? formData.user.account.bank : ''} onChange={formChange} meta={formMeta.bank}></TextViewer>
          <TextViewer
            value={formData.user.account ? formData.user.account.name : ''}
            onChange={formChange}
            meta={formMeta.account_name}
          ></TextViewer>
          <TextViewer value={formData.user.account ? formData.user.account.account : ''} onChange={formChange} meta={formMeta.account}></TextViewer>
          <TextViewer value={formData.withdrawDate} onChange={formChange} meta={formMeta.withdrawDate}></TextViewer>
        </div>
      </section>
      <div style={{ height: 40 }}></div>
      <section className='form__container'>
        <header>
          <h3>정산 결과</h3>
        </header>
        <div className='flex'>
          <SelectBox value={formData.status} onChange={formChange} meta={formMeta.status}></SelectBox>
          <TextInput value={formData.deposit} onChange={formChange} meta={formMeta.deposit}></TextInput>
          <DatePickerBox value={formData.depositDate} onChange={formChange} meta={formMeta.depositDate}></DatePickerBox>
          <TextInput value={formData.note} onChange={formChange} meta={formMeta.note}></TextInput>
        </div>
      </section>
    </article>
  );
};
