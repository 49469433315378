import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'evidence/';

const GetEvidence = (params) => {
  const url = BASE_URL + PATH + 'methodology' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetEvidenceDetail = (seq) => {
  const url = BASE_URL + PATH + seq + '/methodology';
  return AxiosInstance.get(url);
};

const PostEvidence = (body) => {
  const url = BASE_URL + PATH + 'methodology';
  return AxiosInstance.post(url, body);
};

const PutEvidence = (seq, body) => {
  const url = BASE_URL + PATH + seq + '/methodology';
  return AxiosInstance.put(url, body);
};

const Evidence = {
  GetEvidence: GetEvidence,
  GetEvidenceDetail: GetEvidenceDetail,
  PostEvidence: PostEvidence,
  PutEvidence: PutEvidence,
};

export default Evidence;
