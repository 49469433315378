import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { TableBox, SearchFilter, Paging } from '../../components';
import { usePagination, useFilter } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Button from '@mui/material/Button';
import Handler from '../../Handler';
import API from '../../api';

export const ForwardInquiryListPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState(null);
  const [header, setHeader] = useState([
    {
      key: 'seq',
      align: 'center',
      text: 'No',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'user',
      align: 'center',
      text: '기업명',
      style: {
        minWidth: 120,
      },
      render: (value) => {
        return value.companyName;
      },
    },
    {
      key: 'project',
      align: 'center',
      text: '사업ID',
      style: {
        minWidth: 120,
      },
      render: (value) => {
        return value.id;
      },
    },
    {
      key: 'project',
      align: 'center',
      text: '사업명',
      style: {
        minWidth: 200,
      },
      render: (value) => {
        return value.title;
      },
    },
    {
      key: 'amount',
      align: 'center',
      text: '수량',
      style: {
        minWidth: 200,
      },
      render: (value) => {
        return Handler.numberWithCommas(value);
      },
    },
    {
      key: 'createdAt',
      align: 'center',
      text: '상담요청일',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.getYYYYMMDDByUnix(value);
      },
    },
    {
      key: 'status',
      align: 'center',
      text: '상태',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        switch (value) {
          case 'Ready': {
            return <span style={{ color: '#fc312f', fontSize: 14 }}>{'상담대기'}</span>;
          }
          case 'Proceeding': {
            return '진행중';
          }

          case 'Contract': {
            return '완료';
          }

          case 'Cancel': {
            return '취소';
          }
          default: {
            return value;
          }
        }
      },
    },
  ]);

  const [filter, onFilterChange, setFiler] = useFilter([
    {
      key: 'status',
      type: 'select',
      label: '상담 문의 상태',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: 'Ready',
          label: '준비',
        },
        {
          value: 'Proceeding',
          label: '진행중',
        },
        {
          value: 'Contract',
          label: '완료',
        },
        {
          value: 'Cancel',
          label: '취소',
        },
      ],
    },
    {
      key: 'searchType',
      type: 'select',
      label: '검색 종류',
      value: 'Name',
      menus: [
        {
          value: 'Name',
          label: '이름',
        },
        {
          value: 'Company',
          label: '기업명',
        },
        {
          value: 'ProjectId',
          label: '사업ID',
        },
      ],
    },
    {
      key: 'searchValue',
      type: 'input',
      label: '검색 값',
      value: '',
      inputType: 'text',
    },
  ]);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  const tableCallback = (type, index) => {
    console.log('tableCallback type', type);
    console.log('tableCallback index', index);
  };

  const tableRowClick = (index) => {
    console.log('tableRowClick index', index);
    let target_seq = list[index].seq;
    console.log('tableRowClick target_seq', target_seq);

    history.push('/trading/inquiry_detail/' + target_seq);
  };

  const onSearch = (body) => {
    console.log('onSearch', body);
    requestNewList(0);
  };

  useEffect(() => {
    console.log('page effect', page);
    requestNewList();
  }, [page]);

  const requestNewList = (init_page) => {
    let body = Handler.filterToBody(filter);
    console.log('page body', body);
    API.Trading.GetInquiryList({
      ...body,
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
    }).then(
      (res) => {
        console.log('res', res);
        let result = res.data;
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  console.log('list', list);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>기업 선도거래 상담 목록</h3>
        </header>
      </section>
      <section className='filter__container'>
        <SearchFilter data={filter} onChange={onFilterChange} onSearch={onSearch}></SearchFilter>
      </section>
      <section className='table__container'>
        <TableBox data={list} header={header} rowClick={tableRowClick} callback={tableCallback}></TableBox>
      </section>
      <section className='paging__container'>
        <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
      </section>
    </article>
  );
};
