import React from 'react';
import { UserProvider, DialogProvider, NavigationProvider, SnackbarProvider } from './providers';

const Provider = (props) => {
  const { children } = props;

  return (
    <DialogProvider>
      <SnackbarProvider>
        <UserProvider>
          <NavigationProvider>{children}</NavigationProvider>
        </UserProvider>
      </SnackbarProvider>
    </DialogProvider>
  );
};

export default Provider;
