import React, { useEffect, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { TextInput, SelectBox, TextViewer } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext, DialogContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';

export const CsFaqCategoryDetailPage = (props) => {
  const history = useHistory();
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const [formData, formMeta, formChange, setFormData] = useForm(
    {
      site: 'Credit',
      category: '',
      createdAt: '',
    },
    {
      site: {
        key: 'site',
        label: '사이트',
        fullWidth: true,
        menus: [
          {
            value: 'Credit',
            label: '인증시스템',
          },
          {
            value: 'Market',
            label: '마켓플레이스',
          },
        ],
      },
      category: {
        key: 'category',
        label: '카테고리명',
        fullWidth: true,
        required: true,
      },
      createdAt: {
        label: '등록일',
        style: {},
        render: (value) => {
          return <span>{Handler.getYYYYMMDDByUnix(value)}</span>;
        },
      },
    },
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (slug) {
      refreshData();
    }
  }, [slug]);

  const refreshData = () => {
    API.CS.GetFaqCategoryDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log('GetFaqCategoryDetail result', result);
        setFormData({
          site: result.site,
          category: result.category,
          createdAt: result.createdAt,
        });
        setLoading(false);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
        setLoading(false);
      },
    );
  };

  const requsetSave = () => {
    let body = Handler.formToBody(formData, formMeta);
    console.log('body', body);
    const db_body = {
      category: body.category,
      site: body.site,
    };
    if (body) {
      API.CS.PutFaqCategory(slug, db_body).then(
        (res) => {
          let result = res.data;
          console.log('PutFaqCategory post result', result);
          refreshData();
          updateSnackbar({
            type: 'success',
            open: true,
            message: '저장 성공',
          });
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  const requsetDelete = () => {
    console.log('requsetDelete');
    API.CS.DeleteFaqCategory(slug).then(
      (res) => {
        let result = res.data;
        console.log('DeleteFaqCategory post result', result);
        history.push('/cs/faq_category/list');
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };
  console.log('fromData', formData);

  if (loading) {
    return null;
  }

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>FAQ 카테고리 상세</h3>
        </header>
        <div className='button__box'>
          <Button
            variant='contained'
            color='secondary'
            disabled={false}
            onClick={() => {
              updateDialog({
                type: 'error',
                open: true,
                title: 'FAQ 카테고리 삭제',
                description: '삭제 후 되돌릴 수 없습니다. 그래도 진행하시겠습니까?',
                onConfirm: requsetDelete,
              });
            }}
          >
            삭제
          </Button>
          {/* 수정,삭제는 동일한 유저아이디만 가능 */}
          <Button disabled={false} variant='contained' onClick={requsetSave}>
            저장
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <SelectBox value={formData.site} onChange={formChange} meta={formMeta.site}></SelectBox>
          <TextInput value={formData.category} onChange={formChange} meta={formMeta.category}></TextInput>
          <TextViewer value={formData.createdAt} meta={formMeta.createdAt}></TextViewer>
        </div>
      </section>
    </article>
  );
};
