import React, { useState } from 'react';
import { DialogContext } from '../contexts';

export const DialogProvider = (props) => {
  const { children } = props;
  const [dialog, setDialog] = useState({
    type: 'info',
    open: false,
    title: '',
    description: '',
    onConfrim: () => {
      console.log('onClick onConfrim');
    },
  });

  const updateDialog = (value) => {
    setDialog(value);
  };

  return (
    <DialogContext.Provider
      value={{
        dialog: dialog,
        updateDialog: updateDialog,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};
