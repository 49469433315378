import React, { useEffect, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { TextInput, DatePickerBox, FileUploader, TextViewer, FileViewer } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 860,
  bgcolor: 'background.paper',
  border: '2px solid #bcbcbc',
  boxShadow: 24,
  p: 0,
  borderRadius: 2,
  overflow: 'hidden',
};

export const ForwardContractDetailPage = (props) => {
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [loading, setLoading] = useState(true);
  const [formData, formMeta, setFormData] = useForm(
    {
      cancelAdminId: null,
      cancelDate: null,
      contractAmount: null,
      contractDate: null,
      contractFile: null,
      contractName: null,
      contractNumber: null,
      contractUser: null,
      createdAt: null,
      lastOrderDate: null,
      orderAmount: null,
      orderCount: null,
      orderPrice: null,
      payment: null,
      project: null,
      reason: null,
      status: null,
      totalPrice: null,
      unitPrice: null,
    },
    {
      contractNumber: {
        key: 'contractNumber',
        label: '계약번호',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
        render: (value) => {
          return value;
        },
      },
      contractName: {
        key: 'contractName',
        label: '기업명',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
        render: (value) => {
          return value;
        },
      },
      projectId: {
        key: 'project',
        label: '사업ID',
        render: (value) => {
          return value;
        },
      },
      projectTitle: {
        key: 'project',
        label: '사업ID',
        render: (value) => {
          return value;
        },
      },
      reason: {
        key: 'reason',
        label: '구매사유',
        render: (value) => {
          return value;
        },
      },
      contractAmount: {
        key: 'contractAmount',
        label: '총 계약수량',
        fullWidth: true,
        render: (value) => {
          return Handler.numberWithCommas(value) + '톤';
        },
      },
      totalPrice: {
        key: 'totalPrice',
        label: '총 계약 금액',
        render: (value) => {
          return Handler.numberWithCommas(value) + '원';
        },
      },
      unitPrice: {
        key: 'unitPrice',
        label: '1톤당 책정 가격',
        render: (value) => {
          return Handler.numberWithCommas(value) + '원';
        },
      },
      contractDate: {
        key: 'contractDate',
        label: '계약일자',
        render: (value) => {
          return value;
        },
      },
      status: {
        key: 'contractDate',
        label: '계약상태',
        render: (value) => {
          switch (value) {
            case 'Success': {
              return '성공';
            }
            case 'Cancel': {
              return '취소';
            }
            default: {
              return 'none';
            }
          }
        },
      },
      cancelDate: {
        key: 'cancelDate',
        label: '취소 처리일',
        render: (value) => {
          return value;
        },
      },
      cancelAdminId: {
        key: 'cancelAdminId',
        label: '취소 처리자',
        render: (value) => {
          return value;
        },
      },
      // paymentTotalAmount: {
      //   key: 'paymentTotalAmount',
      //   label: '누적 구매수량',
      //   render: (value) => {
      //     return Handler.numberWithCommas(value) + '원';
      //   },
      // },
      // paymentTotalPrice: {
      //   key: 'paymentTotalPrice',
      //   label: '누적 결제금액',
      //   render: (value) => {
      //     return Handler.numberWithCommas(value) + '원';
      //   },
      // },
      lastOrderDate: {
        key: 'lastOrderDate',
        label: '최근 결제일시',
        render: (value) => {
          return value;
        },
      },
      orderAmount: {
        key: 'orderAmount',
        label: '누적 구매 수량',
        fullWidth: true,
        required: true,
        render: (value) => {
          return Handler.numberWithCommas(value) + '톤';
        },
      },
      orderPrice: {
        key: 'orderPrice',
        label: '누적 결제 금액',
        fullWidth: true,
        required: true,
        render: (value) => {
          return Handler.numberWithCommas(value) + '원';
        },
      },
      // orderDate: {
      //   key: 'orderDate',
      //   label: '결제일시',
      //   required: true,
      //   fullWidth: true,
      // },
      orderFile: {
        required: true,
        type: 'file',
        key: 'orderFile',
        label: '입금증 첨부',
        path: 'contract/order',
      },
    },
  );
  const [open, setOpen] = useState(false);
  const [modalFormData, modalFormMeta, modalFormChange, setModalFormData, setModalFormMeta, formModalReset] = useForm(
    {
      orderAmount: null,
      orderDate: null,
      orderPrice: null,
      modalOrderFile: null,
    },
    {
      orderAmount: {
        key: 'orderAmount',
        label: '구매수량',
        fullWidth: true,
        required: true,
      },
      orderPrice: {
        key: 'orderPrice',
        label: '결제금액',
        fullWidth: true,
        required: true,
      },
      orderDate: {
        key: 'orderDate',
        label: '결제일시',
        required: true,
        fullWidth: true,
      },
      modalOrderFile: {
        required: true,
        type: 'file',
        key: 'modalOrderFile',
        label: '입금증 첨부',
        path: 'contract/order',
      },
    },
  );
  const [paymentList, setPaymentList] = useState(null);

  useEffect(() => {
    if (slug) {
      refreshData();
    }
  }, [slug]);

  const refreshData = () => {
    API.Trading.GetContractDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log('GetContractDetail result', result);
        setFormData(result);
        setPaymentList(result.payment);
        setLoading(false);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requsetCancel = () => {
    API.Trading.PutContractCancel(slug).then(
      (res) => {
        let result = res.data;
        console.log('PutContractCancel result', result);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requestSave = (item, index) => {
    let target = paymentList[index];
    console.log('target', target);
    let orderSeq = target.orderNumber;
    console.log('orderSeq', orderSeq);

    let body = {
      orderAmount: target.amount,
      orderPrice: target.price,
      orderDate: Handler.getYYYYMMDDByDate(target.orderDate),
      orderFile: target.orderFile,
    };
    console.log('body', body);

    API.Trading.PutContractOrder(slug, orderSeq, body).then(
      (res) => {
        let result = res.data;
        console.log('PutContractOrder result', result);
        refreshData();
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requestAdd = () => {
    let body = Handler.formToBody(modalFormData, modalFormMeta);
    if (body) {
      body.orderFile = body.modalOrderFile;
      body.orderDate = Handler.getYYYYMMDDByDate(body.orderDate);
      console.log('PostContractOrder body', body);
      // return;
      API.Trading.PostContractOrder(slug, body).then(
        (res) => {
          let result = res.data;
          console.log('PostContractOrder result', result);
          setOpen(false);
          updateSnackbar({
            type: 'success',
            open: true,
            message: '추가 성공',
          });
          refreshData();
        },
        (err) => {
          console.log('err', err);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    }
  };
  if (loading) {
    return null;
  }
  console.log('formData', formData);
  console.log('paymentList', paymentList);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>기업 선도거래 계약 상세</h3>
        </header>
        <div className='button__box'>
          <Button disabled={formData.status === 'Cancel'} variant='contained' onClick={requsetCancel}>
            계약취소
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>계약 정보</h3>
        </header>
        <div className='flex'>
          <TextViewer value={formData.contractNumber} meta={formMeta.contractNumber}></TextViewer>
          <TextViewer value={formData.contractName} meta={formMeta.contractName}></TextViewer>
          <TextViewer value={formData.project.id} meta={formMeta.projectId}></TextViewer>
          <TextViewer value={formData.project.title} meta={formMeta.projectTitle}></TextViewer>
          <TextViewer value={formData.reason} meta={formMeta.reason}></TextViewer>
          <TextViewer value={formData.contractAmount} meta={formMeta.contractAmount}></TextViewer>
          <TextViewer value={formData.totalPrice} meta={formMeta.totalPrice}></TextViewer>
          <TextViewer value={formData.unitPrice} meta={formMeta.unitPrice}></TextViewer>
          <TextViewer value={formData.contractDate} meta={formMeta.contractDate}></TextViewer>
          <FileViewer
            value={formData.contractFile}
            meta={{
              label: '계약서 첨부',
            }}
          ></FileViewer>
          <TextViewer value={formData.status} meta={formMeta.status}></TextViewer>
          {formData.status === 'Cancel' && (
            <>
              <TextViewer value={formData.cancelAdminId} meta={formMeta.cancelAdminId}></TextViewer>
              <TextViewer value={formData.cancelDate} meta={formMeta.cancelDate}></TextViewer>
            </>
          )}

          <TextViewer value={formData.orderAmount} meta={formMeta.orderAmount}></TextViewer>
          <TextViewer value={formData.orderPrice} meta={formMeta.orderPrice}></TextViewer>
          <TextViewer value={formData.lastOrderDate} meta={formMeta.lastOrderDate}></TextViewer>
        </div>
      </section>
      <div style={{ height: 40 }}></div>
      <section className='form__container'>
        <header style={{ alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h3>주문 정보</h3>
            <span style={{ marginLeft: 30, fontSize: 12, color: '#777' }}>
              첫 주문은 계약수량의 30%만 결제합니다. 입금이 들어왔는지 확인 후 계약 내용을 저장하시기 바랍니다.
            </span>
          </div>
          <div className='button__box'>
            <Button
              variant='contained'
              onClick={() => {
                setOpen(true);
              }}
            >
              추가
            </Button>
          </div>
        </header>
        <ul className='order-list__box'>
          {paymentList.map((item, index) => {
            return (
              <li>
                <header>
                  <span>주문정보 {index + 1}</span>
                  <Button
                    variant='contained'
                    onClick={() => {
                      requestSave(item, index);
                    }}
                  >
                    저장
                  </Button>
                </header>
                <TextInput
                  value={item.orderNumber}
                  onChange={() => { }}
                  meta={{
                    key: 'orderNumber',
                    label: '주문번호',
                    fullWidth: true,
                    required: true,
                    InputProps: {
                      readOnly: true,
                    },
                  }}
                ></TextInput>
                <TextInput
                  value={item.amount}
                  onChange={(value, key) => {
                    let new_paymentList = _.clone(paymentList);
                    new_paymentList[index][key] = value;
                    setPaymentList(new_paymentList);
                  }}
                  meta={{
                    key: 'amount',
                    label: '수량',
                    fullWidth: true,
                    required: true,
                  }}
                ></TextInput>
                <TextInput
                  value={item.price}
                  onChange={(value, key) => {
                    let new_paymentList = _.clone(paymentList);
                    new_paymentList[index][key] = value;
                    setPaymentList(new_paymentList);
                  }}
                  meta={{
                    key: 'price',
                    label: '결제금액',
                    fullWidth: true,
                    required: true,
                  }}
                ></TextInput>
                <DatePickerBox
                  value={item.orderDate}
                  onChange={(value, key) => {
                    let new_paymentList = _.clone(paymentList);
                    new_paymentList[index][key] = value;
                    setPaymentList(new_paymentList);
                  }}
                  meta={{
                    key: 'orderDate',
                    label: '결제일시',
                    fullWidth: true,
                    required: true,
                  }}
                ></DatePickerBox>
                <FileUploader
                  value={item.orderFile}
                  onChange={(value, key) => {
                    let new_paymentList = _.clone(paymentList);
                    new_paymentList[index][key] = value;
                    setPaymentList(new_paymentList);
                  }}
                  meta={{
                    key: 'orderFile',
                    label: '입금증 첨부',
                    fullWidth: true,
                    required: true,
                  }}
                ></FileUploader>
              </li>
            );
          })}
        </ul>
        {/* <div className='flex'>
          <TextInput value={formData.orderAmount} onChange={formChange} meta={formMeta.orderAmount}></TextInput>
          <TextInput value={formData.orderPrice} onChange={formChange} meta={formMeta.orderPrice}></TextInput>
          <DatePickerBox value={formData.orderDate} onChange={formChange} meta={formMeta.orderDate}></DatePickerBox>
          <FileUploader value={formData.orderFile} onChange={formChange} meta={formMeta.orderFile}></FileUploader>
        </div> */}
      </section>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={style}>
          <article className='order-add__modal'>
            <header>
              <h3>주문정보 추가</h3>
              <IconButton
                style={{ marginRight: -10 }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </header>
            <section className='section__container'>
              <header>
                <span>정보 입력</span>
              </header>
              <ul className='form-list'>
                <TextInput
                  value={modalFormData.orderAmount}
                  onChange={modalFormChange}
                  meta={modalFormMeta.orderAmount}
                ></TextInput>
                <TextInput
                  value={modalFormData.orderPrice}
                  onChange={modalFormChange}
                  meta={modalFormMeta.orderPrice}
                ></TextInput>
                <DatePickerBox
                  value={modalFormData.orderDate}
                  onChange={modalFormChange}
                  meta={modalFormMeta.orderDate}
                ></DatePickerBox>
                <FileUploader
                  value={modalFormData.modalOrderFile}
                  onChange={modalFormChange}
                  meta={modalFormMeta.modalOrderFile}
                ></FileUploader>
              </ul>
            </section>
            <section className='button__container'>
              <Button
                style={{ marginRight: 20 }}
                variant='outlined'
                onClick={() => {
                  setOpen(false);
                }}
              >
                취소
              </Button>
              <Button
                size='large'
                variant='contained'
                onClick={() => {
                  requestAdd();
                }}
              >
                저장
              </Button>
            </section>
          </article>
        </Box>
      </Modal>
    </article>
  );
};
