import React, { useEffect, useState } from 'react';
import { TextInput, TextViewer, SelectBox, DatePickerBox, ToastEditor, FileViewer, FileUploader, Check } from '..';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useForm } from '../../hooks';
import Button from '@mui/material/Button';
import _ from 'lodash';

export const ModalOpenConsultation = (props) => {
  const { data, type, onClose, requestAction } = props;
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      title: '',
      category: '',
      isOpen: false,
      content: '',
      startDate: '',
      endDate: '',
      commentFile: '',
    },
    {
      title: {
        key: 'title',
        type: 'text',
        fullWidth: true,
        label: '제목',
      },
      content: {
        key: 'content',
        type: 'text',
        multiline: true,
        fullWidth: true,
        label: '내용',
      },
      isOpen: {
        key: 'isOpen',
        label: '공개여부',
      },
      type: {
        key: 'type',
        type: 'text',
        multiline: true,
        fullWidth: true,
        label: '유형',
        menus: [
          {
            label: '신규',
            value: 'New',
          },
          {
            label: '업데이트',
            value: 'Update',
          },
        ],
      },
      category: {
        key: 'category',
        type: 'text',
        multiline: true,
        fullWidth: true,
        label: '논평종류',
        menus: [
          {
            label: '사업',
            value: 'Project',
          },
          {
            label: '방법론',
            value: 'Methodology',
          },
          {
            label: '팝플',
            value: 'Pople',
          },
          {
            label: '스탠다드',
            value: 'Standard',
          },
        ],
      },
      startDate: {
        key: 'startDate',
        type: 'text',
        multiline: true,
        fullWidth: true,
        label: '시작일',
      },
      endDate: {
        key: 'endDate',
        type: 'text',
        multiline: true,
        fullWidth: true,
        label: '종료일',
      },
      commentFile: {
        key: 'commentFile',
        label: '첨부 파일',
      },
    },
  );

  const [commentFile, setCommentFile] = useState('');

  useEffect(() => {
    if (commentFile) {
      const new_data = _.clone(formData);
      new_data.commentFile = commentFile;
      setFormData(new_data);
    }
  }, [commentFile]);

  useEffect(() => {
    if (data && data.consultation) {
      setFormData({ ...data.consultation });
    }
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <article className='modal-action'>
      <header>
        <h3>공개논평 열기</h3>
        <IconButton style={{ marginRight: -10 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </header>
      <section className='section__container'>
        <header>
          <span>{type === 'project' ? '사업' : '방법론'} 정보</span>
        </header>
        <ul className='form-list'>
          <TextViewer
            value={data.title}
            meta={{
              label: `${type === 'project' ? '사업' : '방법론'}명`,
              style: {},
              render: (value) => {
                return value;
              },
            }}
          ></TextViewer>
          <TextViewer
            value={data.id}
            meta={{
              label: `${type === 'project' ? '사업' : '방법론'} ID`,
              style: {},
              render: (value) => {
                return value;
              },
            }}
          ></TextViewer>
        </ul>
      </section>
      <section className='section__container'>
        <header>
          <span>내용</span>
        </header>
        <ul className='form-list'>
          <SelectBox value={formData.type} onChange={formChange} meta={formMeta.type}></SelectBox>
          <SelectBox value={formData.category} onChange={formChange} meta={formMeta.category}></SelectBox>
          <Check value={formData.isOpen} onChange={formChange} meta={formMeta.isOpen}></Check>
          <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
          <ToastEditor value={formData.content} onChange={formChange} meta={formMeta.content}></ToastEditor>
          <DatePickerBox value={formData.startDate} onChange={formChange} meta={formMeta.startDate}></DatePickerBox>
          <DatePickerBox value={formData.endDate} onChange={formChange} meta={formMeta.endDate}></DatePickerBox>
          <FileUploader
            value={commentFile}
            onChange={(value) => {
              setCommentFile(value);
            }}
            meta={{
              key: 'commentFile',
              label: '첨부 파일',
            }}
          ></FileUploader>
        </ul>
      </section>

      <section className='button__container'>
        <Button style={{ marginRight: 20 }} variant='outlined' onClick={onClose}>
          취소
        </Button>
        <Button
          disabled={!formData.content}
          size='large'
          variant='contained'
          onClick={() => {
            requestAction(formData);
          }}
        >
          확인 및 보내기
        </Button>
      </section>
    </article>
  );
};
