import React from 'react';
import Handler from '../../Handler';
import Button from '@mui/material/Button';

export const CardProjectStatus = (props) => {
  const { data, onAction, nextActionTypeList } = props;
  const { projectDocAction, type } = data;
  console.log('CardProjectStatus data', data);

  console.log('CardProjectStatus projectDocAction', projectDocAction);
  console.log('CardProjectStatus nextActionTypeList', nextActionTypeList);

  const buildButtonColor = (value) => {
    switch (value) {
      case 'CancelRejected':
      case 'LaunchRejected':
      case 'CanceledProject':
      case 'ProjectRejected': {
        return 'secondary';
      }
      default: {
        return 'primary';
      }
    }
  };

  const isDegree = (status) => {
    switch (status) {
      case 'WaitingForAuditLaunch':
      case 'WaitingForAuditProject':
      case 'AuditProject': {
        return true;
      }
      default: {
        return false;
      }
    }
  };

  const renderTypeText = () => {
    switch (type) {
      case 'New': {
        return '(신규)';
      }
      case 'Move': {
        return '(이관)';
      }
      // case 'Improve': {
      //   return '(보완)';
      // }
      case 'Modify': {
        return '(변경)';
      }
      default: {
        return null;
      }
    }
  };

  return (
    <article className='card-project-status'>
      <header style={{ display: 'flex', alignItems: 'center' }}>
        <h3>상태 정보</h3>
      </header>
      <article className='content__box'>
        <div className='status__box'>
          <header>
            <span>상태</span>
          </header>
          <div className='value'>
            <span style={{ marginRight: 6 }}>{renderTypeText()}</span>
            <span>
              {Handler.ENUM.ACTION.STATUS(projectDocAction.status)}{' '}
              {isDegree(projectDocAction.status) ? `(${projectDocAction.degree})` : ''}
            </span>
            <span className='date'>({Handler.getYYYYMMDDByUnix(projectDocAction.createdAt)})</span>
          </div>
        </div>
        <div className='action__box'>
          <ul>
            {nextActionTypeList &&
              nextActionTypeList.map((item, index) => {
                return (
                  <li>
                    <Button
                      color={buildButtonColor(item)}
                      variant='contained'
                      onClick={() => {
                        onAction(item);
                      }}
                    >
                      {Handler.ENUM.ACTION.NEXT(item)}
                    </Button>
                  </li>
                );
              })}
          </ul>
        </div>
      </article>
    </article>
  );
};
