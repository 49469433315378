import React from 'react';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export const FileViewer = (props) => {
  const { value, meta } = props;

  console.log('value', value);
  const fileName = value ? value.split('/')[value.split('/').length - 1] : '';
  return (
    <article className='form-file-viewer'>
      <header>
        <span>{meta.label}</span>
      </header>
      <div className='file__box'>
        <Button
          onClick={() => {
            window.open(value, '_blank');
          }}
          size='large'
          variant='outlined'
          endIcon={<FileDownloadIcon />}
        >
          {fileName}
        </Button>
      </div>
    </article>
  );
};
