import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const GetMemo = (seq, params) => {
  const url = BASE_URL + 'memo/' + seq + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const PostMemo = (body) => {
  const url = BASE_URL + 'memo';
  return AxiosInstance.post(url, body);
};

const DeleteMemo = (seq) => {
  const url = BASE_URL + 'memo/' + seq;
  return AxiosInstance.delete(url);
};

const Memo = {
  GetMemo: GetMemo,
  PostMemo: PostMemo,
  DeleteMemo: DeleteMemo,
};

export default Memo;
