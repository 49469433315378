import React from 'react';
import Handler from '../../Handler';
import Button from '@mui/material/Button';

export const CardCreditStatus = (props) => {
  const { data, onAction } = props;
  const { lastCreditDoc, nextActionTypeList } = data;
  const { actionSeq } = lastCreditDoc;

  const buildButtonColor = (value) => {
    switch (value) {
      case 'CommitteeRejected':
      case 'CancelRejected':
      case 'CanceledZCCredit':
      case 'ZCCreditRejected': {
        return 'secondary';
      }
      default: {
        return 'primary';
      }
    }
  };

  const isDegree = (status) => {
    switch (status) {
      case 'WaitingForAuditCommittee':
      case 'WaitingForAuditLaunch':
      case 'WaitingForAuditZCCredit':
      case 'AuditZCCredit': {
        return true;
      }
      default: {
        return false;
      }
    }
  };

  return (
    <article className='card-project-status'>
      <header>
        <h3>상태 정보</h3>
      </header>
      <article className='content__box'>
        <div className='status__box'>
          <header>
            <span>상태</span>
          </header>
          <div className='value'>
            <span>
              {Handler.ENUM.CREDIT_ACTION.STATUS(actionSeq.status)}{' '}
              {isDegree(actionSeq.status) ? `(${actionSeq.degree})` : ''}
            </span>
            <span className='date'>({Handler.getYYYYMMDDByUnix(actionSeq.createdAt)})</span>
          </div>
        </div>
        <div className='action__box'>
          <ul>
            {nextActionTypeList.map((item, index) => {
              return (
                <li>
                  <Button
                    color={buildButtonColor(item)}
                    variant='contained'
                    onClick={() => {
                      onAction(item);
                    }}
                  >
                    {Handler.ENUM.CREDIT_ACTION.NEXT(item)}
                  </Button>
                </li>
              );
            })}
          </ul>
        </div>
      </article>
    </article>
  );
};
