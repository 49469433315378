import React, { useEffect, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import {
  TextInput,
  SelectBox,
  Check,
  ToastEditor,
  DatePickerBox,
  FileMultipleUploader,
  FileUploader,
  TextViewer,
} from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext, DialogContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import { useHistory, useParams } from 'react-router-dom';

export const NewsAddPage = (props) => {
  const history = useHistory();
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const [formData, formMeta, formChange, setFormData] = useForm(
    {
      show: false,
      isMain: false,
      title: '',
      titleEn: '',
      content: '',
      contentEn: '',
      press: '',
      pressDate: '',
      link: '',
      image: '',
    },
    {
      show: {
        key: 'show',
        label: '공개 여부',
      },
      isMain: {
        key: 'isMain',
        label: '메인뉴스 여부',
      },
      title: {
        key: 'title',
        label: '제목',
        fullWidth: true,
        required: true,
      },
      titleEn: {
        key: 'titleEn',
        label: '제목(영문)',
        fullWidth: true,
      },
      content: {
        key: 'content',
        label: '내용',
        fullWidth: true,
        required: true,
        multiline: true,
      },
      contentEn: {
        key: 'contentEn',
        label: '내용(영문)',
        fullWidth: true,
        multiline: true,
      },
      press: {
        key: 'press',
        label: '언론사',
        fullWidth: true,
        required: true,
      },
      pressDate: {
        key: 'pressDate',
        label: '기사 보도일',
        required: true,
      },
      link: {
        key: 'link',
        label: '링크',
        fullWidth: true,
        required: true,
      },
      image: {
        key: 'image',
        type: 'file',
        label: '이미지',
        required: true,
        path: 'news/file',
      },
    },
  );
  const [loading, setLoading] = useState(true);

  const requestAdd = () => {
    let body = Handler.formToBody(formData, formMeta);
    console.log('body', body);

    if (body) {
      //   return;
      API.Marketplace.PostNews({ ...body }).then(
        (res) => {
          let result = res.data;
          updateSnackbar({
            type: 'success',
            open: true,
            message: '추가 성공',
          });
          history.push('/marketplace/news/list');
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>뉴스 추가</h3>
        </header>
        <div className='button__box'>
          <Button variant='contained' onClick={requestAdd}>
            추가
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <Check value={formData.show} onChange={formChange} meta={formMeta.show}></Check>
          <Check value={formData.isMain} onChange={formChange} meta={formMeta.isMain}></Check>
          <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
          <TextInput value={formData.titleEn} onChange={formChange} meta={formMeta.titleEn}></TextInput>
          <TextInput value={formData.content} onChange={formChange} meta={formMeta.content}></TextInput>
          <TextInput value={formData.contentEn} onChange={formChange} meta={formMeta.contentEn}></TextInput>
          <TextInput value={formData.press} onChange={formChange} meta={formMeta.press}></TextInput>
          <DatePickerBox value={formData.pressDate} onChange={formChange} meta={formMeta.pressDate}></DatePickerBox>
          <TextInput value={formData.link} onChange={formChange} meta={formMeta.link}></TextInput>
          <FileUploader value={formData.image} onChange={formChange} meta={formMeta.image}></FileUploader>
        </div>
      </section>
    </article>
  );
};
