import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { TableBox, SearchFilter, Paging } from '../../components';
import { usePagination, useFilter } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';

export const CsContactUsListPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState(null);
  const [header, setHeader] = useState([
    {
      key: 'seq',
      align: 'center',
      text: 'No',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'site',
      align: 'center',
      text: '사이트',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.ENUM.CS.SITE(value);
      },
    },
    {
      key: 'name',
      align: 'center',
      text: '이름',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'email',
      align: 'center',
      text: '이메일',
      style: {
        minWidth: 200,
      },
    },
    {
      key: 'phone',
      align: 'center',
      text: '전화번호',
      style: {
        minWidth: 150,
      },
    },
    {
      key: 'createdAt',
      align: 'center',
      text: '등록일',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.getYYYYMMDDByUnix(value);
      },
    },
    {
      key: 'status',
      align: 'center',
      text: '상태',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        if (value === 'Contact') {
          return <span style={{ color: '#fc312f', fontSize: 14 }}>{Handler.ENUM.CS.STATUS(value)}</span>;
        } else {
          return <span style={{ color: '#4458b8', fontSize: 14 }}>{Handler.ENUM.CS.STATUS(value)}</span>;
        }
      },
    },
  ]);

  const [filter, onFilterChange, setFiler] = useFilter([
    {
      key: 'site',
      type: 'select',
      label: '사이트 구분',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: 'Credit',
          label: '인증시스템',
        },
        {
          value: 'Market',
          label: '마켓플레이스',
        },
      ],
    },
    {
      key: 'status',
      type: 'select',
      label: '상태',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: 'Contact',
          label: '답변 대기',
        },
        {
          value: 'Complete',
          label: '답변 완료',
        },
      ],
    },
    {
      key: 'searchType',
      type: 'select',
      label: '검색 종류',
      value: 'Name',
      menus: [
        {
          value: 'Name',
          label: '이름',
        },
        {
          value: 'Email',
          label: '이메일',
        },
        {
          value: 'Phone',
          label: '전화번호',
        },
        {
          value: 'Message',
          label: '메세지',
        },
      ],
    },
    {
      key: 'searchValue',
      type: 'input',
      label: '검색 값',
      value: '',
      inputType: 'text',
    },
  ]);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  const tableCallback = (type, index) => {
    console.log('tableCallback type', type);
    console.log('tableCallback index', index);
  };

  const tableRowClick = (index) => {
    console.log('tableRowClick index', index);
    let target_seq = list[index].seq;
    console.log('tableRowClick target_seq', target_seq);
    history.push('/cs/contact_us/detail/' + target_seq);
  };

  const onSearch = (body) => {
    console.log('onSearch', body);
    requestNewList(0);
  };

  useEffect(() => {
    console.log('page effect', page);
    requestNewList();
  }, [page]);

  const requestNewList = (init_page) => {
    let body = Handler.filterToBody(filter);
    console.log('page body', body);
    API.CS.GetContactUsList({
      ...body,
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
    }).then(
      (res) => {
        console.log('res', res);
        let result = res.data;
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  console.log('list', list);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>Contact us 목록</h3>
        </header>
      </section>
      <section className='filter__container'>
        <SearchFilter data={filter} onChange={onFilterChange} onSearch={onSearch}></SearchFilter>
      </section>
      <section className='table__container'>
        <TableBox data={list} header={header} rowClick={tableRowClick} callback={tableCallback}></TableBox>
      </section>
      <section className='paging__container'>
        <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
      </section>
    </article>
  );
};
