import React, { useState } from 'react';
import { TextInput } from '../../components';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useForm } from '../../hooks';
import Button from '@mui/material/Button';

export const ModalCreditOffsetRequest = (props) => {
  const { credit, action, nextAction, onClose, requestAction } = props;
  const [formMeta] = useForm(
    {
      amount: 0,
    },
    {
      amount: {
        key: 'amount',
        type: 'number',
        label: '수량',
      },
    },
  );
  const [amount, setAmount] = useState(0);

  console.log('ModalProjectAction action', action);
  console.log('ModalProjectAction nextAction', nextAction);

  return (
    <article className='modal-action'>
      <header>
        <h3>{credit.project.title} 크레딧 철회</h3>
        <IconButton style={{ marginRight: -10 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </header>
      <section className='section__container'>
        <header>
          <span>
            철회할 크레딧 수량: {amount} / {credit.project.reductionNow}
          </span>
        </header>
        <ul className='form-list'>
          <TextInput
            value={amount}
            error={() => console.log('sdfsdf')}
            onChange={(value, key) => {
              const max = credit.project.reductionNow;
              const val = value >= max ? max : value;
              setAmount(val);
            }}
            meta={formMeta.amount}
          ></TextInput>
        </ul>
      </section>

      <section className='button__container'>
        <Button style={{ marginRight: 20 }} variant='outlined' onClick={onClose}>
          취소
        </Button>
        <Button
          size='large'
          variant='contained'
          onClick={() => {
            // requestAction({ amount: amount, nextAction:'CommitteeApproved' });
            requestAction({
              userSeq: credit.project.user.seq,
              amount: parseInt(amount),
              reason: 'string',
              file: 'string',
            });
          }}
        >
          확인 및 보내기
        </Button>
      </section>
    </article>
  );
};
