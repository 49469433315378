import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const BASE_URL_WITHOUT_LAST_SLICE = BASE_URL.slice(0, -1);

const Get = (seq) => {
  const url = BASE_URL + seq;
  return AxiosInstance.get(url);
};

const Post = (body) => {
  const url = BASE_URL_WITHOUT_LAST_SLICE;
  return AxiosInstance.post(url, body);
};

const Put = (seq, params) => {
  const url = BASE_URL + seq + Utils.ObjectToParams(params);
  return AxiosInstance.put(url);
};

const ResetPassword = (seq) => {
  const url = BASE_URL + 'reset-password/' + seq;
  return AxiosInstance.put(url);
};

const CheckDuplicate = (body) => {
  const url = BASE_URL + 'duplicate';
  return AxiosInstance.post(url, body);
};

const Delete = (seq) => {
  const url = BASE_URL + seq;
  return AxiosInstance.delete(url);
};

const GetList = (params) => {
  const url = BASE_URL_WITHOUT_LAST_SLICE + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const AdminUser = {
  Get: Get,
  Post: Post,
  Put: Put,
  Delete: Delete,
  GetList: GetList,
  CheckDuplicate: CheckDuplicate,
  ResetPassword: ResetPassword,
};

export default AdminUser;
