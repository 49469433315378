import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { TableBox, SearchFilter, Paging } from '../../components';
import { usePagination, useFilter } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Button from '@mui/material/Button';
import Handler from '../../Handler';
import API from '../../api';

export const AdminUserListPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState(null);
  const [header, setHeader] = useState([
    {
      key: 'seq',
      align: 'center',
      text: 'No',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'type',
      align: 'center',
      text: 'type',
      style: {
        minWidth: 160,
      },
      render: (value) => {
        console.log('render value', value);
        switch (value) {
          case 'SuperAdmin': {
            return '관리자';
          }
          case 'Committee': {
            return '위원회';
          }
          case 'Pople': {
            return 'Pople';
          }
          default: {
            return 'unknown';
          }
        }
      },
    },
    {
      key: 'name',
      align: 'center',
      text: '이름',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'id',
      align: 'center',
      text: '아이디',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'email',
      align: 'center',
      text: '이메일',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'phone',
      align: 'center',
      text: '전화번호',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'createdAt',
      align: 'center',
      text: '가입일',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.getYYYYMMDDByUnix(value);
      },
    },
    {
      key: 'deleteDate',
      align: 'center',
      text: '삭제일',
      style: {
        minWidth: 100,
      },
    },
  ]);

  const [filter, onFilterChange, setFiler] = useFilter([
    {
      key: 'type',
      type: 'select',
      label: '구분',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: 'Pople',
          label: 'Pople',
        },
        {
          value: 'Committee',
          label: '위원회',
        },
      ],
    },
    {
      key: 'searchType',
      type: 'select',
      label: '검색 종류',
      value: 'Name',
      menus: [
        {
          value: 'Name',
          label: '이름',
        },
        {
          value: 'Id',
          label: '아이디',
        },
        {
          value: 'Phone',
          label: '핸드폰 번호',
        },
        {
          value: 'Email',
          label: '이메일',
        },
      ],
    },
    {
      key: 'searchValue',
      type: 'input',
      label: '검색 값',
      value: '',
      inputType: 'text',
    },
  ]);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  const tableCallback = (type, index) => {
    console.log('tableCallback type', type);
    console.log('tableCallback index', index);
  };

  const tableRowClick = (index) => {
    console.log('tableRowClick index', index);
    let target_seq = list[index].seq;
    history.push('/admin_user/detail/' + target_seq);
  };

  const onSearch = (body) => {
    console.log('onSearch', body);
    requestNewList(0);
  };

  useEffect(() => {
    console.log('page effect', page);
    requestNewList();
  }, [page]);

  const requestNewList = (init_page) => {
    let body = Handler.filterToBody(filter);
    console.log('page body', body);
    API.AdminUser.GetList({
      ...body,
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
    }).then(
      (res) => {
        console.log('res', res);
        let result = res.data;
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requsetAdd = () => {
    console.log('requsetAdd');
    history.push('/admin_user/add');
  };

  console.log('list', list);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>관리자 계정 목록</h3>
        </header>
        <div className='button__box'>
          <Button variant='contained' onClick={requsetAdd}>
            추가
          </Button>
        </div>
      </section>
      <section className='filter__container'>
        <SearchFilter data={filter} onChange={onFilterChange} onSearch={onSearch}></SearchFilter>
      </section>
      <section className='table__container'>
        <TableBox data={list} header={header} rowClick={tableRowClick} callback={tableCallback}></TableBox>
      </section>
      <section className='paging__container'>
        <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
      </section>
    </article>
  );
};
