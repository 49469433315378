import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import { Check, DatePickerBox, FileUploader, SelectBox, TextInput } from '../../components';
import { DialogContext, SnackbarContext, UserContext } from '../../contexts';
import { useForm } from '../../hooks';

export const MethodologyDetailPage = (props) => {
  const history = useHistory();
  const { slug } = useParams();
  const { dialog, updateDialog } = useContext(DialogContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const { authorityInfo, userInfo, businessInfo, isSuper } = useContext(UserContext);
  const [methodologyInfo, setMethodologyInfo] = useState(null);
  const [consultationOpen, setConsultationOpen] = useState(false);
  const [isAuditor, setIsAuditor] = useState(false);
  const [initData, setInitData] = useState(false);
  const [subAreaHold, setSubAreaHold] = useState(false); //초기 렌더링시에 subArea useEffect로 사라지는거 방지;
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      isView: true,
      status: false,
      adminId: '',
      auditor: '',
      version: '',
      title: '',
      titleEn: '',
      standard: '',
      area: '',
      subArea: '',
      startDate: null,
      expiredYear: '',
      mainImage: '',
      introOne: '',
      introOneEn: '',
      introTwo: '',
      introTwoEn: '',
      attachedFile: '',
      terms: [],
      evidence: [],
      calculateFile: '',
      monitoringCalcFile: '',
    },
    {
      isView: {
        key: 'isView',
        label: '노출 여부',
      },
      adminId: {
        key: 'adminId',
        fullWidth: true,
        label: '작성자',
        disabled: true,
      },
      auditor: {
        key: 'auditor',
        fullWidth: true,
        label: '검증기관',
        disabled: true,
      },
      status: {
        key: 'status',
        label: '승인 여부',
        disabled: true,
      },
      version: {
        key: 'version',
        label: '버전',
        fullWidth: true,
        required: true,
      },
      title: {
        key: 'title',
        label: '방법론명',
        fullWidth: true,
        required: true,
      },
      titleEn: {
        key: 'titleEn',
        label: '방법론명(영문)',
        fullWidth: true,
        required: true,
      },
      standard: {
        key: 'standard',
        label: '방법론 기준',
        fullWidth: true,
        required: true,
        menus: [
          {
            value: 'CDM',
            label: 'CDM',
          },
          {
            value: 'Domestic',
            label: '국내 방법론',
          },
          {
            value: 'Oversea',
            label: '국외 방법론',
          },
        ],
      },
      area: {
        key: 'area',
        label: '방법론 감축 분야',
        fullWidth: true,
        required: true,
        menus: [],
      },
      subArea: {
        key: 'subArea',
        label: '방법론 세부분야',
        fullWidth: true,
        required: true,
        menus: [],
      },
      startDate: {
        key: 'startDate',
        label: '유효기간 시작일',
        required: true,
        fullWidth: true,
      },
      expiredYear: {
        key: 'expiredYear',
        label: '유효기간(년수)',
        type: 'number',
        fullWidth: true,
        style: {},
        InputProps: {},
        required: true,
        regexp: Handler.REGEXP.MAX999,
      },
      mainImage: {
        required: true,
        type: 'image',
        key: 'mainImage',
        label: '메인 이미지',
        path: 'methodology/mainImage',
      },
      calculateFile: {
        required: true,
        type: 'file',
        key: 'calculateFile',
        label: '감축량 계산 파일',
        path: 'methodology/calculateFile',
      },
      monitoringCalcFile: {
        required: true,
        type: 'file',
        key: 'monitoringCalcFile',
        label: '모니터링 계산 파일',
        path: 'methodology/monitoringCalcFile',
      },
      introOne: {
        key: 'introOne',
        label: '2줄 소개 (첫째줄)',
        fullWidth: true,
        required: true,
      },
      introOneEn: {
        key: 'introOneEn',
        label: '2줄 소개 (첫째줄) (영문)',
        fullWidth: true,
        required: false,
      },
      introTwo: {
        key: 'introTwo',
        label: '2줄 소개 (둘째줄)',
        fullWidth: true,
        required: false,
      },
      introTwoEn: {
        key: 'introTwoEn',
        label: '2줄 소개 (둘째줄) (영문)',
        fullWidth: true,
        required: false,
      },
      attachedFile: {
        required: true,
        type: 'file',
        key: 'attachedFile',
        label: '첨부파일',
        path: 'methodology/attachedFile',
      },
      terms: {
        key: 'terms',
        mainLabel: '관련 약관',
        labels: [],
        values: [],
      },
      evidence: {
        type: 'evidence',
        key: 'evidence',
        mainLabel: '관련 증빙서류',
        labels: [],
        values: [],
      },
    },
  );

  // useEffect(() => {
  //   if (slug) {
  //     requestNewHistoryList();
  //   }
  // }, []);
  console.log('businessInfo', businessInfo);
  console.log('userInfo', userInfo);
  console.log('businessInfo', businessInfo);
  // const requestNewHistoryList = (init_page) => {
  //   API.Methodology.GetHistoryList(slug, {
  //     page: init_page ? init_page : page,
  //     size: Handler.VARIABLES.PAGE_LIMIT,
  //     ordering: 'DESC',
  //   }).then(
  //     (res) => {
  //       let result = res.data;
  //       console.log('GetHistoryList result', result);
  //       setList(result.content);
  //       setTotalPage(result.totalPages);
  //     },
  //     (err) => {
  //       updateSnackbar({
  //         type: 'error',
  //         open: true,
  //         message: err.data.message,
  //       });
  //     },
  //   );
  // };

  // useEffect(() => {
  //   if (slug) {
  //     requestNewHistoryList();
  //   }
  // }, []);

  useEffect(() => {
    API.Methodology.GetInfo().then(
      (res) => {
        console.log('Getinfo res', res);
        setMethodologyInfo(res.data);
      },
      (err) => {
        console.log('Getinfo err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  }, []);

  useEffect(() => {
    if (slug && businessInfo) {
      API.Methodology.GetDetail(slug).then(
        (res) => {
          console.log('Methodology res', res);
          let result = res.data;
          if (result.isValid) {
            console.log('Methodology result', result);
            setInitData(result);
          } else {
            updateSnackbar({
              type: 'error',
              open: true,
              message: '유효하지 않은 방법론입니다.',
            });
            history.push('/methodology/list');
          }
        },
        (err) => {
          console.log('err', err);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    }
  }, [slug, businessInfo]);

  useEffect(() => {
    if (initData && methodologyInfo) {
      console.log('initData', initData);
      let new_meta = _.clone(formMeta);
      new_meta['terms'].labels = Handler.buildTermsLabels(methodologyInfo);
      new_meta['terms'].values = Handler.buildTermsValues(methodologyInfo);
      new_meta['evidence'].labels = Handler.buildEvidenceLabels(methodologyInfo);
      new_meta['evidence'].values = Handler.buildEvidenceValues(methodologyInfo);

      setSubAreaHold(true);
      setFormMeta(new_meta);
      setFormData({
        isView: initData.isView,
        adminId: initData.adminId,
        version: initData.version,
        title: initData.title,
        titleEn: initData.titleEn,
        area: initData.area,
        subArea: initData.subArea,
        standard: initData.standard,
        status: initData.status,
        auditor: initData.auditor,
        startDate: Handler.dbDateToJsDate(initData.startDate),
        expiredYear: initData.expiredYear,
        mainImage: initData.mainImage,
        calculateFile: initData.calculateFile,
        monitoringCalcFile: initData.monitoringCalcFile,
        introOne: initData.introOne,
        introOneEn: initData.introOneEn,
        introTwo: initData.introTwo,
        introTwoEn: initData.introTwoEn,
        attachedFile: initData.attachedFile,
        terms: Handler.buildTermsCheckByDB(methodologyInfo, initData.termsMapper),
        evidence: Handler.buildEvidenceCheckByDB(methodologyInfo, initData.evidenceMapper),
      });
    }
  }, [initData, methodologyInfo]);

  useEffect(() => {
    if (authorityInfo && userInfo && initData) {
      console.log('>> initData.adminId: ', initData.adminId);
      console.log('>> userInfo.id     : ', userInfo.id);
      setIsAuditor(
        Handler.buildAuthorityValues(authorityInfo).includes(2) && initData.adminId && initData.adminId !== userInfo.id,
      );
      console.log(
        '=======> ',
        Handler.buildAuthorityValues(authorityInfo).includes(2) && initData.adminId && initData.adminId !== userInfo.id,
      );
    }
  }, [authorityInfo, userInfo, initData]);

  useEffect(() => {
    console.log('useEffect businessInfo', businessInfo);

    //사업유형 방법론 분야
    if (businessInfo) {
      let area_menus = [];
      Object.keys(businessInfo).forEach((key) => {
        console.log('key', key);
        let target = businessInfo[key];
        console.log('target', target);
        area_menus.push({
          value: key,
          label: Handler.ENUM.AREA(key),
        });
      });
      let new_meta = _.clone(formMeta);
      new_meta['area'].menus = area_menus;
      setFormMeta(new_meta);
    }
  }, [businessInfo]);

  useEffect(() => {
    //사업유형 방법론 세부분야
    if (formData.area) {
      console.log('change formData.area');
      let sub_area_list = businessInfo[formData.area];
      let sub_area_menus = [];
      for (let i in sub_area_list) {
        let target = sub_area_list[i];
        sub_area_menus.push({
          value: target,
          label: Handler.ENUM.SUB_AREA(target),
        });
      }
      let new_meta = _.clone(formMeta);
      new_meta['subArea'].menus = sub_area_menus;
      setFormMeta(new_meta);
      if (!subAreaHold) {
        formChange(null, 'subArea');
      } else {
        setSubAreaHold(false);
      }
    }
  }, [formData.area]);

  const requsetSave = (status = false) => {
    let body = Handler.formToBody(formData, formMeta);
    console.log('body', body);

    if (body) {
      body.status = status ? true : body.status;
      let db_body = {
        ...body,
        terms: Handler.buildTermsValueByDB(formMeta['terms'].values, body.terms),
        evidence: Handler.buildEvidenceValueByDB(formMeta['evidence'].values, body.evidence),
      };
      console.log('status', status);
      console.log('db_body', db_body);
      API.Methodology.Put(slug, db_body).then(
        (res) => {
          let result = res.data;
          console.log('Methodology Put result', result);
          updateSnackbar({
            type: 'success',
            open: true,
            message: '저장 성공',
          });
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  const requsetUpdate = () => {
    let body = Handler.formToBody(formData, formMeta);
    console.log('body', body);

    if (body) {
      let db_body = {
        ...body,
        terms: Handler.buildTermsValueByDB(formMeta['terms'].values, body.terms),
        evidence: Handler.buildEvidenceValueByDB(formMeta['evidence'].values, body.evidence),
      };
      delete db_body.adminId;
      console.log('db_body', db_body);
      API.Methodology.PutUpdate(slug, db_body).then(
        (res) => {
          let result = res.data;
          console.log('Methodology PutUpdate result', result);
          history.push('/methodology/detail/' + result.seq);
          updateSnackbar({
            type: 'success',
            open: true,
            message: '저장 성공',
          });
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  const canOpenConsultation = useMemo(() => {
    return !formData.status;
  }, [formData.status]);

  const handleConsultationRequest = (body) => {
    const slug = initData?.consultation?.seq || null;
    body.methodologyId = initData.seq;
    const API_REQUEST = slug ? API.Consultation.Put(slug, body) : API.Consultation.Post(body);
    API_REQUEST.then(
      (res) => {
        let result = res.data;
        console.log('PostNextAction  result', result);
        updateSnackbar({
          type: 'success',
          open: true,
          message: `논평이 ${slug ? '수정' : '등록'}되었습니다.`,
        });
        closeConsultationModal();
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const onDialogConfirm = (e) => {
    console.log('onDialogConfirm');
    requsetSave(true);
  };

  const closeConsultationModal = () => {
    setConsultationOpen(false);
  };

  console.log('formData', formData);
  console.log('formMeta', formMeta);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>방법론 상세</h3>
        </header>
        <div className='button__box'>
          {isAuditor && (
            <Button
              variant='contained'
              disabled={formData.status}
              color='success'
              onClick={() => {
                updateDialog({
                  type: 'success',
                  open: true,
                  title: '방법론 승인여부',
                  description: `${formData.title}\n방법론을 승인하시겠습니까?`,
                  onConfirm: onDialogConfirm,
                });
              }}
            >
              방법론 승인
            </Button>
          )}
          {isSuper && (
            <>
              <Button variant='contained' onClick={requsetUpdate}>
                버전 업데이트
              </Button>
              <Button variant='contained' onClick={() => requsetSave()}>
                저장
              </Button>
            </>
          )}
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        {!initData ? (
          <div className='loading__container'>
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <div className='flex'>
            {isSuper && <Check value={formData.isView} onChange={formChange} meta={formMeta.isView}></Check>}
            <Check value={formData.status} onChange={formChange} meta={formMeta.status}></Check>
            <TextInput value={formData.auditor} onChange={formChange} meta={formMeta.auditor}></TextInput>
            <TextInput value={formData.version} onChange={formChange} meta={formMeta.version}></TextInput>
            <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
            <TextInput value={formData.titleEn} onChange={formChange} meta={formMeta.titleEn}></TextInput>
            <SelectBox value={formData.standard} onChange={formChange} meta={formMeta.standard}></SelectBox>
            <SelectBox value={formData.area} onChange={formChange} meta={formMeta.area}></SelectBox>
            <SelectBox value={formData.subArea} onChange={formChange} meta={formMeta.subArea}></SelectBox>
            <DatePickerBox value={formData.startDate} onChange={formChange} meta={formMeta.startDate}></DatePickerBox>
            <TextInput value={formData.expiredYear} onChange={formChange} meta={formMeta.expiredYear}></TextInput>
            <FileUploader value={formData.mainImage} onChange={formChange} meta={formMeta.mainImage}></FileUploader>
            <TextInput value={formData.introOne} onChange={formChange} meta={formMeta.introOne}></TextInput>
            <TextInput value={formData.introOneEn} onChange={formChange} meta={formMeta.introOneEn}></TextInput>
            <TextInput value={formData.introTwo} onChange={formChange} meta={formMeta.introTwo}></TextInput>
            <TextInput value={formData.introTwoEn} onChange={formChange} meta={formMeta.introTwoEn}></TextInput>
            <FileUploader
              value={formData.attachedFile}
              onChange={formChange}
              meta={formMeta.attachedFile}
            ></FileUploader>
            <FileUploader
              value={formData.calculateFile}
              onChange={formChange}
              meta={formMeta.calculateFile}
            ></FileUploader>
            <FileUploader
              value={formData.monitoringCalcFile}
              onChange={formChange}
              meta={formMeta.monitoringCalcFile}
            ></FileUploader>
            <Check value={formData.terms} onChange={formChange} meta={formMeta.terms}></Check>
            <Check value={formData.evidence} onChange={formChange} meta={formMeta.evidence}></Check>
          </div>
        )}
      </section>

      {/* TODO: 공개논평 기능 관련 추가 기획 필요
      <section className='table-header__container' style={{ marginTop: 40 }}>
        <header>
          <h3>논평</h3>
        </header>
        {initData.consultation && initData.consultation.length > 0 ? (
          initData.consultation.map((item, index) => (
            <article key={'consultation-' + index}>
              <div className='py-4'>
                <span className='px-3 py-1 text-sm font-bold text-gray-700 bg-slate-100'>
                  {item.isOpen ? '공개' : '비공개'}
                </span>
                <p className='mt-3 font-bold'>{item.title}</p>
                <p>
                  기간: {dayjs(item.startDate).format('YYYY.MM.DD')} ~ {dayjs(item.endDate).format('YYYY.MM.DD')}
                </p>
              </div>
              <div className='flex gap-2'>
                {item.status === 'Open' && (
                  <Button variant='contained' onClick={() => console.log('')}>
                    논평종료
                  </Button>
                )}
                <Button variant='contained' color='info' onClick={() => setConsultationOpen(true)}>
                  논평수정
                </Button>
              </div>
              <Divider sx={{ marginY: '20px' }} />
            </article>
          ))
        ) : (
          <article>
            <p className='py-6 text-center'>개설된 논평이 없습니다.</p>
            {canOpenConsultation && (
              <div>
                <Button variant='contained' onClick={() => setConsultationOpen(true)}>
                  논평열기
                </Button>
              </div>
            )}
          </article>
        )}
        <Modal open={consultationOpen} onClose={closeConsultationModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 860,
              bgcolor: 'background.paper',
              border: '2px solid #bcbcbc',
              boxShadow: 24,
              p: 0,
              borderRadius: 2,
              overflow: 'hidden',
            }}
          >
            <ModalOpenConsultation
              data={initData}
              type='methodology'
              requestAction={handleConsultationRequest}
              onClose={closeConsultationModal}
            ></ModalOpenConsultation>
          </Box>
        </Modal>        
      </section> */}
    </article>
  );
};
