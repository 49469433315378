import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { NavigationContext, UserContext } from '../contexts';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from 'lodash';

const getTypeByPath = (value) => {
  switch (value) {
    case 'admin_user': {
      return 'AdminUser';
    }
    case 'methodology': {
      return 'Methodology';
    }
    case 'project': {
      return 'Project';
    }
    case 'reduction': {
      return 'ReductionCert';
    }
    case 'credit': {
      return 'ZCCredit';
    }
    case 'trading': {
      return 'Trading';
    }
    case 'payment': {
      return 'Payment';
    }
    case 'member': {
      return 'Member';
    }
    case 'cs': {
      return 'CustomerService';
    }
    case 'marketplace': {
      return 'MarketPlace';
    }
    case 'consultation': {
      return 'Consultation';
    }
    default: {
      return '';
    }
  }
};
export const Navigation = (props) => {
  const history = useHistory();
  const { navList, navOpen, updateNavOpen } = useContext(NavigationContext);
  const { userInfo } = useContext(UserContext);

  useEffect(() => {
    if (userInfo && navList) {
      //유저 권한 체크
      //user authority check
      console.log('nav check history', history.location.pathname);
      console.log('nav check userInfo', userInfo);
      console.log('nav check navList', navList);

      if (history.location.pathname === '/') {
      } else {
        let pathArray = history.location.pathname.split('/');
        let parentPath = pathArray[1];
        let isValidation = false;
        let parentType = getTypeByPath(parentPath);
        console.log('parentType', parentType);
        for (let i in navList) {
          let target = navList[i];
          if (target.type === parentType) {
            isValidation = true;
            break;
          }
        }
        console.log('isValidation', isValidation);

        if (!isValidation) {
          alert('사용권한이 없습니다.');
          window.location.href = '/error';
        }
      }
    }
  }, [history.location.pathname]);

  const renderNavItem = (data, index) => {
    if (data.child) {
      return (
        <li className='accordion-item' key={'ac-item' + index}>
          <Accordion disableGutters={true} expanded={navOpen ? navOpen[index] : false}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#a6b0cf' }} />}
              onClick={() => {
                let new_navOpen = _.clone(navOpen);
                new_navOpen[index] = !new_navOpen[index];
                updateNavOpen(new_navOpen);
              }}
            >
              <span>{data.text}</span>
            </AccordionSummary>
            {data.child.map((item, child_index) => {
              let isActive = false;

              if (item.path === history.location.pathname) {
                isActive = true;
              }
              return (
                <AccordionDetails
                  key={item.path}
                  className={isActive ? 'active' : ''}
                  onClick={() => {
                    history.push(item.path);
                  }}
                >
                  <span>{item.text}</span>
                </AccordionDetails>
              );
            })}
          </Accordion>
        </li>
      );
    } else {
      let isActive = false;
      let startPathname = history.location.pathname.split('/')[1];

      if (startPathname === data.activeText) {
        isActive = true;
      }

      return (
        <li
          key={'main-nvaigation-' + index}
          // className={isActive ? 'active' : ''}
          onClick={() => {
            history.push(data.path);
          }}
        >
          <span>{data.text}</span>
        </li>
      );
    }
  };

  return (
    <article className='navigation'>
      <header>
        <h3
          onClick={() => {
            history.push('/');
          }}
        >
          POPLE 관리자
        </h3>
      </header>
      <ul>
        {navList &&
          navList.map((item, index) => {
            return renderNavItem(item, index);
          })}
      </ul>
    </article>
  );
};
