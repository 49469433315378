import React, { useEffect, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { MonthPickerBox, TextInput } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import { useParams } from 'react-router-dom';

export const EuEtsDetailPage = (props) => {
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      etsDate: null,
      averagePrice: null,
      yearAveragePrice: null,
    },
    {
      etsDate: {
        key: 'etsDate',
        label: '월',
        fullWidth: true,
        required: true,
      },
      averagePrice: {
        key: 'averagePrice',
        label: 'ET-ETS 평균 가격($)',
        fullWidth: true,
        required: true,
      },
      yearAveragePrice: {
        key: 'yearAveragePrice',
        label: '지난 1년 평균 가격($)',
        fullWidth: true,
        required: true,
      },
    },
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (slug) {
      refreshData();
    }
  }, [slug]);

  const refreshData = () => {
    API.Payment.GetEuetsDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log('GetEuetsDetail result', result);
        setFormData({
          averagePrice: result.averagePrice,
          etsDate: new Date(result.etsDate),
          yearAveragePrice: result.yearAveragePrice,
        });
        setLoading(false);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
        setLoading(false);
      },
    );
  };

  const requsetSave = () => {
    let body = Handler.formToBody(formData, formMeta);
    console.log('body', body);
    if (body) {
      let dateObject = new Date(body.etsDate);
      let db_date = dateObject.toISOString();

      API.Payment.PutEuets(slug, {
        ...body,
        etsDate: db_date,
      }).then(
        (res) => {
          let result = res.data;
          console.log('GetEuetsDetail post result', result);
          refreshData();
          updateSnackbar({
            type: 'success',
            open: true,
            message: '저장 성공',
          });
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  if (loading) {
    return null;
  }

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>EU-ETS 상세</h3>
        </header>
        <div className='button__box'>
          <Button disabled={false} variant='contained' onClick={requsetSave}>
            저장
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <MonthPickerBox value={formData.etsDate} onChange={formChange} meta={formMeta.etsDate}></MonthPickerBox>
          <TextInput value={formData.averagePrice} onChange={formChange} meta={formMeta.averagePrice}></TextInput>
          <TextInput
            value={formData.yearAveragePrice}
            onChange={formChange}
            meta={formMeta.yearAveragePrice}
          ></TextInput>
        </div>
      </section>
    </article>
  );
};
