import AxiosInstance from '../AxiosInstance';
import { BASE_URL } from '../Config';
import Utils from '../Utils';

const PATH = 'consultations/';

const GetConsultationList = (params) => {
  const url = BASE_URL + 'consultations' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetConsultationDetail = (seq) => {
  const url = BASE_URL + PATH + seq;
  return AxiosInstance.get(url);
};

const GetConsultationFeedback = (seq, params) => {
  const url = BASE_URL + PATH + seq + '/feedbacks' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const PutConsultation = (seq, body) => {
  const url = BASE_URL + PATH + seq;
  return AxiosInstance.put(url, body);
};

const Consultation = {
  GetList: GetConsultationList,
  GetDetail: GetConsultationDetail,
  GetFeedback: GetConsultationFeedback,
  Put: PutConsultation,
};

export default Consultation;
