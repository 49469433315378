import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { DialogContext, UserContext, SnackbarContext } from '../../contexts';
import Button from '@mui/material/Button';
import API from '../../api';
import TextField from '@mui/material/TextField';

export const UserLoginPage = (props) => {
  const history = useHistory();
  const { updateAccessToken, updateRefreshToken, updateUserSeq } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const [initPassword, setiInitPassword] = useState(false);
  const [newPw, setNewPw] = useState('');
  const [newPwCheck, setNewPwCheck] = useState('');

  const onClickLogin = () => {
    console.log('formData');
    API.Admin.SignIn({
      id: id,
      password: pw,
    }).then(
      (res) => {
        console.log('res', res);
        let result = res.data;
        if (result.initPassword) {
          //비밀번호 재설정 페이지
          setiInitPassword(true);
        } else {
          updateUserSeq(result.seq);
          updateAccessToken(result.token.accessToken);
          updateRefreshToken(result.token.refreshToken);
          history.push('/');
        }
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requestRestPassword = () => {
    console.log('requestRestPassword', id);
    if (newPw === newPwCheck) {
      API.Admin.InitPassword({
        id: id,
        initPassword: newPw,
      }).then(
        (res) => {
          console.log('res', res);
          let result = res.data;
          updateAccessToken(result.token.accessToken);
          updateRefreshToken(result.token.refreshToken);
          history.push('/');
        },
        (err) => {
          console.log('err', err);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '비밀번호가 일치하지 않습니다.',
      });
    }
  };

  // asdfdsaf
  if (initPassword) {
    return (
      <article className='user-login-page'>
        <header>
          <h1>비밀번호 재설정</h1>
        </header>
        <section className='form__box'>
          <TextField
            type='password'
            style={{ marginBottom: 20 }}
            value={newPw}
            onChange={(e) => {
              setNewPw(e.target.value);
            }}
            label={'새 비밀번호'}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                // Do code here
                e.preventDefault();
                requestRestPassword();
              }
            }}
          ></TextField>
          <TextField
            type='password'
            value={newPwCheck}
            onChange={(e) => {
              setNewPwCheck(e.target.value);
            }}
            label={'비밀번호 확인'}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                // Do code here
                e.preventDefault();
                requestRestPassword();
              }
            }}
          ></TextField>
        </section>
        <section className='button__box'>
          <Button
            size='large'
            style={{ width: '100%', marginBottom: 20 }}
            variant='contained'
            onClick={requestRestPassword}
          >
            비밀번호 재설정
          </Button>
          <Button
            color='info'
            size='large'
            style={{ width: '100%' }}
            variant='contained'
            onClick={() => {
              setiInitPassword(false);
            }}
          >
            뒤로가기
          </Button>
        </section>
      </article>
    );
  }
  return (
    <article className='user-login-page'>
      <header>
        <h1>POPLE 관리자</h1>
      </header>
      <section className='form__box'>
        <TextField
          style={{ marginBottom: 20 }}
          value={id}
          onChange={(e) => {
            setId(e.target.value);
          }}
          label={'아이디'}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              // Do code here
              e.preventDefault();
              onClickLogin();
            }
          }}
        ></TextField>
        <TextField
          type='password'
          value={pw}
          onChange={(e) => {
            setPw(e.target.value);
          }}
          label={'비밀번호'}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              // Do code here
              e.preventDefault();
              onClickLogin();
            }
          }}
        ></TextField>
      </section>
      <section className='button__box'>
        <Button size='large' style={{ width: '100%' }} variant='contained' onClick={onClickLogin}>
          로그인
        </Button>
      </section>
    </article>
  );
};
