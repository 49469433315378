import React from 'react';

export const CardSignout = (props) => {
  const { value } = props;
  console.log('CardSignout value', value);
  return (
    <article className='card-signout'>
      <section className='content__box'>
        <ul>
          <li>
            <header>
              <span>탈퇴 요청일</span>
            </header>
            <div className='value'>{value.requestDate}</div>
          </li>
          <li>
            <header>
              <span>탈퇴 사유</span>
            </header>
            <div className='value'>
              <p>{value.scope}</p>
            </div>
          </li>
          <li>
            <header>
              <span>탈퇴 내용</span>
            </header>
            <div className='value'>
              <p>{value.reason}</p>
            </div>
          </li>
        </ul>
      </section>
      <section className='guide__box'>
        <p>* 탈퇴승인 전 아래 내역을 확인 하신 후 '운영자 메모'란에 처리 결과를 기재하시기 바랍니다.</p>
        <p>* 승인 후에는 복구가 불가능하며 탈퇴 처리 완료 메일이 발송됩니다.</p>
        <p>* 선도거래 내역, 사업현황, 판매내역, 출금현황</p>
      </section>
    </article>
  );
};
