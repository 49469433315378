import React, { useEffect, useContext, useState } from 'react';
import { TextInput, CardReply, TextViewer } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext, DialogContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import { useParams } from 'react-router-dom';

export const CsContactUsDetailPage = (props) => {
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const [formData, formMeta, formChange, setFormData] = useForm(
    {
      answerContent: null,
      answerDate: null,
      answerId: null,
      content: '',
      createdAt: 0,
      email: '',
      inquiryDate: '',
      name: '',
      phone: '',
      seq: 0,
      site: '',
      status: '',
    },
    {
      site: {
        key: 'site',
        label: '사이트',
        fullWidth: true,
        menus: [
          {
            value: 'Credit',
            label: '인증시스템',
          },
          {
            value: 'Market',
            label: '마켓플레이스',
          },
        ],
      },
      name: {
        key: 'name',
        label: '이름',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      email: {
        key: 'email',
        label: '이메일',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      phone: {
        key: 'phone',
        label: '전화번호',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      content: {
        key: 'content',
        label: '문의내용',
        type: 'text',
        multiline: true,
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      site: {
        key: 'site',
        label: '사이트',
        render: (value) => {
          return Handler.ENUM.CS.SITE(value);
        },
      },
      inquiryDate: {
        key: 'inquiryDate',
        label: '문의일자',
        type: 'text',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
    },
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (slug) {
      refreshData();
    }
  }, [slug]);

  const refreshData = () => {
    API.CS.GetContactUsDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log('GetFaqCategoryDetail result', result);
        setFormData({ ...result });
        setLoading(false);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
        setLoading(false);
      },
    );
  };

  const requestReplyConfrim = (value) => {
    if (value) {
      updateDialog({
        type: 'info',
        open: true,
        title: '답변 등록',
        description: '등록 후 되돌릴 수 없습니다. 그래도 진행하시겠습니까?',
        onConfirm: () => {
          console.log('confirm work', value);
          API.CS.PutContactUsReply(slug, { answer: value }).then(
            (res) => {
              let result = res.data;
              refreshData();
            },
            (err) => {
              console.log('err', err);
              updateSnackbar({
                type: 'error',
                open: true,
                message: err.data.message,
              });
            },
          );
        },
      });
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '내용을 입력하세요.',
      });
    }
  };

  if (loading) {
    return null;
  }

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>Contact us 상세</h3>
        </header>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <TextInput value={formData.name} onChange={formChange} meta={formMeta.name}></TextInput>
          <TextInput value={formData.email} onChange={formChange} meta={formMeta.email}></TextInput>
          <TextInput value={formData.phone} onChange={formChange} meta={formMeta.phone}></TextInput>
          <TextInput value={formData.content} onChange={formChange} meta={formMeta.content}></TextInput>
          <TextViewer value={formData.site} meta={formMeta.site}></TextViewer>
          <TextInput value={formData.inquiryDate} onChange={formChange} meta={formMeta.inquiryDate}></TextInput>
        </div>
      </section>
      <section className='card__container' style={{ marginTop: 40 }}>
        <CardReply
          data={formData}
          requestSubmit={requestReplyConfrim}
          label='내용을 등록하시면 메일이 발송됩니다. 답변완료 전 입력하신 내용을 다시 한 번 확인하시기 바랍니다. (발송된 메일은 취소가 불가능합니다.)'
        ></CardReply>
      </section>
    </article>
  );
};
