import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { TableBox, SearchFilter, Paging } from '../../components';
import { usePagination, useFilter } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';

export const MemberEnterpriseListPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState(null);
  const [header, setHeader] = useState([
    {
      key: 'seq',
      align: 'center',
      text: 'No',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'companyName',
      align: 'center',
      text: '기업명',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'ceoName',
      align: 'center',
      text: '대표자명',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'businessNumber',
      align: 'center',
      text: '사업자등록번호',
      style: {
        minWidth: 160,
      },
    },
    {
      key: 'email',
      align: 'center',
      text: '이메일',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'phone',
      align: 'center',
      text: '전화번호',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'createdAt',
      align: 'center',
      text: '가입일',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.getYYYYMMDDByUnix(value);
      },
    },
    // {
    //   key: 'marketingAgreeDate',
    //   align: 'center',
    //   text: '마케팅 수신 동의',
    //   style: {
    //     minWidth: 100,
    //   },
    //   render: (value) => {
    //     console.log('render value', value);
    //     if (value) {
    //       return 'O';
    //     } else {
    //       return 'X';
    //     }
    //   },
    // },
    {
      key: 'status',
      align: 'center',
      text: '상태',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        console.log('render value', value);
        switch (value) {
          case 'Registered': {
            return '가입 등록';
          }
          case 'Certification': {
            return '메일 승인 대기';
          }
          case 'ApproveRequired': {
            return '관리자 승인 대기';
          }
          case 'Confirmed': {
            return '승인 완료';
          }
          case 'Disabled': {
            return '사용 불가';
          }
          default: {
            return '';
          }
        }
      },
    },
    {
      key: 'userType',
      align: 'center',
      text: '회원 유형',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        console.log('render value', value);
        switch (value) {
          case 'Integrated': {
            return '통합회원';
          }
          case 'Enterprise': {
            return '마켓플레이스';
          }
          case 'Personal': {
            return '개인';
          }
          default: {
            return '';
          }
        }
      },
    },
  ]);

  const [filter, onFilterChange, setFiler] = useFilter([
    {
      key: 'status',
      type: 'select',
      label: '상태',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: 'ApproveRequired',
          label: '관리자 승인 대기',
        },
        {
          value: 'Confirmed',
          label: '승인 완료',
        },
      ],
    },
    // {
    //   key: 'isMarketingAgree',
    //   type: 'select',
    //   label: '마켓팅 수신 동의',
    //   value: 'SELECT_ALL',
    //   menus: [
    //     {
    //       value: 'SELECT_ALL',
    //       label: '전체',
    //     },
    //     {
    //       value: 'true',
    //       label: '수신 동의',
    //     },
    //     {
    //       value: 'false',
    //       label: '수신 거부',
    //     },
    //   ],
    // },
    {
      key: 'signOutStatus',
      type: 'select',
      label: '탈퇴 여부',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: 'SignoutNone',
          label: '일반 유저',
        },
        {
          value: 'SignoutRequest',
          label: '탈퇴 요청',
        },
        {
          value: 'SignoutComplete',
          label: '탈퇴 유저',
        },
      ],
    },
    {
      key: 'searchType',
      type: 'select',
      label: '검색 종류',
      value: 'CompanyName',
      menus: [
        {
          value: 'CompanyName',
          label: '기업명',
        },
        {
          value: 'CeoName',
          label: '아이디',
        },
        {
          value: 'BusinessNumber',
          label: '핸드폰 번호',
        },
        {
          value: 'Phone',
          label: '전화번호',
        },
        {
          value: 'Email',
          label: '이메일',
        },
      ],
    },
    {
      key: 'searchValue',
      type: 'input',
      label: '검색 값',
      value: '',
      inputType: 'text',
    },
  ]);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  const tableCallback = (type, index) => {
    console.log('tableCallback type', type);
    console.log('tableCallback index', index);
  };

  const tableRowClick = (index) => {
    console.log('tableRowClick index', index);
    let target_seq = list[index].seq;
    console.log('tableRowClick target_seq', target_seq);
    history.push('/member/enterprise/detail/' + target_seq);
  };

  const onSearch = (body) => {
    console.log('onSearch', body);
    requestNewList(0);
  };

  useEffect(() => {
    console.log('page effect', page);
    requestNewList();
  }, [page]);

  const requestNewList = (init_page) => {
    let body = Handler.filterToBody(filter);
    console.log('page body', body);
    API.Member.GetMemberList({
      ...body,
      type: 'Enterprise',
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
    }).then(
      (res) => {
        console.log('res', res);
        let result = res.data;
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requsetAdd = () => {
    console.log('requsetAdd');
  };

  console.log('list', list);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>기업회원 목록</h3>
        </header>
      </section>
      <section className='filter__container'>
        <SearchFilter data={filter} onChange={onFilterChange} onSearch={onSearch}></SearchFilter>
      </section>
      <section className='table__container'>
        <TableBox data={list} header={header} rowClick={tableRowClick} callback={tableCallback}></TableBox>
      </section>
      <section className='paging__container'>
        <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
      </section>
    </article>
  );
};
