import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TableBox, SearchFilter, Paging } from '../../components';
import { usePagination, useFilter } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';

export const ConsultationListPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState(null);
  const [header, setHeader] = useState([
    {
      key: 'seq',
      align: 'center',
      text: 'No',
      style: {
        minWidth: 20,
      },
    },
    {
      key: 'category',
      align: 'center',
      text: '카테고리',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        return Handler.ENUM.CONSULTATION.CATEGORY(value);
      }
    },
    {
      key: 'title',
      align: 'center',
      text: '제목',
      style: {
        minWidth: 80,
      },
    },
    {
      key: '',
      align: 'center',
      text: '논평기간',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return value.startDate + " ~ " + value.endDate;
      }
    },
    {
      key: '',
      align: 'center',
      text: '상태',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        if (Handler.isTodayBetweenDate(value.startDate, value.endDate)) {          
          return value.isOpen ? '공개중' : '논평 중지';
        }else if(Handler.isTodayBeforeDate(value.startDate)) {
          return value.isOpen ? '공개 예정' : '미공개';
        } else {
          return '수렴 종료';
        }
      }
    },
    {
      key: '',
      align: 'center',
      text: '요약서',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        switch(value.category) {
          case 'Methodology': {
            return value.methodology?.documents?.some(document => document.type === 'CONSULTATION') ? "등록" : "미등록"
          }
          case 'Project': {
            return value.project?.documents?.some(document => document.type === 'CONSULTATION') ? "등록" : "미등록"
          }
          case 'Standard': {
            return value.standard?.documents?.some(document => document.type === 'CONSULTATION') ? "등록" : "미등록"
          }
          case 'Terms': {
            return value.terms?.documents?.some(document => document.type === 'CONSULTATION') ? "등록" : "미등록"
          }
          default: {
            return "-"
          }
        }
      }
    },
  ]);

  const [filter, onFilterChange, setFiler] = useFilter([
    {
      key: 'category',
      type: 'select',
      label: '검색 종류',
      value: 'Methodology',
      menus: [
        {
          value: 'Methodology',
          label: '방법론명',
        },
        {
          value: 'Project',
          label: '프로젝트',
        },
        {
          value: 'Standard',
          label: '팝플 스탠다드',
        },
        {
          value: 'Terms',
          label: '레지스트리 이용약관',
        },
      ],
    },
    {
      key: 'title',
      type: 'input',
      label: '제목',
      value: '',
      inputType: 'text',
    },
  ]);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 10);
  const params = new URLSearchParams(location.search);
    
  const tableRowClick = (index) => {
    let target_seq = list[index].seq;
    history.push('/consultation/detail/' + target_seq);
  };

  const onSearch = (body) => {
    requestList(0);
  };

  useEffect(() => {
    requestList();
    history.push(`?page=${page}`);
  }, [page]);

  useEffect(() => {
    const page = parseInt(params.get('page') || '0', 10);
    setPage(page);
  }, []);

  const requestList = (init_page) => {
    let body = Handler.filterToBody(filter);
    console.log('page body', body);
    API.Consultation.GetList({
      ...body,
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
    }).then(
      (res) => {
        console.log('res', res);
        let result = res.data;
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>논평 목록</h3>
        </header>
      </section>
      <section className='filter__container'>
        <SearchFilter data={filter} onChange={onFilterChange} onSearch={onSearch}></SearchFilter>
      </section>
      <section className='table__container'>
        <TableBox data={list} header={header} rowClick={tableRowClick} callback={() => {}}></TableBox>
      </section>
      <section className='paging__container'>
        <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
      </section>
    </article>
  );
};
