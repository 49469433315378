import React, { useEffect, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import {
  TextInput,
  SelectBox,
  Check,
  ToastEditor,
  CardReply,
  TextViewer,
  DatePickerBox,
  ModalCertification,
} from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext, UserContext, DialogContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 0,
  borderRadius: 2,
};

export const PaymentDetailPage = (props) => {
  const history = useHistory();
  const { slug } = useParams();
  const { userInfo } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      amount: null,
      bankCode: null,
      bankName: null,
      buyerName: null,
      calcPrice: 0,
      cancelledAt: 0,
      cert: null,
      certNumber: null,
      createdAt: 0,
      failedAt: 0,
      forwardComplete: null,
      forwardDate: null,
      forwardReady: null,
      impUid: null,
      importStatus: null,
      isForward: false,
      isForwardComplete: false,
      merchantUid: null,
      message: null,
      meta: null,
      orderDate: null,
      orderFile: null,
      orderNumber: null,
      paidAt: 0,
      pgId: null,
      pgTid: null,
      presentEmail: null,
      presentName: null,
      price: 0,
      project: null,
      purchaseType: null,
      reason: null,
      receiptUrl: null,
      revenue: 0,
      seq: 0,
      startedAt: 0,
      status: null,
      statusCode: null,
      type: null,
      unitPrice: 0,
      user: null,
      vbankCode: null,
      vbankDate: 0,
      vbankHolder: null,
      vbankIssuedAt: 0,
      vbankName: null,
      vbankNum: null,
    },
    {
      orderNumber: {
        key: 'orderNumber',
        label: '주문번호',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      projectId: {
        key: 'projectId',
        label: '사업ID',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      projectTitle: {
        key: 'projectTitle',
        label: '사업명',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      purchaseType: {
        label: '거래구분',
        fullWidth: true,
        render: (value) => {
          switch (value) {
            case 'ForwardPersonal': {
              return '개인선도';
            }
            case 'NormalPersonal': {
              return '개인일반';
            }
            case 'ForwardEnterprise': {
              return '기업선도';
            }
            case 'NormalEnterprise': {
              return '기업일반';
            }
            default: {
              return 'none';
            }
          }
        },
      },
      salePrice: {
        fullWidth: true,
        key: 'salePrice',
        label: '상품금액',
        render: (value) => {
          return Handler.numberWithCommas(value) + '원';
        },
      },
      amount: {
        fullWidth: true,
        key: 'amount',
        label: '구매수량',
        render: (value) => {
          return Handler.numberWithCommas(value) + '톤';
        },
      },
      status: {
        label: '결제상태',
        fullWidth: true,
        render: (value) => {
          switch (value) {
            case 'DepositReady': {
              return '입금대기';
            }
            case 'DepositCancel': {
              return '입금취소';
            }
            case 'DepositComplete': {
              return '입금완료';
            }
            case 'Complete': {
              return '결제완료';
            }
            case 'Waiting': {
              return '승인대기';
            }
            default: {
              return value;
            }
          }
        },
      },
      type: {
        label: '결제수단',
        fullWidth: true,
        render: (value) => {
          switch (value) {
            case 'CARD': {
              return '신용카드';
            }
            case 'BANK': {
              return '무통장입금';
            }
            case 'VBANK': {
              return '가상계좌';
            }
            case 'MOBILE': {
              return '모바일';
            }
            case 'BCSH': {
              return 'BCSH';
            }
            case 'CULTURE': {
              return '문화상품권';
            }
            case 'HPMN': {
              return '입금완료';
            }
            case 'EWALLET': {
              return 'EWALLET';
            }
            case 'ETC_': {
              return '기타';
            }
            case 'TAXBILL': {
              return '세금계산서 발행';
            }
            default: {
              return 'none';
            }
          }
        },
      },
      paidAt: {
        label: '결제일시',
        fullWidth: true,
        render: (value) => {
          if (value) {
            return Handler.getYYYYMMDDhhmmssByUnix(value);
          } else {
            return '';
          }
        },
      },
      unitPrice: {
        label: '판매가격(톤)',
        fullWidth: true,
        render: (value) => {
          return Handler.numberWithCommas(value) + '원';
        },
      },
      price: {
        label: '결제금액',
        fullWidth: true,
        render: (value) => {
          return Handler.numberWithCommas(value) + '원';
        },
      },
      buyerName: {
        label: '구매자명',
        fullWidth: true,
        render: (value) => {
          return value;
        },
      },
      buyerEmail: {
        label: '이메일',
        fullWidth: true,
        render: (value) => {
          return value;
        },
      },
      buyerPhone: {
        label: '연락처',
        fullWidth: true,
        render: (value) => {
          return value;
        },
      },
      reason: {
        label: '구매사유',
        fullWidth: true,
        render: (value) => {
          return value;
        },
      },
      present: {
        label: '선물하기 대상',
        fullWidth: true,
        render: (value) => {
          return value.presentName + '(' + value.presentEmail + ')';
        },
      },
      companyName: {
        label: '기업명',
        fullWidth: true,
        render: (value) => {
          return value;
        },
      },
      businessNumber: {
        label: '사업자등록번호',
        fullWidth: true,
        render: (value) => {
          return value;
        },
      },
    },
  );

  const [loading, setLoading] = useState(true);
  const [certificationOpen, setCertificationOpen] = useState(false);
  const [certificationData, setCertificationData] = useState(null);

  useEffect(() => {
    if (slug) {
      refreshData();
    }
  }, [slug]);

  const refreshData = () => {
    API.Payment.GetDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log('GetWithdrawDetail result', result);
        setFormData({ ...result });
        setLoading(false);
        setCertificationData({ ...result.cert });
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
        setLoading(false);
      },
    );
  };

  const regenCertFile = (merchantUid) => {
    const params = { merchantUid: merchantUid };
    API.Payment.RegenPdf(params).then(
      (res) => {
        let result = res.data;
        console.log('regenCertFile', result);
        updateSnackbar({
          type: 'success',
          open: true,
          message: '인증서 재발행이 완료되었습니다.',
        });
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
        setLoading(false);
      },
    );
  };

  const approveTaxbillConfirm = () => {
    updateDialog({
      type: 'info',
      open: true,
      title: '세금계산서 발행 승인',
      description: '세금계산서 발행 결제정보를 최종 결제완료 승인하시겠습니까?',
      onConfirm: () => {
        handleEvent('REQUEST_PUT_STATUS');
      },
    });
  };

  const handleEvent = (type) => {
    console.log('handleEvent', type);

    switch (type) {
      case 'REQUEST_PRESENT_EMAIL': {
        console.log('REQUEST_PRESENT_EMAIL');
        API.Payment.PutPresentResend(slug).then(
          (res) => {
            updateSnackbar({
              type: 'success',
              open: true,
              message: '재발송 성공',
            });
          },
          (err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          },
        );
        break;
      }
      case 'REQUEST_PUT_STATUS': {
        console.log('REQUEST_PRESENT_EMAIL');
        const body = {
          paymentStatus: 'Complete',
          amount: formData.amount,
          projectId: formData.project.id,
        };
        API.Payment.PutListStatus(slug, body).then(
          (res) => {
            updateSnackbar({
              type: 'success',
              open: true,
              message: '최종 결제완료 승인되었습니다.',
            });
            refreshData();
          },
          (err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          },
        );
        break;
      }
      case 'REQUEST_CERTIFICATION_VIEW': {
        setCertificationOpen(true);
      }
    }
  };

  if (loading) {
    return null;
  }

  console.log('formData', formData);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>결제 상세</h3>
        </header>
        <div className='button__box'>
          {formData?.cert?.pdfUrl !== undefined && formData.status === 'Complete' && (
            <>
              <a
                style={{
                  backgroundColor: '#4458b8',
                  padding: '8px 16px',
                  marginRight: '8px',
                  borderRadius: '4px',
                  color: '#ffffff',
                  letterSpacing: '0.02857em',
                  fontSize: '0.875rem',
                  lineHeight: '1.75',
                }}
                className='button__box'
                href={formData.cert?.pdfUrl}
                download
              >
                상쇄 인증서
              </a>
              <Button
                color='success'
                variant='contained'
                onClick={() => {
                  regenCertFile(formData.merchantUid);
                }}
              >
                인증서 재발행
              </Button>
            </>
          )}
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>주문 정보</h3>
        </header>
        <div className='flex'>
          <TextInput value={formData.orderNumber} onChange={formChange} meta={formMeta.orderNumber}></TextInput>
          <TextInput value={formData.project.id} onChange={formChange} meta={formMeta.projectId}></TextInput>
          <TextInput value={formData.project.title} onChange={formChange} meta={formMeta.projectTitle}></TextInput>
          <TextViewer value={formData.purchaseType} onChange={formChange} meta={formMeta.purchaseType}></TextViewer>
          <TextInput value={formData.project.title} onChange={formChange} meta={formMeta.projectTitle}></TextInput>
          <TextViewer value={formData.unitPrice} meta={formMeta.unitPrice}></TextViewer>
          <TextViewer value={formData.amount} meta={formMeta.amount}></TextViewer>
        </div>
      </section>
      <div style={{ height: 40 }}></div>
      <section className='form__container'>
        <header>
          <h3>결제 정보</h3>
        </header>
        {formData.type === 'TAXBILL' && (
          <article>
            <div style={{ marginBottom: '1rem', padding: '1rem 0', borderBottom: '1px solid #ddd' }}>
              <div style={{ padding: '0.5rem 0' }}>
                <p style={{ fontWeight: 'bold' }}>세금계산서 승인</p>
                <p style={{ fontSize: '14px' }}>
                  입금이 확인된 결제정보를 세금계산서 발행 및 최종 결제완료를 승인합니다.
                </p>
              </div>
              <Button
                variant='contained'
                disabled={formData.status === 'Complete'}
                onClick={() => {
                  approveTaxbillConfirm();
                }}
              >
                {formData.status === 'Complete' ? '승인완료' : '승인'}
              </Button>
            </div>
          </article>
        )}
        <div className='flex'>
          <TextViewer value={formData.status} meta={formMeta.status}></TextViewer>
          <TextViewer value={formData.type} meta={formMeta.type}></TextViewer>
          <TextViewer value={formData.paidAt} meta={formMeta.paidAt}></TextViewer>
          <TextViewer value={formData.salePrice} meta={formMeta.salePrice}></TextViewer>
          <TextViewer value={formData.price} meta={formMeta.price}></TextViewer>
        </div>
      </section>
      <div style={{ height: 40 }}></div>

      <section className='form__container'>
        <header>
          <h3>구매자 정보</h3>
        </header>
        <div className='flex'>
          {formData.purchaseType === 'NormalEnterprise' ? (
            <>
              <TextViewer value={formData.user.companyName} meta={formMeta.companyName}></TextViewer>
              <TextViewer value={formData.user.businessNumber} meta={formMeta.businessNumber}></TextViewer>
            </>
          ) : (
            <TextViewer value={formData.buyerName} meta={formMeta.buyerName}></TextViewer>
          )}
          <TextViewer value={formData.user.email} meta={formMeta.buyerName}></TextViewer>
          <TextViewer value={formData.user.phone} meta={formMeta.buyerPhone}></TextViewer>
          <TextViewer value={formData.reason} meta={formMeta.reason}></TextViewer>
          {formData.presentEmail && (
            <TextViewer
              value={formData}
              meta={formMeta.present}
              customButton={{
                label: '메일 재발송',
                onClick: () => {
                  handleEvent('REQUEST_PRESENT_EMAIL');
                },
              }}
            ></TextViewer>
          )}
        </div>
      </section>
      <Modal
        open={certificationOpen}
        onClose={() => {
          setCertificationOpen(false);
        }}
      >
        <Box sx={style}>
          <ModalCertification
            data={certificationData}
            onClose={() => {
              setCertificationOpen(false);
            }}
          ></ModalCertification>
        </Box>
      </Modal>
    </article>
  );
};
