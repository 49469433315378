import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { TableBox, SearchFilter, Paging } from '../../components';
import { usePagination, useFilter } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Button from '@mui/material/Button';
import Handler from '../../Handler';
import API from '../../api';

export const CsInquiryListPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState(null);
  const [header, setHeader] = useState([
    {
      key: 'seq',
      align: 'center',
      text: 'No',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'site',
      align: 'center',
      text: '사이트',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.ENUM.CS.SITE(value);
      },
    },
    {
      key: 'title',
      align: 'center',
      text: '제목',
      style: {
        minWidth: 200,
      },
      render: (value) => {
        return (
          <div style={{ maxWidth: 300 }} className='ellipsis-text'>
            <span>{value}</span>
          </div>
        );
      },
    },
    {
      key: 'type',
      align: 'center',
      text: '문의 타입',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.ENUM.CS.TYPE(value);
      },
    },
    {
      key: 'name',
      align: 'center',
      text: '이름',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'email',
      align: 'center',
      text: '이메일',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'phone',
      align: 'center',
      text: '전화번호',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'createdAt',
      align: 'center',
      text: '문의일자',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.getYYYYMMDDhhmmssByUnix(value);
      },
    },
    {
      key: 'status',
      align: 'center',
      text: '답변상태',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        if (value === 'Inquiry') {
          return <span style={{ color: '#fc312f', fontSize: 14 }}>{Handler.ENUM.CS.STATUS(value)}</span>;
        } else {
          return <span style={{ color: '#4458b8', fontSize: 14 }}>{Handler.ENUM.CS.STATUS(value)}</span>;
        }
      },
    },
    // {
    //   key: 'title',
    //   align: 'center',
    //   text: '제목',
    //   style: {
    //     minWidth: 200,
    //   },
    //   render: (value) => {
    //     return (
    //       <div style={{ maxWidth: 300 }} className='ellipsis-text'>
    //         <span>{value}</span>
    //       </div>
    //     );
    //   },
    // },
  ]);
  const [filter, onFilterChange, setFiler] = useFilter([
    {
      key: 'site',
      type: 'select',
      label: '사이트 구분',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: 'Credit',
          label: '인증시스템',
        },
        {
          value: 'Market',
          label: '마켓플레이스',
        },
      ],
    },
    {
      key: 'type',
      type: 'select',
      label: '문의 타입',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: 'Member',
          label: '회원 가입/탈퇴',
        },
        {
          value: 'Methodology',
          label: '방법론',
        },
        {
          value: 'Project',
          label: '프로젝트 관리',
        },
        {
          value: 'ReductionCert',
          label: '감축량 인증',
        },
        {
          value: 'ZCCredit',
          label: 'ZC크레딧',
        },
        {
          value: 'Etc',
          label: '기타',
        },
      ],
    },
    {
      key: 'status',
      type: 'select',
      label: '문의 상태',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: 'Inquiry',
          label: '답변대기',
        },
        {
          value: 'Complete',
          label: '답변완료',
        },
      ],
    },
    {
      key: 'searchType',
      type: 'select',
      label: '검색 종류',
      value: 'Title',
      menus: [
        {
          value: 'Title',
          label: '제목',
        },
        {
          value: 'Content',
          label: '내용',
        },
      ],
    },
    {
      key: 'searchValue',
      type: 'input',
      label: '검색 값',
      value: '',
      inputType: 'text',
    },
  ]);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  const tableCallback = (type, index) => {
    console.log('type', type);
    console.log('index', index);
  };

  const onSearch = (body) => {
    console.log('onSearch', body);
    requestNewList(0);
  };

  useEffect(() => {
    console.log('page effect', page);
    requestNewList();
  }, [page]);

  const requestNewList = (init_page) => {
    let body = Handler.filterToBody(filter);
    console.log('page body', body);
    API.CS.GetInquiry({
      ...body,
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
    }).then(
      (res) => {
        console.log('res', res);
        let result = res.data;
        setList(result.content);
        setTotalPage(result.totalPages);
        setTotalCount(result.totalElements);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const tableRowClick = (index) => {
    console.log('tableRowClick index', index);
    let target_seq = list[index].seq;
    history.push('/cs/inquiry/detail/' + target_seq);
  };

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <h3>상담문의 목록</h3>
          <span>총 {totalCount}건</span>
        </header>
      </section>
      <section className='filter__container'>
        <SearchFilter data={filter} onChange={onFilterChange} onSearch={onSearch}></SearchFilter>
      </section>
      <section className='table__container'>
        <TableBox data={list} header={header} rowClick={tableRowClick} callback={tableCallback}></TableBox>
      </section>
      <section className='paging__container'>
        <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
      </section>
    </article>
  );
};
