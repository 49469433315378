import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { TableBox, SearchFilter, Paging } from '../../components';
import { usePagination, useFilter } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Button from '@mui/material/Button';
import Handler from '../../Handler';
import API from '../../api';

export const PaymentRevenueListPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState([
    {
      name: 'name',
      description: 'description',
    },
    {
      name: 'name2',
      description: 'description2',
    },
  ]);
  const [header, setHeader] = useState([
    {
      key: 'seq',
      align: 'center',
      text: 'No',
      style: {
        minWidth: 50,
      },
    },
    {
      key: 'orderNumber',
      align: 'center',
      text: '주문번호',
      style: {
        minWidth: 80,
      },
    },
    {
      key: 'buyerName',
      align: 'center',
      text: '구매자명',
      style: {
        minWidth: 80,
      },
    },
    {
      key: null,
      align: 'center',
      text: '사업ID',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return value.project ? value.project.id : '';
      },
    },
    {
      key: null,
      align: 'center',
      text: '사업명',
      style: {
        minWidth: 200,
      },
      render: (value) => {
        return value.project ? value.project.title : '';
      },
    },
    {
      key: 'isForwardComplete',
      align: 'center',
      text: '결제상태',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        if (value) {
          return <span style={{ color: '#4458b8' }}>정산완료</span>;
        } else {
          return '정산진행';
        }
      },
    },
    {
      key: 'forwardReady',
      align: 'center',
      text: '구매수량(톤)',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        return Handler.numberWithCommas(value);
      },
    },
    {
      key: 'forwardComplete',
      align: 'center',
      text: '누적 정산수량(톤)',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        return Handler.numberWithCommas(value);
      },
    },
    {
      key: 'calcPrice',
      align: 'center',
      text: '누적 정산금액',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        return Handler.numberWithCommas(value);
      },
    },
    {
      key: 'forwardDate',
      align: 'center',
      text: '최근 정산일',
      style: {
        minWidth: 80,
      },
      render: (value) => {
        if (value) {
          return Handler.getYYYYMMDDByDate(value);
        } else {
          return '-';
        }
      },
    },
  ]);
  const [filter, onFilterChange, setFiler] = useFilter([
    {
      key: 'status',
      type: 'select',
      label: '정산 상태',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: 'Proceeding',
          label: '정산진행',
        },
        {
          value: 'Complete',
          label: '정산완료',
        },
      ],
    },
    {
      key: 'startDate',
      type: 'date',
      label: '시작일',
      value: null,
    },
    {
      key: 'endDate',
      type: 'date',
      label: '종료일',
      value: null,
    },
    {
      key: 'searchType',
      type: 'select',
      label: '검색 종류',
      value: 'Name',
      menus: [
        {
          value: 'Name',
          label: '구매자명',
        },
        {
          value: 'Email',
          label: '이메일',
        },
        {
          value: 'OrderNumber',
          label: '주문번호',
        },
        {
          value: 'ProjectTitle',
          label: '사업명',
        },
        {
          value: 'ProjectId',
          label: '사업ID',
        },
      ],
    },
    {
      key: 'searchValue',
      type: 'input',
      label: '검색 값',
      value: '',
      inputType: 'text',
    },
  ]);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  const tableCallback = (type, index) => {
    console.log('type', type);
    console.log('index', index);
  };

  const onSearch = (body) => {
    console.log('onSearch', body);
    requestNewList(0);
  };

  useEffect(() => {
    console.log('page effect', page);
    requestNewList();
  }, [page]);

  const requestNewList = (init_page) => {
    let body = Handler.filterToBody(filter);
    console.log('page body', body);
    API.Payment.GetRevenueList({
      ...body,
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
    }).then(
      (res) => {
        console.log('res', res);
        let result = res.data;
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const tableRowClick = (index) => {
    console.log('tableRowClick index', index);
    let target_seq = list[index].seq;
    console.log('tableRowClick target_seq', target_seq);
    history.push('/payment/revenue_detail/' + target_seq);
  };

  console.log('list', list);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>수익 현황 목록 (개인 선도거래)</h3>
        </header>
      </section>
      <section className='filter__container'>
        <SearchFilter data={filter} onChange={onFilterChange} onSearch={onSearch}></SearchFilter>
      </section>
      <section className='table__container'>
        <TableBox data={list} header={header} rowClick={tableRowClick} callback={tableCallback}></TableBox>
      </section>
      <section className='paging__container'>
        <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
      </section>
    </article>
  );
};
