import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { TextInput, SelectBox } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

export const CsFaqCategoryAddPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [formData, formMeta, formChange] = useForm(
    {
      site: 'Credit',
      category: '',
    },
    {
      site: {
        key: 'site',
        label: '사이트',
        fullWidth: true,
        menus: [
          {
            value: 'Credit',
            label: '인증시스템',
          },
          {
            value: 'Market',
            label: '마켓플레이스',
          },
        ],
      },

      category: {
        key: 'category',
        label: '카테고리',
        fullWidth: true,
        required: true,
      },
    },
  );

  const requsetAdd = () => {
    let body = Handler.formToBody(formData, formMeta);
    console.log('body', body);

    if (body) {
      API.CS.PostFaqCategory(body).then(
        (res) => {
          let result = res.data;
          console.log('PostTerms post result', result);
          updateSnackbar({
            type: 'success',
            open: true,
            message: 'FAQ 카테고리가 추가되었습니다.',
          });
          history.push('/cs/faq_category/list');
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>FAQ 카테고리 추가</h3>
        </header>
        <div className='button__box'>
          <Button variant='contained' onClick={requsetAdd}>
            추가
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <SelectBox value={formData.site} onChange={formChange} meta={formMeta.site}></SelectBox>
          <TextInput value={formData.category} onChange={formChange} meta={formMeta.category}></TextInput>
        </div>
      </section>
    </article>
  );
};
