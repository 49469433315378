import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { TextInput, SelectBox, RadioGroupBox } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

export const ReductionEvidenceAddPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      type: 'All',
      title: '',
      isForce: true,
    },
    {
      type: {
        key: 'type',
        label: '모니터링 타입',
        fullWidth: true,
        menus: [
          {
            value: 'All',
            label: '전체',
          },
          {
            value: 'Document',
            label: '서면 모니터링',
          },
          {
            value: 'System',
            label: '시스템 모니터링',
          },
        ],
      },
      title: {
        key: 'title',
        label: '증빙서류명',
        fullWidth: true,
        required: true,
      },
      isForce: {
        key: 'isForce',
        label: '필수 여부',
        menus: [
          {
            label: '필수',
            value: true,
          },
          {
            label: '선택',
            value: false,
          },
        ],
      },
    },
  );

  const requsetAdd = () => {
    let body = Handler.formToBody(formData, formMeta);

    if (body) {
      API.MonitoringEvidence.PostEvidence(body).then(
        (res) => {
          let result = res.data;
          console.log('PostEvidence post result', result);
          history.push('/reduction/evidence/list');
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>모니터링 증빙서류 추가</h3>
        </header>
        <div className='button__box'>
          <Button variant='contained' onClick={requsetAdd}>
            저장
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <SelectBox value={formData.type} onChange={formChange} meta={formMeta.type}></SelectBox>
          <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
          <RadioGroupBox value={formData.isForce} onChange={formChange} meta={formMeta.isForce}></RadioGroupBox>
        </div>
      </section>
    </article>
  );
};
