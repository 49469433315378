import React, { useEffect, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { TextInput, SelectBox, Check, ToastEditor, FileMultipleUploader } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext, DialogContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import _, { omit } from 'lodash';
import { useParams } from 'react-router-dom';

export const MarketplaceDetailPage = (props) => {
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const [initData, setInitData] = useState(false);
  const [etsInfo, setEtsInfo] = useState(null);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      id: '',
      title: '',
      area: '',
      subArea: '',
      startDate: '',
      endDate: '',
      marketAmount: 0,
      mainImages: [],
      unitPrice: 0,
      tradeType: '',
      introOne: '',
      introTwo: '',
      introProject: '',
      introCompany: '',
      isView: true,
      saleStatus: '',
    },
    {
      id: {
        key: 'id',
        label: '사업ID',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      title: {
        key: 'title',
        label: '사업명',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      area: {
        key: 'area',
        label: '사업 유형',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      subArea: {
        key: 'subArea',
        label: '시업 유형 상세',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      startDate: {
        key: 'startDate',
        label: '유효기간 시작일',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      endDate: {
        key: 'endDate',
        label: '유효기간 종료일',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      marketAmount: {
        key: 'marketAmount',
        type: 'number',
        label: '마켓 크레딧 수량',
      },
      mainImages: {
        type: 'image',
        key: 'mainImages',
        label: '대표 이미지',
        path: '/marketplace/mainImages',
        max: 5,
      },
      tradeType: {
        key: 'tradeType',
        label: '거래 타입',
        fullWidth: true,
        required: true,
        menus: [
          {
            value: 'Normal',
            label: '일반 거래',
          },
          {
            value: 'ForwardEnterprise',
            label: '일반 + 선도 거래(기업)',
          },
          {
            value: 'ForwardBoth',
            label: '일반 + 선도 거래(기업,개인)',
          },
        ],
      },
      isView: {
        key: 'isView',
        label: '노출 여부',
      },
      saleStatus: {
        key: 'saleStatus',
        label: '판매 상태',
        fullWidth: true,
        required: true,
        menus: [
          {
            value: 'Ready',
            label: '준비',
          },
          {
            value: 'OnSale',
            label: '판매중',
          },
          {
            value: 'Closed',
            label: '판매 종료',
          },
          {
            value: 'SoldOut',
            label: '판매 완료',
          },
        ],
      },
      unitPrice: {
        key: 'unitPrice',
        label: '기준가격',
        type: 'number',
        required: true,
        fullWidth: true,
        style: {},
      },
      introOne: {
        key: 'introOne',
        label: '2줄 소개 (첫째줄)',
        fullWidth: true,
        required: true,
      },
      introTwo: {
        key: 'introTwo',
        label: '2줄 소개 (둘째줄)',
        fullWidth: true,
        required: true,
      },
      introProject: {
        key: 'introProject',
        label: '사업 소개',
        required: true,
      },
      introCompany: {
        key: 'introCompany',
        label: '기업 소개',
        required: true,
      },
    },
  );

  useEffect(() => {
    API.Common.GetEts().then(
      (res) => {
        let result = res.data;
        console.log('GetEts result', result);
        setEtsInfo(result);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  }, []);

  useEffect(() => {
    if (slug) {
      API.Marketplace.GetDetail(slug).then(
        (res) => {
          let result = res.data;
          console.log('GetDetail result', result);
          setInitData(result);
        },
        (err) => {
          console.log('err', err);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    }
  }, [slug]);

  useEffect(() => {
    if (initData) {
      console.log('initData', initData);
      setFormData({
        id: initData.id,
        title: initData.title,
        area: Handler.ENUM.AREA(initData.methodology.area),
        subArea: Handler.ENUM.SUB_AREA(initData.methodology.subArea),
        startDate: initData.startDate,
        endDate: initData.endDate,
        marketAmount: initData.reductionNow,
        mainImages: initData.mainImages ? initData.mainImages : [],
        tradeType: initData.tradeType,
        isView: initData.isView,
        saleStatus: initData.saleStatus,
        unitPrice: initData.unitPrice ? initData.unitPrice : 0,
        introOne: initData.introOne,
        introTwo: initData.introTwo,
        introTwo: initData.introTwo,
        introProject: initData.introProject,
        introCompany: initData.introCompany,
      });
      let new_fromMeta = _.clone(formMeta);
      if (initData.unitPrice) {
        new_fromMeta.unitPrice = {
          key: 'unitPrice',
          label: '기준가격',
          fullWidth: true,
          required: true,
          disabled: true,
          InputProps: {
            readOnly: true,
          },
        };
      }
      setFormMeta(new_fromMeta);
    }
  }, [initData]);

  const refreshData = () => {
    API.Marketplace.GetDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log('GetDetail result', result);
        setInitData(result);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requestSave = () => {
    console.log('requestSave');
    let body = Handler.formToBody(formData, formMeta);
    console.log('requestSave', body);

    if (body) {
      const db_body = omit(body, ['area', 'endDate', 'id', 'marketAmount', 'startDate', 'subArea', 'title']);
      API.Marketplace.Put(slug, db_body).then(
        (res) => {
          let result = res.data;
          console.log(' PutEuets result', result);
          updateSnackbar({
            type: 'success',
            open: true,
            message: '저장 성공',
          });
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  const requestSignout = () => {
    updateDialog({
      type: 'error',
      open: true,
      title: '탈퇴 승인',
      description: '탈퇴처리 후 되돌릴 수 없습니다. 그래도 진행하시겠습니까?',
      onConfirm: onConfirmSignout,
    });
  };

  const onConfirmSignout = () => {
    API.Member.PutSignout(slug).then(
      (res) => {
        updateSnackbar({
          type: 'success',
          open: true,
          message: '탈퇴 성공',
        });
        refreshData();
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requestApprove = () => {
    console.log('requestApprove');
    updateDialog({
      type: 'info',
      open: true,
      title: '가입 승인',
      description: '가입을 승인하시겠습니까?',
      onConfirm: onConfirmApprove,
    });
  };

  const onConfirmApprove = () => {
    API.Member.PutMemberApproved(slug).then(
      (res) => {
        updateSnackbar({
          type: 'success',
          open: true,
          message: '가입 성공',
        });
        refreshData();
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  console.log('formData', formData);
  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>상품 상세</h3>
        </header>
        <div className='button__box'>
          <Button variant='contained' onClick={requestSave}>
            저장
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <TextInput value={formData.id} onChange={formChange} meta={formMeta.id}></TextInput>
          <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
          <TextInput value={formData.area} onChange={formChange} meta={formMeta.area}></TextInput>
          <TextInput value={formData.subArea} onChange={formChange} meta={formMeta.subArea}></TextInput>
          <TextInput value={formData.startDate} onChange={formChange} meta={formMeta.startDate}></TextInput>
          <TextInput value={formData.endDate} onChange={formChange} meta={formMeta.endDate}></TextInput>
        </div>
      </section>
      <section className='form__container' style={{ marginTop: 40 }}>
        <header>
          <h3>Marketplace 정보</h3>
        </header>
        <div className='flex'>
          <TextInput
            value={formData.marketAmount}
            onChange={(value, key) => {
              const max = initData.reductionNow;
              const val = value >= max ? max : value;
              formChange(val, key);
            }}
            meta={formMeta.marketAmount}
          ></TextInput>
          <FileMultipleUploader
            value={formData.mainImages}
            onChange={formChange}
            meta={formMeta.mainImages}
          ></FileMultipleUploader>
          <SelectBox value={formData.tradeType} onChange={formChange} meta={formMeta.tradeType}></SelectBox>
          <Check value={formData.isView} onChange={formChange} meta={formMeta.isView}></Check>
          <SelectBox value={formData.saleStatus} onChange={formChange} meta={formMeta.saleStatus}></SelectBox>
          {etsInfo && (
            <TextInput
              value={etsInfo.averagePrice}
              onChange={formChange}
              meta={{
                key: 'id',
                label: 'EU-ETS 가격',
                fullWidth: true,
                required: true,
                disabled: true,
              }}
            ></TextInput>
          )}
          <TextInput value={formData.unitPrice} onChange={formChange} meta={formMeta.unitPrice}></TextInput>
          <TextInput value={formData.introOne} onChange={formChange} meta={formMeta.introOne}></TextInput>
          <TextInput value={formData.introTwo} onChange={formChange} meta={formMeta.introTwo}></TextInput>
          <ToastEditor value={formData.introProject} onChange={formChange} meta={formMeta.introProject}></ToastEditor>
          <ToastEditor value={formData.introCompany} onChange={formChange} meta={formMeta.introCompany}></ToastEditor>
        </div>
      </section>
    </article>
  );
};
