import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const GetInquiry = (params) => {
  const url = BASE_URL + 'inquiry' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetInquiryDetail = (seq) => {
  const url = BASE_URL + 'inquiry/' + seq;
  return AxiosInstance.get(url);
};

const PutInquiryAnswer = (seq, body) => {
  const url = BASE_URL + 'inquiry/' + seq;
  return AxiosInstance.put(url, body);
};

const GetNotice = (params) => {
  const url = BASE_URL + 'notices' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const PostNotice = (body) => {
  const url = BASE_URL + 'notices';
  return AxiosInstance.post(url, body);
};

const GetNoticeDetail = (seq) => {
  const url = BASE_URL + 'notices/' + seq;
  return AxiosInstance.get(url);
};

const PutNotice = (seq, body) => {
  const url = BASE_URL + 'notices/' + seq;
  return AxiosInstance.put(url, body);
};

const DeleteNotice = (seq) => {
  const url = BASE_URL + 'notices/' + seq;
  return AxiosInstance.delete(url);
};

const GetFaqCategoryList = (params) => {
  const url = BASE_URL + 'faqs/categories' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const PostFaqCategory = (body) => {
  const url = BASE_URL + 'faqs/categories';
  return AxiosInstance.post(url, body);
};

const PutFaqCategory = (seq, body) => {
  const url = BASE_URL + 'faqs/categories/' + seq;
  return AxiosInstance.put(url, body);
};

const DeleteFaqCategory = (seq) => {
  const url = BASE_URL + 'faqs/categories/' + seq;
  return AxiosInstance.delete(url);
};

const GetFaqCategoryDetail = (seq) => {
  const url = BASE_URL + 'faqs/categories/' + seq;
  return AxiosInstance.get(url);
};

const GetFaqInfo = () => {
  const url = BASE_URL + 'faqs/categories/info';
  return AxiosInstance.get(url);
};

const GetFaqList = (params) => {
  const url = BASE_URL + 'faqs' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const PostFaq = (body) => {
  const url = BASE_URL + 'faqs';
  return AxiosInstance.post(url, body);
};

const PutFaq = (seq, body) => {
  const url = BASE_URL + 'faqs/' + seq;
  return AxiosInstance.put(url, body);
};

const DeleteFaq = (seq) => {
  const url = BASE_URL + 'faqs/' + seq;
  return AxiosInstance.delete(url);
};

const GetFaqDetail = (seq) => {
  const url = BASE_URL + 'faqs/' + seq;
  return AxiosInstance.get(url);
};

const GetContactUsList = (params) => {
  const url = BASE_URL + 'contact' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetContactUsDetail = (seq) => {
  const url = BASE_URL + 'contact/' + seq;
  return AxiosInstance.get(url);
};

const PutContactUsReply = (seq, body) => {
  const url = BASE_URL + 'contact/' + seq + '/answers';
  return AxiosInstance.put(url, body);
};

const CS = {
  GetInquiry: GetInquiry,
  GetInquiryDetail: GetInquiryDetail,
  PutInquiryAnswer: PutInquiryAnswer,
  GetNotice: GetNotice,
  PostNotice: PostNotice,
  GetNoticeDetail: GetNoticeDetail,
  PutNotice: PutNotice,
  DeleteNotice: DeleteNotice,
  GetFaqCategoryList: GetFaqCategoryList,
  PostFaqCategory: PostFaqCategory,
  GetFaqCategoryDetail: GetFaqCategoryDetail,
  DeleteFaqCategory: DeleteFaqCategory,
  PutFaqCategory: PutFaqCategory,
  GetFaqList: GetFaqList,
  PostFaq: PostFaq,
  GetFaqDetail: GetFaqDetail,
  DeleteFaq: DeleteFaq,
  PutFaq: PutFaq,
  GetFaqInfo: GetFaqInfo,
  GetContactUsList: GetContactUsList,
  GetContactUsDetail: GetContactUsDetail,
  PutContactUsReply: PutContactUsReply,
};

export default CS;
