import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import _ from 'lodash';

export const Check = (props) => {
  const { value, onChange, meta } = props;

  const handleChange = (e) => {
    onChange(e.target.checked, meta.key);
  };

  const handleChangeArray = (e, index) => {
    let new_value = _.clone(value);
    new_value[index] = e.target.checked;
    onChange(new_value, meta.key);
  };

  const handleEvidenceChangeArray = (e, index, type) => {
    let new_value = _.clone(value);
    new_value[index][type] = e.target.checked;
    if (type === 'seq' && !e.target.checked) {
      new_value[index]['isForce'] = e.target.checked;
    }
    onChange(new_value, meta.key);
  };

  const renderStyle = () => {
    if (meta?.style) {
      return { ...meta?.style };
    } else {
      return {};
    }
  };

  if (meta?.type === 'evidence') {
    return (
      <article className='form-check-box' style={renderStyle()}>
        <header>
          <span>
            {meta.mainLabel} {meta.required ? '*' : ''}
          </span>
        </header>
        <div className='grid__box'>
          {value.map((item, index) => {
            return (
              <div key={`${item}${index}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        handleEvidenceChangeArray(e, index, 'seq');
                      }}
                      checked={!!value && value[index].seq}
                    />
                  }
                  label={meta.labels[index] ? meta.labels[index] : 'none'}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!value[index].seq}
                      onChange={(e) => {
                        handleEvidenceChangeArray(e, index, 'isForce');
                      }}
                      checked={!!value && value[index].isForce}
                    />
                  }
                  label={'필수'}
                />
              </div>
            );
          })}
        </div>
      </article>
    );
  }

  if (Array.isArray(value)) {
    return (
      <article className='form-check-box' style={renderStyle()}>
        <header>
          <span>
            {meta.mainLabel} {meta.required ? '*' : ''}
          </span>
        </header>
        <div className='grid__box'>
          {value.map((item, index) => {
            return (
              <FormControlLabel
                key={`${item}${index}`}
                disabled={meta.disabeld ? meta.disabeld[index] : false}
                control={
                  <Checkbox
                    onChange={(e) => {
                      handleChangeArray(e, index);
                    }}
                    checked={!!value && value[index]}
                  />
                }
                label={meta.labels[index] ? meta.labels[index] : 'none'}
              />
            );
          })}
        </div>
      </article>
    );
  } else {
    return (
      <article className='form-check-box' style={renderStyle()}>
        <header>
          <span>{meta.label}</span>
        </header>
        <FormControlLabel
          disabled={meta.disabled}
          control={<Checkbox onChange={handleChange} checked={value} />}
          label={meta.label}
        />
      </article>
    );
  }
};
