import React, { useState, useEffect, useContext } from 'react';
import { UserContext, SnackbarContext } from '../contexts';
import Utils from '../api/Utils';
import API from '../api';

export const UserProvider = (props) => {
  const { children } = props;
  const [accessToken, setAccessToken] = useState(Utils.GetStorage('accessToken'));
  const [refreshToken, setRefreshToken] = useState(Utils.GetStorage('refreshToken'));
  const [userSeq, setUserSeq] = useState(0);
  const [userInfo, setUserInfo] = useState(null);
  const [authorityInfo, setAuthorityInfo] = useState(null);
  const [businessInfo, setBusinessInfo] = useState(null);
  const [isSuper, setIsSuper] = useState(false);
  const { updateSnackbar } = useContext(SnackbarContext);

  const updateUserSeq = (seq) => {
    Utils.SetStorage('seq', seq);
  };

  const clearToken = () => {
    Utils.SetStorage('accessToken', '');
    Utils.SetStorage('refreshToken', '');
    Utils.SetStorage('seq', '');
    setAccessToken(null);
    setRefreshToken(null);
    setUserSeq(null);
  };

  const updateAccessToken = (value) => {
    Utils.SetStorage('accessToken', value);
    setAccessToken(value);
  };

  const updateRefreshToken = (value) => {
    Utils.SetStorage('refreshToken', value);
    setRefreshToken(value);
  };

  useEffect(() => {
    if (accessToken) {
      let UpdateUser = async () => {
        try {
          const seq = Utils.GetStorage('seq');

          let user_info = await API.AdminUser.Get(seq);
          setUserInfo(user_info.data);
          setIsSuper(user_info.data.type === 'SuperAdmin');

          let authority_info = await API.Common.GetAuthority();
          setAuthorityInfo(authority_info.data);
          console.log("====> ", authorityInfo);

          let business_info = await API.Common.GetBusinessType();
          setBusinessInfo(business_info.data);
        } catch (err) {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
          clearToken();
        }
      };
      UpdateUser();
    } else {
      clearToken();
    }
  }, [accessToken]);

  return (
    <UserContext.Provider
      value={{
        accessToken: accessToken,
        userSeq: userSeq,
        updateUserSeq: updateUserSeq,
        userInfo: userInfo,
        isSuper: isSuper,
        authorityInfo: authorityInfo,
        businessInfo: businessInfo,
        updateAccessToken: updateAccessToken,
        updateRefreshToken: updateRefreshToken,
        clearToken: clearToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
