import React, { useContext, useEffect, useState } from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import ClearIcon from '@mui/icons-material/Clear';
import API from '../../api';
import { SnackbarContext } from '../../contexts';
import LoadingButton from '@mui/lab/LoadingButton';

const acceptFIleList = (type) => {
  if (type === 'image') {
    return 'image/gif, image/jpeg, image/png, image/gif';
  } else if (type === 'sound') {
    return 'audio/mp3';
  } else if (type === 'video') {
    return 'video/mp4';
  } else {
    return '*';
  }
};

const renderPreview = (value, meta) => {
  const fileName = value ? value.split('/')[value.split('/').length - 1] : '';

  if (!value) {
    return null;
  } else {
    if (meta.type === 'image') {
      return (
        <div className='img__box'>
          <img
            onClick={() => {
              window.open(value, '_blank');
            }}
            src={value}
            alt={value}
          ></img>
        </div>
      );
    } else {
      return (
        <div className='file__box'>
          <span
            onClick={() => {
              window.open(value, '_blank');
            }}
          >
            {fileName}
          </span>
        </div>
      );
    }
  }
};

export const FileUploader = (props) => {
  const { value, onChange, meta } = props;
  const [file, setFile] = useState(null);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (file) {
      console.log('file', file);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('path', meta.path);
      console.log('formData', formData);
      setLoading(true);
      API.Common.PostFile(formData).then(
        (res) => {
          console.log('res', res);
          setLoading(false);
          onChange(res.data.cloudUrl, meta.key);
          removeFileInput();
        },
        (err) => {
          console.log('err', err);
          setLoading(false);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
          removeFileInput();
        },
      );
    }
  }, [file]);

  const removeFileInput = () => {
    document.getElementById(meta.key + '-file-uploader-input').value = '';
    setFile(null);
  };

  const removeFileS3 = () => {
    console.log('value', value);
    onChange(null, meta.key);
    removeFileInput();
    // let body = {
    //   url: value,
    // };
    // console.log('removeFile', body);

    // API.Common.DeleteFile(body).then(
    //   (res) => {
    //     console.log('file remove res', res);
    //     onChange(null, meta.key);
    //     removeFileInput();
    //   },
    //   (err) => {
    //     console.log('file remove err', err);
    //     onChange(null, meta.key);
    //     removeFileInput();
    //   },
    // );
  };

  return (
    <article className='form-file-uploader'>
      <header>
        <span>
          {meta.label} {meta.required ? '*' : ''}
        </span>
      </header>
      {!value && (
        <div className='add__box'>
          <LoadingButton
            disabled={meta.disabled}
            size='large'
            loading={loading}
            onClick={() => {
              document.getElementById(meta.key + '-file-uploader-input').click();
            }}
            variant='outlined'
            endIcon={<UploadIcon />}
          >
            파일 첨부
          </LoadingButton>
        </div>
      )}
      {value && (
        <div className='preview__box'>
          <div className='close__box'>
            {!meta.disabled && (
              <ClearIcon
                onClick={() => {
                  removeFileS3();
                }}
              ></ClearIcon>
            )}
          </div>
          {renderPreview(value, meta)}
        </div>
      )}
      <div className='input__box'>
        <input
          type='file'
          accept={acceptFIleList(meta.type)}
          id={meta.key + '-file-uploader-input'}
          onChange={(e) => {
            setFile(e.target.files[0]);
          }}
        ></input>
      </div>
    </article>
  );
};
