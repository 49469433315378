import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'credit/';

const Get = (params) => {
  const url = BASE_URL + 'credit' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetDetail = (seq) => {
  const url = BASE_URL + PATH + seq;
  return AxiosInstance.get(url);
};

const PostNextAction = (seq, body) => {
  const url = BASE_URL + PATH + seq;
  return AxiosInstance.post(url, body);
};

const PostDirectOffset = (body) => {
  const url = BASE_URL + PATH + 'offset';
  return AxiosInstance.post(url, body);
};

const GetAction = (seq, params) => {
  const url = BASE_URL + PATH + seq + '/action' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const Credit = {
  Get: Get,
  GetDetail: GetDetail,
  GetAction: GetAction,
  PostNextAction: PostNextAction,
  PostDirectOffset: PostDirectOffset,
};

export default Credit;
