import React from 'react';
import { TextViewer, ToastEditor, FileViewer,  } from '..';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Handler from '../../Handler';
import _ from 'lodash';

export const ModalConsultationFeedback = (props) => {

  const { data, onClose } = props;

  return (
    <article className='modal-action'>
      <header>
        <h3>공개논평 상세 조회</h3>
        <IconButton style={{ marginRight: -10 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </header>
      <section className='section__container'>
        <header>
          <span>내용</span>
        </header>
        <ul className='form-list'>
        <TextViewer
            value={Handler.getYYYYMMDDByUnix(data.createdAt)}
            meta={{
              label: '등록일',
              style: {},
              render: (value) => {
                return value;
              },
            }}
          ></TextViewer>
          <TextViewer
            value={data.title}
            meta={{
              label: `제목`,
              style: {},
              render: (value) => {
                return value;
              },
            }}
          ></TextViewer>          
          <ToastEditor 
            value={data.content}
            onChange={() => {}} 
            meta={{
              label: '내용',
              height: 300,
              render: (value) => {
                return value;
              },
            }}>            
          </ToastEditor>
          {data.documents.length > 0 && 
            data.documents.map((document, index) => (
              <FileViewer
                  value={document.attachedFileLink}
                  meta={index === 0 ? { label: '첨부파일' } : {}}
              ></FileViewer>
            ))
          }
        </ul>
      </section>
      <section className='button__container'>
        <Button variant='contained' onClick={onClose}>
          확인
        </Button>
      </section>
    </article>
  );
};
