import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'project/';

const Get = (params) => {
  const url = BASE_URL + 'project' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetAction = (seq, params) => {
  const url = BASE_URL + PATH + seq + '/action' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetDetail = (seq) => {
  const url = BASE_URL + PATH + seq;
  return AxiosInstance.get(url);
};

// const Post = (body) => {
//   const url = BASE_URL + 'project';
//   return AxiosInstance.post(url, body);
// };

const PostNextAction = (seq, body) => {
  const url = BASE_URL + PATH + seq + '/next-action';
  return AxiosInstance.post(url, body);
};

const Put = (seq, body) => {
  const url = BASE_URL + PATH + seq;
  return AxiosInstance.put(url, body);
};

const Patch = (seq, body) => {
  const url = BASE_URL + PATH + seq + '/dispose';
  return AxiosInstance.patch(url, body);
};

const Project = {
  Get: Get,
  GetDetail: GetDetail,
  GetAction: GetAction,
  // Post: Post,
  Put: Put,
  Patch: Patch,
  PostNextAction: PostNextAction,
};

export default Project;
