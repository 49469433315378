import React, { useEffect, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { TextInput, SelectBox, Check, ToastEditor, CardReply, TextViewer } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext, UserContext, DialogContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';

export const ForwardInquiryDetailPage = (props) => {
  const history = useHistory();
  const { slug } = useParams();
  const { userInfo } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      amount: null,
      answerContent: null,
      answerDate: null,
      answerId: null,
      createdAt: null,
      project: null,
      reason: null,
      seq: null,
      status: null,
      user: null,
    },
    {
      companyName: {
        key: 'user',
        label: '기업명',
        render: (value) => {
          return value.companyName;
        },
      },
      projectId: {
        key: 'project',
        label: '사업ID',
        render: (value) => {
          return value.id;
        },
      },
      projectTitle: {
        key: 'project',
        label: '사업명',
        render: (value) => {
          return value.title;
        },
      },
      amount: {
        key: 'amount',
        label: '수량',
        render: (value) => {
          return Handler.numberWithCommas(value);
        },
      },
      reason: {
        key: 'reason',
        label: '사유',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      createdAt: {
        key: 'createdAt',
        label: '상담요청일',
        render: (value) => {
          return Handler.getYYYYMMDDByUnix(value);
        },
      },
      status: {
        key: 'status',
        label: '상태',
        fullWidth: true,
        menus: [
          {
            value: 'Ready',
            label: '준비',
          },
          {
            value: 'Proceeding',
            label: '진행중',
          },
          {
            value: 'Contract',
            label: '완료',
          },
          {
            value: 'Cancel',
            label: '취소',
          },
        ],
      },
      answerId: {
        key: 'answerId',
        label: '작성자 ID',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      answerContent: {
        key: 'answerContent',
        type: 'text',
        multiline: true,
        fullWidth: true,
        label: '상담내용',
      },
      answerDate: {
        key: 'answerDate',
        label: '작성일',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
    },
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (slug) {
      refreshData();
    }
  }, [slug]);

  const refreshData = () => {
    API.Trading.GetInquiryDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log('GetInquiryDetail result', result);
        setFormData({ ...result });
        setLoading(false);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
        setLoading(false);
      },
    );
  };

  const requestSave = () => {
    let body = {
      content: formData.answerContent,
      status: formData.status,
    };

    API.Trading.PutInquiry(slug, body).then(
      (res) => {
        let result = res.data;
        console.log('PutInquiry result', result);
        setFormData({ ...result });
        updateSnackbar({
          type: 'success',
          open: true,
          message: '저장 성공',
        });
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );

    console.log('body', body);
  };
  if (!userInfo || loading) {
    return null;
  }

  console.log('formData', formData);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>기업 선도거래 상담 상세</h3>
        </header>
      </section>
      <section className='form__container'>
        <header>
          <h3>선도구매 상담 내용</h3>
        </header>
        <div className='flex'>
          <TextViewer value={formData.user} meta={formMeta.companyName}></TextViewer>
          <TextViewer value={formData.project} meta={formMeta.projectId}></TextViewer>
          <TextViewer value={formData.project} meta={formMeta.projectTitle}></TextViewer>
          <TextViewer value={formData.amount} meta={formMeta.amount}></TextViewer>
          <TextInput value={formData.reason} onChange={formChange} meta={formMeta.reason}></TextInput>
          <TextViewer value={formData.createdAt} meta={formMeta.createdAt}></TextViewer>
        </div>
      </section>
      <div style={{ height: 40 }}></div>
      <section className='form__container'>
        <header style={{ justifyContent: 'space-between' }}>
          <h3>선도구매 상담 현황</h3>
          <div className='button__box'>
            <Button variant='contained' onClick={requestSave}>
              저장
            </Button>
          </div>
        </header>
        <div className='flex'>
          {formData.answerId ? (
            <TextInput value={formData.answerId} onChange={{}} meta={formMeta.answerId}></TextInput>
          ) : (
            <TextInput
              value={userInfo.id}
              onChange={{}}
              meta={{
                key: 'id',
                label: 'ADMIN ID',
                fullWidth: true,
                InputProps: {
                  readOnly: true,
                },
              }}
            ></TextInput>
          )}

          <SelectBox value={formData.status} onChange={formChange} meta={formMeta.status}></SelectBox>
          <TextInput value={formData.answerContent} onChange={formChange} meta={formMeta.answerContent}></TextInput>
          {formData.answerDate && (
            <TextInput value={formData.answerDate} onChange={{}} meta={formMeta.answerDate}></TextInput>
          )}
        </div>
      </section>
    </article>
  );
};
