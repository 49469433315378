import React, { useEffect, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { TextInput, SelectBox, Check } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext, UserContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

export const AdminUserAddPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { authorityInfo } = useContext(UserContext);
  const [isDuplicated, setIsDuplicated] = useState(true);
  const [formData, formMeta, formChange, setFormMeta, formReset] = useForm(
    {
      type: 'Pople',
      name: '',
      id: '',
      email: '',
      phone: '',
      authority: [],
    },
    {
      type: {
        key: 'type',
        label: '구분',
        fullWidth: true,
        menus: [
          {
            value: 'Pople',
            label: 'Pople',
          },
          {
            value: 'Committee',
            label: '위원회',
          },
        ],
      },
      name: {
        key: 'name',
        label: '이름',
        fullWidth: true,
        required: true,
      },
      id: {
        key: 'id',
        label: '아이디',
        fullWidth: true,
        required: true,
        customButton: {
          label: '중복체크',
          onClick: (value) => {
            console.log(' customButton callback', value);
            API.AdminUser.CheckDuplicate({ id: value }).then(
              (res) => {
                console.log('res', res);
                let result = res.data;
                if (result.isDuplicate) {
                  setIsDuplicated(true);
                  updateSnackbar({
                    type: 'error',
                    open: true,
                    message: '사용할 수 없는 아이디입니다.',
                  });
                } else {
                  setIsDuplicated(false);
                  updateSnackbar({
                    type: 'success',
                    open: true,
                    message: '사용할 수 있는 아이디입니다.',
                  });
                }
              },
              (err) => {
                setIsDuplicated(true);
                updateSnackbar({
                  type: 'error',
                  open: true,
                  message: err.data.message,
                });
              },
            );
          },
        },
      },
      email: {
        key: 'email',
        label: '이메일',
        fullWidth: true,
        required: true,
      },
      phone: {
        key: 'phone',
        label: '핸드폰번호',
        fullWidth: true,
        required: true,
      },
      authority: {
        key: 'authority',
        mainLabel: '권한 정보',
        labels: [],
        values: [],
      },
    },
  );

  const requsetAdd = () => {
    console.log('authorityInfo', authorityInfo);
    let body = Handler.formToBody(formData, formMeta);
    console.log('body', body);
    if (body) {
      let db_authority = [];
      for (let i in formData.authority) {
        let target = formData.authority[i];
        if (target) {
          db_authority.push(formMeta['authority'].values[i].toString());
        }
      }
      let db_body = { ...body, authority: db_authority };
      console.log('db_body', db_body);
      API.AdminUser.Post(db_body).then(
        (res) => {
          let result = res.data;
          console.log('AdminUser post result', result);
          history.push('/admin_user/list');
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  useEffect(() => {
    if (authorityInfo) {
      let new_meta = _.clone(formMeta);
      new_meta.authority.labels = Handler.buildAuthorityLabels(authorityInfo);
      new_meta.authority.values = Handler.buildAuthorityValues(authorityInfo);
      setFormMeta(new_meta);
      formChange(Handler.buildAuthorityCheck(authorityInfo), 'authority');
    }
  }, [authorityInfo]);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>관리자 계정 추가</h3>
        </header>
        <div className='button__box'>
          <Button disabled={isDuplicated} variant='contained' onClick={requsetAdd}>
            저장
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <SelectBox value={formData.type} onChange={formChange} meta={formMeta.type}></SelectBox>
          <TextInput value={formData.name} onChange={formChange} meta={formMeta.name}></TextInput>
          <TextInput
            value={formData.id}
            onChange={(value, meta) => {
              setIsDuplicated(true);
              formChange(value, meta);
            }}
            meta={formMeta.id}
          ></TextInput>
          <TextInput value={formData.email} onChange={formChange} meta={formMeta.email}></TextInput>
          <TextInput value={formData.phone} onChange={formChange} meta={formMeta.phone}></TextInput>
          <Check value={formData.authority} onChange={formChange} meta={formMeta.authority}></Check>
        </div>
      </section>
    </article>
  );
};
