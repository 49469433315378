import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { TextInput, MonthPickerBox } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext, UserContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import { useHistory } from 'react-router-dom';

export const EuEtsAddPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { userInfo } = useContext(UserContext);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      etsDate: null,
      averagePrice: null,
      yearAveragePrice: null,
    },
    {
      etsDate: {
        key: 'etsDate',
        label: '월',
        fullWidth: true,
        required: true,
      },
      averagePrice: {
        key: 'averagePrice',
        label: 'ET-ETS 평균 가격($)',
        fullWidth: true,
        required: true,
      },
      yearAveragePrice: {
        key: 'yearAveragePrice',
        label: '지난 1년 평균 가격($)',
        fullWidth: true,
        required: true,
      },
    },
  );

  const requsetAdd = () => {
    let body = Handler.formToBody(formData, formMeta);
    console.log('body', body);

    if (body) {
      let dateObject = new Date(body.etsDate);
      let db_date = dateObject.toISOString();

      //   return;
      API.Payment.PostEuets({ ...body, etsDate: db_date }).then(
        (res) => {
          history.push('/marketplace/eu_ets/list');
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>EU-ETS 추가</h3>
        </header>
        <div className='button__box'>
          <Button variant='contained' onClick={requsetAdd}>
            추가
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <MonthPickerBox value={formData.etsDate} onChange={formChange} meta={formMeta.etsDate}></MonthPickerBox>
          <TextInput value={formData.averagePrice} onChange={formChange} meta={formMeta.averagePrice}></TextInput>
          <TextInput
            value={formData.yearAveragePrice}
            onChange={formChange}
            meta={formMeta.yearAveragePrice}
          ></TextInput>
        </div>
      </section>
    </article>
  );
};
