import React, { useEffect, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { TextInput, Check, DatePickerBox, FileUploader } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext, DialogContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import { useHistory, useParams } from 'react-router-dom';

export const NewsDetailPage = (props) => {
  const history = useHistory();
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const [formData, formMeta, formChange, setFormData] = useForm(
    {
      show: false,
      isMain: false,
      title: '',
      titleEn: '',
      content: '',
      contentEn: '',
      press: '',
      pressDate: '',
      link: '',
      image: '',
    },
    {
      show: {
        key: 'show',
        label: '공개 여부',
      },
      isMain: {
        key: 'isMain',
        label: '메인뉴스 여부',
      },
      title: {
        key: 'title',
        label: '제목',
        fullWidth: true,
        required: true,
      },
      titleEn: {
        key: 'titleEn',
        label: '제목(영문)',
        fullWidth: true,
      },
      content: {
        key: 'content',
        label: '내용',
        fullWidth: true,
        required: true,
        multiline: true
      },
      contentEn: {
        key: 'contentEn',
        label: '내용(영문)',
        fullWidth: true,
        multiline: true
      },
      press: {
        key: 'press',
        label: '언론사',
        fullWidth: true,
        required: true,
      },
      pressDate: {
        key: 'pressDate',
        label: '기사 보도일',
        required: true,
      },
      link: {
        key: 'link',
        label: '링크',
        fullWidth: true,
        required: true,
      },
      image: {
        key: 'image',
        type: 'file',
        label: '이미지',
        required: true,
        path: 'news/file'
      },
    },
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (slug) {
      refreshData();
    }
  }, [slug]);

  const refreshData = () => {
    API.Marketplace.GetNewsDetail(slug).then(
      (res) => {
        let result = res.data;
        setFormData({
          title: result.title,
          titleEn: result.titleEn,
          content: result.content,
          contentEn: result.contentEn,
          press: result.press,
          pressDate: result.pressDate,
          link: result.link,
          show: result.show,
          isMain: result.isMain,
          image: result.image
        });
        setLoading(false);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
        setLoading(false);
      },
    );
  };

  const requestSave = () => {
    let body = Handler.formToBody(formData, formMeta);
    if (slug) {
      API.Marketplace.PutNews(slug, body).then(
        (res) => {
          refreshData();
          updateSnackbar({
            type: 'success',
            open: true,
            message: '저장 성공',
          });
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  const requestDelete = () => {
    API.Marketplace.DeleteNews(slug).then(
      (res) => {
        history.push('/marketplace/news/list');
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  if (loading) {
    return null;
  }

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>뉴스 상세</h3>
        </header>
        <div className='button__box'>
          <Button
            variant='contained'
            color='secondary'
            disabled={false}
            onClick={() => {
              updateDialog({
                type: 'error',
                open: true,
                title: '뉴스 삭제',
                description: '삭제 후 되돌릴 수 없습니다. 그래도 진행하시겠습니까?',
                onConfirm: requestDelete,
              });
            }}
          >
            삭제
          </Button>
          <Button disabled={false} variant='contained' onClick={requestSave}>
            저장
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <Check value={formData.show} onChange={formChange} meta={formMeta.show}></Check>
          <Check value={formData.isMain} onChange={formChange} meta={formMeta.isMain}></Check>
          <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
          <TextInput value={formData.titleEn} onChange={formChange} meta={formMeta.titleEn}></TextInput>
          <TextInput value={formData.content} onChange={formChange} meta={formMeta.content}></TextInput>
          <TextInput value={formData.contentEn} onChange={formChange} meta={formMeta.contentEn}></TextInput>
          <TextInput value={formData.press} onChange={formChange} meta={formMeta.press}></TextInput>
          <DatePickerBox value={formData.pressDate} onChange={formChange} meta={formMeta.pressDate}></DatePickerBox>
          <TextInput value={formData.link} onChange={formChange} meta={formMeta.link}></TextInput>
          <FileUploader value={formData.image} onChange={formChange} meta={formMeta.image}></FileUploader>
        </div>
      </section>
    </article>
  );
};
