import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { TableBox, SearchFilter, Paging } from '../../components';
import { usePagination, useFilter } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Button from '@mui/material/Button';
import Handler from '../../Handler';
import API from '../../api';

export const PaymentWithdrawListPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState([
    {
      name: 'name',
      description: 'description',
    },
    {
      name: 'name2',
      description: 'description2',
    },
  ]);
  const [header, setHeader] = useState([
    {
      key: 'seq',
      align: 'center',
      text: 'No',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'withdrawDate',
      align: 'center',
      text: '출금 요청일',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return value;
      },
    },
    {
      key: null,
      align: 'center',
      text: '회원명 / 기업명',
      style: {
        minWidth: 200,
      },
      render: (value) => {
        console.log('value', value);
        if (value.user && value.user.companyName) {
          return value.user.companyName;
        } else if (value.user && value.user.name) {
          return value.user.name;
        } else {
          return 'none';
        }
      },
    },
    {
      key: null,
      align: 'center',
      text: '이메일',
      style: {
        minWidth: 200,
      },
      render: (value) => {
        console.log('value', value);
        if (value.user && value.user.email) {
          return value.user.email;
        } else {
          return 'none';
        }
      },
    },
    {
      key: 'withdraw',
      align: 'center',
      text: '출금 요청 금액',
      style: {
        minWidth: 200,
      },
      render: (value) => {
        return Handler.numberWithCommas(value) + '원';
      },
    },
    {
      key: 'status',
      align: 'center',
      text: '정산 금액',
      style: {
        minWidth: 200,
      },
      render: (value) => {
        switch (value) {
          case 'Deposit': {
            return '지급완료';
          }
          case 'NotDeposit': {
            return <span style={{ color: '#fc312f' }}>미지급</span>;
          }
          case 'Etc': {
            return '기타';
          }
          default: {
            return 'none';
          }
        }
      },
    },
    {
      key: 'depositDate',
      align: 'center',
      text: '지급일',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return value;
      },
    },
    {
      key: 'deposit',
      align: 'center',
      text: '지급 금액',
      style: {
        minWidth: 200,
      },
      render: (value) => {
        return Handler.numberWithCommas(value) + '원';
      },
    },
    {
      key: 'deduction',
      align: 'center',
      text: '차감 금액',
      style: {
        minWidth: 200,
      },
      render: (value) => {
        return Handler.numberWithCommas(value) + '원';
      },
    },
    {
      key: 'note',
      align: 'center',
      text: '비고',
      style: {
        minWidth: 200,
      },
      render: (value) => {
        return (
          <div style={{ maxWidth: 200 }} className='ellipsis-text'>
            <span>{value}</span>
          </div>
        );
      },
    },
  ]);
  const [filter, onFilterChange, setFiler] = useFilter([
    {
      key: 'status',
      type: 'select',
      label: '출금 상태',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: 'Deposit',
          label: '지급완료',
        },
        {
          value: 'NotDeposit',
          label: '미지급',
        },
        {
          value: 'Etc',
          label: '기타',
        },
      ],
    },
    {
      key: 'searchDateType',
      type: 'select',
      label: '날짜 검색 타입',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: 'WithdrawDate',
          label: '출금요청일',
        },
        {
          value: 'DepositDate',
          label: '지급일',
        },
      ],
    },
    {
      key: 'startDate',
      type: 'date',
      label: '시작일',
      value: null,
    },
    {
      key: 'endDate',
      type: 'date',
      label: '종료일',
      value: null,
    },
    {
      key: 'searchType',
      type: 'select',
      label: '검색 종류',
      value: 'Name',
      menus: [
        {
          value: 'Name',
          label: '구매자명',
        },
        {
          value: 'Email',
          label: '이메일',
        },
        {
          value: 'Bank',
          label: '은행명',
        },
        {
          value: 'WithdrawCash',
          label: '출금금액',
        },
        {
          value: 'DepositCash',
          label: '입금금액',
        },
      ],
    },
    {
      key: 'searchValue',
      type: 'input',
      label: '검색 값',
      value: '',
      inputType: 'text',
    },
  ]);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  const tableCallback = (type, index) => {
    console.log('type', type);
    console.log('index', index);
  };

  const onSearch = (body) => {
    console.log('onSearch', body);
    requestNewList(0);
  };

  useEffect(() => {
    console.log('page effect', page);
    requestNewList();
  }, [page]);

  const requestNewList = (init_page) => {
    let body = Handler.filterToBody(filter);
    console.log('page body', body);
    API.Payment.GetWithdrawList({
      ...body,
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
    }).then(
      (res) => {
        console.log('res', res);
        let result = res.data;
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const tableRowClick = (index) => {
    console.log('tableRowClick index', index);
    let target_seq = list[index].seq;
    console.log('tableRowClick target_seq', target_seq);
    history.push('/payment/withdraw_detail/' + target_seq);
  };

  const requsetAdd = () => {
    console.log('requsetAdd');
  };

  console.log('list', list);
  console.log('filter', filter);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>출금 요청 목록</h3>
        </header>
        {/* <div className='button__box'>
          <Button variant='contained' onClick={requsetAdd}>
            Add
          </Button>
        </div> */}
      </section>
      <section className='filter__container'>
        <SearchFilter data={filter} onChange={onFilterChange} onSearch={onSearch}></SearchFilter>
      </section>
      <section className='table__container'>
        <TableBox data={list} header={header} rowClick={tableRowClick} callback={tableCallback}></TableBox>
      </section>
      <section className='paging__container'>
        <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
      </section>
    </article>
  );
};
