import React from 'react';
import { TextInput, TextViewer, FileViewer } from '../../components';
import Handler from '../../Handler';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

export const ModalReductionView = (props) => {
  const { action, onClose } = props;

  console.log('ModalAction action', action);

  if (!action) {
    return null;
  }
  console.log('[View] action.status', action.status);

  switch (action.status) {
    case 'WaitingForAuditLaunch': {
      return (
        <article className='modal-action'>
          <header>
            <h3>{Handler.ENUM.REDUCTION_ACTION.STATUS(action.status)}</h3>
            <IconButton style={{ marginRight: -10 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </header>
          <section className='section__container'>
            <header>
              <span>사업 정보</span>
            </header>
            <ul className='form-list'>
              <TextViewer
                value={action.projectTitle}
                meta={{
                  label: '사업명',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.projectId}
                meta={{
                  label: '사업 ID',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.monitorDegree}
                meta={{
                  label: '모니터링 차수',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.version}
                meta={{
                  label: '버전',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={Handler.getYYYYMMDDByUnix(action.createdAt)}
                meta={{
                  label: '신청일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
          <section className='section__container'>
            <header>
              <span>Comment (유저)</span>
            </header>
            <ul className='form-list'>
              <TextViewer
                value={action.projectLaunchDate}
                meta={{
                  label: '사업착수일시',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextInput
                value={action.userComment}
                meta={{
                  key: 'content',
                  label: '의견',
                  type: 'text',
                  multiline: true,
                  fullWidth: true,
                  InputProps: {
                    readOnly: true,
                  },
                }}
              ></TextInput>
              {action.userCommentFile && (
                <FileViewer
                  value={action.userCommentFile}
                  meta={{
                    label: '첨부파일',
                  }}
                ></FileViewer>
              )}
              <TextViewer
                value={action.userCommentDate}
                meta={{
                  label: '작성일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
        </article>
      );
    }
    case 'WritingReductionCertForImprove': {
      return (
        <article className='modal-action'>
          <header>
            <h3>{Handler.ENUM.REDUCTION_ACTION.STATUS(action.status)}</h3>
            <IconButton style={{ marginRight: -10 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </header>
          <section className='section__container'>
            <header>
              <span>사업 정보</span>
            </header>
            <ul className='form-list'>
              <TextViewer
                value={action.projectTitle}
                meta={{
                  label: '사업명',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.projectId}
                meta={{
                  label: '사업 ID',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.monitorDegree}
                meta={{
                  label: '모니터링 차수',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.version}
                meta={{
                  label: '버전',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={Handler.getYYYYMMDDByUnix(action.createdAt)}
                meta={{
                  label: '신청일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
        </article>
      );
    }
    case 'AuditCancel': {
      return (
        <article className='modal-action'>
          <header>
            <h3>{Handler.ENUM.REDUCTION_ACTION.STATUS(action.status)}</h3>
            <IconButton style={{ marginRight: -10 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </header>
          <section className='section__container'>
            <header>
              <span>사업 정보</span>
            </header>
            <ul className='form-list'>
              <TextViewer
                value={action.projectTitle}
                meta={{
                  label: '사업명',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.projectId}
                meta={{
                  label: '사업 ID',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.monitorDegree}
                meta={{
                  label: '모니터링 차수',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.version}
                meta={{
                  label: '버전',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={Handler.getYYYYMMDDByUnix(action.createdAt)}
                meta={{
                  label: '신청일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
          <section className='section__container'>
            <header>
              <span>Comment (유저)</span>
            </header>
            <ul className='form-list'>
              <TextViewer
                value={Handler.ENUM.REDUCTION_ACTION.STOP_REASON(action.stopReason)}
                meta={{
                  label: '구분',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextInput
                value={action.userComment}
                meta={{
                  key: 'content',
                  label: '사유',
                  type: 'text',
                  multiline: true,
                  fullWidth: true,
                  InputProps: {
                    readOnly: true,
                  },
                }}
              ></TextInput>
              {action.userCommentFile && (
                <FileViewer
                  value={action.userCommentFile}
                  meta={{
                    label: '첨부파일',
                  }}
                ></FileViewer>
              )}
              <TextViewer
                value={action.userCommentDate}
                meta={{
                  label: '작성일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
        </article>
      );
    }
    case 'ReductionCertGiveUp':
    case 'ReductionCertRejected': {
      return (
        <article className='modal-action'>
          <header>
            <h3>{Handler.ENUM.REDUCTION_ACTION.STATUS(action.status)}</h3>
            <IconButton style={{ marginRight: -10 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </header>
          <section className='section__container'>
            <header>
              <span>사업 정보</span>
            </header>
            <ul className='form-list'>
              <TextViewer
                value={action.projectTitle}
                meta={{
                  label: '사업명',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.projectId}
                meta={{
                  label: '사업 ID',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.monitorDegree}
                meta={{
                  label: '모니터링 차수',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.version}
                meta={{
                  label: '버전',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={Handler.getYYYYMMDDByUnix(action.createdAt)}
                meta={{
                  label: '신청일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
          <section className='section__container'>
            <header>
              <span>Comment (관리자)</span>
            </header>
            <ul className='form-list'>
              <TextInput
                value={action.adminComment}
                meta={{
                  key: 'content',
                  label: '의견',
                  type: 'text',
                  multiline: true,
                  fullWidth: true,
                  InputProps: {
                    readOnly: true,
                  },
                }}
              ></TextInput>
              {action.adminCommentFile && (
                <FileViewer
                  value={action.adminCommentFile}
                  meta={{
                    label: '첨부파일',
                  }}
                ></FileViewer>
              )}
              <TextViewer
                value={action.adminCommentDate}
                meta={{
                  label: '작성일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
          {action.userComment && (
            <section className='section__container'>
              <header>
                <span>Reply (유저)</span>
              </header>
              <ul className='form-list'>
                <TextInput
                  value={action.userComment}
                  meta={{
                    key: 'content',
                    label: '사유',
                    type: 'text',
                    multiline: true,
                    fullWidth: true,
                    InputProps: {
                      readOnly: true,
                    },
                  }}
                ></TextInput>
                {action.userCommentFile && (
                  <FileViewer
                    value={action.userCommentFile}
                    meta={{
                      label: '첨부파일',
                    }}
                  ></FileViewer>
                )}
                <TextViewer
                  value={action.userCommentDate}
                  meta={{
                    label: '작성일',
                    style: {},
                    render: (value) => {
                      return value;
                    },
                  }}
                ></TextViewer>
              </ul>
            </section>
          )}
        </article>
      );
    }
    case 'CanceledReductionCert': {
      return (
        <article className='modal-action'>
          <header>
            <h3>{Handler.ENUM.REDUCTION_ACTION.STATUS(action.status)}</h3>
            <IconButton style={{ marginRight: -10 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </header>
          <section className='section__container'>
            <header>
              <span>사업 정보</span>
            </header>
            <ul className='form-list'>
              <TextViewer
                value={action.projectTitle}
                meta={{
                  label: '사업명',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.projectId}
                meta={{
                  label: '사업 ID',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.monitorDegree}
                meta={{
                  label: '모니터링 차수',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.version}
                meta={{
                  label: '버전',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={Handler.getYYYYMMDDByUnix(action.createdAt)}
                meta={{
                  label: '신청일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
        </article>
      );
    }
    default: {
      return (
        <article className='modal-action'>
          <header>
            <h3>{Handler.ENUM.REDUCTION_ACTION.STATUS(action.status)}</h3>
            <IconButton style={{ marginRight: -10 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </header>
          <section className='section__container'>
            <header>
              <span>사업 정보</span>
            </header>
            <ul className='form-list'>
              <TextViewer
                value={action.projectTitle}
                meta={{
                  label: '사업명',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.projectId}
                meta={{
                  label: '사업 ID',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.monitorDegree}
                meta={{
                  label: '모니터링 차수',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.version}
                meta={{
                  label: '버전',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={Handler.getYYYYMMDDByUnix(action.createdAt)}
                meta={{
                  label: '신청일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
          <section className='section__container'>
            <header>
              <span>Comment (관리자)</span>
            </header>
            <ul className='form-list'>
              <TextInput
                value={action.adminComment}
                meta={{
                  key: 'content',
                  label: '의견',
                  type: 'text',
                  multiline: true,
                  fullWidth: true,
                  InputProps: {
                    readOnly: true,
                  },
                }}
              ></TextInput>
              {action.adminCommentFile && (
                <FileViewer
                  value={action.adminCommentFile}
                  meta={{
                    label: '첨부파일',
                  }}
                ></FileViewer>
              )}
              <TextViewer
                value={action.adminCommentDate}
                meta={{
                  label: '작성일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
        </article>
      );
    }
  }
};
