import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { TextInput, FileUploader } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

export const MethodologyTermsAddPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      title: '',
      termsFile: null,
    },
    {
      title: {
        key: 'title',
        label: '약관명',
        fullWidth: true,
        required: true,
      },
      termsFile: {
        required: true,
        type: 'file',
        key: 'termsFile',
        label: '약관 파일',
        path: 'methodology/terms',
      },
    },
  );

  const requsetAdd = () => {
    let body = Handler.formToBody(formData, formMeta);

    if (body) {
      API.Term.PostTerms(body).then(
        (res) => {
          let result = res.data;
          console.log('PostTerms post result', result);
          history.push('/methodology/terms/list');
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>약관 추가</h3>
        </header>
        <div className='button__box'>
          <Button variant='contained' onClick={requsetAdd}>
            저장
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
          <FileUploader value={formData.termsFile} onChange={formChange} meta={formMeta.termsFile}></FileUploader>
        </div>
      </section>
    </article>
  );
};
