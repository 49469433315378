import React from 'react';

export const LoginLayout = (props) => {
  const { children } = props;

  return (
    <div className='login-layout'>
      <article className='content__container'>{children}</article>
    </div>
  );
};
