import React, { useEffect, useRef, useState } from 'react';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import API from '../../api';

export const ToastEditor = (props) => {
  const { value, onChange, meta } = props;
  const editorRef = useRef();
  const [initUpdate, setInitUpdate] = useState(true);
  const [height, setHeight] = useState('560px');

  const uploadImage = (file, path) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('path', meta.path);
    return API.Common.PostFile(formData);
  };

  useEffect(() => {
    if (editorRef && value && initUpdate) {
      //초기 설정을 위한 셋업
      editorRef.current.getInstance().setHtml(value);
      setInitUpdate(false);
    }    
  }, [editorRef, value]);

  useEffect(() => {
    if (meta.height) {
      setHeight(meta.height + "px");
    }
  })

  return (
    <article className='form-toast-editor'>
      <header>
        <span>
          {meta.label}
          {meta.required ? '*' : ''}
        </span>
      </header>
      <div className='editor__box'>
        <Editor
          disabled={true}
          height={height}
          initialEditType='wysiwyg'
          useCommandShortcut={true}
          onChange={() => {
            let new_value = editorRef.current.getInstance().getHtml();
            // console.log('[editor__box] new_value', new_value);
            // let remove_new_value = new_value.replaceAll('<br>', '');
            // console.log('[editor__box] remove_new_value', remove_new_value);
            onChange(new_value, meta.key);
          }}
          ref={editorRef}
          hooks={{
            addImageBlobHook: async (blob, callback, lte, test) => {
              try {
                //editor에서 빈칸이 있으면 태그를 못만들기 때문에, 파일 이름의 빈칸을 없애야함.
                let file_name = 'undifend.png';
                if (blob && blob.name) {
                  file_name = blob.name.replaceAll(' ', '_');
                }
                const file = new File([blob], file_name);
                const upload_result = await uploadImage(file, 'editor');

                if (upload_result.data && upload_result.data.url) {
                  const file_url = upload_result.data.url;
                  callback(file_url, file_name);
                } else {
                  return false;
                }
              } catch (e) {
                console.log('e', e);
                alert('upload fail');
                return false;
              }
            },
          }}
          toolbarItems={[
            'heading',
            'bold',
            'italic',
            'strike',
            'divider',
            'hr',
            'quote',
            'divider',
            'ol',
            'ul',
            'task',
            'divider',
            'table',
            'image',
            'link',
            'divider',
            'code',
            'codeblock',
          ]}
        />
      </div>
    </article>
  );
};
