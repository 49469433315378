import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'member/';

const GetMemberList = (params) => {
  const url = BASE_URL + 'member' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetMember = (seq) => {
  const url = BASE_URL + PATH + seq;
  return AxiosInstance.get(url);
};

const PutMember = (seq, body) => {
  const url = BASE_URL + PATH + seq;
  return AxiosInstance.put(url, body);
};

const PutMemberApproved = (seq) => {
  const url = BASE_URL + PATH + seq + '/approve';
  return AxiosInstance.put(url);
};

const PutEmailResend = (seq) => {
  const url = BASE_URL + PATH + seq + '/email-resend';
  return AxiosInstance.put(url);
};

const PutSignout = (seq) => {
  const url = BASE_URL + PATH + seq + '/sign-out';
  return AxiosInstance.put(url);
};

// const GetUser = (params) => {
//   const url = BASE_URL + PATH + Utils.ObjectToParams(params);
//   return AxiosInstance.get(url);
// };

// const GetUserId = (user_id) => {
//   const url = BASE_URL + PATH + user_id;
//   return AxiosInstance.get(url);
// };

// const PutUserId = (user_id, body) => {
//   const url = BASE_URL + PATH + user_id;
//   return AxiosInstance.put(url, body);
// };

// const DelteUserId = (user_id) => {
//   const url = BASE_URL + PATH + user_id;
//   return AxiosInstance.delete(url);
// };

// const PostUserIdResetPassword = (user_id) => {
//   const url = BASE_URL + PATH + user_id + '/reset/password';
//   return AxiosInstance.post(url);
// };

const Member = {
  GetMemberList: GetMemberList,
  GetMember: GetMember,
  PutMember: PutMember,
  PutEmailResend: PutEmailResend,
  PutMemberApproved: PutMemberApproved,
  PutSignout: PutSignout,
};

export default Member;
