import React, { useEffect, useCallback, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import {
  TextInput,
  FileUploader,
  TextViewer,
  ModalReductionView,
  TableBox,
  Paging,
  Address,
  CardCreditMemo,
  ModalCreditAction,
  ModalCreditView,
  CardCreditStatus,
  ModalCreditOffsetRequest,
} from '../../components';
import { useForm, usePagination } from '../../hooks';
import { SnackbarContext, DialogContext, UserContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import { useHistory, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 860,
  bgcolor: 'background.paper',
  border: '2px solid #bcbcbc',
  boxShadow: 24,
  p: 0,
  borderRadius: 2,
  overflow: 'hidden',
};

const getModeText = (mode) => {
  switch (mode) {
    case 'Issue': {
      return '발행';
    }
    case 'Move': {
      return '이전';
    }
    case 'Offset': {
      return '철회';
    }
    default: {
      return null;
    }
  }
};

export const CreditDetailPage = (props) => {
  const history = useHistory();
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const { accessToken, businessInfo } = useContext(UserContext);
  const [initData, setInitData] = useState(false);
  const [open, setOpen] = useState(false);
  const [actionOpen, setActionOpen] = useState(false);
  const [offsetOpen, setOffsetOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedNextAction, setSelectedNextAction] = useState(null);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      id: '',
      title: '',
      methodologyId: '',
      area: '',
      subArea: '',
      companyName: '',
      ceoName: '',
      joinedValue: '',
      businessNumber: '',
      businessFile: '',
      address: {
        daum: null,
        detail: '',
      },
      name: '',
      department: '',
      phone: '',
      email: '',
      startDate: '',
      endDate: '',
      monitorDegree: '',
      amount: '',

      //move
      assigneeUserCompanyName: '',
      assigneeEmail: '',
    },
    {
      id: {
        key: 'id',
        label: '사업ID',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      title: {
        key: 'title',
        label: '사업명',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      methodologyId: {
        key: 'methodologyId',
        label: '적용 방법론',
        style: {},
        render: (value) => {
          return value;
        },
      },
      area: {
        key: 'area',
        label: '사업 유형',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      subArea: {
        key: 'subArea',
        label: '시업 유형 상세',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      companyName: {
        key: 'companyName',
        label: '기업명',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      ceoName: {
        key: 'ceoName',
        label: '대표자명',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      joinedValue: {
        key: 'joinedValue',
        label: '이메일 아이디',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      businessNumber: {
        key: 'businessNumber',
        label: '사업자등록번호',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      businessFile: {
        type: 'file',
        key: 'businessFile',
        label: '사업자등록증',
        path: 'project/business_file',
        required: true,
        disabled: true,
      },
      address: {
        disabled: true,
        required: true,
        key: 'address',
        label: '본사 주소',
      },
      name: {
        key: 'name',
        label: '담당자명',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      department: {
        key: 'department',
        label: '부서명',
        fullWidth: true,
        required: false,
        InputProps: {
          readOnly: true,
        },
      },
      phone: {
        key: 'phone',
        label: '연락처',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      email: {
        key: 'email',
        label: '담당자 이메일',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      startDate: {
        key: 'startDate',
        label: '모니터링 시작일',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      endDate: {
        key: 'endDate',
        label: '모니터링 종료일',
        fullWidth: true,
        required: true,
        InputProps: {
          readOnly: true,
        },
      },
      monitorDegree: {
        key: 'monitorDegree',
        label: '모니터링 차수',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      amount: {
        key: 'reductionExpect',
        label: '크레딧 수량',
        style: {},
        render: (value) => {
          return Handler.numberWithCommas(value);
        },
      },
      assigneeUserCompanyName: {
        key: 'assigneeUserCompanyName',
        label: '기업명(양수인)',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
        render: (value) => {
          return value;
        },
      },
      assigneeEmail: {
        key: 'assigneeEmail',
        label: '이메일(양수인)',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
        render: (value) => {
          return value;
        },
      },
    },
  );
  const [list, setList] = useState(null);
  const [header, setHeader] = useState([
    {
      key: 'createdAt',
      align: 'center',
      text: '일자',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.getYYYYMMDDByUnix(value);
      },
    },
    {
      key: '',
      align: 'center',
      text: '상태',
      style: {
        minWidth: 100,
      },
      render: (row) => {
        switch (row.status) {
          case 'AuditCommittee':
          case 'WaitingForAuditCommittee':
          // case 'WaitingForAuditCommiittee':
          case 'WaitingForAuditZCCredit':
          case 'AuditZCCredit': {
            return Handler.ENUM.CREDIT_ACTION.STATUS(row.status) + '(' + row.degree + ')';
          }
          default: {
            return Handler.ENUM.CREDIT_ACTION.STATUS(row.status);
          }
        }
      },
    },
    {
      key: '',
      align: 'center',
      text: '문서 버전',
      style: {
        minWidth: 100,
      },
      render: (row) => {
        if (row.isNewVersion) {
          return (
            <Button
              variant='contained'
              onClick={(e) => {
                e.stopPropagation();
                console.log('row', row);
                let open_url = Handler.getPublicUrlPath();
                console.log('open_url', window.screen);
                open_url +=
                  'public/credit/' +
                  row.creditDoc.credit.seq +
                  '/' +
                  row.projectVersion +
                  '/' +
                  accessToken +
                  '/' +
                  row.creditDoc.credit.mode;

                var left = window.screen.width / 2 - 1280 / 2;
                var top = window.screen.height / 2 - 860 / 2;
                console.log('left', left);
                let win = window.open(
                  open_url,
                  '',
                  `status=no, menubar=no, toolbar=no, resizable=no, width=1280, height=800, left=${left}, top=${top}`,
                );
              }}
            >
              {row.projectVersion}
            </Button>
          );
        } else {
          return '-';
        }
      },
    },
    {
      key: '',
      align: 'center',
      text: '상세보기',
      style: {
        minWidth: 100,
      },
      render: (row) => {
        switch (row.status) {
          case 'WaitingForAuditZCCredit': {
            return '-';
          }
          case 'WaitingForAuditCommittee': {
            if (row.degree === 1) {
            } else {
              return '-';
            }
          }
          default: {
            return (
              <Button
                variant='contained'
                onClick={(e) => {
                  e.stopPropagation();
                  console.log('row', row);
                  setSelectedAction(row);
                  setViewOpen(true);
                }}
              >
                상세보기
              </Button>
            );
          }
        }
      },
    },
    {
      key: 'adminId',
      align: 'center',
      text: '담당자',
      style: {
        minWidth: 100,
      },
    },
  ]);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  //발행의 경우, reductionCert list 필요
  const [reductionCertSeq, setReductionCertSeq] = useState(null);
  const [offsetAdmin, setOffsetAdmin] = useState(null);
  const [r_list, r_setList] = useState(null);
  const [r_header, r_setHeader] = useState([
    {
      key: 'createdAt',
      align: 'center',
      text: '일자',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.getYYYYMMDDByUnix(value);
      },
    },
    {
      key: '',
      align: 'center',
      text: '상태',
      style: {
        minWidth: 100,
      },
      render: (row) => {
        switch (row.status) {
          case 'WaitingForAuditReductionCert':
          case 'AuditReductionCert': {
            return Handler.ENUM.REDUCTION_ACTION.STATUS(row.status) + '(' + row.degree + ')';
          }
          default: {
            return Handler.ENUM.REDUCTION_ACTION.STATUS(row.status);
          }
        }
      },
    },
    {
      key: '',
      align: 'center',
      text: '문서 버전',
      style: {
        minWidth: 100,
      },
      render: (row) => {
        console.log('row', row);
        if (row.isNewVersion) {
          return (
            <Button
              variant='contained'
              onClick={(e) => {
                e.stopPropagation();
                let open_url = Handler.getPublicUrlPath();
                console.log('open_url', window.screen);
                console.log('open_url', row);

                open_url +=
                  'public/reduction/' + row.reductionCertDoc.reductionCert.seq + '/' + row.version + '/' + accessToken;

                var left = window.screen.width / 2 - 1280 / 2;
                var top = window.screen.height / 2 - 860 / 2;
                console.log('left', left);
                let win = window.open(
                  open_url,
                  '',
                  `status=no, menubar=no, toolbar=no, resizable=no, width=1280, height=800, left=${left}, top=${top}`,
                );
              }}
            >
              {row.version}
            </Button>
          );
        } else {
          return '-';
        }
      },
    },
    {
      key: '',
      align: 'center',
      text: '상세보기',
      style: {
        minWidth: 100,
      },
      render: (row) => {
        switch (row.status) {
          case 'WaitingForAuditReductionCert': {
            return '-';
          }
          default: {
            return (
              <Button
                variant='contained'
                onClick={(e) => {
                  e.stopPropagation();
                  console.log('row', row);
                  r_setSelectedAction(row);
                  r_setViewOpen(true);
                }}
              >
                상세보기
              </Button>
            );
          }
        }
      },
    },
    {
      key: 'adminId',
      align: 'center',
      text: '담당자',
      style: {
        minWidth: 100,
      },
    },
  ]);
  const [r_page, r_totalPage, r_totalCount, r_onPageChange, r_setPage, r_setTotalPage, r_setTotalCount] = usePagination(
    0,
    9,
  );
  const [r_viewOpen, r_setViewOpen] = useState(false);
  const [r_selectedAction, r_setSelectedAction] = useState(null);

  useEffect(() => {
    if (initData) {
      console.log('initData', initData);
      switch (initData.mode) {
        case 'Issue': {
          setFormData({
            id: initData.project.id,
            title: initData.project.title,
            methodologyId: initData.project.methodology.id,
            area: Handler.ENUM.AREA(initData.project.methodology.area),
            subArea: Handler.ENUM.SUB_AREA(initData.project.methodology.subArea),
            companyName: initData.project.user.companyName,
            ceoName: initData.project.user.ceoName,
            email: initData.lastCreditDoc.assignorEmail,
            businessNumber: initData.project.user.businessNumber,
            businessFile: initData.project.user.businessFile,
            joinedValue: initData.project.user.joinedValue,
            address: initData.project.user.address,
            name: initData.lastCreditDoc.assignorName,
            department: initData.lastCreditDoc.assignorTeam,
            phone: initData.lastCreditDoc.assignorPhone,
            monitorDegree: initData.reductionCert.monitorDegree,
            startDate: initData.reductionCert.startDate,
            endDate: initData.reductionCert.endDate,
            amount: initData.amount,
          });
          setFormMeta({ ...formMeta });

          //발행의 경우 감축량 목록을 조회해야함.

          let reductionCertSeq = initData.reductionCert.seq;
          console.log('reductionCertSeq', reductionCertSeq);
          setReductionCertSeq(reductionCertSeq);

          break;
        }
        case 'Move': {
          setFormData({
            id: initData.project.id,
            title: initData.project.title,
            methodologyId: initData.project.methodology.id,
            area: Handler.ENUM.AREA(initData.project.methodology.area),
            subArea: Handler.ENUM.SUB_AREA(initData.project.methodology.subArea),
            companyName: initData.project.user.companyName,
            ceoName: initData.project.user.ceoName,
            email: initData.lastCreditDoc.assignorEmail,
            businessNumber: initData.project.user.businessNumber,
            businessFile: initData.project.user.businessFile,
            joinedValue: initData.project.user.joinedValue,
            address: initData.project.user.address,
            name: initData.lastCreditDoc.assignorName,
            department: initData.lastCreditDoc.assignorTeam,
            phone: initData.lastCreditDoc.assignorPhone,
            amount: initData.lastCreditDoc.amount,
            assigneeUserCompanyName: initData.lastCreditDoc.assigneeUserCompanyName,
            assigneeEmail: initData.lastCreditDoc.assigneeEmail,
          });
          setFormMeta({ ...formMeta });
          break;
        }
        case 'Offset': {
          setFormData({
            id: initData.project.id,
            title: initData.project.title,
            methodologyId: initData.project.methodology.id,
            area: Handler.ENUM.AREA(initData.project.methodology.area),
            subArea: Handler.ENUM.SUB_AREA(initData.project.methodology.subArea),
            companyName: initData.project.user.companyName,
            ceoName: initData.project.user.ceoName,
            email: initData.lastCreditDoc.assignorEmail,
            businessNumber: initData.project.user.businessNumber,
            businessFile: initData.project.user.businessFile,
            joinedValue: initData.project.user.joinedValue,
            address: initData.project.user.address,
            name: initData.lastCreditDoc.assignorName,
            department: initData.lastCreditDoc.assignorTeam,
            phone: initData.lastCreditDoc.assignorPhone,
            amount: initData.lastCreditDoc.amount,
          });
          setFormMeta({ ...formMeta });
          break;
        }
        case 'Retire': {
          setFormData({
            id: initData.project.id,
            title: initData.project.title,
            methodologyId: initData.project.methodology.id,
            area: Handler.ENUM.AREA(initData.project.methodology.area),
            subArea: Handler.ENUM.SUB_AREA(initData.project.methodology.subArea),
            companyName: initData.project.user.companyName,
            ceoName: initData.project.user.ceoName,
            email: initData.lastCreditDoc.assignorEmail,
            businessNumber: initData.project.user.businessNumber,
            businessFile: initData.project.user.businessFile,
            joinedValue: initData.project.user.joinedValue,
            address: initData.project.user.address,
            name: initData.lastCreditDoc.assignorName,
            department: initData.lastCreditDoc.assignorTeam,
            phone: initData.lastCreditDoc.assignorPhone,
            amount: initData.lastCreditDoc.amount,
          });
          setFormMeta({ ...formMeta });
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [initData]);

  const requestReductionCertNewList = useCallback(
    (init_page) => {
      if (reductionCertSeq) {
        API.ReductionCert.GetAction(reductionCertSeq, {
          page: init_page ? init_page : page,
          size: Handler.VARIABLES.PAGE_LIMIT,
        }).then(
          (res) => {
            console.log('requestReductionCertNewList res', res);
            let result = res.data;
            r_setList(result.content);
            r_setTotalPage(result.totalPages);
          },
          (err) => {
            console.log('err', err);
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          },
        );
      }
    },
    [r_page, reductionCertSeq],
  );
  //event

  const handleEvent = (type) => {
    switch (type) {
      case 'OPEN_METHODOLOGY': {
        history.push('/methodology/detail/' + initData.project.methodology.seq);
      }

      default: {
        console.log('handleEvent none');
      }
    }
  };

  const handleAction = (action) => {
    // project status에서 누른 NextAction
    console.log('handleAction action', action);
    console.log('handleAction initData', initData);
    setSelectedAction(initData.lastCreditDoc.actionSeq);
    setSelectedNextAction(action);
    setActionOpen(true);
  };

  const handleModalRequest = (body, data) => {
    setButtonDisabled(true)
    API.Credit.PostNextAction(slug, body).then(
      (res) => {
        let result = res.data;
        console.log('PostNextAction  result', result);
        closeActionModal();
        setButtonDisabled(false);
        refreshData();
        requestNewList(0);
        setPage(0);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const handleModalOffsetRequest = (body) => {
    console.log('handleModalOffsetRequest body', body);
    if (body) {
      setOffsetAdmin(body);
    }
  };

  const onDialogConfirm = () => {
    console.log('handleModalOffsetRequest body', offsetAdmin);
    console.log('offsetAdmin', offsetAdmin);

    if (!offsetAdmin || !offsetAdmin.amount) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '수량을 입력하세요.',
      });
      return;
    }

    API.Credit.PostDirectOffset(offsetAdmin)
      .then((res) => {
        let result = res.data;
        console.log('PostNextAction  result', result);
        closeOffsetModal();
        refreshData();
        requestNewList(0);
        setPage(0);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  useEffect(() => {
    if (offsetAdmin && offsetAdmin.amount) {
      updateDialog({
        type: 'info',
        open: true,
        title: '크레딧 철회',
        description: `${offsetAdmin.amount}VRC를 철회하시겠습니까?`,
        onConfirm: onDialogConfirm,
      });
    }
  }, [offsetAdmin]);

  //table event
  const tableCallback = (type, index) => {
    console.log('tableCallback type', type);
    console.log('tableCallback index', index);
  };

  const tableRowClick = (index) => {
    console.log('tableRowClick index', index);
    let target_seq = list[index].seq;
    console.log('tableRowClick target_seq', target_seq);
  };

  //server

  const refreshData = useCallback(() => {
    API.Credit.GetDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log('Project result', result);
        setInitData(result);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  }, [slug]);

  const requestNewList = useCallback(
    (init_page) => {
      API.Credit.GetAction(slug, {
        page: init_page ? init_page : page,
        size: Handler.VARIABLES.PAGE_LIMIT,
      }).then(
        (res) => {
          console.log('requestNewList res', res);
          let result = res.data;
          setList(result.content);
          setTotalPage(result.totalPages);
        },
        (err) => {
          console.log('err', err);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    },
    [page],
  );

  //hooks
  useEffect(() => {
    if (slug) {
      refreshData();
    }
  }, [slug, refreshData]);

  useEffect(() => {
    console.log('page effect', page);
    requestNewList();
  }, [page, requestNewList]);

  useEffect(() => {
    if (reductionCertSeq) {
      requestReductionCertNewList(r_page);
    }
  }, [reductionCertSeq, r_page, requestReductionCertNewList]);

  // useEffect(() => {
  //   console.log('page effect', r_page);
  //   if (r_page) {
  //     requestReductionCertNewList();
  //   }
  // }, [r_page])

  const closeModal = () => {
    setOpen(false);
    setSelectedNextAction(null);
  };

  const closeActionModal = () => {
    setActionOpen(false);
  };

  const closeOffsetModal = () => {
    setOffsetOpen(false);
  };

  const closeViewModal = () => {
    setViewOpen(false);
  };
  const r_closeViewModal = () => {
    r_setViewOpen(false);
  };

  if (!initData) {
    return null;
  }

  console.log('initData', initData);
  console.log('formData', formData);
  console.log('formMeta', formMeta);
  console.log('list', list);

  //rednerItem
  const renderCreditInfo = () => {
    switch (initData.mode) {
      case 'Issue': {
        return (
          <div className='flex'>
            <TextInput value={formData.monitorDegree} onChange={formChange} meta={formMeta.monitorDegree}></TextInput>
            <TextViewer value={formData.amount} meta={formMeta.amount}></TextViewer>
          </div>
        );
      }
      case 'Move': {
        return (
          <div className='flex'>
            <TextViewer value={formData.assigneeUserCompanyName} meta={formMeta.assigneeUserCompanyName}></TextViewer>
            <TextViewer value={formData.assigneeEmail} meta={formMeta.assigneeEmail}></TextViewer>
            <TextViewer value={formData.amount} meta={formMeta.amount}></TextViewer>
          </div>
        );
      }
      case 'Offset': {
        return (
          <div className='flex'>
            <TextViewer value={formData.amount} meta={formMeta.amount}></TextViewer>
          </div>
        );
      }
    }
  };

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>크레딧 {getModeText(initData.mode)} 상세</h3>
        </header>
        {initData.mode === 'Issue' && initData.project.reductionNow ? (
          <div className='button__box'>
            <Button variant='contained' onClick={() => setOffsetOpen(true)}>
              크레딧 철회
            </Button>
          </div>
        ) : (
          <div>크레딧수량: {initData.project.reductionNow}</div>
        )}
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        {!initData ? (
          <div className='loading__container'>
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <div className='flex'>
            <TextInput value={formData.id} onChange={formChange} meta={formMeta.id}></TextInput>
            <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
            <TextViewer
              value={formData.methodologyId}
              meta={formMeta.methodologyId}
              customButton={{
                label: '해당 방법론 보기',
                onClick: () => {
                  handleEvent('OPEN_METHODOLOGY');
                },
              }}
            ></TextViewer>
            {/* <TextInput value={formData.area} onChange={formChange} meta={formMeta.area}></TextInput>
            <TextInput value={formData.subArea} onChange={formChange} meta={formMeta.subArea}></TextInput> */}
            {initData.mode === 'Issue' && (
              <>
                <TextInput value={formData.startDate} onChange={formChange} meta={formMeta.startDate}></TextInput>
                <TextInput value={formData.endDate} onChange={formChange} meta={formMeta.endDate}></TextInput>
              </>
            )}

            <TextInput value={formData.companyName} onChange={formChange} meta={formMeta.companyName}></TextInput>
            <TextInput value={formData.ceoName} onChange={formChange} meta={formMeta.ceoName}></TextInput>
            <TextInput value={formData.joinedValue} onChange={formChange} meta={formMeta.joinedValue}></TextInput>
            <FileUploader
              value={formData.businessFile}
              onChange={formChange}
              meta={formMeta.businessFile}
            ></FileUploader>
            <TextInput value={formData.businessNumber} onChange={formChange} meta={formMeta.businessNumber}></TextInput>
            <Address value={formData.address} onChange={formChange} meta={formMeta.address}></Address>
            <TextInput value={formData.name} onChange={formChange} meta={formMeta.name}></TextInput>
            <TextInput value={formData.department} onChange={formChange} meta={formMeta.department}></TextInput>
            <TextInput value={formData.email} onChange={formChange} meta={formMeta.email}></TextInput>
            <TextInput value={formData.phone} onChange={formChange} meta={formMeta.phone}></TextInput>
          </div>
        )}
      </section>
      <section className='form__container' style={{ marginTop: 40 }}>
        <header>
          <h3>크레딧 {getModeText(initData.mode)} 정보</h3>
        </header>
        {!initData ? (
          <div className='loading__container'>
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          renderCreditInfo()
        )}
      </section>
      <section className='card__container' style={{ marginTop: 40 }}>
        <CardCreditStatus category={'credit'} mode={''} data={initData} onAction={handleAction}></CardCreditStatus>
      </section>
      <section className='table-header__container' style={{ marginTop: 40 }}>
        <header>
          <h3>이력 조회</h3>
        </header>
        <TableBox data={list} header={header} rowClick={tableRowClick} callback={tableCallback}></TableBox>
        <section className='paging__container' style={{ marginTop: 20, marginBottom: 0 }}>
          <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
        </section>
      </section>
      {initData.mode === 'Issue' && (
        <section className='table-header__container' style={{ marginTop: 40 }}>
          <header>
            <h3>감축량 이력 조회</h3>
          </header>
          <TableBox data={r_list} header={r_header} rowClick={tableRowClick} callback={tableCallback}></TableBox>
          <section className='paging__container' style={{ marginTop: 20, marginBottom: 0 }}>
            <Paging page={r_page} totalPage={r_totalPage} onChange={r_onPageChange}></Paging>
          </section>
        </section>
      )}

      <section className='card__container' style={{ marginTop: 40 }}>
        <header>
          <h3>운영자 메모</h3>
        </header>
        <section className='card__box'>
          <CardCreditMemo value={slug}></CardCreditMemo>
        </section>
      </section>
      <Modal open={actionOpen} onClose={closeActionModal}>
        <Box sx={style}>
          <ModalCreditAction
            mode={initData.mode}
            action={selectedAction}
            nextAction={selectedNextAction}
            requestAction={handleModalRequest}
            onClose={closeActionModal}
            disabled={buttonDisabled}
          ></ModalCreditAction>
        </Box>
      </Modal>
      <Modal open={offsetOpen} onClose={closeOffsetModal}>
        <Box sx={style}>
          <ModalCreditOffsetRequest
            credit={initData}
            requestAction={handleModalOffsetRequest}
            onClose={closeOffsetModal}
          ></ModalCreditOffsetRequest>
        </Box>
      </Modal>
      <Modal open={viewOpen} onClose={closeViewModal}>
        <Box sx={style}>
          <ModalCreditView mode={initData.mode} action={selectedAction} onClose={closeViewModal}></ModalCreditView>
        </Box>
      </Modal>
      <Modal open={r_viewOpen} onClose={r_closeViewModal}>
        <Box sx={style}>
          <ModalReductionView action={r_selectedAction} onClose={r_closeViewModal}></ModalReductionView>
        </Box>
      </Modal>
    </article>
  );
};
