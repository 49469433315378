import React, { useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import { DialogContext, SnackbarContext } from '../../contexts';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';

export const HomePage = (props) => {
  const { dialog, updateDialog } = useContext(DialogContext);
  const { updateSnackbar } = useContext(SnackbarContext);

  const onDialogConfirm = (e) => {
    console.log('onDialogConfirm');
  };

  return (
    <article className='basic-page'>
      <section className='example__container'>
        <header>
          <h3 className='text-primary'>POPLE 관리자페이지</h3>
        </header>
        {/* <ul className='button-list'>
          <Button
            variant='contained'
            onClick={() => {
              updateDialog({
                type: 'info',
                open: true,
                title: 'info title',
                description: 'info subTitle',
                onConfirm: onDialogConfirm,
              });
            }}
          >
            Info
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              updateDialog({
                type: 'error',
                open: true,
                title: 'error title',
                description: 'error subTitle',
                onConfirm: onDialogConfirm,
              });
            }}
          >
            Error
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              updateDialog({
                type: 'success',
                open: true,
                title: 'success title',
                description: 'success subTitle',
                onConfirm: onDialogConfirm,
              });
            }}
          >
            Success
          </Button>
        </ul> */}
      </section>
      {/* <section className='example__container'>
        <header>
          <h3>Snackbar</h3>
        </header>
        <ul className='button-list'>
          <Button
            variant='contained'
            onClick={() => {
              updateSnackbar({
                type: 'info',
                open: true,
                message: 'info text',
              });
            }}
          >
            Info
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              updateSnackbar({
                type: 'warning',
                open: true,
                message: 'warning text',
              });
            }}
          >
            Warning
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              updateSnackbar({
                type: 'success',
                open: true,
                message: 'success text',
              });
            }}
          >
            Success
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              updateSnackbar({
                type: 'error',
                open: true,
                message: 'error text',
              });
            }}
          >
            Error
          </Button>
        </ul>
      </section> */}
    </article>
  );
};
