import Button from '@mui/material/Button';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import { Check, DatePickerBox, FileUploader, SelectBox, TextInput } from '../../components';
import { SnackbarContext, UserContext } from '../../contexts';
import { useForm } from '../../hooks';

export const MethodologyAddPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { businessInfo, isSuper, userInfo } = useContext(UserContext);
  const [methodologyInfo, setMethodologyInfo] = useState(null);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      isView: true,
      version: '',
      isView: false,
      title: '',
      titleEn: '',
      standard: '',
      area: null,
      subArea: null,
      startDate: null,
      expiredYear: null,
      mainImage: '',
      introOne: '',
      introOneEn: '',
      introTwo: '',
      introTwoEn: '',
      attachedFile: '',
      terms: [],
      evidence: [],
      calculateFile: '',
      monitoringCalcFile: '',
    },
    {
      isView: {
        key: 'isView',
        label: '노출 여부',
      },
      version: {
        key: 'version',
        label: '버전',
        fullWidth: true,
        required: true,
      },
      title: {
        key: 'title',
        label: '방법론명',
        fullWidth: true,
        required: true,
      },
      titleEn: {
        key: 'titleEn',
        label: '방법론명(영문)',
        fullWidth: true,
        required: true,
      },
      standard: {
        key: 'standard',
        label: '방법론 기준',
        fullWidth: true,
        required: true,
        menus: [
          {
            value: 'CDM',
            label: 'CDM',
          },
          {
            value: 'Domestic',
            label: '국내 방법론',
          },
          {
            value: 'Oversea',
            label: '국외 방법론',
          },
        ],
      },
      area: {
        key: 'area',
        label: '방법론 분야',
        fullWidth: true,
        required: true,
        menus: [],
      },
      subArea: {
        key: 'subArea',
        label: '방법론 세부분야',
        fullWidth: true,
        required: true,
        menus: [],
      },
      startDate: {
        key: 'startDate',
        label: '유효기간 시작일',
        type: 'date',
        required: true,
        fullWidth: true,
      },
      expiredYear: {
        key: 'expiredYear',
        label: '유효기간(년수)',
        type: 'number',
        fullWidth: true,
        style: {},
        InputProps: {},
        required: true,
        regexp: Handler.REGEXP.MAX999,
      },
      mainImage: {
        required: true,
        type: 'image',
        key: 'mainImage',
        label: '메인 이미지',
        path: 'methodology/mainImage',
      },
      calculateFile: {
        required: true,
        type: 'file',
        key: 'calculateFile',
        label: '감축량 계산 파일',
        path: 'methodology/calculateFile',
      },
      monitoringCalcFile: {
        required: true,
        type: 'file',
        key: 'monitoringCalcFile',
        label: '모니터링 계산 파일',
        path: 'methodology/monitoringCalcFile',
      },
      introOne: {
        key: 'introOne',
        label: '2줄 소개 (첫째줄)',
        fullWidth: true,
        required: true,
      },
      introOneEn: {
        key: 'introOneEn',
        label: '2줄 소개 (첫째줄) (영문)',
        fullWidth: true,
        required: false,
      },
      introTwo: {
        key: 'introTwo',
        label: '2줄 소개 (둘째줄)',
        fullWidth: true,
        required: false,
      },
      introTwoEn: {
        key: 'introTwoEn',
        label: '2줄 소개 (둘째줄) (영문)',
        fullWidth: true,
        required: false,
      },
      attachedFile: {
        required: true,
        type: 'file',
        key: 'attachedFile',
        label: '첨부파일',
        path: 'methodology/attachedFile',
      },
      terms: {
        key: 'terms',
        mainLabel: '관련 약관',
        labels: [],
        values: [],
      },
      evidence: {
        type: 'evidence',
        key: 'evidence',
        mainLabel: '관련 증빙서류',
        labels: [],
        values: [],
      },
    },
  );

  useEffect(() => {
    API.Methodology.GetInfo().then(
      (res) => {
        console.log('Getinfo res', res);
        setMethodologyInfo(res.data);
      },
      (err) => {
        console.log('Getinfo err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  }, []);

  useEffect(() => {
    console.log('useEffect businessInfo', businessInfo);

    //사업유형 방법론 분야
    if (businessInfo) {
      let area_menus = [];
      Object.keys(businessInfo).forEach((key) => {
        console.log('key', key);
        let target = businessInfo[key];
        console.log('target', target);
        area_menus.push({
          value: key,
          label: Handler.ENUM.AREA(key),
        });
      });
      let new_meta = _.clone(formMeta);
      new_meta['area'].menus = area_menus;
      setFormMeta(new_meta);
    }
  }, [businessInfo]);

  useEffect(() => {
    //사업유형 방법론 세부분야

    console.log('area change', formData.area);
    if (formData.area) {
      console.log('area change businessInfo', businessInfo[formData.area]);
      let sub_area_list = businessInfo[formData.area];
      let sub_area_menus = [];
      for (let i in sub_area_list) {
        let target = sub_area_list[i];
        console.log('target', target);
        sub_area_menus.push({
          value: target,
          label: Handler.ENUM.SUB_AREA(target),
        });
      }
      let new_meta = _.clone(formMeta);
      new_meta['subArea'].menus = sub_area_menus;
      setFormMeta(new_meta);
      formChange(null, 'subArea');
    }
  }, [formData.area]);

  useEffect(() => {
    if (methodologyInfo) {
      console.log('use Effect methodologyInfo', methodologyInfo);

      //update form
      let new_data = _.clone(formData);
      new_data['terms'] = Handler.buildTermsCheck(methodologyInfo);
      new_data['evidence'] = Handler.buildEvidenceCheck(methodologyInfo);
      setFormData(new_data);

      let new_meta = _.clone(formMeta);
      new_meta['terms'].labels = Handler.buildTermsLabels(methodologyInfo);
      new_meta['terms'].values = Handler.buildTermsValues(methodologyInfo);
      new_meta['evidence'].labels = Handler.buildEvidenceLabels(methodologyInfo);
      new_meta['evidence'].values = Handler.buildEvidenceValues(methodologyInfo);
      setFormMeta(new_meta);
    }
  }, [methodologyInfo]);

  const requsetAdd = () => {
    let body = Handler.formToBody(formData, formMeta);
    console.log('body', body);

    if (body) {
      let db_body = {
        ...body,
        adminId: userInfo.id,
        terms: Handler.buildTermsValueByDB(formMeta['terms'].values, body.terms),
        evidence: Handler.buildEvidenceValueByDB(formMeta['evidence'].values, body.evidence),
      };
      console.log('db_body', db_body);
      API.Methodology.Post(db_body).then(
        (res) => {
          let result = res.data;
          console.log('Methodology post result', result);
          history.push('/methodology/detail/' + result.seq);
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };
  console.log('formData', formData);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>방법론 추가</h3>
        </header>
        <div className='button__box'>
          <Button variant='contained' onClick={requsetAdd}>
            추가
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          {isSuper && <Check value={formData.isView} onChange={formChange} meta={formMeta.isView}></Check>}
          <TextInput value={formData.version} onChange={formChange} meta={formMeta.version}></TextInput>
          <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
          <TextInput value={formData.titleEn} onChange={formChange} meta={formMeta.titleEn}></TextInput>
          <SelectBox value={formData.standard} onChange={formChange} meta={formMeta.standard}></SelectBox>
          <SelectBox value={formData.area} onChange={formChange} meta={formMeta.area}></SelectBox>
          <SelectBox value={formData.subArea} onChange={formChange} meta={formMeta.subArea}></SelectBox>
          <DatePickerBox value={formData.startDate} onChange={formChange} meta={formMeta.startDate}></DatePickerBox>
          <TextInput value={formData.expiredYear} onChange={formChange} meta={formMeta.expiredYear}></TextInput>
          <FileUploader value={formData.mainImage} onChange={formChange} meta={formMeta.mainImage}></FileUploader>
          <TextInput value={formData.introOne} onChange={formChange} meta={formMeta.introOne}></TextInput>
          <TextInput value={formData.introOneEn} onChange={formChange} meta={formMeta.introOneEn}></TextInput>
          <TextInput value={formData.introTwo} onChange={formChange} meta={formMeta.introTwo}></TextInput>
          <TextInput value={formData.introTwoEn} onChange={formChange} meta={formMeta.introTwoEn}></TextInput>
          <FileUploader value={formData.attachedFile} onChange={formChange} meta={formMeta.attachedFile}></FileUploader>
          <FileUploader
            value={formData.calculateFile}
            onChange={formChange}
            meta={formMeta.calculateFile}
          ></FileUploader>
          <FileUploader
            value={formData.monitoringCalcFile}
            onChange={formChange}
            meta={formMeta.monitoringCalcFile}
          ></FileUploader>
          <Check value={formData.terms} onChange={formChange} meta={formMeta.terms}></Check>
          <Check value={formData.evidence} onChange={formChange} meta={formMeta.evidence}></Check>
        </div>
      </section>
    </article>
  );
};
