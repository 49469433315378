import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TableBox, SearchFilter, Paging } from '../../components';
import { usePagination, useFilter } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';

const getModeText = (mode) => {
  switch (mode) {
    case 'issue': {
      return '발행 수량';
    }
    case 'move': {
      return '이전 수량';
    }
    case 'offset': {
      return '철회 수량';
    }
    case 'retire': {
      return '폐기 수량';
    }
    default: {
      return null;
    }
  }
};

export const CreditListPage = (props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const mode = location.pathname.split('/')[location.pathname.split('/').length - 1];
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState(null);
  const [header, setHeader] = useState([
    {
      key: 'seq',
      align: 'center',
      text: 'No',
      style: {
        minWidth: 100,
      },
    },
    {
      key: '',
      align: 'center',
      text: '사업 ID',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return value.project.id;
      },
    },
    {
      key: '',
      align: 'center',
      text: '사업명',
      style: {
        minWidth: 300,
      },
      render: (value) => {
        return (
          <div style={{ maxWidth: 360 }} className='ellipsis-text'>
            <span>{value.project.title}</span>
          </div>
        );
      },
    },
    {
      key: '',
      align: 'center',
      text: getModeText(mode) + ' (크레딧)',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.numberWithCommas(value.lastCreditDoc.actionSeq.amount);
      },
    },
    {
      key: '',
      align: 'center',
      text: '프로젝트 기업명',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return value.project.user.companyName;
      },
    },
    {
      key: '',
      align: 'center',
      text: '이메일',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return value.project.user.email;
      },
    },
    {
      key: '',
      align: 'center',
      text: '상태',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        switch (value.lastCreditDoc.actionSeq.status) {
          case 'WaitingForAuditCommittee':
          case 'WaitingForAuditZCCredit': {
            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontSize: 14 }}>
                  {Handler.ENUM.CREDIT_ACTION.STATUS(value.lastCreditDoc.actionSeq.status) +
                    '(' +
                    value.lastCreditDoc.degree +
                    ')'}
                </span>
                <span style={{ fontSize: 12, color: '#777' }}>{Handler.getYYYYMMDDByUnix(value.updatedAt)}</span>
              </div>
            );
          }
          default: {
            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontSize: 14 }}>
                  {Handler.ENUM.CREDIT_ACTION.STATUS(value.lastCreditDoc.actionSeq.status)}
                </span>
                <span style={{ fontSize: 12, color: '#777' }}>{Handler.getYYYYMMDDByUnix(value.updatedAt)}</span>
              </div>
            );
          }
        }
      },
    },
  ]);
  const [filter, onFilterChange, setFiler] = useFilter([
    {
      key: 'status',
      type: 'select',
      label: '문서 상태',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          label: '심사 대기',
          value: 'Waiting',
        },
        {
          label: '심사중',
          value: 'Audit',
        },
        {
          label: '보완요청',
          value: 'ImprovementRequest',
        },
        {
          label: '최종승인 / 착수승인',
          value: 'Approved',
        },
        {
          label: '최종반려 / 착수반려',
          value: 'Rejected',
        },
        {
          label: '신청취소 / 사업취소',
          value: 'Canceled',
        },
      ],
    },
    {
      key: 'searchType',
      type: 'select',
      label: '검색 종류',
      value: 'Title',
      menus: [
        {
          value: 'Name',
          label: '이름',
        },
        {
          value: 'Email',
          label: '이메일',
        },
        {
          value: 'Title',
          label: '사업명',
        },
        {
          value: 'ProjectId',
          label: '사업 ID',
        },
      ],
    },
    {
      key: 'searchValue',
      type: 'input',
      label: '검색 값',
      value: '',
      inputType: 'text',
    },
  ]);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  const tableCallback = (type, index) => {
    console.log('tableCallback type', type);
    console.log('tableCallback index', index);
  };

  useEffect(() => {
    if (!!mode && mode === 'move') {
      const move_header = [
        {
          key: '',
          align: 'center',
          text: '양도',
          style: {
            minWidth: 100,
          },
          render: (value) =>
            value.mode === 'Move' ? (
              <div>
                <p>{value.lastCreditDoc.assignorUserCompanyName}</p>
                <p>({value.lastCreditDoc.assignorEmail})</p>
              </div>
            ) : (
              ''
            ),
        },
        {
          key: '',
          align: 'center',
          text: '양수',
          style: {
            minWidth: 100,
          },
          render: (value) =>
            value.mode === 'Move' ? (
              <div>
                <p>{value.lastCreditDoc.assigneeUserCompanyName}</p>
                <p>({value.lastCreditDoc.assigneeEmail})</p>
              </div>
            ) : (
              ''
            ),
        },
        ...header,
      ];
      setHeader(move_header);
    }
  }, [mode]);

  const tableRowClick = (index) => {
    console.log('tableRowClick index', index);
    let target_seq = list[index].seq;
    history.push('/credit/detail/' + target_seq);
  };

  const onSearch = (body) => {
    console.log('onSearch', body);
    requestNewList(0);
  };

  const onReset = () => {
    setPage(0);
    requestNewList(0, true)
  }

  useEffect(() => {
    requestNewList();
    history.push(`?page=${page}`);
  }, [page]);

  useEffect(() => {
    const page = parseInt(params.get('page') || '0', 9);
    setPage(page);
  }, []);

  const requestNewList = (init_page, isReset = false) => {
    let body = isReset ? null : Handler.filterToBody(filter);
    API.Credit.Get({
      ...body,
      mode: Handler.ENUM.CREDIT.SMALL_TO_DB(mode),
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
    }).then(
      (res) => {
        const { content, totalPages } = res.data;
        const list = content.filter((item) => item.lastCreditDoc && item.lastCreditDoc.actionSeq);
        setList(list);
        setTotalPage(totalPages);
      }).catch(
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        }
      )
  };

  console.log('list', list);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>크레딧 {Handler.ENUM.CREDIT.SMALL_TO_TEXT(mode)} 목록</h3>
        </header>
      </section>
      <section className='filter__container'>
        <SearchFilter data={filter} onChange={onFilterChange} onSearch={onSearch} onReset={onReset}></SearchFilter>
      </section>
      <section className='table__container'>
        <TableBox data={list} header={header} callback={tableCallback} rowClick={tableRowClick}></TableBox>
      </section>
      <section className='paging__container'>
        <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
      </section>
    </article>
  );
};
