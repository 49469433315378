import React, { useState } from 'react';
import { TextInput, TextViewer, FileViewer, FileUploader } from '../../components';
import Handler from '../../Handler';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
// import { useForm } from '../../hooks';
import Button from '@mui/material/Button';

export const ModalCreditAction = (props) => {
  const { mode, action, nextAction, onClose, requestAction, disabled } = props;
  // const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
  //   {
  //     comment: '',
  //     commentFile: '',
  //   },
  //   {
  //     comment: {
  //       key: 'comment',
  //       type: 'text',
  //       multiline: true,
  //       fullWidth: true,
  //       label: '의견',
  //     },
  //     commentFile: {
  //       key: 'commentFile',
  //       label: '첨부 파일',
  //     },
  //   },
  // );

  const [comment, setComment] = useState('');
  const [commentFile, setCommentFile] = useState('');

  if (!action || !nextAction) {
    return null;
  }

  console.log('ModalProjectAction action', action);
  console.log('ModalProjectAction nextAction', nextAction);

  const renderInfo = () => {
    switch (mode) {
      case 'Issue': {
        return (
          <section className='section__container'>
            <header>
              <span>신청 정보</span>
            </header>
            <ul className='form-list'>
              <TextViewer
                value={action.projectTitle}
                meta={{
                  label: '사업명',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.projectId}
                meta={{
                  label: '사업 ID',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>

              <TextViewer
                value={Handler.numberWithCommas(action.amount)}
                meta={{
                  label: '발행수량',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={Handler.getYYYYMMDDByUnix(action.createdAt)}
                meta={{
                  label: '신청일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
        );
      }
      case 'Move': {
        return (
          <section className='section__container'>
            <header>
              <span>신청 정보</span>
            </header>
            <ul className='form-list'>
              <TextViewer
                value={action.projectTitle}
                meta={{
                  label: '사업명',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.projectId}
                meta={{
                  label: '사업 ID',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.assignorName}
                meta={{
                  label: '양도인',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.assigneeName}
                meta={{
                  label: '양수인',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={Handler.numberWithCommas(action.amount)}
                meta={{
                  label: '이전수량',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={Handler.getYYYYMMDDByUnix(action.createdAt)}
                meta={{
                  label: '신청일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
        );
      }
      case 'Offset': {
        return (
          <section className='section__container'>
            <header>
              <span>신청 정보</span>
            </header>
            <ul className='form-list'>
              <TextViewer
                value={action.projectTitle}
                meta={{
                  label: '사업명',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={action.projectId}
                meta={{
                  label: '사업 ID',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={Handler.numberWithCommas(action.amount)}
                meta={{
                  label: '철회 수량',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={Handler.getYYYYMMDDByUnix(action.createdAt)}
                meta={{
                  label: '신청일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
        );
      }
      default: {
        return null;
      }
    }
  };

  switch (nextAction) {
    case 'CancelRejected':
    case 'CancelApproved': {
      return (
        <article className='modal-action'>
          <header>
            <h3>{nextAction === 'CancelRejected' ? '심사 중단 취소' : '심사 중단 승인'}</h3>
            <IconButton style={{ marginRight: -10 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </header>
          {renderInfo()}
          <section className='section__container'>
            <header>
              <span>Comment (유저)</span>
            </header>
            <ul className='form-list'>
              <TextViewer
                value={Handler.ENUM.CREDIT_ACTION.STOP_REASON(action.stopReason)}
                meta={{
                  label: '구분',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextInput
                value={action.userComment}
                meta={{
                  key: 'content',
                  label: '사유',
                  type: 'text',
                  multiline: true,
                  fullWidth: true,
                  InputProps: {
                    readOnly: true,
                  },
                }}
              ></TextInput>
              {action.userCommentFile && (
                <FileViewer
                  value={action.userCommentFile}
                  meta={{
                    label: '첨부파일',
                  }}
                ></FileViewer>
              )}
              <TextViewer
                value={action.userCommentDate}
                meta={{
                  label: '작성일',
                  style: {},
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </ul>
          </section>
          <section className='section__container'>
            <header>
              <span>Comment (관리자)</span>
            </header>
            <ul className='form-list'>
              <TextInput
                value={comment}
                onChange={(value) => {
                  setComment(value);
                }}
                meta={{
                  key: 'comment',
                  type: 'text',
                  multiline: true,
                  fullWidth: true,
                  label: '의견',
                }}
              ></TextInput>
              <FileUploader
                value={commentFile}
                onChange={(value) => {
                  setCommentFile(value);
                }}
                meta={{
                  key: 'commentFile',
                  label: '첨부 파일',
                }}
              ></FileUploader>
            </ul>
          </section>

          <section className='button__container'>
            <Button style={{ marginRight: 20 }} variant='outlined' onClick={onClose}>
              취소
            </Button>
            <Button
              disabled={!comment || disabled}
              size='large'
              variant='contained'
              onClick={() => {
                requestAction({ comment: comment, commentFile: commentFile, nextAction: nextAction });
              }}
            >
              확인 및 보내기
            </Button>
          </section>
        </article>
      );
    }
    default: {
      return (
        <article className='modal-action'>
          <header>
            <h3>{Handler.ENUM.CREDIT_ACTION.NEXT(nextAction)}</h3>
            <IconButton style={{ marginRight: -10 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </header>
          {renderInfo()}
          <section className='section__container'>
            <header>
              <span>Comment (관리자)</span>
            </header>
            <ul className='form-list'>
              <TextInput
                value={comment}
                onChange={(value) => {
                  setComment(value);
                }}
                meta={{
                  key: 'comment',
                  type: 'text',
                  multiline: true,
                  fullWidth: true,
                  label: '의견',
                }}
              ></TextInput>
              <FileUploader
                value={commentFile}
                onChange={(value) => {
                  setCommentFile(value);
                }}
                meta={{
                  key: 'commentFile',
                  label: '첨부 파일',
                }}
              ></FileUploader>
            </ul>
          </section>

          <section className='button__container'>
            <Button style={{ marginRight: 20 }} variant='outlined' onClick={onClose}>
              취소
            </Button>
            <Button
              disabled={!comment || disabled}
              size='large'
              variant='contained'
              onClick={() => {
                requestAction({ comment: comment, commentFile: commentFile, nextAction: nextAction });
              }}
            >
              확인 및 보내기
            </Button>
          </section>
        </article>
      );
    }
  }
};
