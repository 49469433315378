import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { TableBox, SearchFilter, Paging } from '../../components';
import { usePagination, useFilter } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Button from '@mui/material/Button';
import Handler from '../../Handler';
import API from '../../api';

export const MarketplaceListPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState(null);
  const [header, setHeader] = useState([
    {
      key: 'seq',
      align: 'center',
      text: 'No',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'id',
      align: 'center',
      text: '사업ID',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'title',
      align: 'center',
      text: '사업명',
      style: {
        minWidth: 210,
      },
    },
    {
      key: 'salePrice',
      align: 'center',
      text: '판매가격(톤)',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.numberWithCommas(value);
      },
    },
    {
      key: 'reductionNow',
      align: 'center',
      text: '판매중 크레딧수량',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.numberWithCommas(value);
      },
    },
    {
      key: null,
      align: 'center',
      text: '기업명',
      style: {
        minWidth: 160,
      },
      render: (value) => {
        return value.user.companyName;
      },
    },
    {
      key: null,
      align: 'center',
      text: 'email',
      style: {
        minWidth: 160,
      },
      render: (value) => {
        return value.user.email;
      },
    },
    {
      key: 'startDate',
      align: 'center',
      text: '유효기간 시작일',
      style: {
        minWidth: 160,
      },
      render: (value) => {
        return value;
      },
    },
    {
      key: 'endDate',
      align: 'center',
      text: '유효기간 종료일',
      style: {
        minWidth: 160,
      },
      render: (value) => {
        return value;
      },
    },
    {
      key: 'tradeType',
      align: 'center',
      text: '선도거래 여부',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        console.log('render value', value);
        switch (value) {
          case 'Normal': {
            return '일반';
          }
          case 'ForwardEnterprise': {
            return '기업';
          }
          case 'ForwardBoth': {
            return '기업 +개인';
          }
          default: {
            return '';
          }
        }
      },
    },
    {
      key: 'saleStatus',
      align: 'center',
      text: '판매상태',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        console.log('render value', value);
        switch (value) {
          case 'Ready': {
            return '준비';
          }
          case 'OnSale': {
            return '판매중';
          }
          case 'Closed': {
            return '판매 종료';
          }
          case 'SoldOut': {
            return '판매 완료';
          }
          default: {
            return '';
          }
        }
      },
    },
    {
      key: 'isView',
      align: 'center',
      text: '노출 여부',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        console.log('render value', value);
        switch (value) {
          case true: {
            return '노출';
          }
          case false: {
            return '숨김';
          }

          default: {
            return '';
          }
        }
      },
    },
  ]);

  const [filter, onFilterChange, setFiler] = useFilter([
    {
      key: 'tradeType',
      type: 'select',
      label: '거래 타입',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: 'Normal',
          label: '일반 거래',
        },
        {
          value: 'ForwardEnterprise',
          label: '일반 + 선도 거래(기업)',
        },
        {
          value: 'ForwardBoth',
          label: '일반 + 선도 거래(기업,개인)',
        },
      ],
    },
    {
      key: 'saleStatus',
      type: 'select',
      label: '상태',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: 'Ready',
          label: '준비',
        },
        {
          value: 'OnSale',
          label: '판매중',
        },
        {
          value: 'Closed',
          label: '판매 종료',
        },
        {
          value: 'SoldOut',
          label: '판매 완료',
        },
      ],
    },
    {
      key: 'isView',
      type: 'select',
      label: '노출 여부',
      value: 'SELECT_ALL',
      menus: [
        {
          value: 'SELECT_ALL',
          label: '전체',
        },
        {
          value: true,
          label: '노출',
        },
        {
          value: false,
          label: '숨김',
        },
      ],
    },
    {
      key: 'searchType',
      type: 'select',
      label: '검색 종류',
      value: 'ProjectTitle',
      menus: [
        {
          value: 'ProjectTitle',
          label: '프로젝트명',
        },
        {
          value: 'ProjectId',
          label: '프로젝트 ID',
        },
        {
          value: 'CompanyName',
          label: '기업명',
        },
        {
          value: 'Email',
          label: '이메일',
        },
      ],
    },
    {
      key: 'searchValue',
      type: 'input',
      label: '검색 값',
      value: '',
      inputType: 'text',
    },
  ]);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  const tableCallback = (type, index) => {
    console.log('tableCallback type', type);
    console.log('tableCallback index', index);
  };

  const tableRowClick = (index) => {
    console.log('tableRowClick index', index);
    let target_seq = list[index].seq;
    console.log('tableRowClick target_seq', target_seq);
    history.push('/marketplace/detail/' + target_seq);
  };

  const onSearch = (body) => {
    console.log('onSearch', body);
    requestNewList(0);
  };

  useEffect(() => {
    console.log('page effect', page);
    requestNewList();
  }, [page]);

  const requestNewList = (init_page) => {
    let body = Handler.filterToBody(filter);
    console.log('page body', body);
    API.Marketplace.GetList({
      ...body,
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
    }).then(
      (res) => {
        console.log('res', res);
        let result = res.data;
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requsetAdd = () => {
    console.log('requsetAdd');
  };

  console.log('list', list);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>상품 목록</h3>
        </header>
      </section>
      <section className='filter__container'>
        <SearchFilter data={filter} onChange={onFilterChange} onSearch={onSearch}></SearchFilter>
      </section>
      <section className='table__container'>
        <TableBox data={list} header={header} rowClick={tableRowClick} callback={tableCallback}></TableBox>
      </section>
      <section className='paging__container'>
        <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
      </section>
    </article>
  );
};
