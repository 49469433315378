import React, { useEffect, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { TextInput, SelectBox, Check, ToastEditor, FileMultipleUploader, TextViewer } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext, DialogContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import { useHistory, useParams } from 'react-router-dom';

export const CsNoticeDetailPage = (props) => {
  const history = useHistory();
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const [formData, formMeta, formChange, setFormData] = useForm(
    {
      site: 'Credit',
      title: '',
      content: '',
      isTop: false,
      fileArray: [],
    },
    {
      site: {
        key: 'site',
        label: '사이트',
        fullWidth: true,
        menus: [
          {
            value: 'Credit',
            label: '인증시스템',
          },
          {
            value: 'Market',
            label: '마켓플레이스',
          },
        ],
      },
      isTop: {
        key: 'isTop',
        label: '상단 고정',
      },
      title: {
        key: 'title',
        label: '제목',
        fullWidth: true,
        required: true,
      },
      titleEn: {
        key: 'titleEn',
        label: '제목(영문)',
        fullWidth: true,
        required: true,
      },
      content: {
        key: 'content',
        label: '내용',
        required: true,
      },
      contentEn: {
        key: 'contentEn',
        label: '내용(영문)',
        required: true,
      },
      fileArray: {
        type: 'file',
        key: 'fileArray',
        label: '파일 첨부',
        path: '/notice/file',
        max: 1,
      },
      fileArrayEn: {
        type: 'file',
        key: 'fileArrayEn',
        label: '파일 첨부(영문)',
        path: '/notice/fileEn',
        max: 1,
      },
    },
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (slug) {
      refreshData();
    }
  }, [slug]);

  const refreshData = () => {
    API.CS.GetNoticeDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log('GetNoticeDetail result', result);
        setFormData({
          site: result.site,
          isTop: result.isTop,
          title: result.title,
          titleEn: result.titleEn,
          content: result.content,
          contentEn: result.contentEn,
          fileArray: result.files,
          fileArrayEn: result.filesEn,
        });
        setLoading(false);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
        setLoading(false);
      },
    );
  };

  const requsetSave = () => {
    let body = Handler.formToBody(formData, formMeta);
    console.log('body', body);

    if (body) {
      API.CS.PutNotice(slug, body).then(
        (res) => {
          let result = res.data;
          console.log('PostTerms post result', result);
          refreshData();
          updateSnackbar({
            type: 'success',
            open: true,
            message: '저장 성공',
          });
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  const requsetDelete = () => {
    console.log('requsetDelete');
    API.CS.DeleteNotice(slug).then(
      (res) => {
        let result = res.data;
        console.log('PostTerms post result', result);
        history.push('/cs/notice/list');
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };
  console.log('fromData', formData);

  if (loading) {
    return null;
  }

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>공지사항 상세</h3>
        </header>
        <div className='button__box'>
          <Button
            variant='contained'
            color='secondary'
            disabled={false}
            onClick={() => {
              updateDialog({
                type: 'error',
                open: true,
                title: '공지사항 삭제',
                description: '삭제 후 되돌릴 수 없습니다. 그래도 진행하시겠습니까?',
                onConfirm: requsetDelete,
              });
            }}
          >
            삭제
          </Button>
          {/* 수정,삭제는 동일한 유저아이디만 가능 */}
          <Button disabled={false} variant='contained' onClick={requsetSave}>
            저장
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <SelectBox value={formData.site} onChange={formChange} meta={formMeta.site}></SelectBox>
          <Check value={formData.isTop} onChange={formChange} meta={formMeta.isTop}></Check>
          <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
          <TextInput value={formData.titleEn} onChange={formChange} meta={formMeta.titleEn}></TextInput>
          <ToastEditor value={formData.content} onChange={formChange} meta={formMeta.content}></ToastEditor>
          <ToastEditor value={formData.contentEn} onChange={formChange} meta={formMeta.contentEn}></ToastEditor>
          <FileMultipleUploader
            value={formData.fileArray}
            onChange={formChange}
            meta={formMeta.fileArray}
          ></FileMultipleUploader>
          <FileMultipleUploader
            value={formData.fileArrayEn}
            onChange={formChange}
            meta={formMeta.fileArrayEn}
          ></FileMultipleUploader>
          <TextViewer
            value={formData.adminId ? formData.adminId : ''}
            meta={{
              label: '어드민 ID',
              render: (value) => {
                return value;
              },
            }}
          ></TextViewer>
        </div>
      </section>
    </article>
  );
};
