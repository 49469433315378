import React, { useEffect, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { TextInput, SelectBox, Check, ToastEditor, FileMultipleUploader, TextViewer } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext, UserContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

export const CsFaqAddPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { userInfo } = useContext(UserContext);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      site: 'Credit',
      category: '',
      title: '',
      content: '',
      fileArray: [],
    },
    {
      site: {
        key: 'site',
        label: '사이트',
        fullWidth: true,
        required: true,
        menus: [
          {
            value: 'Credit',
            label: '인증시스템',
          },
          {
            value: 'Market',
            label: '마켓플레이스',
          },
        ],
      },
      category: {
        key: 'category',
        label: '카테고리',
        fullWidth: true,
        required: true,
        menus: [],
      },
      title: {
        key: 'title',
        label: '제목',
        fullWidth: true,
        required: true,
      },
      content: {
        key: 'content',
        label: '내용',
        required: true,
        path: 'editor/faq',
      },
      fileArray: {
        type: 'file',
        key: 'fileArray',
        label: '파일 첨부',
        path: '/faq/file',
        max: 1,
      },
    },
  );
  const [categoryInfo, setCategoryInfo] = useState(null);

  useEffect(() => {
    API.CS.GetFaqInfo().then(
      (res) => {
        console.log('GetFaqInfo res', res);
        let result = res.data;
        setCategoryInfo(result);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  }, []);

  useEffect(() => {
    let current_site = formData.site;
    if (current_site && categoryInfo) {
      console.log('current_stie', current_site);
      let new_formMeta = _.clone(formMeta);
      console.log('new_formMeta', new_formMeta);
      let category_menus = Handler.buildCategoryMenus(categoryInfo, current_site, false);
      new_formMeta.category.menus = category_menus;
      setFormMeta(new_formMeta);
      let new_formData = _.clone(formData);
      if (category_menus.length > 0) {
        new_formData.category = category_menus[0].value;
      }
      setFormData(new_formData);
    }
  }, [formData.site, categoryInfo]);

  const requsetAdd = () => {
    let body = Handler.formToBody(formData, formMeta);
    console.log('body', body);

    if (body) {
      API.CS.PostFaq(body).then(
        (res) => {
          let result = res.data;
          console.log('PostTerms post result', result);
          updateSnackbar({
            type: 'success',
            open: true,
            message: 'FAQ가 추가되었습니다.',
          });
          history.push('/cs/faq/list');
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>FAQ 카테고리 추가</h3>
        </header>
        <div className='button__box'>
          <Button variant='contained' onClick={requsetAdd}>
            추가
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <SelectBox value={formData.site} onChange={formChange} meta={formMeta.site}></SelectBox>
          <SelectBox value={formData.category} onChange={formChange} meta={formMeta.category}></SelectBox>
          <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
          <ToastEditor value={formData.content} onChange={formChange} meta={formMeta.content}></ToastEditor>
          <FileMultipleUploader
            value={formData.fileArray}
            onChange={formChange}
            meta={formMeta.fileArray}
          ></FileMultipleUploader>
          <TextViewer
            value={userInfo ? userInfo.id : ''}
            meta={{
              label: '어드민 ID',
              render: (value) => {
                return value;
              },
            }}
          ></TextViewer>
        </div>
      </section>
    </article>
  );
};
