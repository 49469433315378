import React from 'react';
import Handler from '../../Handler';

export const ModalCertification = (props) => {
  const { data, onClose } = props;
  console.log('data', data);

  const renderNameBox = () => {
    if (data.CompanyName) {
      if (data.GiftUserName) {
        return (
          <h2>
            {data.GiftUserName} <span>(구매자명 : {data.Name})</span>
          </h2>
        );
      } else {
        return <h2>{data.CompanyName}</h2>;
      }
    } else if (data.GiftUserName) {
      return (
        <h2>
          {data.GiftUserName} <span>(구매자명 : {data.Name})</span>
        </h2>
      );
    } else {
      return <h2>{data.Name}</h2>;
    }
  };

  const renderDescName = () => {
    if (data.CompanyName) {
      return data.CompanyName;
    } else {
      return data.Name;
    }
  };
  if (!data) {
    return null;
  }

  return (
    <article className='certification-modal'>
      <header>
        <div className='ico logo-small'></div>
        <div
          className='ico close'
          onClick={() => {
            onClose();
          }}
        ></div>
      </header>
      <section className='number__box'>
        <span>{data.SN}</span>
        <span className='date'>{data.Date}</span>
      </section>
      <section className='img__box'>
        <div className='ico cert-img'></div>
      </section>
      <section className='title__box'>
        <h1>{data.Title}</h1>
      </section>
      <section className='amount__box'>
        <h2>{Handler.numberWithCommas(data.Amount)} 톤</h2>
      </section>
      <section className='name__box'>{renderNameBox()}</section>
      <article className='info__box'>
        <ul>
          {data.BusinessNumber && (
            <li>
              <header>
                <span>사업자등록번호</span>
              </header>
              <div className='data'>
                <span>{data.BusinessNumber}</span>
              </div>
            </li>
          )}
          {data.CompanyName && (
            <li>
              <header>
                <span>대표자명</span>
              </header>
              <div className='data'>
                <span>{data.Name}</span>
              </div>
            </li>
          )}
          <li className='project'>
            <header>
              <span>사업명</span>
            </header>
            <div className='data'>
              <span>{data.ProjectName}</span>
              <span className='id'>{data.ProjectId}</span>
            </div>
          </li>
          <li>
            <header>
              <span>구매사유</span>
            </header>
            <div className='data'>
              <span>{data.Reason}</span>
            </div>
          </li>
        </ul>
      </article>
      <section className='desc__box'>
        <span>
          온실가스 감축을 위해 {renderDescName()} 가(이) 기탁하신 기금으로
          <br />
          POPLE 레지스트리에 등록된 ZC 크레딧을 상기와 같이 상쇄하였음을 인증합니다.
        </span>
      </section>
      <section className='organizer__box'>
        <span>한국바이오소재패키징협회</span>
      </section>
      <section className='url__box'>
        <span>www.pople.kr</span>
      </section>
    </article>
  );
};
