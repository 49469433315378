import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../contexts';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';

export const MainHeader = (props) => {
  const history = useHistory();
  const { userInfo, updateAccessToken } = useContext(UserContext);
  return (
    <article className='main-header'>
      <section></section>
      <section className='option__box'>
        <div className='name'>
          <b>{userInfo && (userInfo.name || userInfo.id)}</b>
          <span> 님</span>
        </div>
        <IconButton
          onClick={() => {
            updateAccessToken('');
            history.push('/login');
          }}
        >
          <LogoutIcon />
        </IconButton>
      </section>
    </article>
  );
};
