import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { TableBox, Paging } from '../../components';
import { usePagination } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Button from '@mui/material/Button';
import Handler from '../../Handler';
import API from '../../api';

export const EuEtsListPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState(null);
  const [header, setHeader] = useState([
    {
      key: 'etsDate',
      align: 'center',
      text: '해당 월',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.dbDateToYYYYMM(value);
      },
    },
    {
      key: 'averagePrice',
      align: 'center',
      text: 'EU-ETS 평균가격($)',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.numberWithCommas(value);
      },
    },
    {
      key: 'yearAveragePrice',
      align: 'center',
      text: '지난 1년 평균가격($)',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        return Handler.numberWithCommas(value);
      },
    },
  ]);

  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  const tableCallback = (type, index) => {
    console.log('tableCallback type', type);
    console.log('tableCallback index', index);
  };

  const tableRowClick = (index) => {
    console.log('tableRowClick index', index);
    let target_seq = list[index].seq;
    console.log('tableRowClick target_seq', target_seq);
    history.push('/marketplace/eu_ets/detail/' + target_seq);
  };

  const onSearch = (body) => {
    console.log('onSearch', body);
    requestNewList(0);
  };

  useEffect(() => {
    console.log('page effect', page);
    requestNewList();
  }, [page]);

  const requestNewList = (init_page) => {
    API.Payment.GetEuetsList({
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
    }).then(
      (res) => {
        console.log('res', res);
        let result = res.data;
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requsetAdd = () => {
    console.log('requsetAdd');
    history.push('/marketplace/eu_ets/add');
  };

  console.log('list', list);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>EU-ETS 관리</h3>
        </header>
        <div className='button__box'>
          <Button variant='contained' onClick={requsetAdd}>
            추가
          </Button>
        </div>
      </section>
      <section className='table__container'>
        <TableBox data={list} header={header} rowClick={tableRowClick} callback={tableCallback}></TableBox>
      </section>
      <section className='paging__container'>
        <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
      </section>
    </article>
  );
};
