import React from 'react';
import Button from '@mui/material/Button';

export const TextViewer = (props) => {
  const { value, meta, customButton } = props;

  const renderStyle = () => {
    if (meta.style) {
      return { ...meta.style };
    } else {
      return {};
    }
  };

  return (
    <article className='form-text-viewer' style={renderStyle()}>
      <header>
        <span>
          {meta.label} {meta.required ? '*' : ''}
        </span>
      </header>
      <div className='viewer__box'>
        <span>{meta.render(value)}</span>
      </div>
      {customButton && (
        <div className='button__box'>
          <Button variant='contained' onClick={customButton.onClick}>
            {customButton.label}
          </Button>
        </div>
      )}
    </article>
  );
};
