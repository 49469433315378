import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const SignIn = (body) => {
  const url = BASE_URL + 'sign-in';
  return AxiosInstance.post(url, body);
};

const InitPassword = (body) => {
  const url = BASE_URL + 'init-password';
  return AxiosInstance.post(url, body);
};
const Admin = {
  SignIn: SignIn,
  InitPassword: InitPassword,
};

export default Admin;
