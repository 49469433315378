import Admin from './resources/Admin';
import Common from './resources/Common';
import AdminUser from './resources/AdminUser';
import Methodology from './resources/Methodology';
import Member from './resources/Member';
import Memo from './resources/Memo';
import CS from './resources/CS';
import Project from './resources/Project';
import ReductionCert from './resources/ReductionCert';
import Credit from './resources/Credit';
import Marketplace from './resources/Marketplace';
import Payment from './resources/Payment';
import MonitoringEvidence from './resources/MonitoringEvidence';
import Trading from './resources/Trading';
import Consultation from './resources/Consultation';
import Feedback from './resources/Feedback';
import Evidence from './resources/Evidence';
import Term from './resources/Term';

const API = {
  Common: Common,
  Admin: Admin,
  AdminUser: AdminUser,
  Methodology: Methodology,
  Member: Member,
  Memo: Memo,
  CS: CS,
  Evidence: Evidence,
  Project: Project,
  ReductionCert: ReductionCert,
  Credit: Credit,
  Marketplace: Marketplace,
  Payment: Payment,
  MonitoringEvidence: MonitoringEvidence,
  Trading: Trading,
  Consultation: Consultation,
  Feedback: Feedback,
  Term: Term,
};

export default API;
