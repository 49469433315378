import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export const TextInput = (props) => {
  const { value, onChange, meta, onEnter } = props;

  const handleChange = (e) => {
    onChange(e.target.value, meta.key);
  };

  const renderStyle = () => {
    if (meta && meta.style) {
      return { ...meta.style };
    } else {
      return {};
    }
  };

  const checkError = () => {
    if (meta.regexp && value) {
      console.log('meta.regexp', meta.regexp);
      let targetRegexp = new RegExp(meta.regexp, 'i');
      const isValidtion = targetRegexp.test(value);
      console.log('isValidtion', isValidtion);
      return !isValidtion;
    } else {
      return false;
    }
  };

  return (
    <article className='form-text-input' style={renderStyle()}>
      <header>
        <span>
          {meta.label}
          {meta.required ? '*' : ''}
        </span>
      </header>

      <TextField
        shrink="true"
        required={meta.required}
        fullWidth={meta.fullWidth}
        label={meta.label}
        value={value || ''}
        type={meta.type}
        disabled={meta.disabled}
        multiline={meta.multiline}
        placeholder={meta.placeholder}
        error={checkError()}
        helperText={checkError() ? meta.helperText : ''}
        variant='outlined'
        onChange={handleChange}
        InputProps={meta.InputProps ? { ...meta.InputProps } : {}}
        onKeyPress={(e) => {
          // console.log(`Pressed keyCode ${e.key}`);
          if (!meta.multiline && e.key === 'Enter') {
            // Do code here
            e.preventDefault();
            if (onEnter) {
              onEnter();
            }
          }
        }}
      ></TextField>
      {meta.customButton && (
        <div className='button__box'>
          <Button
            variant='contained'
            style={{ marginLeft: 20, minWidth: 140, height: '100%' }}
            onClick={() => {
              meta.customButton.onClick(value);
            }}
          >
            {meta.customButton.label}
          </Button>
        </div>
      )}
    </article>
  );
};
