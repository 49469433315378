import React, { useState, useEffect, useContext } from 'react';
import { NavigationContext, UserContext } from '../contexts';
import API from '../api';
import Handler from '../Handler';

const NavSubList = {
  AdminUser: [
    {
      text: '관리자 계정',
      path: '/admin_user/list',
      key: 'AdminUser',
    },
  ],
  Methodology: [
    {
      text: '방법론',
      path: '/methodology/list',
      key: 'Methodology',
    },
    {
      text: '약관',
      path: '/methodology/terms/list',
      key: 'Methodology',
    },
    {
      text: '증빙서류',
      path: '/methodology/evidence/list',
      key: 'Methodology',
    },
  ],
  Project: [
    {
      text: '프로젝트 목록',
      path: '/project/list',
      key: 'Project',
    },
  ],
  ReductionCert: [
    {
      text: '감축량 목록',
      path: '/reduction/list',
      key: 'ReductionCert',
    },
    {
      text: '모니터링 증빙서류',
      path: '/reduction/evidence/list',
      key: 'ReductionCert',
    },
  ],
  ZCCredit: [
    {
      text: '크레딧 발행 목록',
      path: '/credit/issue',
      key: 'ZCCredit',
    },
    {
      text: '크레딧 이전 목록',
      path: '/credit/move',
      key: 'ZCCredit',
    },
    {
      text: '크레딧 철회 목록',
      path: '/credit/offset',
      key: 'ZCCredit',
    },
    {
      text: '크레딧 폐기 목록',
      path: '/credit/retire',
      key: 'ZCCredit',
    },
  ],
  Payment: [
    {
      text: '결제 목록',
      path: '/payment/list',
      key: 'Payment',
    },
    {
      text: '출금 요청 목록',
      path: '/payment/withdraw_list',
      key: 'Payment',
    },
    {
      text: '수익 현황 목록',
      path: '/payment/revenue_list',
      key: 'Payment',
    },
  ],
  Trading: [
    {
      text: '상담 목록',
      path: '/trading/inquiry_list',
      key: 'Trading',
    },
    {
      text: '계약 목록',
      path: '/trading/contract_list',
      key: 'Trading',
    },
  ],
  MarketPlace: [
    {
      text: '상품 목록',
      path: '/marketplace/list',
      key: 'MarketPlace',
    },
    {
      text: 'EU-ETS 관리',
      path: '/marketplace/eu_ets/list',
      key: 'MarketPlace',
    },
    {
      text: '뉴스 목록',
      path: '/marketplace/news/list',
      key: 'MarketPlace',
    },
  ],
  Member: [
    {
      text: '기업회원',
      path: '/member/enterprise/list',
      key: 'Member',
    },
    {
      text: '개인회원',
      path: '/member/personal/list',
      key: 'Member',
    },
  ],
  CustomerService: [
    {
      text: '상담 문의',
      path: '/cs/inquiry/list',
      key: 'cs',
    },
    {
      text: '공지사항',
      path: '/cs/notice/list',
      key: 'cs',
    },
    {
      text: 'FAQ',
      path: '/cs/faq/list',
      key: 'cs',
    },
    {
      text: 'FAQ 카테고리',
      path: '/cs/faq_category/list',
      key: 'cs',
    },
    {
      text: 'Contact us',
      path: '/cs/contact_us/list',
      key: 'cs',
    },
  ],
  Consultation: [
    {
      text: '논평 목록',
      path: '/consultation/list',
      key: 'Consultation',
    },
  ],
};

const buildNavList = (user_info, authority_info) => {
  console.log('buildNavList user_info', user_info);
  console.log('buildNavList authority_info', authority_info);

  let user_authority = user_info.authority;
  let return_navList = [];
  for (let i in authority_info) {
    let target_authority = authority_info[i];
    // console.log('target_authority', target_authority);

    let exist = false;
    for (let j in user_authority) {
      let user_seq = Number(user_authority[j]);
      if (user_seq === target_authority.seq) {
        exist = true;
      }
    }
    if (exist) {
      return_navList.push({
        type: target_authority.type,
        text: Handler.ENUM.AUTHORITY.TYPE(target_authority.type),
        child: NavSubList[target_authority.type],
      });
    }
  }
  console.log('return_navList', return_navList);
  return return_navList;
};

export const NavigationProvider = (props) => {
  const { children } = props;
  const { userInfo, authorityInfo } = useContext(UserContext);
  const [navOpen, setNavOpen] = useState(null);
  const [navList, setNavList] = useState(null);

  useEffect(() => {
    console.log('NavigationProvider effect userInfo', userInfo);
    if (userInfo && authorityInfo) {
      if (typeof userInfo.authority === 'string') {
        const userAuthority = userInfo.authority;
        userInfo.authority = userAuthority.split(',');
        console.log(userInfo.authority);
      }
      let new_navList = buildNavList(userInfo, authorityInfo);
      setNavList(new_navList);
      setNavOpen(new Array(new_navList.length).fill(false));
    } else {
      setNavList(null);
    }
  }, [userInfo, authorityInfo]);

  const updateNavOpen = (value) => {
    setNavOpen(value);
  };

  return (
    <NavigationContext.Provider
      value={{
        navOpen: navOpen,
        updateNavOpen: updateNavOpen,
        navList: navList,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
