import React, { useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import { TextInput, SelectBox, Check } from '../../components';
import { useForm } from '../../hooks';
import { SnackbarContext, UserContext, DialogContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';

export const AdminUserDetailPage = (props) => {
  const history = useHistory();
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { authorityInfo } = useContext(UserContext);
  const { updateDialog } = useContext(DialogContext);

  const [formData, formMeta, formChange, setFormData, setFormMeta] = useForm(
    {
      type: 'Pople',
      name: '',
      id: '',
      email: '',
      phone: '',
      createdAt: '',
      deleteDate: '',
      authority: [],
    },
    {
      type: {
        key: 'type',
        label: '구분',
        fullWidth: true,
        menus: [
          {
            value: 'Pople',
            label: 'Pople',
          },
          {
            value: 'Committee',
            label: '위원회',
          },
        ],
      },
      name: {
        key: 'name',
        label: '이름',
        fullWidth: true,
        required: true,
      },
      id: {
        key: 'id',
        label: '아이디',
        fullWidth: true,
        disabled: true,
      },
      email: {
        key: 'email',
        label: '이메일',
        fullWidth: true,
        required: false,
      },
      phone: {
        key: 'phone',
        label: '핸드폰번호',
        fullWidth: true,
        required: true,
      },
      createdAt: {
        key: 'createdAt',
        label: '생성일',
        fullWidth: true,
        disabled: true,
      },
      deleteDate: {
        key: 'deleteDate',
        label: '삭제일',
        fullWidth: true,
        disabled: true,
      },
      authority: {
        key: 'authority',
        mainLabel: '권한 정보',
        labels: [],
        values: [],
      },
    },
  );

  useEffect(() => {
    if (slug && authorityInfo) {
      API.AdminUser.Get(slug).then(
        (res) => {
          console.log('AdminUser res', res);
          let result = res.data;

          setFormData({
            type: result.type,
            name: result.name,
            id: result.id,
            email: result.email,
            phone: result.phone,
            createdAt: Handler.getYYYYMMDDByUnix(result.createdAt),
            deleteDate: result.deleteDate,
            authority: Handler.buildAuthorityCheckByDB(authorityInfo, result.authority),
          });
        },
        (err) => {
          console.log('err', err);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    }
  }, [slug, authorityInfo]);

  useEffect(() => {
    if (authorityInfo) {
      let new_meta = _.clone(formMeta);
      new_meta.authority.labels = Handler.buildAuthorityLabels(authorityInfo);
      new_meta.authority.values = Handler.buildAuthorityValues(authorityInfo);
      setFormMeta(new_meta);
      formChange(Handler.buildAuthorityCheck(authorityInfo), 'authority');
    }
  }, [authorityInfo]);

  const requestSave = () => {
    console.log('requestSave');
    let body = Handler.formToBody(formData, formMeta);
    console.log(body.authority);
    if (body) {
      let db_body = {
        type: body.type,
        name: body.name,
        email: body.email,
        phone: body.phone,
        authority: Handler.buildAuthorityValueByDB(formMeta['authority'].values, body.authority),
      };
      console.log('db_body', db_body);
      API.AdminUser.Put(slug, db_body).then(
        (res) => {
          let result = res.data;
          console.log('AdminUser put result', result);
          updateSnackbar({
            type: 'success',
            open: true,
            message: '저장 성공',
          });
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인하세요',
      });
    }
  };

  const requsetDelete = () => {
    console.log('requsetDelete');
    API.AdminUser.Delete(slug).then(
      (res) => {
        updateSnackbar({
          type: 'success',
          open: true,
          message: '삭제 성공',
        });
        history.push('/admin_user/list');
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requestResetPassword = () => {
    console.log('requestResetPassword');
    API.AdminUser.ResetPassword(slug).then(
      (res) => {
        updateSnackbar({
          type: 'success',
          open: true,
          message: '비밀번호 재설정 성공',
        });
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h3>관리자 계정 상세</h3>
        </header>
        <div className='button__box'>
          <Button
            variant='contained'
            color='secondary'
            disabled={formData.deleteDate ? true : false}
            onClick={() => {
              updateDialog({
                type: 'error',
                open: true,
                title: '관리자 삭제',
                description: '삭제 후 되돌릴 수 없습니다. 그래도 진행하시겠습니까?',
                onConfirm: requsetDelete,
              });
            }}
          >
            삭제
          </Button>
          <Button
            disabled={formData.deleteDate ? true : false}
            variant='contained'
            onClick={() => {
              updateDialog({
                type: 'info',
                open: true,
                title: '비밀번호 초기화',
                description: '비밀번호는 Greenery!12 으로 초기화 됩니다.',
                onConfirm: requestResetPassword,
              });
            }}
          >
            비밀번호 재설정
          </Button>
          <Button disabled={formData.deleteDate ? true : false} variant='contained' onClick={requestSave}>
            저장
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <SelectBox value={formData.type} onChange={formChange} meta={formMeta.type}></SelectBox>
          <TextInput value={formData.name} onChange={formChange} meta={formMeta.name}></TextInput>
          <TextInput value={formData.id} onChange={formChange} meta={formMeta.id}></TextInput>
          <TextInput value={formData.email} onChange={formChange} meta={formMeta.email}></TextInput>
          <TextInput value={formData.phone} onChange={formChange} meta={formMeta.phone}></TextInput>
          <TextInput value={formData.createdAt} onChange={formChange} meta={formMeta.createdAt}></TextInput>
          <TextInput value={formData.deleteDate} onChange={formChange} meta={formMeta.deleteDate}></TextInput>
          <Check value={formData.authority} onChange={formChange} meta={formMeta.authority}></Check>
        </div>
      </section>
    </article>
  );
};
